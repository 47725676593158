// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import OwnerInput from './core/OwnerInput';
import PublishingPercentage from './percentage/PublishingPercentage';
import OwnerPublisherInput from './input/OwnerPublisherInput';

// =============================
// Component
// =============================

class PublishersPublishingOwnerInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.array, // eslint-disable-line
    error: PropTypes.array, // eslint-disable-line
    disabled: PropTypes.bool,
    territories: PropTypes.array, // eslint-disable-line
    rightstypes: PropTypes.array, // eslint-disable-line
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    error: [],
    disabled: false,
    territories: [],
    rightstypes: [],
    onChange: null,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { rightstypes, ...props } = this.props;
    return (
      <OwnerInput
        PercentageComponent={PublishingPercentage}
        OwnerInputComponent={OwnerPublisherInput}
        rightstypes={rightstypes.filter(v => v.type === 'publishing-publisher')}
        {...props}
      />
    );
  }
}

export default PublishersPublishingOwnerInput;
