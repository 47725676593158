// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import ImageLoader from '../../imageloader/ImageLoader';

import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import InputBase from '../../inputs/inputunderline/InputUnderline';
import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import FileUploadBase from '../../sidepanel/fileupload/FileUpload';
import GooglePlacesInputBase from '../../inputs/googleplacesinput/GooglePlacesInput';
import SelectInputBase from '../../inputs/selectinput/SelectInput';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import TranslateAreaBase from '../../../containers/inputs/translatearea/TranslateAreaContainer';
import TerritoriesSelectBase from '../../inputs/territoriesselect/TerritoriesSelect';

// Helpers
import isBlank from './../../../../helpers/isBlank';

// Schema
import PublisherSchema from './Publisher.schema';

// Styles
import { Div } from './../../../../themes/views';
import { PanelContent, SubCategory as SubCategoryBase } from './../../sidepanel/SidePanel.styles';
import { Counter, CounterDesc, Count } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const FileUpload = hideable(FileUploadBase);
const GooglePlacesInput = hideable(GooglePlacesInputBase);
const SelectInput = hideable(SelectInputBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const SubCategory = hideable(SubCategoryBase);
const TerritoriesSelect = hideable(TerritoriesSelectBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'contact', 'picture'];

export class Publisher extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      nbOwnerships: PropTypes.number,
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    mechanicalrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    performingrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    territories: PropTypes.array.isRequired, // eslint-disable-line
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {};

  onSelectChange = (name, value) => {
    if (value === '') {
      // We need to transform the empty value to null as the api
      // is not able to unset certain values with an empty string
      this.props.updateForm(name, null);
    } else {
      this.props.updateForm(name, value);
    }
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    const { data } = this.props.panel;

    return data && data.id ? data.publisher_name : t('components.panels.publisher.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { t } = this.props;
    const { nbOwnerships } = this.props.panel;

    if (nbOwnerships && nbOwnerships >= 0) {
      return (
        <Counter>
          <CounterDesc>{t('components.panels.publisher.works')}</CounterDesc>
          <Count>{nbOwnerships}</Count>
        </Counter>
      );
    }

    return null;
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    if (this.checkIfOwned()) return true;

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'picture':
        return !isBlank(get(panel, ['data', 'image', 'original']));
      case 'publishing_info':
        return [
          'performing_rights_society',
          'mechanical_rights_society',
          'ipi',
          'publisher_code',
          'address',
          'territories',
        ].some(this.isShown);
      case 'publisher':
        return ['publisher_name', 'publishing_info'].some(this.isShown);
      case 'contact':
        return ['contact_name', 'contact_email'].some(this.isShown);
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, locale, form, delaySave } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.publisher.false')}
            textTrue={t('components.panels.publisher.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned()
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );


      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isPublisherCollection = c.collections.indexOf('publisher') >= 0;
        return !section
          ? isPublisherCollection
          : isPublisherCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.publisher.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      mechanicalrightssocieties,
      performingrightssocieties,
      territories,
      updateForm,
      delaySave,
      deleteForm,
      t,
      customFieldsToRightSide,
      descriptionAutocomplete,
      user,
    } = this.props;

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          panel={panel}
          title={this.renderPanelTitle()}
          picture={this.renderPanelPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
        />

        <PanelContent>
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.publisher.boxPublisher')}
              isOpen
              show={this.isShown('publisher') && !this.isInHideList('publisher')}
            >
              <Input
                type="text"
                name="publisher_name"
                label={t('components.panels.publisher.labelName')}
                error={form.errors.publisher_name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.publisher_name}
                show={this.isShown('publisher_name') && !this.isInHideList('publisher_name')}
              />

              <SubCategory
                show={(
                  this.isShown('publishing_info')
                  && !this.isInHideList('publishing_info')
                )}
              >
                {t('components.panels.publisher.subCategoryPublishingInfo')}
              </SubCategory>
              <SelectInput
                type="single"
                name="performing_rights_society"
                label={t('components.panels.publisher.labelPerformingRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={performingrightssocieties.map(rmc => ({ id: rmc.id, name: rmc.name }))}
                data={form.values.performing_rights_society}
                error={form.errors.performing_rights_society}
                search
                show={(
                  this.isShown('performing_rights_society')
                  && !this.isInHideList('performing_rights_society')
                )}
              />
              <SelectInput
                type="single"
                name="mechanical_rights_society"
                label={t('components.panels.publisher.labelMechanicalRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={mechanicalrightssocieties.map(rmc => ({ id: rmc.id, name: rmc.name }))}
                data={form.values.mechanical_rights_society}
                error={form.errors.mechanical_rights_society}
                search
                show={(
                  this.isShown('mechanical_rights_society')
                  && !this.isInHideList('mechanical_rights_society')
                )}
              />
              <Input
                type="text"
                name="ipi"
                label={t('components.panels.publisher.labelIpi')}
                error={form.errors.ipi}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.ipi}
                show={this.isShown('ipi') && !this.isInHideList('ipi')}
              />
              <Input
                type="text"
                name="publisher_code"
                label={t('components.panels.publisher.labelPublisherCode')}
                error={form.errors.publisher_code}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.publisher_code}
                show={this.isShown('publisher_code') && !this.isInHideList('publisher_code')}
              />
              <GooglePlacesInput
                name="address"
                label={t('components.panels.publisher.labelAddress')}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                data={form.values.address}
                show={this.isShown('address') && !this.isInHideList('address')}
              />
              <TerritoriesSelect
                label={t('components.panels.publisher.labelHeadquarters')}
                name="territories"
                value={form.values.territories || []}
                territories={territories}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('territories') && !this.isInHideList('territories')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>

            <CollapsibleBox
              title={t('components.panels.publisher.boxContact')}
              mt="5px"
              show={this.isShown('contact') && !this.isInHideList('contact')}
            >
              <Input
                type="text"
                name="contact_name"
                label={t('components.panels.publisher.labelContactName')}
                error={form.errors.contact_name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.contact_name}
                show={this.isShown('contact_name') && !this.isInHideList('contact_name')}
              />
              <Input
                type="text"
                name="contact_email"
                label={t('components.panels.publisher.labelContactEmail')}
                error={form.errors.contact_email}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.contact_email}
                show={this.isShown('contact_email') && !this.isInHideList('contact_email')}
              />
              {this.renderCustomFields('contact')}
            </CollapsibleBox>

            <CollapsibleBox
              title={t('components.panels.publisher.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>

          <Div width="380px">
            <TranslateArea
              name="descriptions"
              label={t('components.panels.publisher.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
          </Div>
        </PanelContent>
      </PanelLoader>
    );
  }
}

export default formPanel(
  PublisherSchema,
  {
    performing_rights_society: prs => prs.id,
    mechanical_rights_society: mrs => mrs.id,
  },
  {
    nbOwnerships: 'count/ownerships',
  },
)(withI18n()(Publisher));
