// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';
import { resetTextarea } from './../';

export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Container = styled.div`
  ${props => props.theme.fontSize.s};
  background-color: ${props => props.theme.colors.background};
  margin-top: 10px;
  padding: 20px;
  position: relative;
  width: 100%;
`;

export const Languages = styled.div`
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 120px;
`;

export const DropDownArrow = styled(SVGInline)`
  align-items: center;
  display: flex;
  height: 8px;
  fill: ${props => props.theme.colors.text.greyDark};
  margin-left: auto;
  width: 8px;
`;

export const IconIsFilled = styled(SVGInline)`
  align-items: center;
  display: inline-flex;
  height: 11px;
  justify-content: center;
  margin-left: auto;
  stroke: #1dca96; /* TODO: Voir si on peut utiliser une couleur du thème */
  width: 14px;
`;

export const TextArea = styled(resetTextarea)`
  background-color: ${props => props.theme.colors.background};
  height: 60px;
  text-align: center;
  width: 100%;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
