// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { saveToCollection } from '../../../helpers/immutable';

// Constants
import { ENABLED_TYPES } from './../../../constants/EntityGrid';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import EntityGrid from './EntityGrid';
import CheckBoxHeadCell from './CheckBoxHeadCell';
import CustomizeViewDropdown from './CustomizeViewDropdown';
import CheckBox from './../../presentationals/checkbox/CheckBox';

// Styles
import { AddEntity, AddEntityIcon, MediaColumn } from './../../../themes/entityGrid';
import { Div, Flex } from './../../../themes/views';

// =============================
// Component
// =============================
const { ALWAYS } = ENABLED_TYPES;

/* eslint-disable react/prop-types */
class SearchEntityGrid extends Component {
  static propTypes = Object.assign({}, EntityGrid.propTypes, {
    value: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    sort: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    enabledColumns: PropTypes.arrayOf(PropTypes.string),
    onAddEntity: PropTypes.func,
    onUpdateEnabledColumns: PropTypes.func,
    renderBatchDropdownItems: PropTypes.func,
    t: PropTypes.func.isRequired,
  });

  static defaultProps = Object.assign({}, EntityGrid.defaultProps, {
    value: [],
    sort: {},
    enabledColumns: [],
    onAddEntity: undefined,
    onUpdateEnabledColumns: () => {},
    renderBatchDropdownItems: () => {},
  });

  state = {
    selection: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value === this.props.value) return;

    this.setState(() => ({
      selection: [],
    }));
  }

  handleChangeCheckbox = (index) => {
    const { selection } = this.state;

    const value = selection.includes(index) ? undefined : index;
    const nextSelection = saveToCollection(selection, index, value, v => v);

    this.setState(() => ({
      selection: nextSelection,
    }));
  };

  handleSelectAll = () => {
    const { value } = this.props;
    const { selection } = this.state;
    let nextSelection;

    if (selection.length === value.length) nextSelection = [];
    else nextSelection = Array.from(value, (_, i) => i);

    this.setState(() => ({ selection: nextSelection }));
  };

  handleGet = (options) => {
    const { onGet } = this.props;
    if (!options) return Promise.resolve();
    const { page: nextPage, sort } = options;

    return onGet(nextPage, !(sort || []).length ? {} : this.transformSort(sort));
  };

  transformSort = ([key, direction]) => {
    const { columns } = this.props;
    const column = columns.find(c => c.key === key);
    const nextKey = (column && column.sortKey) || key;
    const nextDirection = direction === 'ascending' ? 1 : -1;
    return { [nextKey]: nextDirection };
  };

  getSelectedItems = () => {
    const { value } = this.props;
    const { selection } = this.state;

    return selection.map(index => value[index]);
  };

  renderBatchDropdownItems = ({ onSubmit }) => {
    const { renderBatchDropdownItems } = this.props;
    return renderBatchDropdownItems(this.getSelectedItems(), { onSubmit });
  };

  renderCheckboxHead = () => {
    const { value } = this.props;
    const { selection } = this.state;
    return (
      <CheckBoxHeadCell
        checked={selection.length === value.length}
        active={!!selection.length}
        onSelectAll={this.handleSelectAll}
        renderItems={this.renderBatchDropdownItems}
      />
    );
  };

  renderCheckbox = (_, __, index) => {
    const { selection } = this.state;
    return (
      <CheckBox
        check={selection.includes(index)}
        onClick={() => this.handleChangeCheckbox(index)}
        key="0"
      />
    );
  };

  render() {
    const {
      columns,
      enabledColumns,
      onAddEntity,
      onUpdateEnabledColumns,
      sort,
      t,
      ...props
    } = this.props;

    const checkBoxColumn = {
      key: 'checkbox',
      enabled: ALWAYS,
      HeadColumnComponent: MediaColumn,
      ColumnComponent: MediaColumn,
      render: this.renderCheckbox,
      renderHead: this.renderCheckboxHead,

      width: '40px',
      minWidth: '40px',
    };

    const passedColumns = [
      checkBoxColumn,
      ...columns.filter(c => c.enabled === ALWAYS || enabledColumns.includes(c.key)),
    ];

    const sortKeys = Object.keys(sort);
    const defaultSort =
      sortKeys.length > 0
        ? [
          (columns.find(c => c.sortKey === sortKeys[0]) || {}).key || sortKeys[0],
          sort[sortKeys[0]] === 1 ? 'ascending' : 'descending',
        ]
        : [];
    return [
      <Flex align="center" key="0" position="relative">
        <Flex align="center" position="absolute" top="-40px" right="0px">
          {onAddEntity &&
          <Div mr="20px">
            <AddEntity onClick={onAddEntity} align="center">
              <span style={{ marginRight: 10 }}>{t('components.entityGrid.addNew')}</span><AddEntityIcon>+</AddEntityIcon>
            </AddEntity>
          </Div>
          }
          <CustomizeViewDropdown
            columns={columns}
            onUpdateEnabledColumns={onUpdateEnabledColumns}
            enabledColumns={enabledColumns}
          />
        </Flex>
      </Flex>,
      <EntityGrid
        {...props}
        columns={passedColumns}
        defaultSort={defaultSort}
        onGet={this.handleGet}
        overflow="auto"
        key="1"
      />,
    ];
  }
}

export default withI18n()(SearchEntityGrid);
