/**
 * @author Kevin Siow k.siow@passerelle.co
 * @version 1.0
 * @module NotificationList
 * Shows a notification message depending on state tree
 */

// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Notification from './Notification';

// Styles
import { Wrapper } from './NotificationsList.styles';

// =============================
// Component
// =============================

class NotificationsList extends Component {
  static propTypes = {
    // List of notifications
    notifications: PropTypes.array.isRequired, // eslint-disable-line
    // This component will clear notification on its own
    clearNotification: PropTypes.func.isRequired,
    playerShown: PropTypes.bool.isRequired,
  };

  state = {
    activeNotifications: [],
  };

  componentWillReceiveProps(nextProps) {
    // Everytime notifications is updated
    const { clearNotification } = this.props;
    const { notifications: newNotifications } = nextProps;
    const { activeNotifications } = this.state;

    // First we need to check if any notification has been cleared
    // To do so, we compare our active list of notifications
    // from component state to the new one from state tree
    activeNotifications.map((activeNotif, activeNotifIndex) => {
      const notifIndex = newNotifications.findIndex(newNotif =>
        newNotif.notificationKey === activeNotif.props.notificationKey,
      );

      // If we couldn't find our active notification
      // in the list of new notifications, it means
      // that it has been cleared
      if (notifIndex < 0) {
        // We can now delete the Notification component
        // that was cleared
        const newActiveNotifications = activeNotifications;
        newActiveNotifications.splice(activeNotifIndex, 1);

        // Update the state
        this.setState({
          activeNotifications: newActiveNotifications,
        });
      }

      return activeNotif;
    });


    // Now we need to check if there are any new notifications
    // to add to our active list.
    // To do so, we compare our new notifications list from
    // state tree to our active list from component state.
    newNotifications.map((newNotif) => {
      const notifIndex = activeNotifications.findIndex(activeNotif =>
        activeNotif.props.notificationKey === newNotif.notificationKey,
      );

      // If we couldn't find our notification
      // in the list of active notifications
      if (notifIndex < 0) {
        // Add Notification component to activeNotifications list
        this.setState({
          activeNotifications: [
            ...activeNotifications,
            <Notification
              type={newNotif.type}
              duration={newNotif.duration}
              notificationKey={newNotif.notificationKey}
              clearNotification={clearNotification}
              key={newNotif.notificationKey}
              message={newNotif.message}
              actions={newNotif.actions}
            />,
          ],
        });
      }

      return newNotif;
    });
  }

  render() {
    const { activeNotifications } = this.state;
    const { playerShown } = this.props;

    return (
      <Wrapper player={playerShown}>
        {activeNotifications}
      </Wrapper>
    );
  }
}

export default NotificationsList;
