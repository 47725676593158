import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const artistLabels = {
  full_name: 'Full Name',
  artist_ref: 'Artist Ref',
  ipi: 'IPI',
  ipn: 'IPN',
  mechanical_rights_society: 'Mechanical rights society',
  neighboring_rights_society: 'Neighboring rights society',
  performing_rights_society: 'Performing rights society',
  artist_email: 'Email',
  artist_phone: 'Artist phone',
  artist_address: 'Address',
};

const ArtistSchema = {
  full_name: Joi.string().label(artistLabels.full_name).required(),
  aliases: Joi.array().items(Joi.string()),
  artist_ref: Joi.string().label(artistLabels.artist_ref).empty(''),
  ipi: Joi.string().label(artistLabels.ipi).empty(''),
  ipn: Joi.string().label(artistLabels.ipn).empty(''),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string(),
    value: Joi.string(),
  })),
  mechanical_rights_society: Joi.string().label(artistLabels.mechanical_rights_society).allow(null),
  neighboring_rights_society:
    Joi.string().label(artistLabels.neighboring_rights_society).allow(null),
  performing_rights_society: Joi.string().label(artistLabels.performing_rights_society).allow(null),
  artist_email: Joi.string().label(artistLabels.artist_email).email({ minDomainAtoms: 2 }).min(6)
    .empty(''),
  artist_phone: Joi.string().label(artistLabels.artist_phone).empty(''),
  artist_address: Joi.string().label(artistLabels.artist_address).empty(''),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const artistCustomFields = getFieldsByCollection('artist', validation_fields);
  if (!validation_fields || isEmpty(artistCustomFields)) { // eslint-disable-line camelcase
    return ArtistSchema;
  }

  return createCustomSchema(artistCustomFields, custom_fields, {
    defaultSchema: ArtistSchema,
    labels: artistLabels,
  });
};
