// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

// Helpers
import { getViewProps } from './../../../../helpers/helpers';

// HOC
import withI18n from '../../../hoc/WithI18n';

// Styles
import {
  Container,
  ItemsContainer,
  IconCheck,
  IconMinus,
  IconSearch,
  IconDot,
  Input,
  SearchRow,
  Text,
} from './DropdownCheckbox.styles';
import { Item, OutlistItem } from './../DropdownList.styles';

// Assets
import iconCheck from './../../../../assets/images/checkmark.svg';
import iconDot from './../../../../assets/images/dot.svg';
import iconMinus from './../../../../assets/images/minus.svg';
import iconSearch from './../../../../assets/images/icon-search.svg';

// =============================
// Component
// =============================

class DropdownCheckbox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.number.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any.isRequired,
      }),
    ),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    height: PropTypes.string,
    search: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectAllText: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    values: [],
    height: '200px',
    search: true,
    selectAll: true,
    selectAllText: 'Select all',
    onChange: null,
    onFocus: null,
    onBlur: null,
  };

  state = {
    filter: '',
  };

  onFilterItems = ({ target }) => {
    this.setState({
      filter: target.value,
    });
  };

  onToggleAll = () => {
    const { onChange, values, data, name } = this.props;

    if (onChange) {
      if (values.length === data.length && data.length > 0) {
        return onChange(name, []);
      }

      return onChange(name, data.map(item => item.value));
    }

    return null;
  };

  onToggleItem = (value) => {
    const { onChange, values, name } = this.props;

    if (onChange) {
      const nextValues = values.filter(v => v.status === 2).map(v => v.value);
      const itemState = find(values, e => e.value === value);

      if (!itemState) {
        nextValues.push(value);
      } else if (itemState && itemState.status === 1) {
        nextValues.push(value);
      } else {
        nextValues.splice(nextValues.indexOf(value), 1);
      }

      onChange(name, nextValues);
    }
  };

  renderItems = () => {
    const { filter } = this.state;
    let items = this.props.data;

    if (filter) {
      items = items.filter(item => item.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }

    const itemsToRender = [];
    const selectedItems = [];

    items.forEach((item) => {
      const itemState = find(this.props.values, e => e.value === item.value);

      let itemIcon = <IconCheck svg={iconCheck} height="13px" width="13px" />;
      if (!itemState) itemIcon = <IconDot svg={iconDot} height="12px" width="12px" />;
      if (itemState && itemState.status === 1) {
        itemIcon = <IconMinus svg={iconMinus} height="13px" width="13px" />;
      }

      if (itemState && itemState.status > 0) {
        selectedItems.push(
          <Item
            key={item.value}
            height="40px"
            padding="5px 20px"
            onClick={() => {
              this.onToggleItem(item.value);
            }}
            tabIndex="0"
          >
            {itemIcon}
            <Text>{item.label}</Text>
          </Item>,
        );
      } else {
        itemsToRender.push(
          <Item
            key={item.value}
            height="40px"
            padding="5px 20px"
            onClick={() => {
              this.onToggleItem(item.value);
            }}
            tabIndex="0"
          >
            {itemIcon}
            <Text>{item.label}</Text>
          </Item>,
        );
      }
    });

    return selectedItems.concat(itemsToRender);
  };

  render() {
    const {
      data,
      search,
      selectAll,
      selectAllText,
      values,
      onChange,
      onFocus,
      onBlur,
      t,
    } = this.props;
    const items = this.renderItems();
    const allItemChecked = values.length === data.length;
    const noItemChecked = values.length === 0 && data.length > 0;

    let icon = <IconMinus svg={iconMinus} height="13px" width="13px" />;
    if (allItemChecked) icon = <IconCheck svg={iconCheck} height="13px" width="13px" />;
    else if (noItemChecked) icon = <IconDot svg={iconDot} height="12px" width="12px" />;

    return (
      <Container {...getViewProps(this.props)}>
        {search && (
          <SearchRow>
            <OutlistItem inactive padding="0 20px">
              <IconSearch svg={iconSearch} height="100%" width="100%" />
              <Input
                type="text"
                value={this.state.filter}
                onChange={this.onFilterItems}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={t('components.dropdownList.searchPlaceholder')}
              />
            </OutlistItem>
          </SearchRow>
        )}

        <ItemsContainer height={this.props.height} withSearch={search}>
          {selectAll &&
            items.length > 0 &&
            onChange &&
            !this.state.filter && (
              <Item onClick={() => this.onToggleAll()} height="40px" padding="0 20px">
                {icon}
                {selectAllText}
              </Item>
            )}

          {items}

          {items.length === 0 && (
            <Item height="40px" inactive padding="0 20px">
              {t('components.dropdownList.noResult')}
            </Item>
          )}
        </ItemsContainer>
      </Container>
    );
  }
}

export default withI18n()(DropdownCheckbox);
