// =============================
// Imports
// =============================

// Constants
import * as types from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * Reinitialize the player state
 */
export function reinitializePlayer() {
  return {
    type: types.REINITIALIZE_PLAYER,
  };
}

/**
 * Set state of the player
 *
 * @param {string} state - New state
 */
export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: state,
  };
}

/**
 * Show / hide the player
 *
 * @param {bool} show - True to show player
 */
export function showPlayer(show) {
  return {
    type: types.SHOW_PLAYER,
    payload: show,
  };
}

/**
 * Toggle the state of MusicItemDetails within Player
 *
 * @param {bool} state - True for shown details
 */
export function togglePlayerDetailsState(state) {
  return {
    type: types.TOGGLE_PLAYER_DETAILS_STATE,
    payload: state,
  };
}

/**
 * Set the get current time function from howler instance
 *
 * @param {func} getCurrentTime - The get current time function
 */
export function exposeGetCurrentTime(getCurrentTime) {
  return {
    type: types.EXPOSE_GET_CURRENT_TIME,
    payload: getCurrentTime,
  };
}

/**
 * Set current track
 *
 * @param {number} trackDatas - Track datas
 */
export function setCurrentTrack(trackDatas) {
  return {
    type: types.SET_CURRENT_TRACK,
    payload: trackDatas,
  };
}

/**
 * Set current tracklist
 *
 * @param {number} tracklistId - Id of the tracklist
 * @param {object} tracklistDatas - Tracks in tracklist
 */
export function setCurrentTracklist(tracklistId, tracklistDatas) {
  return {
    type: types.SET_CURRENT_TRACKLIST,
    payload: {
      id: tracklistId,
      tracklist: tracklistDatas,
    },
  };
}

/**
 * Fulfill a user request
 *
 * @param {string} req - Request to be fulfilled
 */
export function fulfillRequest(req) {
  return {
    type: types.FULFILL_REQUEST,
    payload: req,
  };
}

/**
 * Request to load a track
 *
 * @param {number} trackDatas - Track Datas
 * @param {string} tracklistId - Id of the tracklist of track
 * @param {object} tracklistDatas - Tracks in tracklist
 */
export function load(trackDatas, tracklistId = null, tracklistDatas = null) {
  return {
    type: types.REQUEST_LOAD_TRACK,
    payload: {
      trackDatas,
      tracklistId,
      tracklistDatas,
    },
  };
}

/**
 * Play track
 */
export function play() {
  return {
    type: types.REQUEST_PLAY,
  };
}

/**
 * Pause track
 */
export function pause() {
  return {
    type: types.REQUEST_PAUSE,
  };
}

/**
 * Go to previous track in list of tracks
 */
export function goToPrevTrack() {
  return {
    type: types.REQUEST_GO_TO_PREV_TRACK,
  };
}

/**
 * Go to next track in list of tracks
 */
export function goToNextTrack() {
  return {
    type: types.REQUEST_GO_TO_NEXT_TRACK,
  };
}

/**
 * Seek in track
 *
 * @param {number} st - Seeking time in seconds
 */
export function seek(st) {
  return {
    type: types.REQUEST_SET_SEEKING_TIME,
    payload: st,
  };
}
