/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import axios from './../helpers/axios';

// Config
import env from './../../config/private/environment';

// Constants
import {
  GET_OPTIONS_LOADING,
  SET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export const optionsKeys = [
  'languages',
  'territories',
  'albumtypes',
  'tonalitykeys',
  'trackversions',
  'tempos',
  'rightstypes',
  'mechanicalrightssocieties',
  'neighboringrightssocieties',
  'performingrightssocieties',
  'tags',
];

/**
 * Get all options from api
 */
export function getOptions() {
  return (dispatch) => {
    dispatch({
      type: GET_OPTIONS_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    };

    const url = `${env.apiUrl}/public/options`;

    return Promise
      .all(optionsKeys.map(key => axios.get(`${url}/${key}`, config)))
      .then((res) => {
        const data = {};

        res.forEach((v, i) => { data[optionsKeys[i]] = v.data; });

        dispatch({
          type: SET_OPTIONS,
          payload: data,
        });

        return dispatch({
          type: GET_OPTIONS_SUCCESS,
        });
      })
      // The only error possible is a invalid_token
      // Since we are going through the axios helper, user will be redirected to dashboard
      .catch(() => dispatch({
        type: GET_OPTIONS_FAILURE,
      }));
  };
}
