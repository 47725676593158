/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

export const PoweredByGoogleLogo = styled.div`
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 120px 14px;
  display: block;
  height: 40px;
  text-align: right;
  width: 100%;
`;
