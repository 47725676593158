// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generate as randomString } from 'randomstring';

// Helpers
import { enableGlobalScroll, disableGlobalScroll } from './../../../helpers/helpers';

// Styles
import { BlackVeil, ExtraLayout, ExtraBlackVeil, Container } from './SidePanel.styles';

// =============================
// Component
// =============================

function renderExtraLayout(panels) {
  const extraLayouts = [];

  // Show a maximum of 4 extra layouts
  for (let i = 1, len = panels.length < 5 ? panels.length : 5; i < len; i += 1) {
    extraLayouts.push(
      <ExtraLayout key={randomString()}>
        <ExtraBlackVeil />
      </ExtraLayout>,
    );
  }

  return extraLayouts;
}

class SidePanel extends Component {
  componentWillMount() {
    const { isOpened } = this.props;
    if (isOpened) disableGlobalScroll();
  }

  componentWillReceiveProps(nextProps) {
    const { isOpened } = nextProps;
    if (isOpened) disableGlobalScroll();
    else enableGlobalScroll();
  }

  componentWillUnmount() {
    enableGlobalScroll();
  }

  render() {
    const { isOpened, close, remove, open, CurrentPanel, panels } = this.props;
    const panel = panels.length ? panels[0] : null;

    return (
      <div>
        <BlackVeil isOpened={isOpened} onClick={() => close(panel.uuid)} />
        <div>{renderExtraLayout(panels)}</div>
        <Container isOpened={isOpened}>
          {CurrentPanel && (
            <CurrentPanel
              closePanel={panels.length === 1 ? close : remove}
              openPanel={open}
              panel={panel}
            />
          )}
        </Container>
      </div>
    );
  }
}

SidePanel.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  CurrentPanel: PropTypes.func,
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  open: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

SidePanel.defaultProps = {
  CurrentPanel: null,
};

export default SidePanel;
