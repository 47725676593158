
require('dotenv').config();

function getBoolEnvVar(name) {
  return !!(process.env[name] && process.env[name] === 'true');
}

function getStringEnvVar(name, defaultVal) {
  return process.env[name] || defaultVal;
}

function getIntEnvVar(name, defaultVal) {
  return process.env[name] ? parseInt(process.env[name], 10) : defaultVal;
}

export default {
  // The host on which the server should run (Private)
  host: getStringEnvVar('HOST', 'localhost'),
  // The port on which the server should run (Private)
  port: getIntEnvVar('PORT', 1337),
  // The port on which the client bundle development server should run (Private)
  clientDevServerPort: getIntEnvVar('CLIENT_DEVSERVER_PORT', 7331),
  // The bmat website title (Public)
  bmatWebsiteTitle: getStringEnvVar('BMAT_WEBSITE_TITLE', 'Meta'),
  // App env (Public)
  appEnv: getStringEnvVar('APP_ENV', 'local'),
  // Api url (Public)
  apiUrl: getStringEnvVar('API_URL', 'https://preprod-api.mewo.io'),
  // Bmat Dashboard url (Public)
  bmatDashboardUrl: getStringEnvVar('BMAT_DASHBOARD_URL', 'https://preprod-cms.bmat.com'),
  // Google API key (Public)
  googleApiKey: getStringEnvVar('GOOGLE_API_KEY', 'xxx'),
  // Node env (Public)
  nodeEnv: getStringEnvVar('NODE_ENV', 'development'),
  // Sentry dsn
  sentryDsn: getStringEnvVar('SENTRY_DSN', ''),
  // Force https redirection
  isHttps: getBoolEnvVar('IS_HTTPS'),
  bmatAuthClientId: getStringEnvVar('BMAT_AUTH_CLIENT_ID'),
  bmatAuthDomain: getStringEnvVar('BMAT_AUTH_DOMAIN'),
  bmatAuthRedirectUri: getStringEnvVar('BMAT_AUTH_REDIRECT_URI'),
  bmatAuthScope: getStringEnvVar('BMAT_AUTH_SCOPE'),
};
