// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { load, play, pause, showPlayer } from '../../../../actions/PlayerActions';

// Components
import MusicItemControls from '../../../presentationals/musicitem/controls/MusicItemControls';

// =============================
// Component
// =============================

function mapStateToProps({ player }) {
  return {
    playerState: player.playerState,
    currentTrack: player.currentTrack,
    currentTracklistId: player.currentTracklistId,
  };
}

export default connect(mapStateToProps, { load, play, pause, showPlayer })(MusicItemControls);
