/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// Components
import InputBase from '../input/Input';

export const Input = styled(InputBase)`
  flex: 1 0 auto;
`;
