// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';
import withConfirmation from './../../../hoc/WithConfirmation';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import ImageLoader from '../../imageloader/ImageLoader';

import InputBase from '../../inputs/inputunderline/InputUnderline';
import ChipInputBase from '../../inputs/chipinput/ChipInput';
import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import FileUploadBase from '../../sidepanel/fileupload/FileUpload';
import GooglePlacesInputBase from '../../inputs/googleplacesinput/GooglePlacesInput';
import SelectInputBase from '../../inputs/selectinput/SelectInput';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import TranslateAreaBase from '../../../containers/inputs/translatearea/TranslateAreaContainer';

// Helpers
import isBlank from './../../../../helpers/isBlank';

// Schema
import ArtistSchema from './Artist.schema';

// Styles
import { Div } from './../../../../themes/views';
import { PanelContent, SubCategory as SubCategoryBase } from './../../sidepanel/SidePanel.styles';
import { Counter, CounterDesc, Count } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const ChipInput = hideable(ChipInputBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const FileUpload = hideable(FileUploadBase);
const GooglePlacesInput = hideable(GooglePlacesInputBase);
const SelectInput = hideable(SelectInputBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);
const SubCategory = hideable(SubCategoryBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'rights', 'contact', 'picture'];

const yleTenant = '5f3551ff3142f200334ff41f';

export class Artist extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      nbOwnerships: PropTypes.number,
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    mechanicalrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    neighboringrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    performingrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    syncArtist: PropTypes.func.isRequired,
    changed: PropTypes.bool.isRequired,
  };

  state = {};

  saveWithConfirm = () => {
    const { t, delaySave, onConfirm } = this.props;
    return onConfirm(t('components.panels.artist.updateConfirmation.message'), {
      title: t('components.panels.artist.updateConfirmation.title').toUpperCase(),
      submit: t('components.panels.artist.updateConfirmation.submit'),
    }).then((confirm) => {
      delaySave(!confirm);
    });
  };

  onSelectChange = (name, value) => {
    if (value === '') {
      this.props.updateForm(name, null);
    } else {
      this.props.updateForm(name, value);
    }
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  renderArtistName = () => {
    const { t } = this.props;
    const { data } = this.props.panel;
    let panelTitle = t('components.panels.artist.new');

    if (data && data.full_name) {
      panelTitle = data.full_name;

      const aliases = data.aliases.slice(0, 3);

      if (aliases.length === 1) {
        panelTitle = `${panelTitle} (${aliases[0]})`;
      } else if (aliases.length === 2) {
        panelTitle = `${panelTitle} (${aliases[0]}, ${aliases[1]})`;
      } else if (aliases.length > 2) {
        panelTitle = `${panelTitle} (${aliases[0]}, ${aliases[1]}, ...)`;
      }
    }

    return panelTitle;
  };

  renderArtistPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { t } = this.props;
    const { nbOwnerships } = this.props.panel;

    if (nbOwnerships) {
      return (
        <div>
          <Counter>
            <CounterDesc>{t('components.panels.artist.works')}</CounterDesc>
            <Count>{nbOwnerships.publishing}</Count>
          </Counter>
          <Counter>
            <CounterDesc>{t('components.panels.artist.recordings')}</CounterDesc>
            <Count>{nbOwnerships.master}</Count>
          </Counter>
        </div>
      );
    }

    return null;
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    if (this.checkIfOwned()) return true;

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'picture':
        return !isBlank(get(panel, ['data', 'image', 'original']));
      case 'artist':
        return ['full_name', 'aliases', 'artist_ref'].some(this.isShown);
      case 'rights':
        return ['publishing_info', 'recording_info'].some(this.isShown);
      case 'publishing_info':
        return ['ipi', 'performing_rights_society', 'mechanical_rights_society'].some(this.isShown);
      case 'recording_info':
        return ['ipn', 'neighboring_rights_society'].some(this.isShown);
      case 'contact':
        return ['artist_address', 'artist_email', 'artist_phone'].some(this.isShown);
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, panel, locale, form, delaySave, changed } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.artist.false')}
            textTrue={t('components.panels.artist.true')}
            show={
              this.isShown(`custom_fields.${field.key}`) &&
              !this.isInHideList(`custom_fields.${field.key}`)
            }
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => {
              delaySave(true);
            }}
            onBlur={() => {
              delaySave(false);
            }}
            items={options.map((o) => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={
              this.isShown(`custom_fields.${field.key}`) &&
              !this.isInHideList(`custom_fields.${field.key}`)
            }
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? (n, v) => this.updateCustomField(n, v || '') : null}
            onFocus={() => {
              delaySave(true);
            }}
            onBlur={() => {
              delaySave(false);
            }}
            items={options.map((o) => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={
              this.isShown(`custom_fields.${field.key}`) &&
              !this.isInHideList(`custom_fields.${field.key}`)
            }
          />
        );
      }

      case 'text': {
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => {
              delaySave(true);
            }}
            onBlur={() => {
              if (
                panel.data.tenant === yleTenant &&
                ['birth_year', 'death_year'].includes(field.key) &&
                changed
              ) {
                return this.saveWithConfirm();
              }
              return delaySave(false);
            }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={
              this.isShown(`custom_fields.${field.key}`) &&
              !this.isInHideList(`custom_fields.${field.key}`)
            }
          />
        );
      }

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={(e) => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => {
              delaySave(true);
            }}
            onBlur={() => {
              delaySave(false);
            }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', [])
      .filter((c) => {
        const isArtistCollection = c.collections.indexOf('artist') >= 0;
        return !section
          ? isArtistCollection
          : isArtistCollection && c.section && c.section === section;
      })
      .filter(
        (c) => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
      );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, (field) => field.order);

    return section ? (
      sortedFields.map((field) => this.renderCustomField(field))
    ) : (
      <CollapsibleBox
        title={t('components.panels.artist.boxCustomFields')}
        show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
      >
        {sortedFields.map((field) => this.renderCustomField(field))}
      </CollapsibleBox>
    );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      mechanicalrightssocieties,
      neighboringrightssocieties,
      performingrightssocieties,
      updateForm,
      delaySave,
      deleteForm,
      t,
      customFieldsToRightSide,
      descriptionAutocomplete,
      user,
      syncArtist,
      changed,
    } = this.props;

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          panel={panel}
          title={this.renderArtistName()}
          picture={this.renderArtistPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
          syncArtist={syncArtist}
        />

        <PanelContent>
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.artist.boxArtist')}
              isOpen
              show={this.isShown('artist') && !this.isInHideList('artist')}
            >
              <Input
                type="text"
                name="full_name"
                label={t('components.panels.artist.labelFullName')}
                error={form.errors.full_name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  if (panel.data.tenant === yleTenant && changed) {
                    return this.saveWithConfirm();
                  }
                  return delaySave(false);
                }}
                value={form.values.full_name}
                mt="0"
                show={this.isShown('full_name') && !this.isInHideList('full_name')}
              />
              <ChipInput
                label={t('components.panels.artist.labelArtistAliases')}
                name="aliases"
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                data={form.values.aliases}
                error={form.errors.aliases}
                show={this.isShown('aliases') && !this.isInHideList('aliases')}
              />
              <Input
                type="text"
                name="artist_ref"
                label={t('components.panels.artist.labelArtistRef')}
                error={form.errors.artist_ref}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                value={form.values.artist_ref}
                show={this.isShown('artist_ref') && !this.isInHideList('artist_ref')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>

            <CollapsibleBox
              title={t('components.panels.artist.boxRights')}
              mt="5px"
              show={this.isShown('rights') && !this.isInHideList('rights')}
            >
              <SubCategory
                show={this.isShown('publishing_info') && !this.isInHideList('publishing_info')}
              >
                {t('components.panels.artist.subCategoryPublishingInfo')}
              </SubCategory>
              <Input
                type="text"
                name="ipi"
                label={t('components.panels.artist.labelIpi')}
                error={form.errors.ipi}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                value={form.values.ipi}
                show={this.isShown('ipi') && !this.isInHideList('ipi')}
              />
              <SelectInput
                type="single"
                name="performing_rights_society"
                label={t('components.panels.artist.labelPerformingRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                items={performingrightssocieties.map((rmc) => ({ id: rmc.id, name: rmc.name }))}
                data={form.values.performing_rights_society}
                error={form.errors.performing_rights_society}
                search
                show={
                  this.isShown('performing_rights_society') &&
                  !this.isInHideList('performing_rights_society')
                }
              />
              <SelectInput
                type="single"
                name="mechanical_rights_society"
                label={t('components.panels.artist.labelMechanicalRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                items={mechanicalrightssocieties.map((rmc) => ({ id: rmc.id, name: rmc.name }))}
                data={form.values.mechanical_rights_society}
                error={form.errors.mechanical_rights_society}
                search
                show={
                  this.isShown('mechanical_rights_society') &&
                  !this.isInHideList('mechanical_rights_society')
                }
              />
              <SubCategory
                show={this.isShown('recording_info') && !this.isInHideList('recording_info')}
              >
                {t('components.panels.artist.subCategoryRecordingInfo')}
              </SubCategory>
              <Input
                type="text"
                name="ipn"
                label={t('components.panels.artist.labelIpn')}
                error={form.errors.ipn}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                value={form.values.ipn}
                show={this.isShown('ipn') && !this.isInHideList('ipn')}
              />
              <SelectInput
                type="single"
                name="neighboring_rights_society"
                label={t('components.panels.artist.labelNeighboringRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                items={neighboringrightssocieties.map((rmc) => ({ id: rmc.id, name: rmc.name }))}
                data={form.values.neighboring_rights_society}
                error={form.errors.neighboring_rights_society}
                search
                show={
                  this.isShown('neighboring_rights_society') &&
                  !this.isInHideList('neighboring_rights_society')
                }
              />
              {this.renderCustomFields('rights')}
            </CollapsibleBox>

            <CollapsibleBox
              title={t('components.panels.artist.boxContact')}
              mt="5px"
              show={this.isShown('contact') && !this.isInHideList('contact')}
            >
              <GooglePlacesInput
                name="artist_address"
                label={t('components.panels.artist.labelAddress')}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                data={form.values.artist_address}
                error={form.errors.artist_address}
                show={this.isShown('artist_address') && !this.isInHideList('artist_address')}
              />
              <Input
                type="text"
                name="artist_email"
                label={t('components.panels.artist.labelEmail')}
                error={form.errors.artist_email}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                value={form.values.artist_email}
                show={this.isShown('artist_email') && !this.isInHideList('artist_email')}
              />
              <Input
                type="text"
                name="artist_phone"
                label={t('components.panels.artist.labelPhoneNumber')}
                error={form.errors.artist_phone}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => {
                  delaySave(true);
                }}
                onBlur={() => {
                  delaySave(false);
                }}
                value={form.values.artist_phone}
                show={this.isShown('artist_phone') && !this.isInHideList('artist_phone')}
              />
              {this.renderCustomFields('contact')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.artist.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <TranslateArea
              name="descriptions"
              label={t('components.panels.artist.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => {
                delaySave(true);
              }}
              onBlur={() => {
                delaySave(false);
              }}
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
          </Div>
        </PanelContent>
      </PanelLoader>
    );
  }
}

Artist.propTypes = {
  closePanel: PropTypes.func.isRequired,
};

export default formPanel(
  ArtistSchema,
  {
    mechanical_rights_society: (mrs) => mrs.id,
    neighboring_rights_society: (nrs) => nrs.id,
    performing_rights_society: (prs) => prs.id,
  },
  {
    nbOwnerships: 'count/ownerships',
  },
)(withI18n()(withConfirmation(Artist, false)));
