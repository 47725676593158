// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { open } from './../../../../actions/SidePanelActions';
import { onTheFlyCreationError, fetchError } from './../../../../actions/ApiSelectInputActions';

// Components
import ApiSelectInput from './../../../presentationals/inputs/apiselectinput/ApiSelectInput';

function mapStateToProps({ user }) {
  return {
    sessionToken: user.sessionToken,
  };
}

export default connect(mapStateToProps, {
  openSidePanel: open,
  onTheFlyCreationError,
  onFetchError: fetchError,
})(ApiSelectInput);
