// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Helpers
import { getViewProps, filterTagCategory } from '../../../helpers/helpers';

// Components
import TagList from './../../presentationals/taglist/TagList';

// =============================
// Component
// =============================

function TagListContainer(props) {
  const { isFetchingOptions, tagHideList, tagCategories } = props;

  if (isFetchingOptions) return null;


  const { hideAll, filteredCategories } = filterTagCategory(tagCategories, tagHideList);
  // hide search if no filteredCategories provided
  if (hideAll) return null;

  return (
    <TagList
      tagCategories={filteredCategories}
      data={props.data}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      changeWithFilter={props.changeWithFilter}
      {...getViewProps(props)}
    />
  );
}


function mapStateToProps({ options }) {
  const { tags } = options.data;

  return {
    isFetchingOptions: options.isFetching,
    tagCategories: tags,
  };
}

TagListContainer.propTypes = {
  isFetchingOptions: PropTypes.bool.isRequired,
  tagCategories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  changeWithFilter: PropTypes.bool,
  tagHideList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

TagListContainer.defaultProps = {
  onChange: null,
  onFocus: null,
  onBlur: null,
  changeWithFilter: false,
  tagHideList: null,
};

export default connect(mapStateToProps)(TagListContainer);
