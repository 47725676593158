// =============================
// Imports
// =============================

// Constants
import {
  PRINT_DATA_LOADING, PRINT_DATA_SUCCESS, PRINT_TRACK_FAILURE,
} from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  data: {},
  err: null,
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  switch (action.type) {
    case PRINT_DATA_LOADING:
      return {
        data: {},
        err: null,
        isFetching: true,
      };
    case PRINT_DATA_SUCCESS:
      return {
        data: action.payload,
        err: null,
        isFetching: false,
      };

    case PRINT_TRACK_FAILURE:
      return {
        data: {},
        err: action.err,
        isFetching: false,
      };
    default: return state;
  }
}
