// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// =============================
// Styles
// =============================

export const GenericView = styled.div`
  align-items: stretch;
  background-color: ${props => props.bgColor};
  display: flex;
  flex-direction: column;
  min-width: ${props => props.theme.bp.minWidth}px;
  padding: 40px 50px;
  padding: ${props => props.padding};
  width: 100%;
`;

GenericView.propTypes = {
  bgColor: PropTypes.string,
};

GenericView.defaultProps = {
  bgColor: 'inherit',
};

export const Div = styled.div`
  ${props => props.hide && css`
    display: none !important;
  `};

  ${props => props.whiteSpace && css`
    white-space: ${props.whiteSpace};
  `};

  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `};

  ${props => props.position && css`
    position: ${props.position};
  `};

  ${props => props.height && css`
    height: ${props.height};
  `};

  ${props => props.width && css`
    width: ${props.width};
  `};

  ${props => props.minHeight && css`
    min-height: ${props.minHeight};
  `};

  ${props => props.minWidth && css`
    min-width: ${props.minWidth};
  `};

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `};

  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth};
  `};

  ${props => props.margin && css`
    margin: ${props.margin};
  `};

  ${props => props.mt && css`
    margin-top: ${props.mt};
  `};

  ${props => props.mr && css`
    margin-right: ${props.mr};
  `};

  ${props => props.mb && css`
    margin-bottom: ${props.mb};
  `};

  ${props => props.ml && css`
    margin-left: ${props.ml};
  `};

  ${props => props.mx && css`
    margin-left: ${props.mx};
    margin-right: ${props.mx};
  `};

  ${props => props.my && css`
    margin-bottom: ${props.my};
    margin-top: ${props.my};
  `};

  ${props => props.padding && css`
    padding: ${props.padding};
  `};

  ${props => props.pt && css`
    padding-top: ${props.pt};
  `};

  ${props => props.pr && css`
    padding-right: ${props.pr};
  `};

  ${props => props.pb && css`
    padding-bottom: ${props.pb};
  `};

  ${props => props.pl && css`
    padding-left: ${props.pl};
  `};

  ${props => props.px && css`
    padding-left: ${props.px};
    padding-right: ${props.px};
  `};

  ${props => props.py && css`
    padding-bottom: ${props.py};
    padding-top: ${props.py};
  `};

  ${props => props.overflow && css`
    overflow: ${props.overflow};
  `};

  ${props => props.overflowWrap && css`
    overflow-wrap: ${props.overflowWrap};
  `};

  ${props => props.order && css`
    order: ${props.order};
  `};

  ${props => props.zIndex && css`
    z-index: ${props.zIndex};
  `};

  ${props => props.top && css`
    top: ${props.top};
  `};

  ${props => props.bottom && css`
    bottom: ${props.bottom};
  `};

  ${props => props.left && css`
    left: ${props.left};
  `};

  ${props => props.right && css`
    right: ${props.right};
  `};
`;

export const Text = styled.span`
  color: ${props => props.theme.colors[props.textcolor]};
`;

export const Flex = Div.extend`
  display: flex;

  ${props => props.align && css`
    align-items: ${props.align};
  `};

  ${props => props.justify && css`
    justify-content: ${props.justify};
  `};

  ${props => props.direction && css`
    flex-direction: ${props.direction};
  `};

  ${props => props.flex && css`
    flex: ${props.flex};
  `};

  ${props => props.wrap && css`
    flex-wrap: ${props.wrap};
  `};

  ${props => props.flexBasis && css`
    flex-basis: ${props.flexBasis};
  `};

  ${props => props.alignContent && css`
    align-content: ${props.alignContent};
  `};

  ${props => props.disabled && css`
    cursor: not-allowed;
  `};
`;

export const EllipsisContainer = styled(Div)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FlexBreakWord = styled(Div)`
  max-width: 100%;
  overflow: auto;
  overflow-wrap: break-word;
  width: 100%;
`;

export const ContainerView = styled(Flex)`
  background-color: ${props => props.bgColor};
  min-width: ${props => props.theme.bp.minWidth}px;
  padding: 0 50px;
  width: 100%;
`;

ContainerView.propTypes = {
  bgColor: PropTypes.string,
};

ContainerView.defaultProps = {
  bgColor: 'inherit',
};

export const Link = styled.a`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  cursor: ${props => props.cursor};
  &:hover {
    opacity: 0.7;
  }
`;

export const OnTheFlyPreview = styled.div`
  ${props => props.theme.fonts.openSansSemiBold};
  color: ${props => props.theme.colors.primary};
  max-width: 100%;
  overflow-wrap: break-word;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const Em = styled.em`
  font-style: italic;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
