// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Styles
import { Circle } from './../../../../themes/icons';

// =============================
// Renderer
// =============================

export default (func = ({ value }) => value) => (value, object) => (
  <Circle color={func({ value, object }) ? 'success' : 'error'} />
);
