// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

const Common = styled.button`
  ${props => props.theme.fontSize.m};
  border-radius: ${props => props.theme.borderRadius.element};
  cursor: pointer;
  height: ${props => (props.height ? props.height : '50px')};
  padding: 10px 0;
  text-transform: uppercase;
  transition-duration: ${props => props.theme.transition.medium};
  width: ${props => (props.width ? props.width : '150px')};
`;

export default Common;
