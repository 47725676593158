// =============================
// Imports
// =============================

// External Dependencies
import styled, { keyframes } from 'styled-components';
import SVGInline from 'react-svg-inline';

// =============================
// Styles
// =============================


const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: relative;
  margin-left: 20px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.loading) {
      return 'transparent';
    }

    switch (props.status) {
      case true: return props.theme.colors.success;
      case false: return props.theme.colors.error;
      default: return 'transparent';
    }
  }};
`;

export const Loading = styled(SVGInline)`
  position: relative;
  display: block;
  width: 13px;
  height: 13px;
  animation: ${rotateAnim} 2000ms linear infinite;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;

    path {
      fill: ${props => props.theme.colors.text.grey};
    }
  }
`;

export const FloatingProgress = styled.span`
  position: absolute;
  pointer-events: none;
  right: calc(100% + 5px);
  top: -6px;
  white-space: nowrap;
  font-size: 10px;
  color: ${props => props.theme.colors.text.grey};
`;
