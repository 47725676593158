// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

// Helpers
import { getName, findByLocale } from './../../../helpers/I18n';
import {
  getTagsWithCategoriesFromTagCategories,
  getSubCategoriesFromTagCategories,
} from './../../../helpers/tags';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import SelectDropdown from '../selectdropdown/SelectDropdown';

// Styles
import { Strong, Faded } from './SearchBar.styles';
import { Item } from './../dropdownlist/DropdownList.styles';

// =============================
// Component
// =============================

class SearchBarAutocomplete extends Component {
  static setMatchIdsToLowerCase(matches) {
    return matches.map(match => ({
      ...match,
      id: match.id.toLowerCase(),
    }));
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  static CUSTOM_FIELD_MAPPING = {
    status: 'WorkflowStatus',
    custom_id: 'CustomID',
    formats: 'Formats',
    recording_year: 'recording_year',
    composition_year: 'composition_year',
    re_release_year: 're_release_year',
    languages: 'languages',
    NumeroPratica: 'NumeroPratica',
    DescrizionePratica: 'DescrizionePratica',
    CodiciGeneri: 'CodiciGeneri',
    wbs: 'wbs',
  };

  handleSelect = (match) => {
    const { onSelect, matchKey } = this.props;
    onSelect(matchKey, match.id, match.key, match.is_subcategory);
  };

  stringIncludes = (string, searchString) =>
    new RegExp(`\\b${searchString.toLowerCase()}`).test(string.toLowerCase());

  stringIndexOf = (string, searchString) => {
    const execResult = new RegExp(`\\b${searchString.toLowerCase()}`).exec(string.toLowerCase());
    return execResult === null ? undefined : execResult.index;
  };

  highlightMatch = (string, searchString) => {
    const indexStart = this.stringIndexOf(string, searchString);
    if (indexStart === undefined) return string;
    const indexEnd = indexStart + searchString.length;
    return (
      <span key={string}>
        {string.substring(0, indexStart)}
        <Strong>{string.substring(indexStart, indexEnd)}</Strong>
        {string.substring(indexEnd)}
      </span>
    );
  };

  presentSynonyms = (match, value) => {
    const presentation = sortBy(this.getTagSynonyms(match), [s => s.toLowerCase()])
      .map(s => this.highlightMatch(s, value))
      .reduce((sum, synonym, i) => (i === 0 ? [synonym] : [...sum, ', ', synonym]), []);

    return <Faded key="synonyms">({presentation})</Faded>;
  };

  presentMatch = (match, value, { withSynonyms = false, subCategory = false } = {}) => {
    const { locale } = this.props;
    const presentation = [];
    presentation.push(this.highlightMatch(getName(match, locale), value));
    if (withSynonyms) {
      presentation.push(this.presentSynonyms(match, value));
    }

    if (getName(match.category, locale) && subCategory) {
      presentation.push(<Faded key="category">&nbsp;› {getName(match.category, locale)}</Faded>);
    }
    if (getName(match.category, locale) && !subCategory) {
      presentation.push(<Faded key="category">&nbsp;— {getName(match.category, locale)}</Faded>);
    }

    if (getName(match.subCategory, locale)) {
      presentation.push(
        <Faded key="subcategory">&nbsp;› {getName(match.subCategory, locale)}</Faded>,
      );
    }
    return presentation;
  };

  findTagMatchesByName = () => {
    const { value, tagCategories, locale } = this.props;
    return getTagsWithCategoriesFromTagCategories(tagCategories)
      .filter(tag => this.stringIncludes(getName(tag, locale), value))
      .map(tag => ({
        id: tag.id,
        key: tag.key,
        presentation: this.presentMatch(tag, value),
        category: getName(tag.category, locale),
        subCategory: getName(tag.subCategory, locale),
        is_subcategory: false,
      }));
  };

  getTagSynonyms = (tag) => {
    const { locale } = this.props;
    const synonymsObject = findByLocale(tag.synonyms, locale);
    return synonymsObject ? synonymsObject.values : [];
  };

  findTagMatchesBySynonym = () => {
    const { value, tagCategories, locale } = this.props;
    return getTagsWithCategoriesFromTagCategories(tagCategories)
      .filter(tag => this.getTagSynonyms(tag).some(s => this.stringIncludes(s, value)))
      .map(tag => ({
        id: tag.id,
        key: tag.key,
        presentation: this.presentMatch(tag, value, { withSynonyms: true }),
        category: getName(tag.category, locale),
        subCategory: getName(tag.subCategory, locale),
        is_subcategory: false,
      }));
  };

  findTagMatchesBySubCategory = () => {
    const { value, tagCategories, locale } = this.props;
    return getSubCategoriesFromTagCategories(tagCategories)
      .filter(sc => this.stringIncludes(getName(sc, locale), value))
      .map(sc => ({
        id: sc.id,
        key: sc.key,
        presentation: this.presentMatch(sc, value, { subCategory: true }),
        category: getName(sc.category, locale),
        subCategory: getName(sc, locale),
        is_subcategory: true,
      }));
  };

  findTagMatches = () => {
    const matches = [
      ...this.findTagMatchesByName(),
      ...this.findTagMatchesBySynonym(),
      ...this.findTagMatchesBySubCategory(),
    ];
    return sortBy(uniqBy(matches, 'key'), ['category', 'subCategory', 'key']);
  };

  renderItem = ({ item, isSelected }) => {
    const { onSelect, matchKey } = this.props;
    return (
      <Item
        height="40px"
        selected={isSelected}
        key={item.id}
        data-id={item.id}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(matchKey, item.id, item.key, item.is_subcategory);
        }}
        tabIndex="-1"
      >
        {item.presentation}
      </Item>
    );
  };

  bpmFields = () => {
    const valuesArr = [
      {
        presentation: 'SLOW (BPM from 20 to 70)',
        value: '20-70',
      },
      {
        presentation: 'MEDIUM (BPM from 71 to 90)',
        value: '71-90',
      },
      {
        presentation: 'FAST (BPM from 91 to 120)',
        value: '91-120',
      },
      {
        presentation: 'VERY FAST (BPM more than 120)',
        value: '121-999',
      },
    ];
    return valuesArr.map(elem => ({
      id: elem.value,
      key: elem.value,
      presentation: elem.presentation,
      category: elem.value,
      subCategory: elem.value,
      is_subcategory: false,
    }));
  };

  findMatches() {
    const { matchKey } = this.props;

    switch (matchKey) {
      case 'tag':
        return this.findTagMatches();
      case 'status':
      case 'custom_id':
      case 'formats':
      case 'recording_year':
      case 'composition_year':
      case 're_release_year':
      case 'languages':
      case 'NumeroPratica':
      case 'DescrizionePratica':
      case 'CodiciGeneri':
      case 'wbs':
        return this.findCustomFields();
      case 'isrc':
      case 'ean':
      case 'album_ref':
        return this.findFields();
      case 'bpm':
        return this.bpmFields();
      default:
        return [];
    }
  }

  findCustomFields() {
    const { matchKey, value, customFields } = this.props;
    const mappedKey = SearchBarAutocomplete.CUSTOM_FIELD_MAPPING[matchKey];
    const customField = customFields.find(field => field.key === mappedKey);

    if (!customField) return [];

    return customField.type === 'text'
      ? [{
        id: value,
        key: value,
        presentation: value,
        category: value,
        subCategory: value,
        is_subcategory: false,
      }]
      : customField.data.list_options
        .filter(option => this.stringIncludes(option, value))
        .map(option => ({
          id: option,
          key: option,
          presentation: this.presentCustomFieldMatch(option, value, customField),
          category: option,
          subCategory: option,
          is_subcategory: false,
        }));
  }

  findFields() {
    const { value } = this.props;

    return [{
      id: value,
      key: value,
      presentation: value,
      category: value,
      subCategory: value,
      is_subcategory: false,
    }];
  }

  isEmptyCollection() {
    const { value, matchKey, tagCategories, customFields } = this.props;

    switch (matchKey) {
      case 'tag':
        return isEmpty(tagCategories);
      case 'status':
      case 'custom_id':
      case 'formats':
      case 'recording_year':
      case 'composition_year':
      case 're_release_year':
      case 'languages':
      case 'NumeroPratica':
      case 'DescrizionePratica':
      case 'CodiciGeneri':
      case 'wbs':
        return isEmpty(customFields);
      case 'isrc':
      case 'ean':
      case 'album_ref':
        return isEmpty(value);
      case 'bpm':
        return false;
      default:
        return true;
    }
  }

  presentCustomFieldMatch(match, value, customField) {
    return [
      this.highlightMatch(match, value),
      <Faded key="customField">&nbsp;› {customField.name}</Faded>,
    ];
  }

  render() {
    const { isOpen, value, onClose, matchKey } = this.props;
    let matches = this.findMatches();
    const isEmptyCollection = this.isEmptyCollection();
    if (!isOpen || isEmptyCollection || (value.length < 1 && matchKey !== 'bpm') || isEmpty(matches)) return null;

    if (matchKey === SearchBarAutocomplete.CUSTOM_FIELD_MAPPING.formats.toLowerCase()) {
      matches = SearchBarAutocomplete.setMatchIdsToLowerCase(matches);
    }

    return (
      <SelectDropdown
        value={matches}
        renderItem={this.renderItem}
        isOpen
        onSelect={this.handleSelect}
        onClose={onClose}
      />
    );
  }
}

SearchBarAutocomplete.propTypes = {
  tagCategories: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  customFields: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.string,
  matchKey: PropTypes.string,
  isOpen: PropTypes.bool,

  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  locale: PropTypes.string.isRequired,
};

SearchBarAutocomplete.defaultProps = {
  tagCategories: undefined,
  customFields: undefined,
  value: '',
  matchKey: '',
  isOpen: false,

  onSelect: () => {},
  onClose: () => {},
};

export default withI18n()(SearchBarAutocomplete);
