/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External dependencies
import castArray from 'lodash/castArray';

// =============================
// Helper
// =============================

// NOTE: simple solution for current needs
export const toText = text =>
  castArray(text)[0]
    .replace(/<em>/g, '')
    .replace(/<\/em>/g, '');
