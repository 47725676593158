// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Helpers
import isBlank from './../../../../helpers/isBlank';
import { findByLocale } from './../../../../helpers/I18n';

// Styles
import { Flex } from './../../../../themes/views';
import { Highlight } from './../../../../themes/entityGrid';

// =============================
// Renderer
// =============================

export default locale => (descriptions, object, rowIndex, highlightKey) => {
  let description = '';

  if (highlightKey && !isBlank(object.highlight[highlightKey])) {
    description = object.highlight[highlightKey][0]; // eslint-disable-line
  } else if (!isBlank(descriptions)) {
    description = (findByLocale(descriptions, locale) || descriptions[0]).value;
  }

  return (
    <Flex width="100%" overflow="auto" maxHeight="100%">
      <Flex>
        <Highlight dangerouslySetInnerHTML={{ __html: description }} />
      </Flex>
    </Flex>
  );
};
