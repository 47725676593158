// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { clearNotification } from './../../../actions/NotificationActions';

// Components
import NotificationsList from './../../presentationals/notifications/NotificationsList';

// =============================
// Component
// =============================

function mapStateToProps({ notifications, player }) {
  return {
    notifications,
    playerShown: player.show,
  };
}

export default connect(
  mapStateToProps,
  { clearNotification },
)(NotificationsList);
