// =============================
// Imports
// =============================

import {
  GET_OPTIONS_LOADING,
  SET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
} from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  data: {
    languages: {},
    territories: [],
    albumtypes: [],
    tonalitykeys: [],
    trackversions: [],
    tempos: [],
    rightstypes: [],
    mechanicalrightssocieties: [],
    neighboringrightssocieties: [],
    performingrightssocieties: [],
    tags: [],
  },
};

// =============================
// Reducer
// =============================

export default function options(state = initialState, action) {
  switch (action.type) {
    case GET_OPTIONS_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case SET_OPTIONS: {
      const data = {};
      Object.keys(action.payload).forEach((key) => { data[key] = action.payload[key]; });

      return {
        ...state,
        data,
      };
    }

    case GET_OPTIONS_SUCCESS:
    case GET_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default: return state;
  }
}
