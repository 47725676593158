/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
// Import styles

// =============================
// Styled Components
// =============================

export const Label = styled.div`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;
  position: relative;
  top: 20px;

  &::first-letter {
    text-transform: uppercase;
  }
`;
