/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// =============================
// Styled Components
// =============================

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  width: 100%;
`;

export const IconCrossContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;
export const IconCross = styled(SVGInline)`
  width: 18px;
  height: 18px;
  stroke: #999;
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 8px;
  };

  &:hover {
    stroke: #000;
  }
`;
