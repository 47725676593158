// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import get from 'lodash/get';

// Helpers
import { getHiddenFieldsFromUserData } from './../../../helpers/helpers';

// Components
import PublisherPanel from './../../presentationals/panels/publisher/Publisher';

// =============================
// Component
// =============================

function mapStateToProps({ options, user }) {
  return {
    mechanicalrightssocieties: options.data.mechanicalrightssocieties,
    performingrightssocieties: options.data.performingrightssocieties,
    territories: options.data.territories,
    isFetchingOptions: options.isFetching,
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'publisher'),
    descriptionAutocomplete: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'descriptionAutocomplete'],
      false,
    ),
    customFieldsToRightSide: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'customFieldsToRightSide'],
      false,
    ),
  };
}

export default connect(mapStateToProps)(PublisherPanel);
