import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const publisherLabels = {
  publisher_name: 'Publisher Name',
  performing_rights_society: 'Performing rights society',
  mechanical_rights_society: 'Mechanical rights society',
  ipi: 'IPI',
  publisher_code: 'Publisher Code',
  contact_name: 'Contact Name',
  contact_email: 'Contact Email',
  address: 'Address',
};

const PublisherSchema = {
  publisher_name: Joi.string().label(publisherLabels.publisher_name).required(),
  performing_rights_society:
    Joi.string().label(publisherLabels.performing_rights_society).allow(null),
  mechanical_rights_society:
    Joi.string().label(publisherLabels.mechanical_rights_society).allow(null),
  territories: Joi.array().items(Joi.string()),
  ipi: Joi.string().label(publisherLabels.ipi).empty(''),
  publisher_code: Joi.string().label(publisherLabels.publisher_code).empty(''),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string(),
    value: Joi.string(),
  })),
  contact_name: Joi.string().label(publisherLabels.contact_name).empty(''),
  contact_email: Joi.string().label(publisherLabels.contact_email).email({ minDomainAtoms: 2 })
    .min(6)
    .empty(''),
  address: Joi.string().label(publisherLabels.address).empty(''),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const publisherCustomFields = getFieldsByCollection('publisher', validation_fields);
  if (!validation_fields || isEmpty(publisherCustomFields)) { // eslint-disable-line camelcase
    return PublisherSchema;
  }

  return createCustomSchema(publisherCustomFields, custom_fields, {
    defaultSchema: PublisherSchema,
    labels: publisherLabels,
  });
};
