// =============================
// Imports
// =============================

// Helper
import axios from './../helpers/axios';

// Constants
import { GENERATE_CUSTOM_ID_FAILURE, CHECK_CUSTOM_ID_FAILURE } from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export const generateAlbumCustomID = (panel, data) => (dispatch, getState) =>
  axios({
    method: 'POST',
    url: `${panel}/generate-custom-id`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    data,
  })
    .then(response => response.data)
    .catch(err =>
      dispatch({
        type: GENERATE_CUSTOM_ID_FAILURE,
        payload: {
          error: err,
          status: err.response.status,
        },
      }),
    );

export const checkAlbumCustomID = (panel, CustomID, albumId) => (dispatch, getState) =>
  axios({
    method: 'POST',
    url: `${panel}/check-custom-id`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    data: { CustomID, albumId },
  })
    .then(response => response.data)
    .catch(err =>
      dispatch({
        type: CHECK_CUSTOM_ID_FAILURE,
        payload: {
          error: err,
          status: err.response.status,
        },
      }),
    );
