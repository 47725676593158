// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../../hoc/WithI18n';

// Components
import Input from './../../../inputs/inputunderline/InputUnderline';

// Themes
import { Flex } from './../../../../../themes/views';

// =============================
// Component
// =============================

class PublishingPercentage extends Component {
  static propTypes = {
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { ownership, errors, disabled, onChange, onFocus, onBlur, t } = this.props;

    return (
      <Flex direction="column">
        <Flex align="baseline" justify="space-between">
          <Input
            type="text"
            name="mechanical_percentage"
            label={t('components.inputs.percentage.mechanical_percentage')}
            onChange={
              disabled
                ? null
                : (name, value) => onChange({ ...ownership, mechanical_percentage: value })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={ownership.mechanical_percentage}
            error={errors.mechanical_percentage}
            width="48%"
            key="1"
          />
          <Input
            type="text"
            name="performing_percentage"
            label={t('components.inputs.percentage.performing_percentage')}
            onChange={
              disabled
                ? null
                : (name, value) => onChange({ ...ownership, performing_percentage: value })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={ownership.performing_percentage}
            error={errors.performing_percentage}
            width="48%"
            key="2"
          />
        </Flex>
        <Flex align="baseline" justify="space-between">
          <Input
            type="text"
            name="sync_percentage"
            label={t('components.inputs.percentage.sync_percentage')}
            onChange={
              disabled ? null : (name, value) => onChange({ ...ownership, sync_percentage: value })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={ownership.sync_percentage}
            error={errors.sync_percentage}
            width="48%"
            key="3"
          />
        </Flex>
        <Flex align="baseline" justify="space-between">
          <Input
            type="text"
            name="download_percentage"
            label={t('components.inputs.percentage.download_percentage')}
            onChange={
              disabled ? null : (name, value) => onChange({
                ...ownership,
                download_percentage: value,
              })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={ownership.download_percentage}
            error={errors.download_percentage}
            width="48%"
            key="4"
          />
          <Input
            type="text"
            name="rent_percentage"
            label={t('components.inputs.percentage.rent_percentage')}
            onChange={
              disabled ? null : (name, value) => onChange({
                ...ownership,
                rent_percentage: value,
              })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={ownership.rent_percentage}
            error={errors.rent_percentage}
            width="48%"
            key="5"
          />
        </Flex>
      </Flex>

    );
  }
}

export default withI18n()(PublishingPercentage);
