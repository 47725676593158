// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import Joi from 'joi-browser';
import indexOf from 'lodash/indexOf';
import debounce from 'lodash/debounce';

// Constants
import {
  TRACK_PANEL,
  MULTIPART_TRACK_PANEL,
  ARTIST_PANEL,
} from './../../../../constants/SidePanelTypes';

// Helpers
import { getName } from './../../../../helpers/I18n';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import MusicItemControls from './../../../containers/musicitem/controls/MusicItemControlsContainer';
import Chip from './../../chip/Chip';
import SelectInput from '../../inputs/selectinput/SelectInput';
import Input from '../../inputs/inputunderline/InputUnderline';
import ApiSelectInput from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Styles
import { Div } from './../../../../themes/views';
import { GridRow, HeadColumn, MediaColumn, Column } from './../../../../themes/entityGrid';
import { Circle, IconText, IconTrash } from './../../../../themes/icons';

// =============================
// Component
// =============================

class AlbumTracks extends Component {
  static propTypes = {
    tracks: PropTypes.array, // eslint-disable-line
    tracklistId: PropTypes.string,
    trackversions: PropTypes.array.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    openSidePanel: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    canDownload: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
  };

  static defaultProps = {
    tracks: [],
    tracklistId: null,
    disabled: false,
  };

  constructor(props) {
    super(props);
    const sorted = this.sortTracks(props.tracks);
    this.state = {
      tracks: props.tracks,
      sortedTracks: sorted,
      errors: {
        trackNumbers: {},
        custom: {
          discNumbers: {},
        },
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.state.tracks, nextProps.tracks)) return;
    const sorted = this.sortTracks(nextProps.tracks);
    this.setState(() => ({
      tracks: nextProps.tracks,
      sortedTracks: sorted,
      errors: {
        trackNumbers: {},
        custom: {
          discNumbers: {},
        },
      },
    }));
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return !isEqual(nextState.sortedTracks, this.state.sortedTracks);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.sortedTracks, this.state.sortedTracks)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        sortedTracks: this.state.sortedTracks,
      });
    }
  }

  // Sorting function that considers both 'multipart' and non-'multipart' tracks
  sortTracks = (tracks) => {
    const sort = arr => sortBy(arr, [
      el => get(el, ['custom', 'disc_number']),
      el => (el.track_number > 0 ? Number(el.track_number) : Infinity)
    ]);

    return tracks.sort((a, b) => {
      // compare track vs multipart
      if (!get(a, 'multipart') && get(b, 'multipart')) {
        const tracksArr = sort([a, ...b.tracks]);
        const arrLength = tracksArr.length;
        const trackIndex = indexOf(tracksArr, a);
        if (arrLength === 1) return 1; // track before multipart
        if (trackIndex < Math.ceil(arrLength / 2)) return 1; // track before multipart
        if (trackIndex >= Math.ceil(arrLength / 2)) return -1; // track after multipart
      }

      // compare multipart vs track
      if (get(a, 'multipart') && !get(b, 'multipart')) {
        const tracksArr = sort([...a.tracks, b]);
        const arrLength = tracksArr.length;
        const trackIndex = indexOf(tracksArr, b);
        if (arrLength === 1) return -1; // track before multipart
        if (trackIndex < Math.ceil(arrLength / 2)) return -1; // track before multipart
        if (trackIndex >= Math.ceil(arrLength / 2)) return 1; // track after multipart
      }

      // compare track vs track
      if (!get(a, 'multipart') && !get(b, 'multipart')) {
        const tracksArr = sort([a, b]);
        return tracksArr[0] === a ? 1 : -1;
      }

      // compare multipart vs multipart
      if (get(a, 'multipart') && get(b, 'multipart')) {
        const al = a.tracks.length;
        const bl = b.tracks.length;
        if (al && bl) {
          // the multipart that contains the first element
          // of the sorted array of tracks, will go first
          const tracksArr = sort([...a.tracks, ...b.tracks]);
          return a.tracks.includes(tracksArr[0]) ? 1 : -1;
        }
        if (!al && !bl) return sort([a, b])[0] === a ? 1 : -1;
        if (!al && bl) return -1;
        if (al && !bl) return 1;
      }

      return 0;
    }).reverse();
  };

  handleDiscNumberChange = (name, value, track) => {
    this.setState(({ sortedTracks }) => ({
      sortedTracks: sortedTracks.map((t) => {
        if (t.id !== track.id) return t;
        return {
          ...t,
          custom: {
            ...t.custom,
            [name]: value,
          },
        };
      }),
    }));

    if (!this.validateAndSetDiscNumberError(track.id, value)) return;

    this.handleDiscNumberChangeDebouncer(name, value, track);
  };

    // eslint-disable-next-line react/sort-comp
    handleDiscNumberChangeDebouncer = debounce((name, value, track) => {
      const { updateAdditionalFormData } = this.props;
      if (!this.validateAndSetDiscNumberError(
        track.id, value,
      )) return;
      const isMultipart = track.multipart;
      const url = isMultipart ? `../../multiparts/${track.id}` : `../../tracks/${track.id}`;
      const { custom } = pick(track, ['custom']);
      updateAdditionalFormData(
        'custom',
        {
          ...custom,
          [name]: value || null,
        },
        { url, method: 'PUT' },
        {
          ...(isMultipart ? { multipartTracks: 'multiparts' } : { tracks: 'tracks' }),
        },
        track.custom ? track.custom : {},
      );
    }, 100e100);

  handleTrackNumberChange = (name, value, track) => {
    this.setState(({ sortedTracks }) => ({
      sortedTracks: sortedTracks.map((t) => {
        if (t.id !== track.id) return t;
        return { ...t, [name]: Number(value) };
      }),
    }));

    if (!this.validateAndSetTrackNumberError(track.id, value)) return;

    this.handleTrackNumberChangeDebouncer(value, track);
  };

  // eslint-disable-next-line react/sort-comp
  handleTrackNumberChangeDebouncer = debounce((value, track) => {
    const { updateAdditionalFormData } = this.props;
    if (!this.validateAndSetTrackNumberError(
      track.id, track.track_number, track.custom.disc_number || null,
    )) return;
    const isMultipart = track.multipart;
    const url = isMultipart ? `../../multiparts/${track.id}` : `../../tracks/${track.id}`;
    updateAdditionalFormData(
      'track_number',
      value || null,
      { url, method: 'PUT' }, // TODO: fix url
      {
        ...(isMultipart ? { multipartTracks: 'multiparts' } : { tracks: 'tracks' }),
      },
      track.custom ? track.custom : {},
    );
  }, 100e100);

  validateAndSetDiscNumberError = (id, value) => {
    const { t } = this.props;
    const schema = Joi.string()
      .label(t('components.panels.album.tracksDiscNumber'))
      .empty(null);
    const { error } = Joi.validate(value || null, schema);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        custom: {
          discNumbers: {
            ...this.state.errors.custom.discNumbers,
            [id]: error && error.details[0].message,
          },
        },
      },
    }));
    return !error;
  };

  validateAndSetTrackNumberError = (id, value, discNumber) => {
    const { t, tracks } = this.props;
    const schema = Joi.number()
      .label(t('components.panels.album.tracksTrackNumber'))
      .empty(null);
    const { error } = Joi.validate(value || null, schema);
    let uniqueMessage = '';
    if (tracks.filter(tr => (discNumber
      ? tr.custom.disc_number + tr.track_number === discNumber + parseInt(value, 10)
      : tr.track_number === discNumber + parseInt(value, 10)) && tr.id !== id).length) {
      uniqueMessage = 'not unique track number';
    }
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        trackNumbers: {
          ...this.state.errors.trackNumbers,
          [id]: (error && error.details[0].message) || uniqueMessage,
        },
      },
    }));
    if (uniqueMessage) {
      return false;
    }
    return !error;
  };

  render() {
    const {
      tracklistId,
      updateAdditionalFormData,
      trackversions,
      disabled,
      openSidePanel,
      canDownload,
      t,
      locale,
    } = this.props;
    const { errors, sortedTracks } = this.state;
    const tracks = sortedTracks;
    const custom = {
      finnish_content: 'no',
      yle_master: 'no',
    };

    const normalizedTracklistId = tracklistId || tracks.map(tt => tt.id).toString();

    function getPlayButton(track) {
      if (track.audiofile.hd_mp3) {
        return (
          <MusicItemControls
            trackDatas={track}
            tracklistId={normalizedTracklistId}
            tracklistDatas={{
              tracks: tracks.filter(tt => !!tt.audiofile.hd_mp3),
            }}
          />
        );
      }
      return null;
    }

    function getVersion(track) {
      if (track.version) {
        return getName(track.version, locale);
      }

      return t('components.panels.album.tracksNoVersion');
    }

    function getDisplayArtists(track) {
      const displayArtists = track.display_artists || [];

      return displayArtists.map(da => (
        <Chip
          key={da.alias ? `${da.artist.full_name}-${da.alias}` : da.artist.full_name}
          name={da.alias || da.artist.full_name}
          onClick={() => {
            openSidePanel(ARTIST_PANEL, da.artist.id);
          }}
        />
      ));
    }

    function getStatus(track) {
      if (!track.audiofile || track.maia_conversion_state) {
        return false;
      }

      return true;
    }

    const trackList = tracks.map(track => (
      <GridRow
        key={track.id}
        floor={track.version && !disabled ? '2' : '1'}
        direction={
          track.version && !disabled ? 'column' : 'row'
        }
      >
        <GridRow noBorder>
          <MediaColumn width="50px">{getPlayButton(track)}</MediaColumn>
          <Column width="75px" justify="center">
            {(get(track, ['custom', 'disc_number']) || !disabled) && (
              <Input
                type="text"
                name="disc_number"
                error={errors.custom.discNumbers[track.id]}
                onChange={
                  !disabled
                    ? (name, value) => this.handleDiscNumberChange(name, value, track)
                    : null
                }
                onBlur={() => this.handleDiscNumberChangeDebouncer.flush()}
                value={get(track, ['custom', 'disc_number']) || ''}
                width="35px"
                mt="0"
                hideErrorText
              />
            )}
          </Column>
          <Column width="75px" justify="center">
            {(track.track_number || !disabled) && (
              <Input
                type="text"
                name="track_number"
                error={errors.trackNumbers[track.id]}
                onChange={
                  !disabled
                    ? (name, value) => this.handleTrackNumberChange(name, value, track)
                    : null
                }
                onBlur={() => this.handleTrackNumberChangeDebouncer.flush()}
                value={track.track_number || ''}
                width="35px"
                mt="0"
                hideErrorText
              />
            )}
          </Column>
          <Column width="150px">
            <Chip
              name={trim(track.display_title) || trim(track.title)}
              onClick={() => {
                openSidePanel(track.multipart ? MULTIPART_TRACK_PANEL : TRACK_PANEL, track.id);
              }}
            />
          </Column>
          <Column width="150px">{getVersion(track)}</Column>
          <Column width="200px">{getDisplayArtists(track)}</Column>
          <Column width="100px" justify="center" mr="auto">
            <Circle color={getStatus(track) ? 'success' : 'error'} />
          </Column>
          {canDownload && (
            <div>
              <Column width="30px" pl="4px">
                {!!track.audiofile.hd_mp3 && (
                  <a
                    href={track.audiofile.hd_mp3.url}
                    title={t('components.panels.album.tracksDownloadHdMp3')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    key="0"
                  >
                    <IconText>MP3</IconText>
                  </a>
                )}
              </Column>
              <Column width="30px" pl="4px">
                {!!track.audiofile.original && (
                  <a
                    href={track.audiofile.original.url}
                    title={t('components.panels.album.tracksDownloadRaw')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    key="1"
                  >
                    <IconText>WAV</IconText>
                  </a>
                )}
              </Column>
            </div>
          )}
          <Column width="30px" pl="0">
            {!track.multipart && !disabled && (
              <IconTrash
                onClick={() => {
                  updateAdditionalFormData(
                    null,
                    null,
                    { url: `tracks/${track.id}`, method: 'DELETE' },
                    { tracks: 'tracks' },
                    track.custom ? track.custom : {},
                  );
                }}
                size="18px"
              />
            )}
            {track.multipart && !disabled && (
              <IconTrash
                onClick={() => {
                  updateAdditionalFormData(
                    null,
                    null,
                    { url: `multiparts/${track.id}`, method: 'DELETE' },
                    { multipartTracks: 'multiparts' },
                    track.custom ? track.custom : {},
                  );
                }}
                size="18px"
              />
            )}
          </Column>
        </GridRow>

        {track.version &&
          !disabled && (
            <GridRow noBorder>
              <MediaColumn width="50px" />
              <Column width="150px" />
              <Column width="150px" mb="50px" overflow="visible">
                <Chip
                  name={track.multipart === true
                    ? t('components.panels.album.multipartsCopy')
                    : t('components.panels.album.tracksCopy')
                  }
                  onClick={() => {
                    const isMultipart = !!track.multipart;
                    const key = isMultipart ? 'multiparts' : 'tracks';
                    updateAdditionalFormData(
                      'version',
                      track.version.id,
                      { url: `${key}/${track.id}/copy`, method: 'POST' },
                      {
                        [isMultipart ? 'multipartTracks' : 'tracks']: key,
                      },
                      track.custom ? track.custom : {},
                    );
                  }}
                />
              </Column>
              {track.version.key === 'original' && (
                <Column width="150px" mb="50px" overflow="visible">
                  {track.multipart ? (
                    <Div>MULTIPART WORK</Div>
                  ) : (
                    <SelectInput
                      type="single"
                      name="version"
                      label={t('components.panels.album.tracksAddVersion')}
                      onChange={(name, value) => {
                        updateAdditionalFormData(
                          name,
                          value,
                          { url: `tracks/${track.id}/versions`, method: 'POST' },
                          {
                            tracks: 'tracks',
                          },
                        );
                      }}
                      items={trackversions
                        .filter(v => v.key !== 'original')
                        .map(v => ({ id: v.id, name: getName(v, locale) }))}
                      data={null}
                      search
                      width="100%"
                    />
                  )}
                </Column>
              )}
            </GridRow>
          )}
      </GridRow>
    ));

    return (
      <div>
        {tracks.length > 0 && (
          <Div>
            <MediaColumn width="50px" />
            <HeadColumn width="75px" justify="center">
              №
            </HeadColumn>
            <HeadColumn width="75px" justify="center">
              #
            </HeadColumn>
            <HeadColumn width="150px">{t('components.panels.album.tracksTitle')}</HeadColumn>
            <HeadColumn width="150px">{t('components.panels.album.tracksVersion')}</HeadColumn>
            <HeadColumn width="200px">
              {t('components.panels.album.tracksDisplayArtists')}
            </HeadColumn>
            <HeadColumn width="100px" justify="center" mr="auto">
              {t('components.panels.album.tracksFiles')}
            </HeadColumn>
            <HeadColumn width="30px" pl="0" />
            <HeadColumn width="30px" pl="0" />
            <HeadColumn width="30px" pl="0" />
          </Div>
        )}
        {trackList}
        {!disabled && (
          <ApiSelectInput
            name="track"
            parseName={e => e.title}
            label={t('components.panels.album.tracksAddTrack')}
            entity="meta/tracks"
            panelName={TRACK_PANEL}
            defaultField="title"
            type="single"
            data={null}
            values={null}
            skipByFields={['multipart']}
            search
            onChange={(name, value) => {
              updateAdditionalFormData(
                null,
                null,
                { url: `tracks/${value}`, method: 'POST' },
                { tracks: 'tracks', data: '' },
              );
            }}
            customData={custom}
          />
        )}
        {!disabled && (
          <ApiSelectInput
            name="multipart"
            parseName={e => e.title}
            label={t('components.panels.album.tracksAddMultiTrack')}
            entity="meta/multiparts"
            panelName={MULTIPART_TRACK_PANEL}
            defaultField="title"
            type="single"
            data={null}
            values={null}
            search
            onChange={(name, value) => {
              updateAdditionalFormData(
                null,
                null,
                { url: `multiparts/${value}`, method: 'POST' },
                { multipartTracks: 'multiparts', data: '' },
              );
            }}
            customData={custom}
          />
        )}
      </div>
    );
  }
}


export default withI18n()(AlbumTracks);
