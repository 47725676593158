// =============================
// Imports
// =============================

// Constants
import {
  INITIALIZE_APP,
  CLOSE_SIDE_PANEL,
  DID_BATCH_DELETE_OPERATION,
} from './../constants/ActionTypes';

// Actions
import { getSearchResults, getPendingSearchResults } from '../actions/SearchActions';

// =============================
// Middleware
// =============================

const searchMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === INITIALIZE_APP:
      store.dispatch(getSearchResults(''));
      break;

    case action.type === CLOSE_SIDE_PANEL:
    case action.type === DID_BATCH_DELETE_OPERATION:
      store.dispatch(getPendingSearchResults());
      break;

    default:
      break;
  }

  return next(action);
};

export default searchMiddleware;
