import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const trackLabels = {
  public: 'Public',
  showcase: 'Showcase',
  title: 'Title',
  release_date: 'Release Date',
  track_number: 'Track Number',
  track_ref: 'Track Ref',
  display_title: 'Display Title',
  isrc: 'ISRC',
  iswc: 'ISWC',
  cocv: 'COCV',
  prs_tunecode: 'PRS Tunecode',
  lyrics: {
    default: 'Lyrics',
  },
  rating: 'Rating',
  duration: 'Duration',
  descriptions: 'Descriptions',
  display_artists: 'Display Artists',
  original_nrs: 'Original neighbouring rights society',
  original_prs: 'Original performing rights society',
  original_mrs: 'Original mechanical rights society',
  artists_publishing_ownerships: {
    default: 'Artists Publishing Ownerships',
    artist: 'Artist',
    alias: 'Alias',
    rights_type: 'Rights Type',
    performing_percentage: 'Performing Percentage',
    mechanical_percentage: 'Mechanical Percentage',
    sync_percentage: 'Sync Percentage',
    download_percentage: 'Download Percentage',
    rent_percentage: 'Rent Percentage',
    territories: 'Territories',
    additional_notes: 'Additional Notes',
  },
  publishing_ownerships: {
    default: 'Publishing Ownerships',
    publisher: 'Publisher',
    rights_type: 'Rights Type',
    performing_percentage: 'Performing Percentage',
    mechanical_percentage: 'Mechanical Percentage',
    sync_percentage: 'Sync Percentage',
    download_percentage: 'Download Percentage',
    rent_percentage: 'Rent Percentage',
    territories: 'Territories',
  },
  artists_master_ownerships: {
    default: 'Artists Master Ownerships',
    artist: 'Artist',
    alias: 'Alias',
    rights_type: 'Rights Type',
    ownership_percentage: 'Owner Percentage',
    sync_percentage: 'Sync Percentage',
    territories: 'Territories',
    additional_notes: 'Additional Notes',
  },
  master_ownerships: {
    default: 'Master Ownerships',
    label: 'Label',
    rights_type: 'Rights Type',
    ownership_percentage: 'Owner Percentage',
    sync_percentage: 'Sync Percentage',
    territories: 'Territories',
  },
};

const percentageValidation = Joi.alternatives().try(Joi.string().regex(/^\d+(?:\.\d+)?\/\d+(?:\.\d+)?$/), Joi.number().min(0).max(100));
const allowClearPercentageValidation = Joi.alternatives().try(
  Joi.string().allow('').regex(/^$/),
  Joi.string().regex(/^\d+(?:\.\d+)?\/\d+(?:\.\d+)?$/),
  Joi.number().min(0).max(100));

const TrackSchema = {
  public: Joi.boolean().label(trackLabels.public),
  showcase: Joi.boolean().label(trackLabels.showcase),
  title: Joi.string().label(trackLabels.title).required(),
  release_date: Joi.string().label(trackLabels.release_date).empty(null),
  track_number: Joi.number().label(trackLabels.track_number).empty(null),
  album: Joi.string().empty(null),
  track_ref: Joi.string().label(trackLabels.track_ref).empty(''),
  display_title: Joi.string().label(trackLabels.display_title).empty(''),
  isrc: Joi.string().label(trackLabels.isrc).empty(''),
  iswc: Joi.string().label(trackLabels.iswc).empty(''),
  cocv: Joi.string().label(trackLabels.cocv).empty(''),
  prs_tunecode: Joi.string().label(trackLabels.prs_tunecode).empty(''),
  lyrics: Joi.object().keys({
    value: Joi.string(),
    locale: Joi.string(),
  }).label(trackLabels.lyrics.default),
  rating: Joi.number().label(trackLabels.rating).empty(null),
  duration: Joi.number().min(0).label(trackLabels.duration).allow(null)
    .allow(''),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string().empty(''),
    value: Joi.string(),
  })).label(trackLabels.descriptions),
  display_artists: Joi.array().items(Joi.object().keys({
    full_name: Joi.string(),
    id: Joi.string().required(),
    aliases: Joi.array().items(Joi.string()),
    alias: Joi.string().empty(''),
  })).label(trackLabels.display_artists),
  version: Joi.string().empty(null),
  tags: Joi.array().items(Joi.string()),
  original_track: Joi.string().empty(null),
  agents: Joi.array().items(Joi.string()),
  territories: Joi.array().items(Joi.string()),
  original_nrs: Joi.string().label(trackLabels.original_nrs).empty(null),
  original_prs: Joi.string().label(trackLabels.original_prs).empty(null),
  original_mrs: Joi.string().label(trackLabels.original_mrs).empty(null),
  artists_publishing_ownerships: Joi.array().items(
    Joi.object().keys({
      artist: Joi.any().required().label(trackLabels.artists_publishing_ownerships.artist),
      alias: Joi.string().empty('').label(trackLabels.artists_publishing_ownerships.alias),
      rights_type:
        Joi.any().required().label(trackLabels.artists_publishing_ownerships.rights_type),
      performing_percentage: percentageValidation
        .label(trackLabels.artists_publishing_ownerships.performing_percentage),
      mechanical_percentage: percentageValidation
        .label(trackLabels.artists_publishing_ownerships.mechanical_percentage),
      sync_percentage:
        percentageValidation.label(trackLabels.artists_publishing_ownerships.sync_percentage),
      download_percentage: allowClearPercentageValidation
        .label(trackLabels.artists_publishing_ownerships.download_percentage),
      rent_percentage: allowClearPercentageValidation
        .label(trackLabels.artists_publishing_ownerships.rent_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(trackLabels.artists_publishing_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
      additional_notes: Joi.string().allow(null).allow('')
        .label(trackLabels.artists_publishing_ownerships.additional_notes),
    }),
  ).label(trackLabels.artists_publishing_ownerships.default),
  publishing_ownerships: Joi.array().items(
    Joi.object().keys({
      publisher: Joi.any().required().label(trackLabels.publishing_ownerships.publisher),
      rights_type: Joi.any().required().label(trackLabels.publishing_ownerships.rights_type),
      performing_percentage:
        percentageValidation.label(trackLabels.publishing_ownerships.performing_percentage),
      mechanical_percentage:
        percentageValidation.label(trackLabels.publishing_ownerships.mechanical_percentage),
      sync_percentage:
        percentageValidation.label(trackLabels.publishing_ownerships.sync_percentage),
      download_percentage: allowClearPercentageValidation
        .label(trackLabels.artists_publishing_ownerships.download_percentage),
      rent_percentage: allowClearPercentageValidation
        .label(trackLabels.artists_publishing_ownerships.rent_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(trackLabels.publishing_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
    }),
  ).label(trackLabels.publishing_ownerships.default),
  artists_master_ownerships: Joi.array().items(
    Joi.object().keys({
      artist: Joi.any().required().label(trackLabels.artists_master_ownerships.artist),
      alias: Joi.string().empty('').label(trackLabels.artists_master_ownerships.alias),
      rights_type: Joi.any().required().label(trackLabels.artists_master_ownerships.rights_type),
      ownership_percentage:
        percentageValidation.label(trackLabels.artists_master_ownerships.ownership_percentage),
      sync_percentage:
        percentageValidation.label(trackLabels.artists_master_ownerships.sync_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(trackLabels.artists_master_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
      instruments: Joi.array().items(Joi.any()),
      additional_notes: Joi.string().allow(null).allow('')
        .label(trackLabels.artists_master_ownerships.additional_notes),
    }),
  ).label(trackLabels.artists_master_ownerships.default),
  master_ownerships: Joi.array().items(
    Joi.object().keys({
      label: Joi.any().required().label(trackLabels.master_ownerships.label),
      rights_type: Joi.any().required().label(trackLabels.master_ownerships.rights_type),
      ownership_percentage:
        percentageValidation.label(trackLabels.master_ownerships.ownership_percentage),
      sync_percentage: percentageValidation.label(trackLabels.master_ownerships.sync_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(trackLabels.master_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
    }),
  ).label(trackLabels.master_ownerships.default),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const trackCustomFields = getFieldsByCollection('track', validation_fields);
  if (!validation_fields || isEmpty(trackCustomFields)) { // eslint-disable-line camelcase
    return TrackSchema;
  }

  return createCustomSchema(trackCustomFields, custom_fields, {
    defaultSchema: TrackSchema,
    labels: trackLabels,
  });
};
