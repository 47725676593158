// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// Other styles
import { bounceInLeft } from './../../../themes/animations';

// =============================
// Styled Components
// =============================

export const Wrapper = styled.div`
  cursor: pointer;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  display: flex;
  min-height: 100px;
  overflow: hidden;
  padding: 0 20px;
  animation-name: ${bounceInLeft};
  animation-duration: 500ms;

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

export const NotificationIcon = styled(SVGInline)`
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  fill: ${props => props.theme.colors[props.type]};
`;

export const Message = styled.span`
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 250px;
  overflow: hidden;

  & p {
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
  }
`;
