// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles
import { Div, Flex } from './views';

// =============================
// Styled Components
// =============================

export const PageTitle = styled.div`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.xl};
  margin-right: 55px;
  text-transform: uppercase;
`;

export const AddEntity = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  &:hover > div {
    background-color: ${props => props.theme.colorsHover.primary};
  }
`;

export const AddEntityIcon = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-size: 20px;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  transition: background-color ${props => props.theme.transition.medium};
  width: 26px;
`;

export const Dropdown = styled(Div)`
  position: relative;
`;

export const DropdownTitle = styled.div`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.s};
  align-items: center;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  height: 20px;
  padding-right: 7px;
`;

export const IconArrowDown = styled(SVGInline)`
  align-items: center;
  display: inline-flex;
  fill: #999;
  height: 6px;
  justify-content: center;
  margin-left: 7px;
  width: 13px;
`;

export const GridRow = styled(Flex)`
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  height: calc(70px * ${props => props.floor});
  min-height: calc(70px * ${props => props.floor});
  min-width: 100%;
  padding: 5px 0;

  &:not(:first-child):hover {
    ${props =>
    !props.noHover &&
      css`
        background-color: ${props.theme.colors.background};
      `};
  }

  ${props =>
    !props.noBorder &&
    css`
      border-bottom: 1px solid #ccc;
    `};
`;

GridRow.defaultProps = {
  floor: '1',
  noBorder: false,
};

export const MediaColumn = styled(Div)`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  min-width: ${props => props.width};
  padding-left: 10px;
`;

MediaColumn.defaultProps = {
  height: '100%',
  width: '90px',
};

export const Column = Flex.extend`
  ${props => props.theme.fontSize.xxs};
  align-items: safe center;
  align-content: normal;
  display: inline-flex;
  flex-wrap: wrap;
  min-width: ${props => props.width};
`;

Column.defaultProps = {
  height: '100%',
  pl: '20px',
  overflow: 'auto',
  width: '120px',
};

export const HeadColumn = Column.extend`
  ${props => props.theme.fonts.openSansSemiBold};
  color: ${props => props.theme.colors.primary};
  cursor: ${props => props.onClick && 'pointer'};
`;

export const NoEntity = styled.div`
  ${props => props.theme.fonts.openSansSemiBold};
  align-items: center;
  color: ${props => props.theme.colors.primary};
  display: inline-flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  min-height: 70px;
  min-width: 100%;
  padding: 5px 0;
  height: 400px;
`;

export const Highlight = styled.span`
  & em {
    font-weight: bold;
  }
`;

export const Wrapper = styled.div`
  overflow: ${(props) => props.overflow || 'visible'};
  margin-bottom: 40px;
  transform: rotateX(180deg);
`;

export const WrapperChild = styled.div`
  transform: rotateX(180deg);
`;

export const ImageLoaderWrapper = styled.div`
  ${props =>
    props.underlay &&
    css`
      position: absolute;
      &::before {
        content: '';
        position: absolute;
        background: black;
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0.3;
      }
    `};
`;
