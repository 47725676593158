// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'axios';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Themes
import { Flex } from './../../../themes/views';

// Styles
import { WaveformContainer, OriginalWaveform, SeekingContainer, SeekingWaveform } from './Waveform.styles';

// Constants
import * as states from './../../../constants/PlayerStates';

import FakeWaveform from '../../../assets/images/fake-waveform.svg';
// =============================
// Component
// =============================

class Waveform extends Component {
  animation = null;
  unmounted = false;

  constructor(props) {
    super(props);

    this.state = {
      svgFile: null,
      progress: 0,
    };
  }

  componentWillMount() {
    if (this.props.svg !== null) {
      get(this.props.svg)
        .then(({ data }) => {
          if (!this.unmounted) {
            this.setState({ svgFile: data });
          }
        }).catch(() => {
          this.setState({ svgFile: FakeWaveform });
        });
    } else {
      this.setState({ svgFile: FakeWaveform });
    }
    const isCurrentTrack = this.checkIsCurrentTrack(this.props);

    if (isCurrentTrack && this.props.playerState === states.PLAYING) {
      this.startAnimation();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { progress } = this.state;
    const isCurrentTrack = this.checkIsCurrentTrack(nextProps);

    if (this.props.trackDatas.id !== nextProps.trackDatas.id) {
      if (nextProps.svg !== null) {
        get(nextProps.svg)
          .then(({ data }) => {
            if (!this.unmounted) {
              this.setState({ svgFile: data });
            }
          }).catch(() => {
            this.setState({ svgFile: FakeWaveform });
          });
      } else {
        this.setState({ svgFile: FakeWaveform });
      }
    }

    if (isCurrentTrack) {
      // Start animation when it's current track and PlayerCore
      // state is Playing
      if (nextProps.playerState === states.PLAYING) {
        this.startAnimation();
      }

      if (
        nextProps.playerState === states.STOPPED ||
        nextProps.playerState === states.LOADING ||
        nextProps.playerState === states.LOADED ||
        nextProps.playerState === states.ENDED ||
        nextProps.playerState === states.ERROR
      ) {
        // Stop animation in theses cases
        this.stopAnimation();
        // progress is resetted to 0, we do not keep time history
        // of previously played tracks
        if (progress !== 0) this.setState({ progress: 0 });
      }

      if (
        nextProps.playerState === states.SEEKING ||
        nextProps.playerState === states.PAUSED
      ) {
        // Clear animation but keep current progress in theses cases
        this.stopAnimation();
      }
    }

    // Clear animation when current track changes
    if (!isCurrentTrack) {
      this.stopAnimation();
      // progress is resetted to 0, we do not keep time history
      // of previously played tracks
      if (progress !== 0) this.setState({ progress: 0 });
    }
  }

  componentWillUnmount() {
    this.stopAnimation();
    this.unmounted = true;
  }

  startAnimation = () => {
    const { trackDatas, getCurrentTime } = this.props;

    clearInterval(this.animation);
    this.animation = setInterval(() => {
      this.setState({ progress: (getCurrentTime() / trackDatas.duration) * 100 });
    }, 50);
  }

  stopAnimation = () => {
    clearInterval(this.animation);
  }

  checkIsCurrentTrack = (props) => {
    const {
      trackDatas,
      tracklistId,
      currentTrack,
      currentTracklistId,
    } = props;

    if (
      currentTrack &&
      currentTrack.id === trackDatas.id &&
      tracklistId === currentTracklistId
    ) {
      return true;
    }

    return false;
  }

  handleClick = (event) => {
    const {
      playerState,
      trackDatas,
      tracklistId,
      tracklistDatas,
      load,
      play,
      seek,
    } = this.props;

    // Determine if this track is current track
    const isCurrentTrack = this.checkIsCurrentTrack(this.props);

    if (isCurrentTrack) {
      if (
        playerState === states.STOPPED ||
        playerState === states.PAUSED ||
        playerState === states.ENDED ||
        playerState === states.ERROR
      ) {
        // Play track in these states
        play();
      } else if (
        playerState === states.PLAYING ||
        playerState === states.SEEKING
      ) {
        // Seek track in these states
        const { duration } = trackDatas;
        const offsetX =
          event.nativeEvent.pageX - event.nativeEvent.target.getBoundingClientRect().x;
        const { width } = this.waveformContainer.getBoundingClientRect();

        // Calculate seek value
        const mouseLeftPositionToContainer = (offsetX / width) * 100;
        const seekValue = (duration / 100) * mouseLeftPositionToContainer;

        // Seek to position
        seek(parseInt(seekValue, 10));
      }
    } else {
      // Load track when it's not current track
      load(trackDatas, tracklistId, tracklistDatas);
    }
  }

  render() {
    const { svgFile, progress } = this.state;
    const { width } = this.props;

    if (!svgFile) return null;
    // const { progress } = this.state;
    // const { svg, width } = this.props;

    return (
      <Flex align="center" {...getViewProps(this.props)} height="100%" width={width}>
        <WaveformContainer
          onClick={this.handleClick}
          innerRef={(e) => { this.waveformContainer = e; }}
        >
          <OriginalWaveform svg={svgFile} height="100%" width={width} />
          <SeekingContainer width={`${progress}%`}>
            <SeekingWaveform svg={svgFile} height="100%" width={width} />
          </SeekingContainer>
        </WaveformContainer>
      </Flex>
    );
  }
}

Waveform.propTypes = {
  svg: PropTypes.string,
  width: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  seek: PropTypes.func.isRequired,
  getCurrentTime: PropTypes.func,
  playerState: PropTypes.string.isRequired,
  currentTrack: PropTypes.object, // eslint-disable-line
  currentTracklistId: PropTypes.string,
  trackDatas: PropTypes.object.isRequired, // eslint-disable-line
  tracklistId: PropTypes.string.isRequired,
  tracklistDatas: PropTypes.object.isRequired, // eslint-disable-line
};

Waveform.defaultProps = {
  getCurrentTime: null,
  svg: null,
  currentTracklistId: null,
  currentTrack: null,
};

export default Waveform;
