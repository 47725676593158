// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import { Container, InputBase, Placeholder } from './Input.styles';

// =============================
// Component
// =============================

class Input extends Component {
  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    hasLabel: PropTypes.bool,
    isEditing: PropTypes.bool,
    onChange: PropTypes.func,
    getContainerRef: PropTypes.func,
    getInputRef: PropTypes.func,
  };

  static defaultProps = {
    name: '',
    type: 'text',
    value: '',
    placeholder: '',
    hasLabel: false,
    isEditing: false,
    onChange: null,
    getContainerRef: null,
    getInputRef: null,
  };

  onChange = (event) => {
    if (this.props.onChange) {
      const { name, value } = event.target;

      this.props.onChange(name, value, event);
    }
  }

  renderPlaceholder() {
    if (!this.props.placeholder ||
        this.props.value ||
        (this.props.hasLabel && !this.props.isEditing)
    ) {
      return null;
    }

    return <Placeholder>{this.props.placeholder}</Placeholder>;
  }

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <Container innerRef={this.props.getContainerRef}>
        <InputBase autocomplete="off" {...rest} innerRef={this.props.getInputRef} onChange={e => this.onChange(e)} />
        {this.renderPlaceholder()}
      </Container>
    );
  }
}

export default Input;
