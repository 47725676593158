// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';

// Helpers
import { translateWithLocale, getOrResetLocale } from './../../helpers/I18n';

// =============================
// Component
// =============================

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default () => WrappedComponent =>
  class extends Component {
    static displayName = `WithI18n(${getDisplayName(WrappedComponent)})`;

    static propTypes = {};

    static defaultProps = {};

    render() {
      return (
        <WrappedComponent {...this.props} t={translateWithLocale} locale={getOrResetLocale()} />
      );
    }
  };
