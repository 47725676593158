// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { HOME_PAGE_ROUTE, UPLOADS_PAGE_ROUTE } from './../../../constants/Routes';

// Config
import envConfig from './../../../../config/private/environment';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import MenuDropdown from './../../containers/menus/menudropdown/MenuDropdownContainer';
import SearchBar from './../../containers/searchbar/SearchBarContainer';

// Styles
import {
  Container,
  LinkToHome,
  LogoStyled,
  Actions,
  Link,
  ExternalLink,
  UserInitials,
  Initials,
  DashboardIconStyled,
} from './Header.styles';
import { ContainerView, Div } from './../../../themes/views';

// Helpers

// Assets
import BmatLogo from './../../../assets/images/logo-bmat.svg';
import IconCheck from './../../../assets/images/icon-arrow-left.svg';
import IconLogout from './../../../assets/images/icon-logout.svg';

// =============================
// Component
// =============================

const Header = ({ userInfo, height, children, t, match: { path }, canUseUploadManager }) => {
  const dropdownLinks = [
    {
      name: t('components.header.dashboard'),
      href: envConfig.bmatDashboardUrl,
      icon: <DashboardIconStyled svg={IconCheck} width="100%" height="100%" />,
    },
    {
      name: t('components.menus.menuDropdown.signOut'),
      href: '/logout',
      icon: <DashboardIconStyled svg={IconLogout} width="100%" height="100%" />,
    },
  ];
  return [
    <Container key="1">
      <ContainerView align="center" height="50px" justify="space-between">
        <LinkToHome to={HOME_PAGE_ROUTE}>
          <LogoStyled svg={BmatLogo} width="100%" height="100%" />
        </LinkToHome>
        {UPLOADS_PAGE_ROUTE !== path && <SearchBar />}
        <Actions>
          <ExternalLink href="https://reportingtool.bmat.com">{t('components.header.reportingTool')}</ExternalLink>
          { canUseUploadManager && <Link to={UPLOADS_PAGE_ROUTE}>{t('components.header.uploadManager')}</Link> }
          <MenuDropdown ml="auto" links={dropdownLinks}>
            <UserInitials color={userInfo.avatar_color}>
              <Initials>
                {userInfo.first_name.charAt(0)}
                {userInfo.last_name.charAt(0)}
              </Initials>
            </UserInitials>
          </MenuDropdown>
        </Actions>
      </ContainerView>

      {children}
    </Container>,
    <Div height={height} key="2" />,
  ];
};

Header.propTypes = {
  userInfo: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    avatar_color: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
  }).isRequired,
  canUseUploadManager: PropTypes.bool.isRequired,
  height: PropTypes.string,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

Header.defaultProps = {
  height: '50px',
  children: null,
};

export default withI18n()(Header);
