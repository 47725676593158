// =============================
// Imports
// =============================

// External Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { uploadFile, removeFileById } from '../../../../actions/SidePanelActions';

// HOC
import withConfirmation from '../../../hoc/WithConfirmation';
import withI18n from '../../../hoc/WithI18n';

// Components
import FileUpload from './FileUpload';
import Status from './status/Status';

// Styles
import {
  Container,
  IconType,
  FileName,
  Icon,
  DownloadLink,
} from './FileUpload.styles';
import trashSVG from '../../../../assets/images/icon-trash.svg';
import downloadSVG from '../../../../assets/images/icon-download.svg';
import imageSVG from '../../../../assets/images/file-picture.svg';

// =============================
// Component
// =============================

class AttachmentFileInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    isShow: PropTypes.bool.isRequired,
    filetype: PropTypes.string,
    type: PropTypes.string.isRequired,
    panel: PropTypes.shape({
      id: PropTypes.string,
      uploads: PropTypes.object, // eslint-disable-line
    }).isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
      file_name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    removeFileById: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    conversion_state: PropTypes.bool,
    canDownload: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filetype: 'File',
    files: [],
    conversion_state: false,
    onConfirm: () => Promise.resolve(false),
  };

  state = {
    status: this.props.panel.uploads.get(this.props.type),
  };

  onDelete = (fileId) => {
    const { disabled, type, panel, removeFileById: propsRemoveFile, onConfirm, t } = this.props;
    if (!disabled) {
      onConfirm(t('components.fileUpload.deleteConfirmationMessage'), {
        title: t('components.fileUpload.deleteConfirmationTitle'),
        submit: t('components.fileUpload.deleteConfirmationSubmit'),
      }).then((result) => {
        if (result) propsRemoveFile(type, panel, fileId);
      });
    }
  };

  getStatus = (file) => {
    const { status } = this.state;

    if (!file || (status && status.progress)) {
      return null;
    }

    if (file && !status) {
      return !this.props.conversion_state;
    }

    return status && !status.error;
  };

  renderDownload(file) {
    const { canDownload, t } = this.props;
    if (!canDownload) return null;

    return (
      <DownloadLink
        download
        href={file.url}
        title={t('components.fileUpload.download')}
        target="_blank"
      >
        <Icon svg={downloadSVG} height="100%" width="100%" />
      </DownloadLink>
    );
  }

  render() {
    const { files: propFiles, disabled, filetype, panel, type, isShow } = this.props;
    const files = propFiles.filter(file => file);

    return (
      <Fragment>
        {files.map(({ original: file }) => (
          <Container direction="row" align="center">
            <IconType svg={imageSVG} />
            <FileName title={file.file_name}>{file.file_name}</FileName>
            {this.renderDownload(file)}
            {!disabled && <Icon svg={trashSVG} height="100%" width="100%" onClick={() => this.onDelete(file.id)} />}
            <Status status={this.getStatus(file)} />
          </Container>
        ))}
        <FileUpload
          disabled={disabled}
          filetype={filetype}
          panel={panel}
          type={type}
          file={null}
          show={isShow}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    canDownload: user.canDownload,
  };
}

export default connect(
  mapStateToProps,
  { uploadFile, removeFileById },
)(withConfirmation(withI18n()(AttachmentFileInput)));
