// =============================
// Imports
// =============================

// External dependencies
import axios from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { remove as removeCookie } from 'js-cookie';

// Configs

// Helpers
import { getCookieConfig } from './helpers';

// =============================
// Helper
// =============================

/**
 * The purpose of this helper is to redirect the user to the dashboard
 * whenever his token is invalid
 */
const instance = axios.create();

instance.interceptors.response.use(response => response, (error) => {
  // When token is invalid
  if (!axios.isCancel(error) &&
    includes(['invalid_token', 'user_permission', 'inactive_subscription'], get(error, 'response.data.key'))
  ) {
    // Remove x-auth cookie
    removeCookie('x-auth', getCookieConfig());
  }

  // For all other errors
  return Promise.reject(error);
});

export default instance;
