/* eslint-disable import/prefer-default-export */
// NOTE: helper for create, update or remove action. adds element to collection if
//   not present, updates otherwise, or if value is undefined - removes it from
//   collection

export function saveToCollection(collection, id, value, getId) {
  const finder = (object, i) => getId(object, i) === id;
  if (collection.some(finder)) {
    return collection.reduce((c, o, i) => {
      if (getId(o, i) !== id) return [...c, o];
      if (value === undefined) return c;
      if (typeof o === 'object') return [...c, { ...o, ...value }];
      return [...c, value];
    }, []);
  }

  if (value === undefined) return collection;
  return [...collection, value];
}
