/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import { Div } from './../../../themes/views';

export const Container = Div.extend`
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius.box};
  box-shadow: 0 0 20px 0 ${props => props.theme.colors.shadow};
  color: #000;
  display: none;
  height: auto;
  max-height: ${props => (props.height ? props.height : '100%')};
  min-height: 20px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity ${props => props.theme.transition.medium};
  width: ${props => (props.width ? props.width : '100%')};
  z-index: ${props => props.theme.zIndex.dropdownlist};
  ${props =>
    props.isOpen &&
    css`
      display: block;
      opacity: 1;
      pointer-events: auto;
    `};
  ${props =>
    !props.isShownOnEmpty &&
    css`
      &:empty {
        display: none;
      }
    `};
  ${props =>
    (props.direction === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `)};
  ${(props) => {
    if (props.verticalPosition === 'top') {
      return css`
        top: calc(100% + 10px);
      `;
    }
    if (props.verticalPosition === 'verytop') {
      return css`
        top: 0;
      `;
    }

    return css`
      bottom: calc(100% + 10px);
    `;
  }};

  /* pb won't work, so we add mb on :last-child (pt added on DefaultProps) */
  & > :last-child {
    margin-bottom: 5px;
  }
`.withComponent('ul');

Container.defaultProps = {
  verticalPosition: 'top',
  height: '200px',
  overflow: 'auto',
  pt: '5px',
};

export const Item = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  min-height: ${props => (props.height ? props.height : '50px')};
  margin: 0;
  padding: ${props => (props.padding ? props.padding : '0 10px')};
  width: 100%;
  ${props =>
    props.selected &&
    css`
      background-color: #ecf4f8; /* TODO: Voir si on peut utiliser une couleur du thème */
      opacity: 1;
    `};
  ${props =>
    !props.inactive &&
    css`
      &:hover {
        background-color: #ecf4f8; /* TODO: Voir si on peut utiliser une couleur du thème */
        opacity: 1;
      }

      &:focus {
        background-color: #cfebfb; /* TODO: Voir si on peut utiliser une couleur du thème */
        outline: none;
      }
    `};
  ${props =>
    props.borderBottom &&
    css`
      &:not(:last-child) {
        border-bottom: ${props.borderBottom};
      }
    `};
  ${props =>
    props.borderTop &&
    css`
      &:not(:first-child) {
        border-top: ${props.borderTop};
      }
    `};
`;

export const OutlistItem = Item.withComponent('div');

export const SubItem = styled.div`
  position: relative;
  width: 100%;
  right: -100%;
`;
