import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const playlistLabels = {
  title: 'Title',
  public: 'Public',
  showcase: 'Showcase',
  agents: 'Agents',
  tracks: 'Tracks',
};

const PlaylistSchema = {
  title: Joi.string().label(playlistLabels.title).required(),
  public: Joi.boolean().label(playlistLabels.public),
  showcase: Joi.boolean().label(playlistLabels.showcase),
  agents: Joi.array().items(Joi.string()).label(playlistLabels.agents),
  tracks: Joi.array().items(Joi.string()).label(playlistLabels.tracks),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string(),
    value: Joi.string(),
  })),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const playlistCustomFields = getFieldsByCollection('playlist', validation_fields);
  if (!validation_fields || isEmpty(playlistCustomFields)) { // eslint-disable-line camelcase
    return PlaylistSchema;
  }

  return createCustomSchema(playlistCustomFields, custom_fields, {
    defaultSchema: PlaylistSchema,
    labels: playlistLabels,
  });
};
