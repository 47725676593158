// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import CollapsibleBox from './../collapsiblebox/CollapsibleBox';
import TagListBody from './TagListBody';
import TagSubCategory from './TagSubCategory';

// Helpers
import { tagFilter } from './../../../helpers/tags';
import { getName } from './../../../helpers/I18n';

// =============================
// Component
// =============================

class TagCategory extends Component {
  static propTypes = {
    tagCategory: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    filter: PropTypes.string,
    onChange: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    filter: null,
    onChange: null,
  };

  allTags = () => {
    const { tagCategory } = this.props;
    return tagCategory.tags.concat(...tagCategory.subCategories.map(tc => tc.tags));
  };

  getCount = (allTags) => {
    const { data } = this.props;
    return intersection(allTags.map(t => t.id), data.map(dt => dt.value)).length;
  };

  isOpen = (allTags) => {
    const { filter, locale } = this.props;

    if (this.getCount(allTags) > 0) return true;

    return !!filter && allTags.some(tagFilter(filter, locale));
  };

  filteredTags = (tags, filter, locale) => tags.filter(tagFilter(filter, locale));

  renderBody = () => {
    const { tagCategory, data, filter, onChange, locale } = this.props;

    const props = {
      color: tagCategory.color,
      data,
      filter,
      onChange,
    };

    if (tagCategory.tags.length > 0) {
      const filteredTags = this.filteredTags(tagCategory.tags, filter, locale);
      return <TagListBody filteredTags={filteredTags} {...props} />;
    }

    return tagCategory.subCategories.map((subCategory) => {
      const filteredTags = filter
        ? this.filteredTags(subCategory.tags, filter, locale)
        : subCategory.tags;
      return (<TagSubCategory
        key={subCategory.key}
        subCategory={subCategory}
        filteredTags={filteredTags}
        {...props}
      />);
    });
  };

  render() {
    const { tagCategory, locale } = this.props;

    const allTags = this.allTags();

    return (
      <CollapsibleBox
        key={tagCategory.key}
        title={getName(tagCategory, locale)}
        counter={this.getCount(allTags) || undefined}
        isOpen={this.isOpen(allTags)}
        light
      >
        <div>{this.renderBody()}</div>
      </CollapsibleBox>
    );
  }
}

export default withI18n()(TagCategory);
