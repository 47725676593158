// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Components
import PlaylistAdder from '../../presentationals/playlistadder/PlaylistAdder';

function mapStateToProps({ user }) {
  return {
    sessionToken: user.sessionToken,
  };
}

export default connect(
  mapStateToProps,
)(PlaylistAdder);
