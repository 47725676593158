// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import InputContainer from '../container/InputContainer';
import DropdownList from '../../dropdownlist/DropdownList';
import Input from '../input/Input';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import { PoweredByGoogleLogo } from './GooglePlacesInput.styles';
import { Item } from '../../dropdownlist/DropdownList.styles';

// =============================
// Component
// =============================
class GooglePlacesInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    data: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    data: '',
    error: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
  };

  constructor(props) {
    super(props);

    this.autocompleteApi = new window.google.maps.places.AutocompleteService(
      document.createElement('div'),
    );
    this.geocodingApi = new window.google.maps.Geocoder();
    this.placesApi = new window.google.maps.places.PlacesService(document.createElement('div'));

    // Debounced Request for textSearch
    this.debounceRequest = debounce(this.makeRequest, 200, {
      leading: true,
      maxWait: 300,
    });
  }

  state = {
    isEditing: false,
    dropDownIsOpened: false,
    query: '',
    places: [],
    error: '',
  };

  componentWillMount() {
    const { data } = this.props;
    if (data) this.reverseGeocoding();
  }

  getDropdowRef = (e) => {
    this.dropDownRef = e;
  };

  getAddressLabel = (name) => {
    const { t } = this.props;
    switch (name) {
      case 'street_number':
        return t('components.inputs.googlePlacesInput.labelStreetNumber');
      case 'route':
        return t('components.inputs.googlePlacesInput.labelRoute');
      case 'locality':
        return t('components.inputs.googlePlacesInput.labelLocality');
      case 'postal_code':
        return t('components.inputs.googlePlacesInput.labelPostalCode');
      case 'country':
        return t('components.inputs.googlePlacesInput.labelCountry');
      default:
        return name;
    }
  };

  getErrorsFromStatus = (status) => {
    const { t } = this.props;
    switch (status) {
      case 'ZERO_RESULTS':
        return t('components.inputs.googlePlacesInput.errorZeroResults');
      case 'INVALID_REQUEST':
        return t('components.inputs.googlePlacesInput.errorInvalidRequest');
      case 'UNKNOWN_ERROR':
        return t('components.inputs.googlePlacesInput.errorUnknown');
      case 'ERROR':
        return t('components.inputs.googlePlacesInput.errorUnknown');
      default:
        return t('components.inputs.googlePlacesInput.errorDefault');
    }
  };

  reverseGeocoding = () => {
    const { data } = this.props;

    this.geocodingApi.geocode({ placeId: data }, (result, status) => {
      if (status === 'OK') {
        this.setState({
          query: result[0].formatted_address,
        });
      } else {
        this.setState({
          error: this.getErrorsFromStatus(status),
        });
      }
    });
  };

  makeRequest = () =>
    this.autocompleteApi.getQueryPredictions(
      // Query
      { input: this.state.query, types: ['address'] },
      // Callback
      (result, status) => {
        if (status === 'OK') {
          this.setState({
            places: result,
          });
        } else {
          this.setState({
            error: this.getErrorsFromStatus(status),
          });
        }
      },
    );

  /*
    Check if an address has every mandatory informations
    street_number - 17
    route - Rue des Longs
    locality - Boulogne-Billancourt
    postal_code - 92100
    country - France
    administrative_area_level_1 - Île-de-France
    administrative_area_level_2 - Hauts-de-Seine
  */
  checkAddress = placeId =>
    new Promise((resolve, reject) => {
      this.placesApi.getDetails(
        {
          placeId,
        },
        (result, status) => {
          const { t } = this.props;
          if (status === 'OK') {
            const addressComponent = ['locality', 'country'];

            // We get all types present in our API response
            const types = [];
            result.address_components.forEach((comp) => {
              comp.types.forEach(type => types.push(type));
            });

            // We check which mandatory address component are present
            const check = addressComponent.map(comp => ({
              name: comp,
              label: this.getAddressLabel(comp),
              value: types.indexOf(comp) !== -1,
            }));

            const errors = filter(check, comp => comp.value === false).map(error => error.label);

            if (errors.length) {
              const error = `${t(
                'components.inputs.googlePlacesInput.errorMissingDetails',
              )} ${errors.join(', ')}`;

              reject(error);
            }

            this.setState({
              error: '',
            });

            resolve(result);
          } else {
            this.setState({
              error: this.getErrorsFromStatus(status),
            });

            reject(this.getErrorsFromStatus(status));
          }
        },
      );
    });

  handlePickItem = (placeId) => {
    const { name, onChange } = this.props;

    this.setState({
      dropDownIsOpened: false,
    });

    this.checkAddress(placeId)
      .then((result) => {
        this.setState({ query: result.formatted_address });

        if (onChange) {
          onChange(name, result.place_id);
        }
      })
      .catch(err => this.setState({ error: err }));

    this.inputRef.focus();
  };

  handleChange = (name, value) => {
    const { onChange } = this.props;

    if (onChange) {
      this.setState(
        {
          query: value,
          dropDownIsOpened: true,
        },
        () => {
          if (value === '' && onChange) return onChange(name, '');

          return this.debounceRequest();
        },
      );
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }

    this.setState({
      isEditing: false,
    });
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }

    this.setState({
      isEditing: true,
    });
  };

  // When user clicks outside of menu
  // close the menu
  handleClickOutside = () => {
    this.toggleDropDown(false);
  };

  // Open & Close Dropdown
  toggleDropDown = (state) => {
    this.setState({
      dropDownIsOpened: state,
    });
  };

  handleInputKeyDown = (event) => {
    const { key } = event;

    // Open Dropdown on keyDown, except if user press tab
    this.toggleDropDown(key !== 'Tab');

    // Clear error
    this.setState({
      error: '',
    });

    // KeyDown
    if (key === 'ArrowDown') {
      event.preventDefault();
      if (this.dropDownRef.firstChild) this.dropDownRef.firstChild.focus();
    }
  };

  handleItemKeyDown = (e) => {
    const { key, target } = e;

    switch (key) {
      case 'Tab':
        this.setState({
          dropDownIsOpened: false,
        });
        break;

      // Enter: Save item
      case 'Enter':
        this.handlePickItem(target.dataset.id);
        break;

      // Arrow Up: Go to next item
      case 'ArrowUp':
        e.preventDefault();
        if (!target.previousSibling) {
          this.dropDownRef.lastChild.previousSibling.focus();
          this.dropDownRef.lastChild.previousSibling.scrollIntoView();
          break;
        }

        target.previousSibling.focus();
        target.previousSibling.scrollIntoView();
        break;

      // Arrow Down: Go to previous item
      case 'ArrowDown':
        e.preventDefault();
        if (!target.nextSibling || target.nextSibling.dataset.google) {
          this.dropDownRef.firstChild.focus();
          this.dropDownRef.firstChild.scrollIntoView();
          break;
        }

        target.nextSibling.focus();
        target.nextSibling.scrollIntoView();
        break;

      // If user is not navigating nor saving, we assume he wants to add some filter
      default:
        this.inputRef.focus();
        break;
    }
  };

  renderItem = () => {
    const { places } = this.state;

    const retVal = places.map((place) => {
      if (!place.place_id) return null; // We remove prediction without place_id
      return (
        <Item
          height="40px"
          key={place.id}
          data-id={place.place_id}
          onClick={() => {
            this.handlePickItem(place.place_id);
          }}
          onKeyDown={e => this.handleItemKeyDown(e)}
          tabIndex="-1"
        >
          {place.description}
        </Item>
      );
    });

    if (retVal.length) retVal.push(<PoweredByGoogleLogo data-google key="poweredByGoogleLogo" />);

    return retVal;
  };

  render() {
    const { name, label } = this.props;
    const { isEditing, query, dropDownIsOpened, error } = this.state;

    return (
      <InputContainer
        filled={!!query}
        active={isEditing}
        label={label}
        error={error}
        {...getViewProps(this.props)}
      >
        <Input
          getInputRef={(e) => {
            this.inputRef = e;
          }}
          name={name}
          value={query}
          onClick={() => this.toggleDropDown(true)}
          onChange={this.handleChange}
          onKeyDown={this.handleInputKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
        <DropdownList
          getRef={this.getDropdowRef}
          isOpen={dropDownIsOpened}
          items={() => this.renderItem()}
          handleClickOutside={this.handleClickOutside}
        />
      </InputContainer>
    );
  }
}

export default withI18n()(GooglePlacesInput);
