// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Constants
import { ENABLED_TYPES } from './../../../constants/EntityGrid';

// Helpers
import { saveToCollection } from './../../../helpers/immutable';
import { getColumnName } from './../../../helpers/entitygrid';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import DropdownList from './../../presentationals/dropdownlist/DropdownList';
import CheckBox from './../../presentationals/checkbox/CheckBox';

// Styles
import { Dropdown, DropdownTitle, IconArrowDown } from './../../../themes/entityGrid';
import { Item } from './../../presentationals/dropdownlist/DropdownList.styles';

// Assets
import iconDropdownArrowDown from './../../../assets/images/icon-dropdown-arrow.svg';

// =============================
// Component
// =============================
const { ALWAYS } = ENABLED_TYPES;

class CustomizeViewDropdown extends Component {
  static propTypes = {
    columns: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    enabledColumns: PropTypes.arrayOf(PropTypes.string),

    onUpdateEnabledColumns: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    columns: [],
    enabledColumns: [],

    onUpdateEnabledColumns: () => {},
  };

  state = {
    isOpen: false,
  };

  toggleDropdown = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  handleChange = key => () => {
    const { enabledColumns, onUpdateEnabledColumns } = this.props;
    const value = enabledColumns.some(k => k === key) ? undefined : key;

    onUpdateEnabledColumns(saveToCollection(enabledColumns, key, value, v => v));
  };

  handleSelectAll = () => {
    const { columns, enabledColumns, onUpdateEnabledColumns } = this.props;
    const allColumns = columns.filter(({ enabled }) => enabled !== ALWAYS);
    if (enabledColumns.length === allColumns.length) onUpdateEnabledColumns([]);
    else onUpdateEnabledColumns(allColumns.map(c => c.key));
  };

  renderItems = () => {
    const { columns, enabledColumns } = this.props;

    return columns.filter(({ enabled }) => enabled !== ALWAYS).map(column => (
      <Item key={column.key} onClick={this.handleChange(column.key)} height="40px">
        <CheckBox check={enabledColumns.includes(column.key)} onClick={() => {}} mr="10px" />
        {getColumnName(column)}
      </Item>
    ));
  };

  render() {
    const { t } = this.props;
    const { isOpen } = this.state;

    return (
      <Dropdown ml="15">
        <DropdownTitle className="customize-view" onClick={this.toggleDropdown}>
          {t('components.entityGrid.customizeView')}
          <IconArrowDown svg={iconDropdownArrowDown} height="100%" width="100%" />
        </DropdownTitle>
        <DropdownList
          direction="right"
          height="220px"
          width="220px"
          isOpen={isOpen}
          handleClickOutside={this.toggleDropdown}
          outsideClickIgnoreClass="customize-view"
        >
          <Item onClick={this.handleSelectAll} height="40px">
            {t('components.entityGrid.selectAll')}
          </Item>
          {this.renderItems()}
        </DropdownList>
      </Dropdown>
    );
  }
}

export default withI18n()(CustomizeViewDropdown);
