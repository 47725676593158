/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// =============================
// Styled Components
// =============================

export const DialogForm = styled.form`
  display: flex;
  width: 350px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;
