// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ApiSelectInput from './../../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Constants
import { LABEL_PANEL } from './../../../../../constants/SidePanelTypes';

// =============================
// Component
// =============================

class OwnerLabelInput extends Component {
  static propTypes = {
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { ownership, errors, disabled, label, onChange, onFocus, onBlur } = this.props;

    const { label: labelEntity } = ownership;

    return (
      <ApiSelectInput
        name="label"
        parseName={e => e.label_name}
        label={label}
        entity="meta/labels"
        panelName={LABEL_PANEL}
        defaultField="label_name"
        type="single"
        data={labelEntity}
        values={labelEntity ? labelEntity.id : null}
        onChange={
          disabled ? null : (name, value, newLabel) => onChange({ ...ownership, label: newLabel })
        }
        onFocus={onFocus}
        onBlur={onBlur}
        error={errors.label}
        width="calc(100% - 70px)"
      />
    );
  }
}

export default OwnerLabelInput;
