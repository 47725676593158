// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// Styles
import { Div } from './../../../../themes/views';

export const MaiaInfo = styled(Div)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const MaiaLabel = styled(Div)`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PlayableCover = styled(Div)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const PlayerContainer = styled(Div)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  fill: transparent;
  left: 0;
  position: absolute;
  transition: background-color ${props => props.theme.transition.medium};
  top: 0;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    fill: #fff;
  }
`;
