/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// Constants
import { ON_THE_FLY_CREATION_ERROR, API_SELECT_INPUT_FETCH_ERROR } from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export function onTheFlyCreationError(error, query) {
  return {
    type: ON_THE_FLY_CREATION_ERROR,
    payload: {
      error,
      query,
    },
  };
}

export function fetchError(error) {
  return {
    type: API_SELECT_INPUT_FETCH_ERROR,
    payload: {
      error,
    },
  };
}
