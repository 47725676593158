// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import MenuTabs from '../../../presentationals/menus/menutabs/MenuTabs';

// =============================
// Container
// =============================

function mapStateToProps({ search }) {
  return {
    searchData: search,
  };
}

export default withRouter(connect(mapStateToProps)(MenuTabs));
