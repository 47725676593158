// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import AlbumTracks from './AlbumTracks';
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import ImageLoader from '../../imageloader/ImageLoader';
import Tag from './../../tag/Tag';

import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import InputBase from '../../inputs/inputunderline/InputUnderline';
import ApiSelectInputBase from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';
import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import DisplayArtistInputBase from '../../inputs/displayartistinput/DisplayArtistInput';
import DateInputBase from '../../inputs/dateinput/DateInput';
import FileUploadBase from '../../sidepanel/fileupload/FileUpload';
import AttachmentFileInputBase from '../../sidepanel/fileupload/AttachmentFileInput';
import MultipleSelectBase from './../../inputs/multipleselect/MultipleSelect';
import SelectInputBase from '../../inputs/selectinput/SelectInput';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import LabelsMasterOwnerInputBase from './../../inputs/ownerinput/LabelsMasterOwnerInput';
import PublishersPublishingOwnerInputBase from './../../inputs/ownerinput/PublishersPublishingOwnerInput';
import ArtistsMasterOwnerInputBase from '../../inputs/ownerinput/ArtistsMasterOwnerInput';
import ArtistsPublishingOwnerInputBase from '../../inputs/ownerinput/ArtistsPublishingOwnerInput';
import TranslateAreaBase from '../../../containers/inputs/translatearea/TranslateAreaContainer';
import TagListBase from '../../../containers/taglist/TagListContainer';
import CustomIdGeneratorBase from './../../customs/CustomIdGenerator';

// Helpers
import isBlank from './../../../../helpers/isBlank';
import { getName } from './../../../../helpers/I18n';

// Schema
import AlbumSchema from './Album.schema';

// Constants
import { CATALOG_PANEL, ALBUM_PANEL } from '../../../../constants/SidePanelTypes';

// Styles
import { IconArchive, IconExportCsv } from './../../../../themes/icons';
import { Div, Flex } from './../../../../themes/views';
import { PanelContent, SubCategory as SubCategoryBase } from './../../sidepanel/SidePanel.styles';
import { Action } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const CollapsibleBox = hideable(CollapsibleBoxBase);
const Input = hideable(InputBase);
const ApiSelectInput = hideable(ApiSelectInputBase);
const DisplayArtistInput = hideable(DisplayArtistInputBase);
const DateInput = hideable(DateInputBase);
const FileUpload = hideable(FileUploadBase);
const AttachmentFileInput = hideable(AttachmentFileInputBase);
const MultipleSelect = hideable(MultipleSelectBase);
const SelectInput = hideable(SelectInputBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);
const TagList = hideable(TagListBase);
const SubCategory = hideable(SubCategoryBase);
const ArtistsMasterOwnerInput = hideable(ArtistsMasterOwnerInputBase);
const ArtistsPublishingOwnerInput = hideable(ArtistsPublishingOwnerInputBase);
const LabelsMasterOwnerInput = hideable(LabelsMasterOwnerInputBase);
const PublishersPublishingOwnerInput = hideable(PublishersPublishingOwnerInputBase);
const CustomIdGenerator = hideable(CustomIdGeneratorBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'distribution', 'picture', 'master_info', 'publishing_info'];

export class Album extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      tracks: PropTypes.array, // eslint-disable-line
      multipartTracks: PropTypes.array, // eslint-disable-line
      tracksTags: PropTypes.array, // eslint-disable-line
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    openSidePanel: PropTypes.func.isRequired,
    exportCsv: PropTypes.func.isRequired,
    exportJson: PropTypes.func.isRequired,
    downloadArchive: PropTypes.func.isRequired,
    territories: PropTypes.array.isRequired, // eslint-disable-line
    neighboringrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    mechanicalrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    performingrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    rightstypes: PropTypes.array.isRequired, // eslint-disable-line
    albumtypes: PropTypes.array.isRequired, // eslint-disable-line
    trackversions: PropTypes.array.isRequired, // eslint-disable-line
    tagCategories: PropTypes.array.isRequired, // eslint-disable-line
    agents: PropTypes.array.isRequired, // eslint-disable-line
    canDownload: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool.isRequired,
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {};

  onSelectChange = (name, value) => {
    if (value === '') {
      this.props.updateForm(name, null);
    } else {
      this.props.updateForm(name, value);
    }
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    const tracks = panel.tracks || [];

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'tracks':
        return !!(panel.data && (this.checkIfOwned() || tracks.length > 0));
      case 'album_info':
        return ['upc', 'ean', 'catalog'].some(this.isShown);
      case 'album':
        return [
          'display_artists',
          'album_info',
          'title',
          'album_ref',
          'album_type',
          'release_date',
        ].some(this.isShown);
      case 'master_info':
        return ['isrc', 'artists_master_ownerships', 'master_ownerships', 'original_nrs'].some(
          this.isShown,
        );
      case 'publishing_info':
        return [
          'iswc',
          'cocv',
          'prs_tunecode',
          'artists_publishing_ownerships',
          'publishing_ownerships',
          'original_mrs',
          'original_prs',
        ].some(this.isShown);
      case 'distribution':
        return !!(this.checkIfOwned() && panel.data);
      case 'public':
        return !!(this.checkIfOwned() && panel.data);
      case 'showcase':
        return !!panel.data;
      case 'picture':
        return this.checkIfOwned() || !isBlank(get(panel, ['data', 'image', 'original']));
      case 'attachments':
        return this.checkIfOwned() || !isBlank(panel.data && panel.data.attachments);
      case 'tags':
        return !!(this.checkIfOwned() && panel.data && tracks.length > 0);
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return this.checkIfOwned() || !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  getNestedHiddenFields = (prefix) => {
    const { hiddenFields } = this.props;
    // get fields by prefix and remove prefix from field name
    const nestedHiddenFields = hiddenFields.filter(field => startsWith(field, prefix))
      .map(field => field.split('.').filter((word, index) => index > 0).join('.'));
    return compact(nestedHiddenFields);
  };

  getInstruments = () => {
    const { tagCategories } = this.props;
    const instrumentCategory = tagCategories.find(category => category.key === 'instruments');
    const instruments = [];

    if (instrumentCategory) {
      instrumentCategory.subCategories.forEach(
        subCategory => instruments.push(...(subCategory.tags || [])),
      );
    }

    return instruments;
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    const { data } = this.props.panel;

    if (data && data.title && data.album_ref) return `${data.album_ref} - ${data.title}`;
    if (data && data.title) return data.title;
    return t('components.panels.album.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { panel, tagCategories, locale } = this.props;
    const tracksTags = panel.tracksTags || [];

    const getTags = (tags) => {
      let tagsList = null;
      if (tags.length) {
        tagsList = tags.map((tag) => {
          // Find color
          let color;
          for (let c = 0, catLen = tagCategories.length; c < catLen; c += 1) {
            const category = tagCategories[c];
            if (find(category.tags, t => t.id === tag.tag.id)) {
              ({ color } = category);
              break;
            }

            const { subCategories } = category;
            for (let s = 0, subLen = subCategories.length; s < subLen; s += 1) {
              const subCategory = subCategories[s];
              if (find(subCategory.tags, t => t.id === tag.tag.id)) {
                ({ color } = category);
                break;
              }
            }
          }

          return (
            <Tag
              id={tag.tag.id}
              color={color}
              key={tag.tag.id}
              label={getName(tag.tag, locale)}
              value={tag.tag.id}
              status={2}
            />
          );
        });

        return (
          <Div height="100px" overflow="auto">
            {tagsList}
          </Div>
        );
      }

      return null;
    };

    return getTags(tracksTags);
  };

  renderPanelActions = () => {
    const {
      exportCsv,
      exportJson,
      downloadArchive,
      panel: { data },
      t,
    } = this.props;

    if (!data || !data.id) return null;

    return (
      <Flex direction="column">
        <Action
          onClick={() => {
            downloadArchive('album', data.id);
          }}
          align="center"
        >
          <IconArchive inherit />
          <Div ml="8px">{t('components.panels.album.downloadArchive')}</Div>
        </Action>
        <Action
          onClick={() => {
            exportCsv('album', data.id);
          }}
          align="center"
        >
          <IconExportCsv inherit />
          <Div ml="4px">{t('components.panels.album.exportCsv')}</Div>
        </Action>
        {data &&
        data.id && (
          <Action
            onClick={() => {
              exportJson('album', data.id);
            }}
            align="center"
          >
            <IconExportCsv inherit />
            <Div ml="4px">{t('components.panels.track.exportJson')}</Div>
          </Action>
        )}
        {data &&
        data.id && (
          <Action
            align="center"
          >
            <IconExportCsv inherit />
            <Div ml="4px">
              <Link
                to={`/print/albums/${data.id}`}
                component={Action}
                align="center"
                target="_blank"
              >{t('components.print.openPrintableVersion')}
              </Link>
            </Div>
          </Action>
        )}
      </Flex>
    );
  };

  updateTags = (name, value) => {
    const { panel, updateAdditionalFormData } = this.props;

    const tracksTags = panel.tracksTags || [];
    const tagList = tracksTags.filter(t => t.status === 2).map(t => t.tag.id);

    if (tagList.indexOf(value) === -1) {
      // Add tag to list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'POST' },
        { tracksTags: 'tracks/tags' },
      );
    } else {
      // Remove tag from list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'DELETE' },
        { tracksTags: 'tracks/tags' },
      );
    }
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, locale, form, delaySave, panel } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;
    const { id: albumId } = panel;

    let textCustomComponent = (
      <Input
        key={field.key}
        type="text"
        name={field.key}
        label={fieldLabel}
        onChange={this.checkIfOwned() ? this.updateCustomField : null}
        onFocus={() => { delaySave(true); }}
        onBlur={() => { delaySave(false); }}
        value={(form.values.custom || {})[field.key] || ''}
        error={(form.errors.custom || {})[field.key] || ''}
        show={(
          this.isShown(`custom_fields.${field.key}`)
          && !this.isInHideList(`custom_fields.${field.key}`)
        )}
      />
    );

    // modify some custom fields section
    // no need to delay document saving
    if (field.key === 'CustomID') {
      textCustomComponent = (
        <CustomIdGenerator
          key={field.key}
          type="text"
          name={field.key}
          albumId={albumId}
          label={fieldLabel}
          onChange={this.checkIfOwned() ? this.updateCustomField : null}
          value={(form.values.custom || {})[field.key] || ''}
          error={(form.errors.custom || {})[field.key] || ''}
          show={(
            this.isShown(`custom_fields.${field.key}`)
            && !this.isInHideList(`custom_fields.${field.key}`)
          )}
        />
      );
    }

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.album.false')}
            textTrue={t('components.panels.album.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() && options.length !== 0 ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() && options.length !== 0
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return textCustomComponent;

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isAlbumCollection = c.collections.indexOf('album') >= 0;
        return !section
          ? isAlbumCollection
          : isAlbumCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.album.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      updateForm,
      updateAdditionalFormData,
      delaySave,
      openSidePanel,
      deleteForm,
      albumtypes,
      neighboringrightssocieties,
      performingrightssocieties,
      mechanicalrightssocieties,
      agents,
      trackversions,
      canDownload,
      canDelete,
      territories,
      hiddenFields,
      showAdditionalNotes,
      descriptionAutocomplete,
      customFieldsToRightSide,
      rightstypes,
      t,
      locale,
      user,
    } = this.props;

    const tracks = (panel.tracks || [])
      .concat((panel.multipartTracks || []).map(mt => ({ ...mt, multipart: true })));
    const tracksTags = panel.tracksTags || [];
    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          // updateAdditionalFormData={updateAdditionalFormData}
          actions={this.renderPanelActions()}
          panel={panel}
          title={this.renderPanelTitle()}
          picture={this.renderPanelPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
          canDelete={canDelete}
        />

        <CollapsibleBox
          title={t('components.panels.album.boxTracks')}
          isOpen
          counter={tracks.length}
          show={this.isShown('tracks') && !this.isInHideList('tracks')}
        >
          <AlbumTracks
            canDownload={canDownload}
            trackListId={panel.data && panel.data.id}
            tracks={tracks}
            trackversions={trackversions}
            disabled={!this.checkIfOwned()}
            updateAdditionalFormData={updateAdditionalFormData}
            openSidePanel={openSidePanel}
            show={this.isShown('tracks')}
          />
        </CollapsibleBox>

        <PanelContent justify="space-between">
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.album.boxAlbum')}
              isOpen
              show={this.isShown('album') && !this.isInHideList('album')}
            >
              <Input
                type="text"
                name="title"
                label={t('components.panels.album.labelTitle')}
                error={form.errors.title}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.title}
                show={this.isShown('title') && !this.isInHideList('title')}
              />
              <Input
                type="text"
                name="album_ref"
                label={t('components.panels.album.labelAlbumRef')}
                error={form.errors.album_ref}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.album_ref}
                show={this.isShown('album_ref') && !this.isInHideList('album_ref')}
              />
              <SelectInput
                type="single"
                name="album_type"
                label={t('components.panels.album.labelAlbumType')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={albumtypes.map(a => ({
                  id: a.id,
                  name: getName(a, locale),
                }))}
                data={form.values.album_type}
                error={form.errors.album_type}
                search
                show={this.isShown('album_type') && !this.isInHideList('album_type')}
              />
              <DateInput
                name="release_date"
                date={form.values.release_date}
                error={form.errors.release_date}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('release_date') && !this.isInHideList('release_date')}
              />

              <SubCategory
                show={(
                  this.isShown('display_artists')
                  && !this.isInHideList('display_artists')
                )}
              >
                {t('components.panels.album.subCategoryAlbumArtists')}
              </SubCategory>
              <DisplayArtistInput
                name="display_artists"
                data={form.values.display_artists}
                error={form.errors.display_artists}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('display_artists') && !this.isInHideList('display_artists')}
              />

              <SubCategory show={this.isShown('album_info') && !this.isInHideList('album_info')}>
                {t('components.panels.album.subCategoryAlbumInfo')}
              </SubCategory>
              <Flex align="center" justify="space-between">
                <Input
                  type="text"
                  name="upc"
                  label={t('components.panels.album.labelUpc')}
                  error={form.errors.upc}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  value={form.values.upc}
                  width="48%"
                  show={this.isShown('upc') && !this.isInHideList('upc')}
                />
                <Input
                  type="text"
                  name="ean"
                  label={t('components.panels.album.labelEan')}
                  error={form.errors.ean}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  value={form.values.ean}
                  width="48%"
                  show={this.isShown('ean') && !this.isInHideList('ean')}
                />
              </Flex>
              <ApiSelectInput
                name="catalog"
                parseName={e => e.name}
                label={t('components.panels.album.labelCatalog')}
                entity="meta/catalogs"
                panelName={CATALOG_PANEL}
                defaultField="name"
                type="single"
                data={panel.data && panel.data.catalog}
                values={form.values.catalog || null}
                error={form.errors.catalog}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('catalog') && !this.isInHideList('catalog')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.album.boxMasterInfo')}
              mt="5px"
              show={this.isShown('master_info') && !this.isInHideList('master_info')}
            >
              <SelectInput
                type="single"
                name="original_nrs"
                label={t('components.panels.album.labelOriginalNeighboringRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={neighboringrightssocieties.map(nrs => ({ id: nrs.id, name: nrs.name }))}
                search
                show={this.isShown('original_nrs') && !this.isInHideList('original_nrs')}
                data={form.values.original_nrs}
              />
              <Input
                type="text"
                name="isrc"
                label={t('components.panels.album.labelIsrc')}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('isrc') && !this.isInHideList('isrc')}
                value={form.values.isrc}
                error={form.errors.isrc}
              />
              <SubCategory
                show={(
                  this.isShown('artists_master_ownerships')
                  && !this.isInHideList('artists_master_ownerships')
                )}
              >
                {t('components.panels.album.subCategoryArtistsMaster')}
              </SubCategory>
              <ArtistsMasterOwnerInput
                name="artists_master_ownerships"
                value={form.values.artists_master_ownerships}
                error={form.errors.artists_master_ownerships}
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('artists_master_ownerships')
                  && !this.isInHideList('artists_master_ownerships')
                )}
                instruments={this.getInstruments()}
                showAdditionalNotes={showAdditionalNotes}
                panelName={ALBUM_PANEL}
                sessionToken={this.props.user.sessionToken}
                hiddenFields={this.getNestedHiddenFields('artists_master_ownerships')}
              />
              <SubCategory
                show={(
                  this.isShown('master_ownerships')
                  && !this.isInHideList('master_ownerships')
                )}
              >
                {t('components.panels.album.subCategoryLabels')}
              </SubCategory>
              <LabelsMasterOwnerInput
                name="master_ownerships"
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('master_ownerships') && !this.isInHideList('master_ownerships')}
                value={form.values.master_ownerships}
                error={form.errors.master_ownerships}
                panelName={ALBUM_PANEL}
                sessionToken={this.props.user.sessionToken}
              />
              {this.renderCustomFields('master_info')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.album.boxPublishingInfo')}
              mt="5px"
              show={this.isShown('publishing_info') && !this.isInHideList('publishing_info')}
            >
              <SelectInput
                type="single"
                name="original_prs"
                label={t('components.panels.album.labelOriginalPerformingRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={performingrightssocieties.map(prs => ({ id: prs.id, name: prs.name }))}
                search
                show={this.isShown('original_prs') && !this.isInHideList('original_prs')}
                data={form.values.original_prs}
              />
              <SelectInput
                type="single"
                name="original_mrs"
                label={t('components.panels.album.labelOriginalMechanicalRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={mechanicalrightssocieties.map(mrs => ({ id: mrs.id, name: mrs.name }))}
                search
                show={this.isShown('original_mrs') && !this.isInHideList('original_mrs')}
                data={form.values.original_mrs}
              />
              <Input
                type="text"
                name="iswc"
                label={t('components.panels.album.labelIswc')}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('iswc') && !this.isInHideList('iswc')}
                value={form.values.iswc}
                error={form.errors.iswc}
              />
              <Flex align="center" justify="space-between">
                <Input
                  type="text"
                  name="cocv"
                  label={t('components.panels.album.labelCocv')}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  width="48%"
                  show={this.isShown('cocv') && !this.isInHideList('cocv')}
                  value={form.values.cocv}
                  error={form.errors.cocv}
                />
                <Input
                  type="text"
                  name="prs_tunecode"
                  label={t('components.panels.album.labelPrsTunecode')}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  width="48%"
                  show={this.isShown('prs_tunecode') && !this.isInHideList('prs_tunecode')}
                  value={form.values.prs_tunecode}
                  error={form.errors.prs_tunecode}
                />
              </Flex>
              <SubCategory
                show={(
                  this.isShown('artists_publishing_ownerships')
                  && !this.isInHideList('artists_publishing_ownerships')
                )}
              >
                {t('components.panels.album.subCategoryArtistsPublishing')}
              </SubCategory>
              <ArtistsPublishingOwnerInput
                name="artists_publishing_ownerships"
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                value={form.values.artists_publishing_ownerships}
                error={form.errors.artists_publishing_ownerships}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('artists_publishing_ownerships')
                  && !this.isInHideList('artists_publishing_ownerships')
                )}
                showAdditionalNotes={showAdditionalNotes}
                panelName={ALBUM_PANEL}
                sessionToken={this.props.user.sessionToken}
                hiddenFields={this.getNestedHiddenFields('artists_publishing_ownerships')}
              />
              <SubCategory
                show={(
                  this.isShown('publishing_ownerships')
                  && !this.isInHideList('publishing_ownerships')
                )}
              >
                {t('components.panels.album.subCategoryPublishers')}
              </SubCategory>
              <PublishersPublishingOwnerInput
                name="publishing_ownerships"
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('publishing_ownerships')
                  && !this.isInHideList('publishing_ownerships')
                )}
                value={form.values.publishing_ownerships}
                error={form.errors.publishing_ownerships}
                panelName={ALBUM_PANEL}
                sessionToken={this.props.user.sessionToken}
              />
              {this.renderCustomFields('publishing_info')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.album.boxDistribution')}
              mt="5px"
              show={this.isShown('distribution') && !this.isInHideList('distribution')}
            >
              <MultipleSelect
                label={t('components.panels.album.labelAgents')}
                displayText={{
                  one: t('components.panels.album.oneAgent'),
                  multiple: t('components.panels.album.multipleAgents'),
                  none: t('components.panels.album.noneAgents'),
                  all: t('components.panels.album.allAgents'),
                }}
                name="agents"
                selectAll
                values={(form.values.agents || []).map(id => ({
                  value: id,
                  status: 2,
                }))}
                data={agents.map(agent => ({
                  label: agent.company_name || `${agent.first_name} ${agent.last_name}`,
                  value: agent.id,
                }))}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
              />
              {this.renderCustomFields('distribution')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.album.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              <AttachmentFileInput
                disabled={!this.checkIfOwned()}
                filetype="Attachments"
                panel={panel}
                type="attachments"
                files={get(panel, ['data', 'attachments'])}
                show={this.isShown('attachments') && !this.isInHideList('attachments')}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <SwitchInput
              name="public"
              label={t('components.panels.album.labelPublic')}
              onChange={updateForm}
              value={form.values.public}
              error={form.errors.public}
              textFalse={t('components.panels.album.publicFalse')}
              textTrue={t('components.panels.album.publicTrue')}
              show={this.isShown('public') && !this.isInHideList('public')}
            />
            <SwitchInput
              name="showcase"
              label={t('components.panels.album.labelShowcase')}
              onChange={updateForm}
              value={form.values.showcase}
              error={form.errors.showcase}
              textFalse={t('components.panels.album.showcaseFalse')}
              textTrue={t('components.panels.album.showcaseTrue')}
              show={this.isShown('showcase') && !this.isInHideList('showcase')}
            />
            <TranslateArea
              name="descriptions"
              label={t('components.panels.album.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              mt="10px"
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
            <TagList
              data={tracksTags.map(trackTag => ({
                status: trackTag.status,
                value: trackTag.tag.id,
              }))}
              onChange={this.checkIfOwned() ? this.updateTags : null}
              show={this.isShown('tags') && !this.isInHideList('tags')}
              tagHideList={hiddenFields.filter(field => field.startsWith('categories'))}
            />
          </Div>
        </PanelContent>
      </PanelLoader>
    );
  }
}

function ownershipToRequestTransformer(ownerships) {
  /* eslint-disable camelcase */
  return (
    ownerships &&
    ownerships.map(
      ({
        artist,
        alias,
        label,
        publisher,
        rights_type,
        territories,
        mechanical_percentage,
        performing_percentage,
        ownership_percentage,
        sync_percentage,
        download_percentage,
        rent_percentage,
        chain_of_title,
        instruments,
        additional_notes,
      }) => ({
        artist: artist && artist.id,
        alias,
        label: label && label.id,
        publisher: publisher && publisher.id,
        rights_type: rights_type.id,
        territories,
        mechanical_percentage,
        performing_percentage,
        ownership_percentage,
        sync_percentage,
        download_percentage,
        rent_percentage,
        chain_of_title,
        instruments: instruments ? map(instruments, i => i.id || i) : undefined,
        additional_notes,
      }),
    )
  );
  /* eslint-enable camelcase */
}

export default formPanel(
  AlbumSchema,
  {
    original_nrs: nrs => nrs.id,
    original_mrs: mrs => mrs.id,
    original_prs: prs => prs.id,
    agents: agents => agents.map(agent => agent.id),
    album_type: albumType => albumType.id,
    catalog: catalog => catalog.id,
    display_artists: displayArtists =>
      displayArtists.map(da => ({
        full_name: da.artist.full_name,
        id: da.artist.id,
        aliases: da.artist.aliases,
        alias: da.alias,
      })),
  },
  {
    tracks: 'tracks',
    tracksTags: 'tracks/tags',
    multipartTracks: 'multiparts',
  },
  null,
  // eslint-disable-next-line camelcase
  (
    {
      display_artists, // eslint-disable-line camelcase
      artists_master_ownerships, // eslint-disable-line camelcase
      artists_publishing_ownerships, // eslint-disable-line camelcase
      publishing_ownerships, // eslint-disable-line camelcase
      master_ownerships, // eslint-disable-line camelcase
      ...values
    },
    panel,
  ) => {
    if (get(panel, 'data.owned_by_tenant') === false) {
      return {
        showcase: values.showcase,
      };
    }

    return {
      ...values,
      display_artists:
        // eslint-disable-next-line camelcase
        display_artists &&
        display_artists.map(({ id, alias }) => ({
          artist: id,
          alias,
        })),
      artists_master_ownerships: ownershipToRequestTransformer(artists_master_ownerships),
      artists_publishing_ownerships: ownershipToRequestTransformer(artists_publishing_ownerships),
      publishing_ownerships: ownershipToRequestTransformer(publishing_ownerships),
      master_ownerships: ownershipToRequestTransformer(master_ownerships),
    };
  },
)(withI18n()(Album));
