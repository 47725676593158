// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import * as states from './../../../../constants/PlayerStates';

// Themes
import { IconPause, IconPlay } from './../../../../themes/icons';

// =============================
// Component
// =============================

function MusicItemControls({
  playerState,
  load,
  play,
  pause,
  currentTrack,
  currentTracklistId,
  trackDatas,
  tracklistId,
  tracklistDatas,
  inheritColors,
  noHover,
  white,
  showPlayer,
}) {
  let showPlay = false;

  // Determine if this track is current track
  let isCurrentTrack = false;
  if (currentTrack && currentTrack.id === trackDatas.id && tracklistId === currentTracklistId) {
    isCurrentTrack = true;
  }

  if (isCurrentTrack) {
    // Determine if we show pause or play button depending on state
    if (
      playerState === states.STOPPED ||
      playerState === states.PAUSED ||
      playerState === states.ENDED ||
      playerState === states.ERROR
    ) {
      showPlay = true;
    }
  }

  return (
    <div>
      {/* Load a track */}
      {!isCurrentTrack && (
        <IconPlay
          inherit={inheritColors}
          noHover={noHover}
          onClick={() => {
            load(trackDatas, tracklistId, tracklistDatas);
          }}
          white={white}
        />
      )}

      {/* Play current track */}
      {isCurrentTrack && showPlay && (
        <IconPlay
          inherit={inheritColors}
          noHover={noHover}
          onClick={() => {
            showPlayer(true);
            play();
          }}
          white={white}
        />
      )}

      {/* Pause current track */}
      {isCurrentTrack && !showPlay && (
        <IconPause inherit={inheritColors} noHover={noHover} onClick={pause} white={white} />
      )}
    </div>
  );
}

MusicItemControls.propTypes = {
  playerState: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  showPlayer: PropTypes.func.isRequired,
  currentTrack: PropTypes.object, // eslint-disable-line
  currentTracklistId: PropTypes.string,
  trackDatas: PropTypes.object, // eslint-disable-line
  tracklistId: PropTypes.string,
  tracklistDatas: PropTypes.object, // eslint-disable-line
  inheritColors: PropTypes.bool,
  noHover: PropTypes.bool,
  white: PropTypes.bool,
};

MusicItemControls.defaultProps = {
  currentTrack: null,
  currentTracklistId: null,
  trackDatas: null,
  tracklistId: null,
  tracklistDatas: null,
  inheritColors: false,
  noHover: false,
  white: false,
};

export default MusicItemControls;
