// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Actions
// import { logout } from './../../../../actions/UserActions';

// Components
import MenuDropdown from './../../../presentationals/menus/menudropdown/MenuDropdown';

// =============================
// Component
// =============================

export default withRouter(
  connect(
    null,
    // { logout },
  )(MenuDropdown),
);
