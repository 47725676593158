// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Theme
import { Flex } from '../../../../themes/views';

// =============================
// Styles
// =============================

export const Container = styled(Flex)`
  ${props => props.theme.fontSize.m};
  margin-top: 16px;
  padding: 5px 0;
  ${props =>
    props.border &&
    css`
      border-bottom: 1px solid #eee;
    `};
`;

export const IconType = styled(SVGInline)`
  flex: 0 0 auto;
  height: 25px;
  margin-right: 10px;
  width: 19px;

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: ${props => props.theme.colors.text.grey};
    }
  }
`;

export const FileName = styled.div`
  color: ${props => props.theme.colors.text.greyDark};
  flex: 1 0 0;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectFile = styled.div`
  color: ${props => props.theme.colors.text.grey};
  cursor: pointer;
  flex: 1 0 auto;
`;

export const Status = styled(SVGInline)`
  background-color: red;
  border-radius: 50%;
  height: 13px;
  margin-left: 20px;
  width: 13px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const DownloadLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: ${props => props.ml};
`;

export const Icon = styled(SVGInline)`
  cursor: pointer;
  height: 19px;
  stroke: #959595;
  transition: stroke ${props => props.theme.transition.medium};
  width: 19px;
  margin-left: 20px;

  &:hover {
    stroke: #000;
  }
`;
