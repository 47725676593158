// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import {
  Container,
  ContentWrapper,
  ChildrenContainer,
  Label,
  ErrorText,
  Border,
} from './InputContainer.styles';

// =============================
// Component
// =============================

class InputContainer extends Component {
  static propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    children: PropTypes.node,
    filled: PropTypes.bool,
    active: PropTypes.bool,
    hideErrorText: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    error: '',
    children: null,
    filled: false,
    active: false,
    hideErrorText: false,
  };

  render() {
    const {
      label,
      active,
      filled,
      error,
      children,
      hideErrorText,
    } = this.props;

    return (
      <Container {...getViewProps(this.props)}>
        <ContentWrapper>
          {label &&
            <Label
              active={active}
              up={filled || active}
              error={!!error}
              tabIndex="-1"
            >
              {label}
            </Label>
          }

          <ChildrenContainer
            hasLabel={!!label}
          >
            {children}
          </ChildrenContainer>

          <Border error={!!error} active={active} />
        </ContentWrapper>

        {error && !hideErrorText && <ErrorText>{error}</ErrorText>}
      </Container>
    );
  }
}

export default InputContainer;
