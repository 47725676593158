/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles

// =============================
// Styled Components
// =============================

export const Container = styled.div`
  ${props => props.theme.fontSize.xs};
  align-items: center;
  border: 1px solid ${props => props.categoryColor};
  border-radius: ${props => props.theme.borderRadius.element};
  color: ${props => props.categoryColor};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  display: inline-flex;
  min-height: 20px;
  justify-content: center;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 0 8px;
  opacity: 0.6;
  ${props => props.status > 0 && css`
    color: #fff;
    background-color: ${props.categoryColor};
    border: 0;
  `};
  ${props => props.status === 1 && css`
    opacity: 0.8;
  `};
  ${props => props.status === 2 && css`
    opacity: 1;
  `};
  ${props => props.onClick && css`
    &:hover {
      box-shadow: 0 0 5px ${props.theme.colors.shadow};
    }
  `}
`;

export const DeleteTag = styled(SVGInline)`
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  margin-left: 6px;
  stroke: #fff;
  width: 10px;

  &:hover {
    opacity: 0.8;
    stroke: #fff;
  }

  & > svg > * {
    stroke-width: 3 !important;
  }
`;
