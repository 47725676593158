// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Themes
import { Flex } from './../../../themes/views';

// Styles
import { Pagination } from './LinkPaginator.styles';

// =============================
// Component
// =============================

class LinkPaginator extends Component {
  static propTypes = {
    entity: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    changeSearchPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    entity: undefined,
  };

  state = {};

  getDisplayPageNumber = pageNb => pageNb + 1;

  render() {
    const { currentPage, pageCount, changeSearchPage, entity } = this.props;

    const nbPageShowed = 2; // Number of page showed before & after currentage

    // If pageCount is 1, do not render anything
    if (pageCount === 0) return null;

    // Check if the page is far from beggining & end
    const farFromBeginning = currentPage - nbPageShowed >= 1;
    const farFromEnd = (pageCount - 1) - nbPageShowed > currentPage;

    // Render Previous page from current one
    const prevPages = [];
    for (let i = 1; i <= nbPageShowed; i += 1) {
      if (currentPage - i >= 0) {
        prevPages.unshift(
          <Pagination
            key={currentPage - i}
            onClick={() => { changeSearchPage(entity, currentPage - i); }}
          >
            {this.getDisplayPageNumber(currentPage - i)}
          </Pagination>,
        );
      }
    }

    // Render Next page from current one
    const nextPages = [];
    for (let i = 1; i <= nbPageShowed; i += 1) {
      if (currentPage + i <= pageCount - 1) {
        nextPages.push(
          <Pagination
            key={currentPage + i}
            onClick={() => { changeSearchPage(entity, currentPage + i); }}
          >
            {this.getDisplayPageNumber(currentPage + i)}
          </Pagination>,
        );
      }
    }

    return (
      <Flex justify="center">
        {farFromBeginning && (
          <Flex>
            <Pagination onClick={() => { changeSearchPage(entity, 0); }}>1</Pagination>
            {currentPage - nbPageShowed > 1 && <Pagination noLink>...</Pagination>}
          </Flex>
        )}

        {prevPages}
        <Pagination active>
          {this.getDisplayPageNumber(currentPage)}
        </Pagination>
        {nextPages}


        {farFromEnd && (
          <Flex>
            {(pageCount - 1) - (nbPageShowed + 1) !== currentPage && (
              <Pagination noLink>...</Pagination>
            )}
            <Pagination
              onClick={() => { changeSearchPage(entity, pageCount - 1); }}
            >
              {pageCount}
            </Pagination>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default LinkPaginator;
