// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

// HOC
import withI18n from './../../../../hoc/WithI18n';
import hideable from './../../../../hoc/Hideable';

// Components
import InputBase from './../../inputunderline/InputUnderline';
import ApiSelectInputBase from './../../apiselectinput/ApiSelectInput';
import TerritoriesSelectBase from './../../territoriesselect/TerritoriesSelect';

// Themes
import { Div } from './../../../../../themes/views';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const ApiSelectInput = hideable(ApiSelectInputBase);
const TerritoriesSelect = hideable(TerritoriesSelectBase);

// =============================
// Component
// =============================

class OwnershipDetails extends Component {
  static propTypes = {
    PercentageComponent: PropTypes.func.isRequired,
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    territories: PropTypes.array.isRequired, // eslint-disable-line
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    getInstrumentName: PropTypes.func.isRequired,
    instruments: PropTypes.array, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool,
    panelName: PropTypes.string.isRequired,
    sessionToken: PropTypes.string.isRequired,
    hiddenFields: PropTypes.array, // eslint-disable-line
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    instruments: [],
    showAdditionalNotes: false,
    hiddenFields: [],
    onFocus: null,
    onBlur: null,
  };

  state = {};

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  render() {
    const {
      PercentageComponent,
      ownership,
      errors,
      disabled,
      territories,
      onChange,
      onFocus,
      onBlur,
      panelName,
      getInstrumentName,
      instruments,
      showAdditionalNotes,
      sessionToken,
      t,
    } = this.props;

    const isMusician = ownership.rights_type && ownership.rights_type.key === 'musician';
    const selectedInstruments = map(ownership.instruments, i => i.id || i);

    return (
      <Div ml="auto" width="90%">
        <Input
          type="text"
          name="chain_of_title"
          label={t('components.inputs.ownershipDetails.chain_of_title')}
          error={errors.chain_of_title}
          onChange={
            disabled ? null : (name, value) => onChange({ ...ownership, chain_of_title: value })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          value={ownership.chain_of_title}
          show={!this.isInHideList('chain_of_title')}
        />
        <ApiSelectInput
          name="instrument"
          parseName={getInstrumentName}
          label={t('components.inputs.ownershipDetails.instrument')}
          entity="meta/instrument-tag"
          panelName={panelName}
          defaultField="name"
          type="multiple"
          data={instruments}
          values={selectedInstruments}
          onChange={
            disabled ? null : (name, value) => onChange({ ...ownership, instruments: value })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          sessionToken={sessionToken}
          search
          show={isMusician && !this.isInHideList('instrument')}
        />
        <Input
          width="100%"
          type="text"
          name="additional_notes"
          label={t('components.inputs.ownershipDetails.additional_notes')}
          error={errors.additional_notes}
          onChange={
            disabled ? null : (name, value) => onChange({
              ...ownership,
              additional_notes: value,
            })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          value={ownership.additional_notes}
          show={showAdditionalNotes && !this.isInHideList('additional_notes')}
        />
        <PercentageComponent
          ownership={ownership}
          errors={errors}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          show={!this.isInHideList('percentage')}
        />
        <TerritoriesSelect
          label={t('components.inputs.ownershipDetails.territories')}
          name="territories"
          value={ownership.territories}
          territories={territories}
          onChange={
            disabled ? null : (name, value) => onChange({ ...ownership, territories: value })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          error={errors.territories}
          show={!this.isInHideList('territories')}
        />
      </Div>
    );
  }
}

export default withI18n()(OwnershipDetails);
