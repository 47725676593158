// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

// Constants
import { TRACK_PANEL, ARTIST_PANEL } from './../../../constants/SidePanelTypes';

// Helpers
import { getName } from './../../../helpers/I18n';
// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import MusicItemControls from './../../containers/musicitem/controls/MusicItemControlsContainer';
import Chip from './../chip/Chip';

// Styles
import { GridRow, MediaColumn, Column } from './../../../themes/entityGrid';
import { IconText, IconTrash } from './../../../themes/icons';

// =============================
// Component
// =============================

class TrackList extends Component {
  static propTypes = {
    tracks: PropTypes.array, // eslint-disable-line
    tracklistId: PropTypes.string,
    disabled: PropTypes.bool,
    openSidePanel: PropTypes.func.isRequired,
    deleteTrackFromPlaylist: PropTypes.func,
    canDownload: PropTypes.bool,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tracks: [],
    tracklistId: null,
    disabled: false,
    canDownload: true,
    deleteTrackFromPlaylist: () => {},
  };

  state = {};

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const {
      canDownload,
      tracks,
      tracklistId,
      disabled,
      openSidePanel,
      deleteTrackFromPlaylist,
      t,
      locale,
    } = this.props;

    const normalizedTracklistId = tracklistId || tracks.map(tt => tt.id).toString();

    function getPlayButton(track) {
      if (track.audiofile.hd_mp3) {
        return (
          <MusicItemControls
            trackDatas={track}
            tracklistId={normalizedTracklistId}
            tracklistDatas={{ tracks: tracks.filter(tt => !!tt.audiofile.hd_mp3) }}
          />
        );
      }
      return null;
    }

    function getVersion(track) {
      if (track.version) {
        return getName(track.version, locale);
      }

      return t('components.trackList.noVersion');
    }

    function getDisplayArtists(track) {
      const displayArtists = track.display_artists || [];

      return displayArtists.map(da => (
        <Chip
          key={da.alias ? `${da.artist.full_name}-${da.alias}` : da.artist.full_name}
          name={da.alias || da.artist.full_name}
          onClick={() => {
            openSidePanel(ARTIST_PANEL, da.artist.id);
          }}
        />
      ));
    }

    return (
      <div>
        {tracks.map(track => (
          <GridRow key={track.id}>
            <MediaColumn width="50px">{getPlayButton(track)}</MediaColumn>
            <Column width="200px">
              <Chip
                name={track.display_title || track.title}
                onClick={() => {
                  openSidePanel(TRACK_PANEL, track.id);
                }}
              />
            </Column>
            <Column width="150px">{getVersion(track)}</Column>
            <Column width="250px" mr="auto">
              {getDisplayArtists(track)}
            </Column>
            {canDownload && (
              <div>
                <Column width="30px" pl="4px">
                  {!!track.audiofile.hd_mp3 && (
                    <a
                      href={track.audiofile.hd_mp3.url}
                      title={t('components.trackList.downloadHdMp3')}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      key="0"
                    >
                      <IconText>MP3</IconText>
                    </a>
                  )}
                </Column>
                <Column width="30px" pl="4px">
                  {!!track.audiofile.original && (
                    <a
                      href={track.audiofile.original.url}
                      title={t('components.trackList.downloadRaw')}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      key="1"
                    >
                      <IconText>WAV</IconText>
                    </a>
                  )}
                </Column>
              </div>
            )}
            {!disabled && (
              <Column width="30px" pl="0">
                <IconTrash
                  onClick={() => {
                    deleteTrackFromPlaylist(track.id);
                  }}
                  size="18px"
                />
              </Column>
            )}
          </GridRow>
        ))}
      </div>
    );
  }
}

export default withI18n()(TrackList);
