export default () => (date) => {
  if (!date) return null;
  const parsedDate = new Date(date);

  const padToTwoDigits = number => number.toString().padStart(2, '0');

  const day = padToTwoDigits(parsedDate.getDate());
  const month = padToTwoDigits(parsedDate.getMonth() + 1); // Moment = 0 to 11, new Date = 1 to 12
  const year = parsedDate.getFullYear().toString();

  const hour = padToTwoDigits(parsedDate.getHours());
  const minute = padToTwoDigits(parsedDate.getMinutes());
  const second = padToTwoDigits(parsedDate.getSeconds());

  return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
};
