// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import { Label, Container, TextArea, ErrorText } from './Textarea.styles';
import { Div } from './../../../../themes/views';

// =============================
// Component
// =============================

class Textarea extends Component {
  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.string,
  };

  static defaultProps = {
    label: null,
    disabled: false,
    value: '',
    error: '',
    onFocus: null,
    onBlur: null,
  };

  state = {
    value: this.props.value,
  };

  handleChange = ({ target }) => {
    const { name, onChange } = this.props;

    if (onChange) {
      this.setState({
        value: target.value,
      });

      this.props.onChange(name, target.value);
    }
  }

  render() {
    const { name, placeholder, label, disabled, error, onFocus, onBlur } = this.props;
    const { value } = this.state;

    return (
      <Div {...getViewProps(this.props)}>
        {label && <Label>{label}</Label>}
        <Container>
          <TextArea
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Container>
        {error && <ErrorText>{error}</ErrorText>}
      </Div>
    );
  }
}

export default Textarea;
