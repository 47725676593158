// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

// Helpers
import isBlank from './../../../../helpers/isBlank';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import MultipleSelect from './../multipleselect/MultipleSelect';

// =============================
// Component
// =============================

const WORLDWIDE_CODE = 'WW';

class TerritoriesSelect extends Component {
  static propTypes = {
    territories: PropTypes.array, // eslint-disable-line
    value: PropTypes.arrayOf(PropTypes.string), // eslint-disable-line
    onChange: PropTypes.func, // eslint-disable-line
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    territories: [],
    value: undefined,
    onChange: null,
    onFocus: null,
    onBlur: null,
  };

  constructor(props) {
    super(props);
    this.setTerritories(props.territories);
  }

  state = {};

  componentWillReceiveProps(nextProps) {
    this.setTerritories(nextProps.territories);
  }

  handleChange = (name, value) => {
    const { onChange } = this.props;
    if (value.length === this.territories.length) {
      onChange(name, [WORLDWIDE_CODE]);
      return;
    }
    onChange(name, value);
  };

  setTerritories = (territories) => {
    if (isBlank(territories) || this.territories) return;
    this.territories = territories.reduce((sum, t) => {
      if (t.code === WORLDWIDE_CODE) return sum;

      return [
        ...sum,
        {
          label: t.name,
          value: t.code,
        },
      ];
    }, []);
  };

  getValues = () => {
    const { value } = this.props;
    if (!value || isEqual(value, [WORLDWIDE_CODE])) {
      return this.territories.map(t => ({
        value: t.value,
        status: 2,
      }));
    }

    return value.map(t => ({
      value: t,
      status: 2,
    }));
  };

  render() {
    const { onChange, value, territories, t, ...props } = this.props;

    return (
      <MultipleSelect
        label={t('components.inputs.territoriesSelect.label')}
        displayText={{
          one: t('components.inputs.territoriesSelect.one'),
          multiple: t('components.inputs.territoriesSelect.multiple'),
          none: t('components.inputs.territoriesSelect.none'),
          all: t('components.inputs.territoriesSelect.all'),
        }}
        selectAll
        name="territories"
        values={this.getValues()}
        data={this.territories}
        onChange={this.handleChange}
        {...props}
      />
    );
  }
}

export default withI18n()(TerritoriesSelect);
