// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getName } from './../../../helpers/I18n';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import Tag from './../tag/Tag';

// =============================
// Component
// =============================

class TagListBody extends Component {
  static propTypes = {
    filteredTags: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    color: PropTypes.string,
    onChange: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: null,
    onChange: null,
  };

  getTagStatus = (tagId) => {
    const { data } = this.props;
    const dataTag = data.find(t => t.value === tagId);

    return dataTag ? dataTag.status : 0;
  };

  render() {
    const { color, onChange, locale, filteredTags } = this.props;
    return filteredTags.map(tag => (
      <Tag
        key={tag.id}
        id={tag.id}
        value={tag.id}
        label={getName(tag, locale)}
        color={color}
        status={this.getTagStatus(tag.id)}
        onChange={onChange}
      />
    ));
  }
}

export default withI18n()(TagListBody);
