// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Button, ProgressBar, Link, ExternalLink } from './ButtonPrimary.styles';

// =============================
// Component
// =============================

const ButtonPrimary = ({
  isLoading,
  disabled,
  onClick,
  children,
  warning,
  to,
  type,
  href,
  mt,
  width,
  height,
}) => {
  if (to) {
    return (
      <Link
        to={to}
        mt={mt}
        warning={warning}
        width={width}
        height={height}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <ExternalLink
        href={href}
        mt={mt}
        warning={warning}
        width={width}
        height={height}
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <Button
      onClick={isLoading ? null : onClick}
      warning={warning}
      disabled={disabled}
      type={type}
      mt={mt}
      width={width}
      height={height}
    >
      {isLoading &&
        <ProgressBar />
      }
      <div>{children}</div>
    </Button>
  );
};

ButtonPrimary.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  warning: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  mt: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ButtonPrimary.defaultProps = {
  isLoading: false,
  disabled: false,
  onClick: null,
  type: 'button',
  warning: false,
  to: '',
  href: '',
  mt: '',
  width: '',
  height: '',
};

export default ButtonPrimary;
