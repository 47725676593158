/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles

// =============================
// Styled Components
// =============================

export const DeleteIcon = styled(SVGInline)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: center;
  margin-left: 10px;
  margin-right: auto;
  stroke: #000;
  width: 18px;

  &:hover {
    stroke: ${props => props.theme.colors.error};
  }
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
