// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';

export const Rating = styled.div`
  border: none;
  position: relative;

  & > input {
    display: none;
  }

  & > label {
    color: #ddd;
    float: right;
  }

  & > label::before {
    ${props => props.activeHover && css`
      cursor: pointer;
    `};
    font-size: 2em;
    display: inline-block;
    margin: 3px;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: FontAwesome;
    content: '\f005';
  }

  & > .half::before {
    content: "\f089";
    position: absolute;
  }

  /* Animation Magic */
  & > input:checked ~ label {
    /* show gold star when clicked */
    color: #ffd700;
  }

  &:not(:checked) > label:hover, /* hover current star */
  &:not(:checked) > label:hover ~ label { /* hover previous stars in list */
    ${props => props.activeHover && css`
      color: #ffd700;
    `};
  }

  & > input:checked + label:hover, /* hover current star when changing rating */
  & > input:checked ~ label:hover,
  & > label:hover ~ input:checked ~ label, /* lighten current selection */
  & > input:checked ~ label:hover ~ label {
    ${props => props.activeHover && css`
      color: #ffed85;
    `};
  }

  ${props => !props.activeHover && css`
    /* NOTE: seems there is no easy way to fix this issue */
    /* stylelint-disable-next-line no-descending-specificity */
    & > label {
      pointer-events: none;
    }
  `};
`;


export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Helper = styled.span`
  font-size: 10px;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
