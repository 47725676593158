// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles
import { Div } from './../../../themes/views';

// =============================
// Styled Components
// =============================

export const Box = styled(Div)`
  align-items: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: ${props => props.theme.borderRadius.element};
  cursor: pointer;
  display: flex;
  height: ${props => props.size};
  justify-content: center;
  stroke: #fff;
  width: ${props => props.size};
  min-width: ${props => props.size};
  ${props => props.check && css`
    border: 1px solid ${props.theme.colors.primary};
    background-color: ${props.theme.colors.primary};
  `};
`;

export const Icon = styled(SVGInline)`
  align-items: center;
  display: flex;
  height: 60%;
  justify-content: center;
  width: 60%;
`;
