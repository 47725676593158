import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const labelLabels = {
  label_name: 'Label Name',
  neighboring_rights_society: 'Neighbouring rights society',
  ipn: 'IPN',
  label_code: 'Label Code',
  contact_name: 'Contact Name',
  contact_email: 'Contact Email',
  address: 'Address',
};

const LabelSchema = {
  label_name: Joi.string().label(labelLabels.label_name).required(),
  neighboring_rights_society:
    Joi.string().label(labelLabels.neighboring_rights_society).empty(null),
  territories: Joi.array().items(Joi.string()),
  ipn: Joi.string().label(labelLabels.ipn).empty(''),
  label_code: Joi.string().label(labelLabels.label_code).empty(''),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string(),
    value: Joi.string(),
  })),
  contact_name: Joi.string().label(labelLabels.contact_name).empty(''),
  contact_email: Joi.string().label(labelLabels.contact_email).email({ minDomainAtoms: 2 }).min(6)
    .empty(''),
  address: Joi.string().label(labelLabels.address).empty(''),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const labelCustomFields = getFieldsByCollection('label', validation_fields);
  if (!validation_fields || isEmpty(labelCustomFields)) { // eslint-disable-line camelcase
    return LabelSchema;
  }

  return createCustomSchema(labelCustomFields, custom_fields, {
    defaultSchema: LabelSchema,
    labels: labelLabels,
  });
};
