// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Helpers
import { getName } from './../../../helpers/I18n';
import {
  getTagsWithCategoriesFromTagCategories,
  getSubCategoriesFromTagCategories,
} from './../../../helpers/tags';

// HOC
import withI18n from '../../hoc/WithI18n';

// Components
import Tag from './../tag/Tag';

// =============================
// Component
// =============================

class TagsChunk extends Component {
  static propTypes = {
    tagCategories: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    tags: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    tagKeys: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tagCategories: [],
    tags: [],
    tagKeys: [],
    onChange: null,
    onDelete: null,
  };

  getTags = () => {
    const { tags, tagCategories, tagKeys } = this.props;
    const passedTagKeys = isEmpty(tagKeys) ? tags.map(t => t.key) : tagKeys;

    return [
      ...getTagsWithCategoriesFromTagCategories(tagCategories),
      ...getSubCategoriesFromTagCategories(tagCategories),
    ].filter(twc => passedTagKeys.includes(twc.key));
  };

  render() {
    const { onChange, onDelete, locale } = this.props;
    return this.getTags().map(tag => (
      <Tag
        id={tag.id}
        key={tag.id}
        value={tag.key}
        label={getName(tag, locale)}
        onChange={onChange}
        onDelete={onDelete}
        status={2}
        color={tag.category.color}
      />
    ));
  }
}

export default withI18n()(TagsChunk);
