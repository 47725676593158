// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { generate as randomString } from 'randomstring';
import { connect } from 'react-redux';
// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import SelectInput from '../../inputs/selectinput/SelectInput';
import ApiSelectInput from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Constants
import { ARTIST_PANEL } from '../../../../constants/SidePanelTypes';

// Themes
import { Flex, Div } from './../../../../themes/views';

// Styles
import { DeleteIcon, ErrorText } from './DisplayArtistInput.styles';

// Assets
import iconTrash from './../../../../assets/images/icon-trash.svg';


// =============================
// Component
// =============================

class DisplayArtistInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    data: PropTypes.array, // eslint-disable-line
    multi: PropTypes.bool,
    t: PropTypes.func.isRequired,
    error: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object,
  };

  static defaultProps = {
    onChange: null,
    onFocus: null,
    onBlur: null,
    data: [],
    multi: true,
    error: '',
    user: null,
  };

  state = {
    data: [],
  };

  componentWillMount() {
    this.setData(this.props.data);
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.props.data, nextProps.data)) return;
    this.setData(nextProps.data);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  setData = (data) => {
    const stateData = [...(data || []).map(da => ({ ...da, key: randomString() }))];
    // Push an additional empty artist
    if ((this.props.multi && this.props.onChange) || !stateData.length) {
      stateData.push({
        aliases: [],
        alias: '',
        key: randomString(),
      });
    }

    this.setState({
      data: stateData,
    });
  };

  handleArtistChange = (displayArtist, name, value, foundArtist) => {
    const { onChange } = this.props;
    if (onChange) {
      const nextState = [...this.state.data];

      if (displayArtist.id && value) {
        const index = findIndex(nextState, da => da.id === displayArtist.id);
        nextState[index].id = value;
        nextState[index].full_name = foundArtist.full_name;
        nextState[index].alias = (foundArtist && foundArtist.alias) || '';
        nextState[index].aliases = (foundArtist && foundArtist.aliases) || [];
      } else if (!displayArtist.id && value) {
        nextState[nextState.length - 1].id = value;
        nextState[nextState.length - 1].full_name = foundArtist.full_name;
        nextState[nextState.length - 1].alias = (foundArtist && foundArtist.alias) || '';
        nextState[nextState.length - 1].aliases = (foundArtist && foundArtist.aliases) || [];
        nextState.push({
          aliases: [],
          alias: '',
          key: randomString(),
        });
      } else if (displayArtist.id && !value) {
        const index = findIndex(nextState, da => da.id === displayArtist.id);
        nextState.splice(index, 1);

        if (nextState.length === 0) {
          nextState.push({
            aliases: [],
            alias: '',
            key: randomString(),
          });
        }
      }

      const nextValues = nextState.filter(da => !!da.id);

      onChange(this.props.name, nextValues);
    }
  };

  removeArtistById = (id) => {
    const { onChange } = this.props;
    const nextState = [...this.state.data];
    const index = findIndex(nextState, da => da.id === id);
    nextState.splice(index, 1);

    const nextValues = nextState.filter(da => !!da.id);

    onChange(this.props.name, nextValues);
  };

  handleAliasChange = (displayArtist, name, value) => {
    const { onChange } = this.props;

    if (onChange && displayArtist.id) {
      const nextState = [...this.state.data];

      const index = findIndex(nextState, da => da.id === displayArtist.id);
      nextState[index].alias = value || '';

      const nextValues = nextState.filter(da => !!da.id);

      onChange(this.props.name, nextValues);
    }
  };

  getErrors = () => {
    const { error } = this.props;
    const errors = [];
    if (error) {
      Object.keys(error).map(key => errors.push({
        index: key,
        errors: Object.values(error[key]), // eslint-disable-line compat/compat
      }));
    }

    return errors;
  };

  render() {
    const { onChange, onFocus, onBlur, t } = this.props;
    const { data } = this.state;

    const l = data.length;
    const errors = this.getErrors();

    return (
      <Flex flex="1 1 auto" direction="column">
        {data.map((displayArtist, index) => {
          const handleAliasChange = () => {
            if (displayArtist.aliases ? displayArtist.aliases.length === 0 : false) return null;
            if (onChange) {
              return (name, value) => {
                this.handleAliasChange(displayArtist, name, value);
              };
            }
            return null;
          };

          const hideAliasesInput = () => {
            if (
              !displayArtist.aliases ||
              (displayArtist.aliases && displayArtist.aliases.length === 0)
            ) {
              return true;
            }
            return false;
          };

          const renderDeleteButton = () => {
            if (displayArtist.id && this.props.onChange) {
              return (
                <DeleteIcon
                  onClick={() => this.removeArtistById(displayArtist.id)}
                  svg={iconTrash}
                  height="100%"
                  width="100%"
                />
              );
            }
            return null;
          };
          const custom = {};

          let label;
          if (index === l - 1 && onChange) {
            label = t('components.inputs.displayArtistInput.labelAdd');
          } else label = t('components.inputs.displayArtistInput.label');

          const artistErrors = errors.length > 0
            ? errors.find(e => e.index.toString() === index.toString())
            : null;

          return (
            <Div
              // eslint-disable-next-line react/no-array-index-key
              key={`${displayArtist.key}-${index}`}
              direction="column"
            >
              <Flex
                flex="1 1 auto"
                align="center"
                justify="space-between"
              >
                <ApiSelectInput
                  name="artist"
                  parseName={a => a.full_name}
                  label={label}
                  entity="meta/artists"
                  panelName={ARTIST_PANEL}
                  defaultField="full_name"
                  type="single"
                  data={displayArtist.id && displayArtist}
                  values={displayArtist.id || null}
                  onChange={
                    onChange
                      ? (name, value, foundArtist) => {
                        this.handleArtistChange(displayArtist, name, value, foundArtist);
                      }
                      : null
                  }
                  onFocus={onFocus}
                  onBlur={onBlur}
                  width={hideAliasesInput() ? '100%' : '48%'}
                  customData={custom}
                />
                <SelectInput
                  type="single"
                  name="alias"
                  label={t('components.inputs.displayArtistInput.labelAlias')}
                  onChange={handleAliasChange()}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  items={displayArtist.aliases
                    ? displayArtist.aliases.map(alias => ({ id: alias, name: alias }))
                    : []
                  }
                  data={displayArtist.alias}
                  search
                  width="48%"
                  hide={hideAliasesInput()}
                />
                {renderDeleteButton()}
              </Flex>
              {artistErrors && (
                artistErrors.errors.map((error, errorIndex) => (
                  <ErrorText
                    // eslint-disable-next-line react/no-array-index-key
                    key={errorIndex}
                  >
                    {error}
                  </ErrorText>
                ))
              )}
            </Div>
          );
        })}
      </Flex>
    );
  }
}


function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps)(withI18n()(DisplayArtistInput));
