// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { open as openSidePanel } from './../../../actions/SidePanelActions';

// Helpers
import { getHiddenFieldsFromUserData } from './../../../helpers/helpers';

// Components
import BatchTracksPanel from './../../presentationals/panels/batchtracks/BatchTracks';

// =============================
// Component
// =============================

function mapStateToProps({ user }) {
  return {
    agents: user.agents.data.filter(a => a.active_as_agent === true),
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'batch-track'),
  };
}

export default connect(
  mapStateToProps,
  { openSidePanel },
)(BatchTracksPanel);
