// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';

// Themes
import { Div } from '../../../../themes/views';

// =============================
// Styled Components
// =============================

export const Container = styled(Div)`
  position: relative;
  margin-top: ${props => (props.mt ? props.mt : '16px')};
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const ChildrenContainer = styled.div`
  min-height: 32px;
  ${props => props.hasLabel && css`
    padding-top: 12px;
  `};

  &:hover .border {
    background-color: black;
  }
`;

export const Border = styled.div`
  background-color: ${props => (props.error ? props.theme.colors.error : '#efefef')};
  bottom: 0;
  height: 1px;
  pointer-events: none;
  left: 0;
  position: absolute;
  width: 100%;
  ${props => (props.active) && css`
    height: 2px;
  `};

  &::after {
    background-color: ${props => (props.error ? props.theme.colors.error : props.theme.colors.primary)};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: none;
    width: 100%;
    z-index: 1;
    ${props => (props.active) && css`
      transform: scaleX(1);
      transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1);
    `};
  }
`;

export const Label = styled.label`
  position: absolute;
  bottom: 8px;
  left: 0;
  color: ${props => (props.error ? props.theme.colors.error : props.theme.colors.text.grey)};
  font-size: 16px;
  overflow-x: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  transition: all ${props => props.theme.transition.medium};
  white-space: nowrap;
  width: 100%;

  &::first-letter {
    text-transform: uppercase;
  }

  ${props => props.active && !props.error && css`
    color: ${props.theme.colors.primary};
  `};
  ${props => props.up && css`
    font-size: 12px;
    bottom: calc(100% - 16px);
  `};
`;

export const InfoText = styled.div`
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;

export const ErrorText = styled(InfoText)`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
