// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { open, remove, close } from './../../../actions/SidePanelActions';

// Constants
import {
  TRACK_PANEL,
  ALBUM_PANEL,
  ARTIST_PANEL,
  PLAYLIST_PANEL,
  CATALOG_PANEL,
  PUBLISHER_PANEL,
  LABEL_PANEL,
  BATCH_TRACKS_PANEL,
  MULTIPART_TRACK_PANEL,
} from './../../../constants/SidePanelTypes';

// Components
import SidePanel from './../../presentationals/sidepanel/SidePanel';

// Panels
import Track from './../../containers/panels/TrackContainer';
import BatchTracks from './../../containers/panels/BatchTracksContainer';
import MultipartTracks from './../../containers/panels/MultipartTrackContainer';
import Album from './../../containers/panels/AlbumContainer';
import Artist from './../../containers/panels/ArtistContainer';
import Playlist from './../../containers/panels/PlaylistContainer';
import Catalog from './../../containers/panels/CatalogContainer';
import Publisher from './../../containers/panels/PublisherContainer';
import Label from './../../containers/panels/LabelContainer';

// =============================
// Component
// =============================

export function getPanel(name) {
  switch (name) {
    case TRACK_PANEL:
      return Track;

    case BATCH_TRACKS_PANEL:
      return BatchTracks;

    case MULTIPART_TRACK_PANEL:
      return MultipartTracks;

    case ALBUM_PANEL:
      return Album;

    case ARTIST_PANEL:
      return Artist;

    case PLAYLIST_PANEL:
      return Playlist;

    case CATALOG_PANEL:
      return Catalog;

    case PUBLISHER_PANEL:
      return Publisher;

    case LABEL_PANEL:
      return Label;

    default:
      return null;
  }
}

export class SidePanelContainer extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })).isRequired,
  };

  state = {
    currentPanel: null,
  };

  componentWillMount() {
    const { panels } = this.props;

    if (panels.length) {
      this.setState({ currentPanel: getPanel(panels[0].name) });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { panels } = this.props;

    if (panels.length !== nextProps.panels.length && nextProps.panels.length) {
      // We need to reset currentPanel first in case if the next panel is the
      // same one as the current
      // Eg: Transition from Track Panel to another Track Panel (through track version link)
      this.setState({ currentPanel: null }, () => {
        this.setState({ currentPanel: getPanel(nextProps.panels[0].name) });
      });
    } else if (panels.length !== nextProps.panels.length && !nextProps.panels.length) {
      this.setState({ currentPanel: null });
    }
  }

  render() {
    return <SidePanel {...this.props} CurrentPanel={this.state.currentPanel} />;
  }
}

function mapStateToProps({ sidepanel }) {
  const { isOpened, panels } = sidepanel;

  return {
    panels,
    isOpened,
  };
}

export default connect(
  mapStateToProps,
  { open, remove, close },
)(SidePanelContainer);
