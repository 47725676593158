// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
// import iconLogout from './../../../../assets/images/icon-logout.svg';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import {
  Container,
  MenuTitle,
  Dropdown,
  DropdownItem,
  ExternalDropdownItem,
  Wrapper,
  // SignOut,
  // SignOutIcon,
} from './MenuDropdown.styles';

// =============================
// Component
// =============================

class MenuDropdown extends Component {
  state = {
    isOpen: false,
  };

  // When user clicks outside of menu
  // close the menu
  handleClickOutside = () => {
    this.toggleMenu(false);
  };

  toggleMenu = (value) => {
    this.setState({
      isOpen: value,
    });
  };

  renderLinks = () => {
    const { links } = this.props;

    return links.map((link) => {
      if (link.href) {
        return (
          <ExternalDropdownItem
            key={link.name}
            href={link.href}
            onClick={() => {
              this.toggleMenu(false);
            }}
          >
            {link.name}
            <Wrapper>{link.icon}</Wrapper>
          </ExternalDropdownItem>
        );
      }

      return (
        <DropdownItem
          key={link.name}
          to={link.to}
          onClick={() => {
            this.toggleMenu(false);
          }}
        >
          {link.name}
          <Wrapper>{link.icon}</Wrapper>
        </DropdownItem>
      );
    });
  };

  render() {
    const { isOpen } = this.state;
    const { children /* , logout, t */ } = this.props;

    /* TODO: remove sigout out button. It is now an external link
    This goes inside Dropdown
    <SignOut onClick={() => logout()}>
      {t('components.menus.menuDropdown.signOut')}
      <Wrapper>
        <SignOutIcon height="100%" width="100%" svg={iconLogout} />
      </Wrapper>
    </SignOut> */
    return (
      <Container {...getViewProps(this.props)}>
        <MenuTitle onClick={() => this.toggleMenu(!isOpen)}>{children}</MenuTitle>
        <Dropdown isOpen={isOpen}>
          {this.renderLinks()}
        </Dropdown>
      </Container>
    );
  }
}

MenuDropdown.propTypes = {
  // logout: PropTypes.func.isRequired,
  ml: PropTypes.string,
  children: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.node.isRequired,
    }),
  ),
  t: PropTypes.func.isRequired,
};

MenuDropdown.defaultProps = {
  ml: '',
  links: [],
};

export default withI18n()(onClickOutside(MenuDropdown));
