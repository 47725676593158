// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validate } from 'joi-browser';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import WithI18n from './WithI18n';

// Helpers
import { setStatePromise } from './../../helpers/helpers';

// Actions
import {
  fetchPanelData,
  savePanel,
  saveAdditionalPanelData,
  deletePanel,
  setData,
  copyMetadata,
  pasteMetadata,
} from './../../actions/SidePanelActions';
import { translateWithLocale as t } from '../../helpers/I18n';

// =============================
// Component
// =============================

const getValidSchema = (user, schema) => {
  if (typeof schema === 'function') {
    const customFields = _.get(user, ['customFields', 'data', 'fields'], []);
    return schema(user.userInfo.data.validation_fields, customFields);
  }
  return schema;
};

const getDefaultCustomValues = (user) => {
  const customFields = _.get(user, ['customFields', 'data', 'fields'], []);
  return _.filter(customFields, customField => !_.isNil(customField.defaultValue));
};

export function formPanelPresentational(
  schema,
  panelToFormValuesTransformer = null,
  fetchData = null,
  updateData = null,
  formValuesToRequestTransformer = null,
) {
  return (WrappedComponent) => {
    class HOC extends Component {
      pendingSave = false;

      constructor(props) {
        super(props);

        this.state = {
          initialForm: { values: {} },
          changed: false,

          form: {
            values: {},
            errors: {},
          },
          stopSave: false,
          saved: true,
        };

        this.save = debounce(this.save.bind(this), 2000);
      }

      componentWillMount() {
        // When mounting, if the panel has an id, it is an existing document
        // Thus, we need to fetch the data once
        if (this.props.panel.id) {
          this.props.fetchPanelData(this.props.panel, fetchData);
        }
      }

      componentWillReceiveProps(nextProps) {
        const { panel } = this.props;
        const { panel: nextPanel } = nextProps;

        if (_.get(nextProps, 'panel.isMetadataSaved', null) === false) this.save();
        // No data, i.e. when error
        if (!nextPanel.data) return;
        if (
          // Not fetching finished
          !(panel.isLoading && !nextPanel.isLoading) &&
          // Not saving finished
          !(panel.isSaving && !nextPanel.isSaving)
          // No nextPanel, i.e. error
        ) {
          return;
        }
        // If fetching or saving is done

        const data = this.doFormMutation(nextPanel);
        const validSchema = getValidSchema(nextProps.user, nextProps.schema);
        // We go through Joi to strip unknown values
        validate(data, validSchema, { stripUnknown: true }, (err, values) => {
          this.setState({
            initialForm: {
              ...this.state.initialForm,
              values,
            },
            changed: false,

            form: {
              ...this.state.form,
              values,
            },
            saved: true,
          });
        });
      }

      /**
       * Check if there are errors in form values using Joi
       */
      getErrors = () => {
        const validSchema = getValidSchema(this.props.user, this.props.schema);
        const result = validate(this.state.form.values, validSchema, {
          abortEarly: false,
          allowUnknown: true,
        });
        const { values: newValues } = this.state.form;
        const errors = { };
        const exceptionSigns = ['$'];
        const exceptionFields = ['title', 'full_name'];
        exceptionFields.forEach((field) => {
          if (newValues[field]) {
            exceptionSigns.forEach((sign) => {
              if (newValues[field].indexOf(sign) !== -1) {
                _.set(errors, field, `${t('notifications.exceptionString') } - $`);
              }
            });
          }
        });

        let countErrors = Object.keys(errors).length;
        if (result.error !== null) {
          const { error: { details } } = result;

          for (let i = 0; i < details.length; i += 1) {
            countErrors += 1;
            _.set(errors, details[i].path, details[i].message);
          }
        }
        // Return errors if they exist
        return countErrors > 0 ? errors : false;
      };

      saveErrors = errors => setStatePromise(this, {
        form: {
          ...this.state.form,
          errors: {
            ...this.state.errors,
            ...errors,
          },
        },
        saved: true,
      }).then(() => Promise.reject());

      /**
       * Update the current document
       * @param {string} name - Key of the value to be updated
       * @param {string} value - Key of the value to be updated
       */
      updateForm = (name, value) => {
        const { user } = this.props;

        // Set the new state of the form
        const statePromise = setStatePromise(this, {
          form: {
            values: {
              ...this.state.form.values,
              [name]: value,
            },
            errors: {
              ...this.state.form.errors,
              // Reset error for value
              [name]: '',
              publishing_ownerships: '',
              master_ownerships: '',
              artists_publishing_ownerships: '',
            },
          },
          saved: false,
        });

        // Check if there are any errors
        return statePromise
          .then(() => {
            const { values } = this.state.form;
            if (_.isNil(values.custom)) {
              const newValues = { ...values };
              const defaultValuesForCustomFields = getDefaultCustomValues(user);
              // init custom object field if not exist
              if (!_.isEmpty(defaultValuesForCustomFields)) {
                newValues.custom = {};
              }

              defaultValuesForCustomFields.forEach((defaultObject) => {
                if (_.isNil(newValues.custom[defaultObject.key])) {
                  newValues.custom[defaultObject.key] = defaultObject.defaultValue;
                }
              });

              return setStatePromise(this, {
                form: {
                  ...this.state.form,
                  values: newValues,
                },
              });
            }
            return null;
          })
          .then(() => {
            const errors = this.getErrors();
            return errors ? this.saveErrors(errors) : Promise.resolve();
          })
          .then(() => {
            const initialFormState = this.state.initialForm.values;
            const currentState = this.state.form.values;

            return setStatePromise(this, {
              changed: !_.isEqual(initialFormState, currentState),
            });
          })
          .then(() => this.save())
          .catch(() => {});
      };

      /**
       * Saves the document
       * with delay in 1000 ms
       */
      save = () => {
        setTimeout(() => {
          const { panel, savePanel: propsSavePanel } = this.props;
          const { form, stopSave } = this.state;

          if (stopSave) {
            return;
          }

          const someErrors = this.getErrors();
          if (!someErrors) {
            propsSavePanel(formValuesToRequestTransformer(form.values, panel), panel, updateData);
          } else {
            Promise.resolve()
              .then(() => this.saveErrors(someErrors))
              .catch(() => {});
          }
        }, 1000);
      };

      delaySave = (isStop = false) => {
        const { saved } = this.state;
        const statePromise = setStatePromise(this,
          prevState => ({ ...prevState, stopSave: isStop }));
        return statePromise
          .then(() => {
            if (!isStop && !saved) {
              this.save();
            }
          });
      };

      /**
       * Mutate form so it becomes usable by the react components
       * @param {Object} form - data retrieved by the api
       */
      doFormMutation = (panel) => {
        const form = panel.data;
        if (panelToFormValuesTransformer) {
          const nextData = {};

          Object.keys(form).forEach((name) => {
            const valueMutator = panelToFormValuesTransformer[name];

            if (valueMutator && typeof valueMutator === 'function' && form[name] !== undefined) {
              nextData[name] = valueMutator(form[name]);
            } else {
              nextData[name] = form[name];
            }
          });

          return nextData;
        }

        return form;
      };

      /**
       * Function used to edit documents which are linked to the current document
       * @param {string} name - Key of value to be updated
       * @param {string} value - Value to be updated
       * @param {Object} query - Query to be executed
       * @param {Object} getQueries - Queries to be executed after update
       */
      updateAdditionalFormData = (name, value, query, getQueries, custom = {}) => {
        const { panel } = this.props;
        const exceptionKeys = ['custom', 'version', 'tag'];
        const data = name ? { [name]: value } : null;
        if (data && !exceptionKeys.includes(name)) {
          data.custom = custom;
        }
        if (panel.id) {
          this.props.saveAdditionalPanelData(data, panel, query, getQueries);
        } else {
          const errors = this.getErrors();
          if (errors) {
            this.saveErrors(errors);
          }
        }
      };

      /**
       * Delete document
       */
      deleteForm = () => {
        const { panel } = this.props;

        // Delete can only be called if document exists
        if (panel.id) {
          this.props.deletePanel(panel);
        }
      };

      render() {
        // Strip unnecessary values from props
        const { fetchPanelData: _1, savePanel: _2, ...rest } = this.props;

        return (
          <WrappedComponent
            updateForm={this.updateForm}
            updateAdditionalFormData={this.updateAdditionalFormData}
            delaySave={this.delaySave}
            deleteForm={this.deleteForm}
            form={this.state.form}
            changed={this.state.changed}
            {...rest}
          />
        );
      }
    }

    HOC.propTypes = {
      panel: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          // For batch edit of tracks, we need to specify array of ids
          PropTypes.arrayOf(PropTypes.string),
        ]),
        isSaving: PropTypes.bool.isRequired,
        uuid: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        data: PropTypes.object,
        isLoading: PropTypes.bool,
      }).isRequired,
      // Joi validation schema
      schema: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired, // eslint-disable-line
      fetchPanelData: PropTypes.func.isRequired,
      savePanel: PropTypes.func.isRequired,
      setData: PropTypes.func.isRequired,
      copyMetadata: PropTypes.func.isRequired,
      pasteMetadata: PropTypes.func.isRequired,
      saveAdditionalPanelData: PropTypes.func.isRequired,
      deletePanel: PropTypes.func.isRequired,
      locale: PropTypes.string.isRequired,
    };

    return HOC;
  };
}

export function formPanel(
  schema,
  panelToFormValuesTransformer = null,
  fetchData = null,
  updateData = null,
  formValuesToRequestTransformer = v => v,
) {
  return (WrappedComponent) => {
    const HOC = WithI18n()(formPanelPresentational(
      schema,
      panelToFormValuesTransformer,
      fetchData,
      updateData,
      formValuesToRequestTransformer,
    )(WrappedComponent));
    function mapStateToProps(state) {
      const { user } = state;

      return {
        user,
        schema,
      };
    }

    return connect(mapStateToProps, {
      fetchPanelData,
      savePanel,
      saveAdditionalPanelData,
      setData,
      copyMetadata,
      pasteMetadata,
      deletePanel,
    })(HOC);
  };
}

export default formPanel;
