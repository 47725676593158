// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CancelToken } from 'axios';

import filter from 'lodash/filter';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import DropdownList from '../../dropdownlist/DropdownList';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';
import axios from '../../../../helpers/axios';
import envConfig from '../../../../../config/private/environment';

// Styles
import {
  Label,
  Container,
  Languages,
  DropDownArrow,
  IconIsFilled,
  TextArea,
  ErrorText,
} from './TranslateArea.styles';
import { Item } from '../../dropdownlist/DropdownList.styles';
import { Div, FlexBreakWord } from './../../../../themes/views';

// Assets
import iconDropDownArrow from './../../../../assets/images/icon-dropdown-arrow.svg';
import iconCheck from './../../../../assets/images/icon-check.svg';

// =============================
// Component
// =============================

class TranslateArea extends Component {
  static getPlaceholder(language) {
    const placeholders = {
      de: 'Beschreibung',
      en: 'Description',
      es: 'Descripción',
      fr: 'Description',
      it: 'Descrizione',
      fi: 'Description',
      ja: '説明',
      zh: '描述',
    };

    return placeholders[language];
  }

  getEntity = debounce((query) => {
    const { name, sessionToken, onFetchError } = this.props;

    if (!query) return null;

    // Cancel last axios request
    if (this.cancelAxios) this.cancelAxios.cancel('Request canceled by user.');

    // Generate new cancel token
    this.cancelAxios = CancelToken.source();
    this.cancelToken = this.cancelAxios.token;

    const url = `${envConfig.apiUrl}/meta/search/list?search=${query}&field=${name}`;

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
      cancelToken: this.cancelToken,
    };

    return axios
      .get(url, config)
      .then((res) => {
        this.setState({
          items: res.data.results,
          autocompleteDropdownIsOpened: true,
        });
      })
      .catch(onFetchError);
  }, 500);

  static propTypes = {
    sessionToken: PropTypes.string.isRequired,
    languages: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        locale: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    locale: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.string,
    descriptionAutocomplete: PropTypes.bool,
    error: PropTypes.array, // eslint-disable-line
    onFetchError: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    onChange: null,
    onFocus: null,
    onBlur: null,
    error: [],
    defaultLanguage: 'en',
    descriptionAutocomplete: false,
    onFetchError: () => {},
  };

  constructor(props) {
    super(props);

    const language = props.defaultLanguage || props.locale;
    const activeLanguage = TranslateArea.getPlaceholder(language) ? language : 'en';

    this.state = {
      activeLanguage,
      dropdownIsOpened: false,
      autocompleteDropdownIsOpened: false,
      items: [],
    };
  }

  getDropdownRef = (e) => {
    this.dropDownRef = e;
  };

  setDescriptionValue = (value) => {
    const { data, name, onChange } = this.props;
    const { activeLanguage } = this.state;

    if (onChange) {
      const retVal = [
        ...filter([...data], e => e.locale !== activeLanguage),
        {
          locale: activeLanguage,
          value,
        },
      ];

      onChange(name, filter(retVal, val => val.value !== ''));
      this.setState({ autocompleteDropdownIsOpened: false });
    }
  };

  handleChange = ({ target }) => {
    const { descriptionAutocomplete } = this.props;
    const { value } = target;

    if (descriptionAutocomplete && value) {
      this.getEntity(value);
    }

    this.setDescriptionValue(value);
  };

  changeLanguage = (languageCode) => {
    this.setState({ activeLanguage: languageCode });
  };

  toggleDropdown = () => {
    this.setState({ dropdownIsOpened: !this.state.dropdownIsOpened });
  };

  handleClickOutside = () => {
    this.setState({ dropdownIsOpened: false, autocompleteDropdownIsOpened: false });
  };

  getErrors = () => {
    const { error } = this.props;
    const errors = [];
    if (error) {
      error.forEach(e => errors.push(...Object.values(e))); // eslint-disable-line compat/compat
    }
    return errors;
  };

  handleItemKeyDown = (e, value) => {
    const { key, target } = e;
    switch (key) {
      // Tab
      case 'Tab':
        this.setState({
          autocompleteDropdownIsOpened: false,
        });
        break;

      // Enter
      case 'Enter':
        if (value) {
          this.setDescriptionValue(value);
        }
        break;

      // Arrow Up: Go to next item
      case 'ArrowUp':
        e.preventDefault();
        if (!target.previousSibling) {
          this.dropDownRef.lastChild.focus();
          break;
        }
        target.previousSibling.focus();
        break;

      // Arrow Down: Go to previous item
      case 'ArrowDown':
        e.preventDefault();
        if (!target.nextSibling) {
          this.dropDownRef.firstChild.focus();
          break;
        }
        target.nextSibling.focus();
        break;

      // If user is not navigating nor saving, we assume he wants to add some query
      default:
        this.inputRef.focus();
        break;
    }
  };

  renderItems = () => {
    const { items } = this.state;

    const dropdownItems = [];

    if (items.length) {
      items.forEach((item) => {
        dropdownItems.push(
          <Item
            height="40px"
            key={item.id}
            onClick={() => {
              this.setDescriptionValue(item.title);
            }}
            onKeyDown={e => this.handleItemKeyDown(e, item.title)}
            tabIndex="-1"
          >
            <FlexBreakWord>{item.title}</FlexBreakWord>
          </Item>,
        );
      });
    }

    return dropdownItems;
  };

  renderDropdownItems = () => {
    const { languages, data } = this.props;
    // List of language codes
    const languageCodes = Object.keys(languages);

    const values = keyBy(data, 'locale');

    return orderBy(languageCodes, language => get(values[language], 'value', ''), 'desc').map(
      languageCode => (
        <Item
          align="center"
          width="100%"
          onClick={() => this.changeLanguage(languageCode)}
          key={languageCode}
          height="25px"
        >
          {languages[languageCode].name}
          {get(values[languageCode], 'value') && (
            <IconIsFilled svg={iconCheck} height="100%" width="100%" />
          )}
        </Item>
      ),
    );
  };

  render() {
    const { name, label, languages, data, descriptionAutocomplete, onFocus, onBlur } = this.props;
    const { activeLanguage, dropdownIsOpened, autocompleteDropdownIsOpened } = this.state;
    const values = keyBy(data, 'locale');
    const errors = this.getErrors();

    return (
      <Div {...getViewProps(this.props)}>
        <Label>{label}</Label>
        <Container>
          <Languages
            onClick={() => this.toggleDropdown()}
            innerRef={(e) => {
              this.language = e;
            }}
            className="language"
          >
            {languages[activeLanguage].name}
            <DropDownArrow svg={iconDropDownArrow} height="100%" width="100%" />
            <DropdownList
              items={() => this.renderDropdownItems()}
              isOpen={dropdownIsOpened}
              handleClickOutside={this.handleClickOutside}
              disableOnClickOutside={!dropdownIsOpened}
              outsideClickIgnoreClass="language"
            />
          </Languages>
          <TextArea
            id={`textarea-${name}`}
            name={name}
            value={get(values[activeLanguage], 'value', '')}
            placeholder={TranslateArea.getPlaceholder(activeLanguage)}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Container>
        {errors.map((error, index) => (
          <ErrorText
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {error}
          </ErrorText>
        ))}
        {descriptionAutocomplete && (
          <Div position="relative" {...getViewProps(this.props)}>
            <DropdownList
              getRef={this.getDropdownRef}
              isOpen={autocompleteDropdownIsOpened}
              items={() => this.renderItems()}
              handleClickOutside={this.handleClickOutside}
            />
          </Div>
        )}
      </Div>
    );
  }
}

export default withI18n()(TranslateArea);
