import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const batchTrackLabels = {
  rating: 'Rating',
  public: 'Public',
};

const BatchTracksSchema = {
  tracks: Joi.array().items(Joi.string()),
  rating: Joi.number().label(batchTrackLabels.rating).empty(null),
  public: Joi.boolean().label(batchTrackLabels.public),
};

export default (validation_fields = null, custom_fields = []) => {
  const batchTrackCustomFields = getFieldsByCollection('batch-track', validation_fields);
  if (!validation_fields || isEmpty(batchTrackCustomFields)) { // eslint-disable-line camelcase
    return BatchTracksSchema;
  }

  return createCustomSchema(batchTrackCustomFields, custom_fields, {
    defaultSchema: BatchTracksSchema,
    labels: batchTrackLabels,
  });
};
