// =============================
// Imports
// =============================

// External Dependencies
import { useTranslation } from 'react-i18next';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import Favicon from 'react-favicon';

// Assets
import './../assets/styles/reset.css';

// Config
import htmlPageConfig from '../../config/public/htmlPage';

// Constants
import * as routes from './../constants/Routes';

// Components
import NotFoundPage from './pages/notfound/NotFound';
import TracksPage from './containers/pages/TracksContainer';
import MultipartTracksPage from './containers/pages/MultipartTracksContainer';
import SearchLayout from './layouts/searchlayout/SearchLayout';
import PrivateRoute from './containers/privateRoute';

import MultipartPrintContainer from './containers/print/MultipartPrintContainer';

import { AuthGuard } from './containers/auth/auth-guard';
import NotificationsList from './containers/notifications/NotificationsListContainer';
import Logout from './pages/auth/logout';
import { Login } from './pages/auth/login';
import Loader from './presentationals/loader/Loader';

// Helpers
import { getOrResetLocale } from '../helpers/I18n';

// Themes imports
import './../themes/globals';

// Styles
import { AppContainer } from './App.styles';

// Lazy components
const Player = lazy(() => import('./containers/player/PlayerContainer'));
const PlayerCore = lazy(() => import('./containers/playercore/PlayerCoreContainer'));
const AlbumsPage = lazy(() => import('./containers/pages/AlbumsContainer'));
const PlaylistsPage = lazy(() => import('./containers/pages/PlaylistsContainer'));
const CatalogsPage = lazy(() => import('./containers/pages/CatalogsContainer'));
const ProvidersPage = lazy(() => import('./containers/pages/ProvidersContainer'));
const UploadsPage = lazy(() => import('./containers/pages/UploadsPagesContainer'));
const TracksPrintContainer = lazy(() => import('./containers/print/TracksPrintContainer'));
const AlbumPrintContainer = lazy(() => import('./containers/print/AlbumPrintContainer'));

// =============================
// Component
// =============================

const App = () => {
  const { t } = useTranslation();

  return (
    <AppContainer>
      <Helmet
        htmlAttributes={{ lang: getOrResetLocale() }}
        titleTemplate={htmlPageConfig.titleTemplate}
        defaultTitle={htmlPageConfig.defaultTitle}
        meta={htmlPageConfig.meta}
      />
      <Favicon url="/bmat-favicon.png" />
      <NotificationsList />
      <PrivateRoute>
        <AuthGuard>
          <SearchLayout>
            <Suspense
              fallback={<Loader fullscreen text={t('components.initializer.loading', 'Loading')} />}
            >
              <Switch>
                <Route exact path={routes.ALBUMS_PAGE_ROUTE} component={AlbumsPage} />
                <Route exact path={routes.CATALOGS_PAGE_ROUTE} component={CatalogsPage} />
                <Route exact path={routes.HOME_PAGE_ROUTE} component={TracksPage} />
                <Route exact path={routes.LOGIN} component={Login} />
                <Route exact path={routes.LOGOUT} component={Logout} />
                <Route
                  exact
                  path={routes.MULTIPART_TRACKS_PAGE_ROUTE}
                  component={MultipartTracksPage}
                />
                <Route exact path={routes.PLAYLISTS_PAGE_ROUTE} component={PlaylistsPage} />
                <Route exact path={routes.PRINT_ALBUM_PAGE} component={AlbumPrintContainer} />
                <Route
                  exact
                  path={routes.PRINT_MULTIPART_PAGE}
                  component={MultipartPrintContainer}
                />
                <Route exact path={routes.PRINT_TRACK_PAGE} component={TracksPrintContainer} />
                <Route exact path={routes.PROVIDERS_PAGE_ROUTE} component={ProvidersPage} />
                <Route exact path={routes.UPLOADS_PAGE_ROUTE} component={UploadsPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </SearchLayout>
          <Suspense fallback={null}>
            <Player />
            <PlayerCore />
          </Suspense>
        </AuthGuard>
      </PrivateRoute>
    </AppContainer>
  );
};

export default App;
