// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../../hoc/WithI18n';

// Components
import Input from './../../../inputs/inputunderline/InputUnderline';

// Themes
import { Flex } from './../../../../../themes/views';

// =============================
// Component
// =============================

class MasterPercentage extends Component {
  static propTypes = {
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { ownership, errors, disabled, onChange, onFocus, onBlur, t } = this.props;

    return (
      <Flex align="baseline" justify="space-between">
        <Input
          name="ownership_percentage"
          label={t('components.inputs.percentage.ownership_percentage')}
          onChange={
            disabled
              ? null
              : (name, value) => onChange({ ...ownership, ownership_percentage: value })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          value={ownership.ownership_percentage}
          error={errors.ownership_percentage}
          mr="20px"
          width="48%"
          key="1"
        />
        <Input
          name="sync_percentage"
          label={t('components.inputs.percentage.sync_percentage')}
          onChange={
            disabled ? null : (name, value) => onChange({ ...ownership, sync_percentage: value })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          value={ownership.sync_percentage}
          error={errors.sync_percentage}
          mr="20px"
          width="48%"
          key="2"
        />,
      </Flex>
    );
  }
}

export default withI18n()(MasterPercentage);
