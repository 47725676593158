// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import union from 'lodash/union';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';

// Configs
import envConfig from '../../../../config/private/environment';

// Helpers
import axios from './../../../helpers/axios';

// Components
import PlaylistAdderDropdown from '../../presentationals/playlistadder/PlaylistAdderDropdown';

// =============================
// Component
// =============================

class PlaylistAdderDropdownWrapper extends Component {
  static propTypes = {
    sessionToken: PropTypes.string,
    trackIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    sessionToken: undefined,
    trackIds: [],
    isOpen: false,
  };

  state = {
    playlists: [],
    selectedPlaylistIds: [],
  };

  componentWillMount() {
    this.fetchTracksPlaylists(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.trackIds, this.props.trackIds) ||
      (typeof nextProps.trackIds === 'string' && nextProps.isOpen !== this.props.isOpen)
    ) {
      this.fetchTracksPlaylists(nextProps);
    }
  }

  componentWillUnmount() {
    this.cancelAllRequest = true;
  }

  fetchTracksPlaylists = (props) => {
    const { sessionToken, trackIds } = props;
    // Fetch list of playlists
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
    };

    const url = `${envConfig.apiUrl}/meta`;
    return axios
      .get(`${url}/playlists/completelist`, config)
      .then((res) => {
        if (!this.cancelAllRequest) {
          this.setState({
            playlists: res.data,
          });
        }

        if (typeof trackIds === 'string') {
          return axios.get(`${url}/tracks/${trackIds}/playlists`, config).then((resTwo) => {
            if (!this.cancelAllRequest) {
              this.setState({
                selectedPlaylistIds: resTwo.data.map(p => p.id),
              });
            }
          });
        }

        return Promise.resolve();
      })
      .catch(err => err);
  };

  setTracksPlaylists = (name, value) => {
    const { trackIds } = this.props;

    if (typeof trackIds === 'string') this.setTracksPlaylistsSingle(name, value);
    else this.setTracksPlaylistsMultiple(name, value);
  };

  setTracksPlaylistsSingle = (name, value) => {
    const nextPlaylists = value;
    const { sessionToken, trackIds } = this.props;
    const { selectedPlaylistIds } = this.state;

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
    };

    const url = `${envConfig.apiUrl}/meta`;

    const ops = [];

    if (difference(nextPlaylists, selectedPlaylistIds).length > 0) {
      ops.push(axios.post(
        `${url}/tracks/${trackIds}/playlists`,
        { playlists: difference(nextPlaylists, selectedPlaylistIds) },
        config,
      ));
    }

    if (difference(selectedPlaylistIds, nextPlaylists).length > 0) {
      ops.push(axios({
        method: 'delete',
        url: `${url}/tracks/${trackIds}/playlists`,
        data: { playlists: difference(selectedPlaylistIds, nextPlaylists) },
        ...config,
      }));
    }

    return Promise.all(ops)
      .then(() =>
        axios.get(`${url}/tracks/${trackIds}/playlists`, config).then((res) => {
          if (!this.cancelAllRequest) {
            this.setState({
              selectedPlaylistIds: res.data.map(p => p.id),
            });
          }
        }),
      )
      .catch(err => err);
  };

  setTracksPlaylistsMultiple = (name, value) => {
    const selectedPlaylistIds = union(value, this.state.selectedPlaylistIds);
    this.setState(() => ({
      selectedPlaylistIds,
    }));
    const { sessionToken, trackIds } = this.props;

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
    };

    const url = `${envConfig.apiUrl}/meta`;

    return axios
      .post(
        `${url}/playlists/addtracks`,
        { playlists: selectedPlaylistIds, tracks: trackIds },
        config,
      )
      .then(() => {})
      .catch(err => err);
  };

  render() {
    const { sessionToken, ...props } = this.props;
    const { playlists, selectedPlaylistIds } = this.state;
    return (
      <PlaylistAdderDropdown
        playlists={playlists}
        values={selectedPlaylistIds}
        onChange={this.setTracksPlaylists}
        {...props}
      />
    );
  }
}

function mapStateToProps({ user }) {
  return {
    sessionToken: user.sessionToken,
  };
}

export default connect(mapStateToProps)(PlaylistAdderDropdownWrapper);
