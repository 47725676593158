// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

// Styles
import { Div } from './../../../../themes/views';

export const Container = styled(Div)`
  height: 100%;
  position: relative;
  z-index: ${props => props.theme.zIndex.header};
`;

export const MenuTitle = styled.div`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.m};
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 100%;
`;

export const Dropdown = styled.ul`
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius.box};
  box-shadow: 0 0 20px 0 rgba(22, 41, 42, 0.3);
  opacity: 1;
  padding: 20px 0;
  position: absolute;
  top: 100%;
  transform: translate3d(-180px, 0, 0);
  transition: opacity ${props => props.theme.transition.medium};
  width: 220px;
  ${props => !props.isOpen && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

export const DropdownItem = styled(NavLink)`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.s};
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0 20px;
  transition: background-color ${props => props.theme.transition.medium};
  width: 100%;

  &:hover {
    background-color: #ecf4f8; /* TODO: Voir si on peut utiliser une couleur du thème */
    opacity: 1;
  }
`;

export const ExternalDropdownItem = DropdownItem.withComponent('a');

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 24px;
`;

export const SignOut = DropdownItem.extend`
  color: ${props => props.theme.colors.text.grey};
  cursor: pointer;
`.withComponent('div');

export const SignOutIcon = styled(SVGInline)`
  height: 22px;
  stroke: ${props => props.theme.colors.text.grey};
  width: 22px;
`;
