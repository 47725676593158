// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { uploadFile, removeFile } from '../../../../actions/SidePanelActions';

// HOC
import withConfirmation from '../../../hoc/WithConfirmation';
import withI18n from '../../../hoc/WithI18n';

// Components
import Status from './status/Status';

// Styles
import {
  Container,
  IconType,
  FileName,
  SelectFile,
  Icon,
  FileInput,
  DownloadLink,
} from './FileUpload.styles';

import { InfoText, ErrorText } from '../../inputs/container/InputContainer.styles';

// Icons
import downloadSVG from './../../../../assets/images/icon-download.svg';
import trashSVG from './../../../../assets/images/icon-trash.svg';
import imageSVG from './../../../../assets/images/file-picture.svg';
import audioSVG from './../../../../assets/images/file-music.svg';
import documentSVG from './../../../../assets/images/file-text.svg';
import { IconText } from './../../../../themes/icons';

// =============================
// Component
// =============================

class FileUpload extends Component {
  file = null;
  allowedFormats = {
    image: ['jpg', 'jpeg', 'png', 'gif'],
    audiofile: ['mp3', 'm4a', 'alac', 'aac', 'wav', 'aif', 'aiff', 'flac'],
    stems: ['zip'],
    attachments: ['jpg', 'jpeg', 'png', 'pdf', 'wav'],
  };

  constructor(props) {
    super(props);

    this.state = {
      status: props.panel.uploads.get(props.type),
      pending: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextUploadStatus = nextProps.panel.uploads.get(this.props.type);

    if (this.props.panel.uploads.get(this.props.type) !== nextUploadStatus) {
      this.setState({
        status: nextUploadStatus,
      });
    }

    // Panel has been saved
    if (!this.props.panel.id && nextProps.panel.id) {
      if (this.file) {
        this.setState({ pending: false });
        this.doUpload(this.file, nextProps.panel);
      }
    }
  }

  onSelectFile = () => {
    if (!this.getStatusLoading() && !this.props.disabled) {
      this.input.click();
    }
  };

  onDelete = () => {
    const { disabled, type, panel, removeFile: propsRemoveFile, onConfirm, t } = this.props;
    if (!disabled) {
      onConfirm(t('components.fileUpload.deleteConfirmationMessage'), {
        title: t('components.fileUpload.deleteConfirmationTitle'),
        submit: t('components.fileUpload.deleteConfirmationSubmit'),
      }).then((result) => {
        if (result) propsRemoveFile(type, panel);
      });
    }
  };

  onCheckFile = (event) => {
    const { files } = event.target;

    if (files.length) {
      const file = files[0];

      if (!this.checkFileFormat(file.name)) {
        return;
      }

      this.file = file;

      if (!this.props.panel.id) {
        this.setState({ pending: true });
        return;
      }

      this.doUpload(this.file, this.props.panel);
    } else {
      this.file = null;
    }
  };

  getStatus = () => {
    const { status } = this.state;

    if (!this.props.file || (status && status.progress)) {
      return null;
    }

    if (this.props.file && !status) {
      return !this.props.conversion_state;
    }

    return status && !status.error;
  };

  getStatusLoading = () => {
    const { status } = this.state;

    return status && status.progress > 0 && status.progress < 1;
  };

  getIcon = () => {
    switch (this.props.type) {
      case 'image':
        return imageSVG;
      case 'audiofile':
        return audioSVG;
      case 'stems':
        return documentSVG;
      case 'attachments':
        return imageSVG;
      default:
        return documentSVG;
    }
  };

  doUpload = (file, panel) => {
    this.props.uploadFile(this.props.type, file, panel);
  };

  isFormatAllowed(format) {
    switch (this.props.type) {
      case 'image':
        return this.allowedFormats.image.indexOf(format) > -1;
      case 'audiofile':
        return this.allowedFormats.audiofile.indexOf(format) > -1;
      case 'stems':
        return this.allowedFormats.stems.indexOf(format) > -1;
      case 'attachments':
        return this.allowedFormats.attachments.indexOf(format) > -1;
      default:
        return false;
    }
  }

  checkFileFormat = (name) => {
    const { t } = this.props;
    const ext = name.substr(name.lastIndexOf('.') + 1);

    if (this.isFormatAllowed(ext)) {
      return true;
    }

    this.setState({
      status: {
        error: {
          message: `${t('components.fileUpload.invalidFileFormat')} ${this.allowedFormats[
            this.props.type
          ].join(', ')}`,
        },
      },
    });

    return false;
  };

  renderDownload() {
    const { file, hdMp3, type, canDownload, t } = this.props;
    if (!canDownload) return null;

    if (type === 'audiofile') {
      return [
        hdMp3 && hdMp3.url && (
          <DownloadLink
            download
            href={hdMp3.url}
            title={t('components.fileUpload.download')}
            target="_blank"
            key="0"
          >
            <IconText>MP3</IconText>
          </DownloadLink>
        ),
        file && file.url && (
          <DownloadLink
            download
            href={file.url}
            title={t('components.fileUpload.download')}
            target="_blank"
            ml="20px"
            key="1"
          >
            <IconText>WAV</IconText>
          </DownloadLink>
        ),
      ];
    }

    return (
      <DownloadLink
        download
        href={file.url}
        title={t('components.fileUpload.download')}
        target="_blank"
      >
        <Icon svg={downloadSVG} height="100%" width="100%" />
      </DownloadLink>
    );
  }

  renderFile() {
    const { file, disabled, canDelete } = this.props;

    return (
      <Container direction="row" align="center">
        <IconType svg={this.getIcon()} />
        <FileName title={file.file_name}>{file.file_name}</FileName>
        {this.renderDownload()}
        {canDelete && !disabled && (
          <Icon svg={trashSVG} height="100%" width="100%" onClick={this.onDelete} />
        )}
        <Status status={this.getStatus()} />
      </Container>
    );
  }

  renderDefaultText = () => {
    const { filetype, disabled, t } = this.props;

    if (disabled && filetype === 'Stems') {
      return t('components.fileUpload.noStems');
    }

    if (disabled && filetype === 'Audiofile') {
      return t('components.fileUpload.noAudiofile');
    }

    if (disabled && filetype === 'Attachments') {
      return t('component.fileUpload.noAttachment');
    }

    if (disabled && filetype === 'Picture') {
      return t('components.fileUpload.noPicture');
    }

    if (filetype === 'Stems') {
      return t('components.fileUpload.addStems');
    }

    if (filetype === 'Audiofile') {
      return t('components.fileUpload.addAudiofile');
    }

    if (filetype === 'Attachments') {
      return t('components.fileUpload.addAttachment');
    }

    if (filetype === 'Picture') {
      return t('components.fileUpload.addPicture');
    }
    throw `Untranslated filetype: ${filetype}`; // eslint-disable-line
  };

  renderSelect() {
    const { t } = this.props;
    const { status, pending } = this.state;

    return (
      <div>
        <Container direction="row" align="center" border>
          <SelectFile onClick={this.onSelectFile}>{this.renderDefaultText()}</SelectFile>
          <Status progress={status && status.progress} status={this.getStatus()} />
          <FileInput
            innerRef={(ref) => {
              this.input = ref;
            }}
            type="file"
            onChange={this.onCheckFile}
          />
        </Container>
        {status && status.error && <ErrorText>{this.state.status.error.message}</ErrorText>}
        {pending && (
          <InfoText>
            ${t('components.fileUpload.pending')} {this.file.name} $
            {t('components.fileUpload.pendingMessage')}
          </InfoText>
        )}
      </div>
    );
  }

  render() {
    return this.props.file && this.props.file.file_name ? this.renderFile() : this.renderSelect();
  }
}

FileUpload.propTypes = {
  filetype: PropTypes.string,
  uploadFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  panel: PropTypes.shape({
    id: PropTypes.string,
    uploads: PropTypes.object, // eslint-disable-line
  }).isRequired,
  file: PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  hdMp3: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  type: PropTypes.string.isRequired,
  conversion_state: PropTypes.bool,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  canDownload: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  filetype: 'File',
  file: {
    file_name: '',
    url: '',
  },
  hdMp3: undefined,
  conversion_state: false,
  disabled: false,
  onConfirm: () => Promise.resolve(false),
};

export const FileUploadPresentational = FileUpload;

function mapStateToProps({ user }) {
  return {
    canDownload: user.canDownload,
    canDelete: user.canDelete,
  };
}

export default connect(mapStateToProps, { uploadFile, removeFile })(
  withConfirmation(withI18n()(FileUpload)),
);
