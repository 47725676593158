/* eslint-disable no-fallthrough, no-console */

// =============================
// Imports
// =============================

// External dependencies
import get from 'lodash/get';

// Helpers
import { translateWithLocale as t } from '../helpers/I18n';

// Actions
import { setNotification, clearNotification } from './../actions/NotificationActions';
import { updateThePage } from './../actions/SearchActions';

// Constants
import * as actions from './../constants/ActionTypes';
import * as ntfs from './../constants/NotificationTypes';
import { ERROR } from './../constants/PlayerStates';
import { PLAYLIST_PANEL } from './../constants/SidePanelTypes';
import { TRACK_NUMBER_NOT_UNIQUE } from './../constants/ServerErrorKeys';

// =============================
// Middleware
// =============================

const noPlaylistDistribution = (action) => {
  if (
    action.type === actions.SAVE_PANEL_ERROR &&
    action.payload.panel.name === PLAYLIST_PANEL &&
    get(action.payload.error, 'response.status') === 402 &&
    get(action.payload.error, 'response.data.key') === 'permission_needed'
  ) {
    return true;
  }

  return false;
};

const isReachedDocQuotaError = (action) => {
  // NOTE: Identifying quota error by it properties, could be fragile.
  if (
    // Docs saved from panel
    (action.type === actions.SAVE_PANEL_ERROR ||
      // Track saved from track versions, or album track versions
      action.type === actions.SAVE_ADD_PANEL_ERROR ||
      // Docs saved on the fly
      action.type === actions.ON_THE_FLY_CREATION_ERROR) &&
    get(action.payload.error, 'response.status') === 402 &&
    get(action.payload.error, 'response.data.key') === 'quota_exceeded'
  ) {
    return true;
  }

  return false;
};

const getSearchError = (err) => {
  switch (true) {
    case err.status === 402:
      return t('notifications.searchResultErrorInfoQuota');

    default:
      return t('notifications.searchResultErrorInfoDefault');
  }
};

const getLinkTracksFailureMsg = (err) => {
  let txt;

  switch (err.key) {
    case 'two_tracks_minimum':
      txt = t('notifications.linkTracksFailureInfoTwoTracksMinimum');
      break;

    case 'must_own_two_tracks':
      txt = t('notifications.linkTracksFailureInfoMustOwnTwoTracks');
      break;

    case 'must_have_one_original':
      txt = t('notifications.linkTracksFailureInfoMustHaveOneOriginal');
      break;

    case 'cannot_have_no_versions':
      txt = t('notifications.linkTracksFailureInfoCannotHaveNoVersions');
      break;

    default:
      txt = t('notifications.linkTracksFailureInfoDefault');
      break;
  }

  return txt;
};

const notificationsMiddleware = store => next => (action) => {
  switch (true) {
    // ======================
    // Track panel
    // ======================

    case noPlaylistDistribution(action):
      store.dispatch(
        setNotification({
          notificationKey: ntfs.NO_PLAYLIST_DISTRIBUTION,
          message: t('notifications.noPlaylistDistribution'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    case isReachedDocQuotaError(action):
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REACHED_DOC_QUOTA_ERROR,
          message: t('notifications.reachedDocQuotaError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.GET_SEARCH_RESULTS_FAILURE:
      if (action.payload) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.SEARCH_RESULT_ERROR_INFO,
            message: getSearchError(action.payload),
            type: 'error',
            duration: 6000,
          }),
        );
      }
      return next(action);

    case action.type === actions.DID_BATCH_DELETE_OPERATION:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DID_BATCH_DELETE_OPERATION_INFO,
          message: t('notifications.didBatchDeleteOperationInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.DID_CHANGE_PLAYLIST_TRACKS_STATUS_OPERATION:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DID_CHANGE_PLAYLIST_TRACKS_STATUS_INFO,
          message: t('notifications.didChangePlaylistTracksStatusInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.COPY_PANEL_METADATA:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.COPY_PANEL_METADATA_SUCCESS_INFO,
          message: t('notifications.copyMetadataSuccess'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.PASTE_PANEL_METADATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.PASTE_PANEL_METADATA_ERROR_INFO,
          message: t('notifications.pasteMetadataError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.PASTE_PANEL_METADATA_TYPE_ERROR:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.PASTE_PANEL_METADATA_TYPE_ERROR_INFO,
          message: t('notifications.pasteMetadataTypeError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Export CSV
    // ======================
    case action.type === actions.EXPORT_CSV_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_CSV_SUCCESS_INFO,
          message: t('notifications.exportCsvSuccessInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.EXPORT_CSV_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_CSV_FAILURE_INFO,
          message: action.payload,
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Export JSON
    // ======================
    case action.type === actions.EXPORT_JSON_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_JSON_SUCCESS_INFO,
          message: t('notifications.exportJsonSuccessInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.EXPORT_JSON_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_JSON_FAILURE_INFO,
          message: action.payload,
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

      // ======================
      // Export bulk JSON
      // ======================

    case action.type === actions.EXPORT_BULK_JSON_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_BULK_JSON_SUCCESS_INFO,
          message: t('notifications.exportBulkJsonSuccessInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.EXPORT_BULK_JSON_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_BULK_JSON_FAILURE_INFO,
          message: action.payload,
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Download Archive
    // ======================
    case action.type === actions.DOWNLOAD_ARCHIVE_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_ARCHIVE_SUCCESS_INFO,
          message: t('notifications.downloadArchiveSuccessInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.DOWNLOAD_ARCHIVE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_ARCHIVE_FAILURE_INFO,
          message: action.payload,
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Link track versions
    // ======================
    case action.type === actions.LINK_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.LINK_TRACKS_SUCCESS_INFO,
          message: t('notifications.linkTracksSuccessInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.LINK_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.LINK_TRACKS_FAILURE_INFO,
          message: getLinkTracksFailureMsg(action.payload),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Player
    // ======================
    // When player goes to ERROR state
    case action.type === actions.SET_STATE && action.payload === ERROR:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.PLAYER_LOAD_ERROR,
          message: t('notifications.playerLoadError'),
          type: 'error',
          duration: 6000,
        }),
      );

      return next(action);

    // ======================
    // Ingestions
    // ======================
    case action.type === actions.SET_PAGE_TAGS_STILL_FINDING:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SET_PAGE_TAGS_STILL_FINDING_INFO,
          message: t('notifications.setPageTagsStillFindingInfo'),
          type: 'info',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.SET_VERSION_MATCHES_DRAFT_SAVED:
    case action.type === actions.SET_TAG_MATCHES_DRAFT_SAVED:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SET_MATCHES_INFO,
          message: t('notifications.setMatchesInfo'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // Pending search
    // ======================
    case action.type === actions.SET_PENDING_SEARCH:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SEARCH_UPDATE_NEEDED_INFO,
          message: t('notifications.searchUpdateNeededInfo'),
          type: 'info',
          actions: [
            {
              name: t('notifications.searchUpdateNeededInfoAction'),
              func: () =>
                store.dispatch(
                  updateThePage(
                    action.payload.key,
                    action.payload.resetPage,
                    action.payload.searchState,
                  ),
                ),
            },
          ],
          duration: Infinity,
        }),
      );
      return next(action);

      // ======================
      // Custom fields
      // ======================

    case action.type === actions.GENERATE_CUSTOM_ID_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GENERATE_CUSTOM_ID_ERROR,
          message: action.payload.status === 404
            ? t('notifications.customIdEmptyValue')
            : t('notifications.customIdPrefixError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.CHECK_CUSTOM_ID_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CHECK_CUSTOM_ID_ERROR,
          message: action.payload.status === 409
            ? t('notifications.customIdDuplicate')
            : t('notifications.customIdError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    // ======================
    // File upload
    // ======================
    case action.type === actions.FILE_UPLOAD_FAILURE:
      if (action.payload.type === 'stems' && action.payload.error.status === 402) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.STEMS_UPLOAD_FAILURE_INFO,
            message: t('notifications.stemsUploadFailureInfo'),
            type: 'error',
            duration: 6000,
          }),
        );
      } else if (action.payload.error.status === 402) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.DOC_BINARIES_UPLOAD_FAILURE_INFO,
            message: t('notifications.docBinariesUploadFailureInfo'),
            type: 'error',
            duration: 6000,
          }),
        );
      }

      return next(action);

    case action.type === actions.FILE_DELETE_FAILURE:
      if (action?.payload?.error?.response?.data?.status === 400
        && action?.payload?.error?.response?.data?.message === 'File is being processed. Please try again later') {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.FILE_DELETE_FAILURE,
            message: t('notifications.fileDeleteErrorInfo'),
            type: 'error',
            duration: 6000,
          }),
        );
        return next(action);
      }
    case action.type === actions.PENDING_SEARCH_RESET:
      store.dispatch(clearNotification(ntfs.SEARCH_UPDATE_NEEDED_INFO));
      return next(action);

    case action.type === actions.FETCH_PANEL_ERROR:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.FETCH_PANEL_ERROR_INFO,
          message: t('notifications.fetchPanelErrorInfo'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);
    case action.type === actions.ON_THE_FLY_CREATION_ERROR:
      const fullName = get(action, ['payload', 'query', 'full_name'], null);
      const title = get(action, ['payload', 'query', 'title'], null);
      if ((fullName && fullName?.indexOf('$') !== -1) || (title && title?.indexOf('$') !== -1)) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.FETCH_PANEL_ERROR_INFO,
            message: `${t('notifications.exceptionString') } - $`,
            type: 'error',
            duration: 6000,
          }),
        );
        return next(action);
      }

    case action.type === actions.FETCH_BATCH_PANEL_ERROR:
    case action.type === actions.SAVE_BATCH_PANEL_ERROR:
    case action.type === actions.SAVE_ADD_PANEL_ERROR: {
      const errorKey = get(action, ['payload', 'error', 'response', 'data', 'key'], null);
      if (errorKey === TRACK_NUMBER_NOT_UNIQUE) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.FETCH_PANEL_ERROR_INFO,
            message: t('notifications.trackNumberNotUnique'),
            type: 'error',
            duration: 6000,
          }),
        );
        return next(action);
      }
    }
    case action.type === actions.DELETE_PANEL_FAILURE:
    case action.type === actions.API_SELECT_INPUT_FETCH_ERROR:
    case action.type === actions.GET_INGESTIONS_FAILURE:
    case action.type === actions.CREATE_INGESTION_FAILURE:
    case action.type === actions.BATCH_DELETE_OPERATION_FAILURE:

    case action.type === actions.CHANGE_PLAYLIST_TRACKS_STATUS_FAILURE:
      console.warn(action.payload);
      break;

    // ======================
    // SYNC
    // ======================
    case action.type === actions.MANUAL_SYNC_ARTIST_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MANUAL_SYNC_ARTIST_SUCCESS_INFO,
          message: t('notifications.yleManualSyncSuccess'),
          type: 'success',
          duration: 6000,
        }),
      );
      return next(action);

    case action.type === actions.MANUAL_SYNC_ARTIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MANUAL_SYNC_ARTIST_ERROR_INFO,
          message: t('notifications.yleManualSyncError'),
          type: 'error',
          duration: 6000,
        }),
      );
      return next(action);

    default:
      break;
  }

  return next(action);
};

export default notificationsMiddleware;
