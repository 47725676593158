// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// =============================
// Styled Components
// =============================

export const WaveformContainer = styled.div`
  cursor: pointer;
  height: 100%;
  position: relative;
  width: ${props => props.width};
`;

export const OriginalWaveform = styled(SVGInline)`
  align-items: center;
  display: flex;
  height: 100%;
  width: ${props => props.width};

  & > svg > g {
    fill: ${props => props.theme.colors.text.grey} !important;
  }
`;

export const SeekingContainer = styled.div.attrs({
  style: ({ width }) => ({
    width,
  }) })`
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
`;

export const SeekingWaveform = styled(SVGInline)`
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${props => props.width};

  & > svg > g {
    fill: ${props => props.theme.colors.primary} !important;
  }
`;
