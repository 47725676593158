// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CancelToken } from 'axios';
import debounce from 'lodash/debounce';

// Configs
import envConfig from '../../../../../config/private/environment';

// Helpers
import axios from './../../../../helpers/axios';
import { getViewProps } from '../../../../helpers/helpers';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import DropdownList from '../../dropdownlist/DropdownList';
import InputContainer from '../container/InputContainer';
import Input from '../input/Input';

// Theme
import { Div, Flex, FlexBreakWord } from './../../../../themes/views';

// Styles
import { Item } from '../../dropdownlist/DropdownList.styles';


// =============================
// Component
// =============================

class ApiAutocompleteInput extends Component {
  getEntity = debounce((query) => {
    const { name, entity, isCustom, sessionToken, onFetchError } = this.props;

    if (!entity || !query) return null;

    // Cancel last axios request
    if (this.cancelAxios) this.cancelAxios.cancel('Request canceled by user.');

    // Generate new cancel token
    this.cancelAxios = CancelToken.source();
    this.cancelToken = this.cancelAxios.token;

    let params = '';
    if (isCustom) {
      params = `&field=${name}&isCustom=true`;
    }

    const url = `${envConfig.apiUrl}/${entity}/list?search=${query}${params}`;

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
      cancelToken: this.cancelToken,
    };

    return axios
      .get(url, config)
      .then((res) => {
        this.setState({
          items: res.data.results,
        });
      })
      .catch(onFetchError);
  }, 500);

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entity: PropTypes.string,
    isCustom: PropTypes.bool,
    placeholder: PropTypes.string,
    parseName: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    sessionToken: PropTypes.string.isRequired,
    value: PropTypes.string,
    onFetchError: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    entity: '',
    isCustom: false,
    value: '',
    error: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
    onFetchError: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dropDownIsOpened: false,
      query: props.value || '',
      items: [],
      isEditing: false,
    };

    this.cancelAxios = CancelToken.source();
    this.cancelToken = this.cancelAxios.token;
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (this.props.value !== value) {
      this.setState({ query: value });
    }
  }

  getDropdownRef = (e) => {
    this.dropDownRef = e;
  };

  handleBlur = () => {
    const { name, onChange, onBlur } = this.props;
    const { query } = this.state;

    if (onBlur) {
      onBlur();
    }

    this.setState({
      isEditing: false,
    });

    if (onChange) {
      onChange(name, query);
    }
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }

    this.setState({
      isEditing: true,
      dropDownIsOpened: true,
    });
  };

  // When user clicks outside of menu
  // close the menu
  handleClickOutside = () => {
    this.toggleDropDown(false);
  };

  // Open & Close Dropdown
  toggleDropDown = (state) => {
    this.setState({
      dropDownIsOpened: state,
    });
  };

  hasData = () => {
    const { value } = this.props;
    return !!value;
  };

  // Pick an item
  handlePickItem = (item) => {
    this.inputRef.focus();

    return this.setState({
      query: item.title || '',
      dropDownIsOpened: false,
    });
  };

  handleQueryChange = (name, value) => {
    if (this.props.onChange) {
      this.setState({ query: value });
      this.getEntity(value);
    }
  };

  handleInputKeyDown = (event) => {
    const { key } = event;
    const { onChange } = this.props;

    // Open Dropdown on keyDown, except if user press tab
    this.toggleDropDown(key !== 'Tab');

    // Arrow Down: Give focus to Dropdown first item
    if (onChange && key === 'ArrowDown') {
      event.preventDefault();
      if (this.dropDownRef.firstChild) {
        this.dropDownRef.firstChild.focus();
      }
    }
  };

  handleItemKeyDown = (e, item) => {
    const { key, target } = e;

    switch (key) {
      // Tab
      case 'Tab':
        this.setState({
          dropDownIsOpened: false,
        });
        break;

      // Enter
      case 'Enter':
        if (item) {
          this.handlePickItem(item);
        }
        break;

      // Arrow Up: Go to next item
      case 'ArrowUp':
        e.preventDefault();
        if (!target.previousSibling) {
          this.dropDownRef.lastChild.focus();
          break;
        }
        target.previousSibling.focus();
        break;

      // Arrow Down: Go to previous item
      case 'ArrowDown':
        e.preventDefault();
        if (!target.nextSibling) {
          this.dropDownRef.firstChild.focus();
          break;
        }
        target.nextSibling.focus();
        break;

      // If user is not navigating nor saving, we assume he wants to add some query
      default:
        this.inputRef.focus();
        break;
    }
  };

  renderItems = () => {
    const { parseName } = this.props;
    const { items, query } = this.state;

    const dropdownItems = [];

    if (items.length && query.length) {
      items.forEach((item) => {
        dropdownItems.push(
          <Item
            height="40px"
            key={item.id}
            onClick={() => {
              this.handlePickItem(item);
            }}
            onKeyDown={e => this.handleItemKeyDown(e, item)}
            tabIndex="-1"
          >
            <FlexBreakWord>{parseName(item)}</FlexBreakWord>
          </Item>,
        );
      });
    }

    return dropdownItems;
  };

  render() {
    const { name, label, value, placeholder, error, onChange } = this.props;
    const { dropDownIsOpened, query, isEditing } = this.state;

    return (
      <Div position="relative" {...getViewProps(this.props)}>
        <InputContainer
          label={label}
          placeholder={placeholder}
          active={isEditing || dropDownIsOpened}
          filled={isEditing || !!query || (!!value)}
          error={error}
        >
          <Flex direction="row" wrap="nowrap">
            <Input
              name={name}
              placeholder={placeholder}
              value={query}
              getInputRef={(e) => {
                this.inputRef = e;
              }}
              onChange={this.handleQueryChange}
              onClick={() => this.toggleDropDown(true)}
              onKeyDown={e => this.handleInputKeyDown(e)}
              onBlur={() => this.handleBlur()}
              onFocus={() => this.handleFocus()}
              hasLabel={!!label}
              isEditing={isEditing}
              disabled={!onChange}
            />
          </Flex>
        </InputContainer>

        <DropdownList
          getRef={this.getDropdownRef}
          isOpen={dropDownIsOpened}
          items={() => this.renderItems()}
          handleClickOutside={this.handleClickOutside}
        />
      </Div>
    );
  }
}

export default withI18n()(ApiAutocompleteInput);
