// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// =============================
// Styles
// =============================

export const AppContainer = styled.div`
  max-width: 100vw;
  min-height: 100vh;
`;

export default AppContainer;
