// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import InputContainer from './../container/InputContainer';
import DropdownList from './../../dropdownlist/DropdownList';
import DropdownCheckbox from './../../dropdownlist/dropdowncheckbox/DropdownCheckbox';

// Helpers
import { getViewProps } from './../../../../helpers/helpers';

// Styles
import { Toggler } from './MultipleSelect.styles';

// =============================
// Component
// =============================

class MultipleSelect extends Component {
  static propTypes = {
    label: PropTypes.string,
    displayText: PropTypes.shape({
      one: PropTypes.string.isRequired,
      multiple: PropTypes.string.isRequired,
      none: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.any),
    error: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
    selectAll: PropTypes.bool,
    verticalPosition: PropTypes.string,
    direction: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    values: [],
    width: '',
    selectAll: false,
    error: '',
    onChange: null,
    verticalPosition: 'top',
    direction: 'right',
  };

  state = {
    isOpen: false,
  };

  onToggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onCloseDropdown = ({ target }) => {
    const { onBlur } = this.props;
    // need for resume saving document
    if (onBlur) {
      onBlur();
    }

    if (target === this.togglerRef) {
      return;
    }

    this.setState({ isOpen: false });
  };

  renderDisplayText() {
    const { displayText, values } = this.props;
    const text = values.length === 1 ? displayText.one : displayText.multiple;

    return `${values.length} ${text}`;
  }

  render() {
    const {
      name,
      label,
      values,
      data,
      error,
      onChange,
      onFocus,
      onBlur,
      displayText,
      selectAll,
      verticalPosition,
      direction,
      t,
    } = this.props;
    const { isOpen } = this.state;
    const allItems = data.length > 0 && values && values.length === data.length;
    const noItems = !values || values.length === 0;

    return (
      <InputContainer
        filled
        active={isOpen && !!onChange}
        label={label}
        error={error}
        {...getViewProps(this.props)}
      >
        <Toggler
          innerRef={(ref) => {
            this.togglerRef = ref;
          }}
          onClick={this.onToggleDropdown}
        >
          {allItems && (displayText.all || t('components.inputs.multipleSelect.all'))}
          {noItems && (displayText.none || t('components.inputs.multipleSelect.none'))}
          {!allItems && !noItems && this.renderDisplayText()}
        </Toggler>
        <DropdownList
          handleClickOutside={this.onCloseDropdown}
          height="350px"
          isOpen={isOpen}
          pt="0"
          overflow="hidden"
          verticalPosition={verticalPosition}
          direction={direction}
        >
          <DropdownCheckbox
            height="350px"
            name={name}
            values={values}
            data={data}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            selectAll={selectAll}
            selectAllText={`${displayText.all}`}
          />
        </DropdownList>
      </InputContainer>
    );
  }
}

export default withI18n()(MultipleSelect);
