// =============================
// Imports
// =============================

// External Dependencies
import { injectGlobal } from 'styled-components';

// Variables
import { fonts, fontSize } from './variables';

// =============================
// Globals Styles
// =============================

const GlobalStyles = injectGlobal`
  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans.eot');
    src: url('/fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSans.woff2') format('woff2'),
      url('/fonts/OpenSans.woff') format('woff'),
      url('/fonts/OpenSans.ttf') format('truetype'),
      url('/fonts/OpenSans.svg#OpenSans') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Semibold.eot');
    src: url('/fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSans-Semibold.woff2') format('woff2'),
      url('/fonts/OpenSans-Semibold.woff') format('woff'),
      url('/fonts/OpenSans-Semibold.ttf') format('truetype'),
      url('/fonts/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Bold.eot');
    src: url('/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSans-Bold.woff2') format('woff2'),
      url('/fonts/OpenSans-Bold.woff') format('woff'),
      url('/fonts/OpenSans-Bold.ttf') format('truetype'),
      url('/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neutra Display';
    src: url('/fonts/NeutraDisp-MediumAlt.eot');
    src: url('/fonts/NeutraDisp-MediumAlt.eot?#iefix') format('embedded-opentype'),
      url('/fonts/NeutraDisp-MediumAlt.woff2') format('woff2'),
      url('/fonts/NeutraDisp-MediumAlt.woff') format('woff'),
      url('/fonts/NeutraDisp-MediumAlt.ttf') format('truetype'),
      url('/fonts/NeutraDisp-MediumAlt.svg#NeutraDisp-MediumAlt') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neutra Display';
    src: url('/fonts/NeutraDisp-BoldAlt.eot');
    src: url('/fonts/NeutraDisp-BoldAlt.eot?#iefix') format('embedded-opentype'),
      url('/fonts/NeutraDisp-BoldAlt.woff2') format('woff2'),
      url('/fonts/NeutraDisp-BoldAlt.woff') format('woff'),
      url('/fonts/NeutraDisp-BoldAlt.ttf') format('truetype'),
      url('/fonts/NeutraDisp-BoldAlt.svg#NeutraDisp-BoldAlt') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  html,
  body {
    ${fonts.openSans};
    ${fontSize.s};
    box-sizing: border-box;
    overflow: hidden;
    color: #474748;
  }

  html {
    height: 100%;
    overflow-y: scroll;
  }

  html.Html--no-scroll {
    overflow: hidden;

    body {
      z-index: 1;
      overflow-y: scroll;
      position: relative;
    }
  }

  html, body, #app {
    min-height: 100vh;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Inline our ul for our menu */
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul li { display: inline; margin: 0 .5rem; }

  a {
    color: inherit;
    text-decoration: none;
  }

  input,
  button,
  select {
    font-family: inherit;
    font-weight: inherit;
    outline: 0;
    text-transform: inherit;
  }

  /* Remove IE/Edge useless input helper */
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
  }
`;

export default GlobalStyles;
