// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

// =============================
// Styles
// =============================

export const headerHeight = 250;

export const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${props => props.theme.bp.minWidth}px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${props => props.theme.zIndex.header};
`;

export const LinkToHome = styled(NavLink)`
  height: 100%;
  transition: opacity ${props => props.theme.transition.medium} ease;

  &:hover {
    opacity: 0.8;
  }
`;

export const LogoStyled = styled(SVGInline)`
  display: inline-flex;
  width: 137px;
  height: 100%;

`;

export const DashboardIconStyled = styled(SVGInline)`
  height: 22px;
  width: 22px;
  display: inline-flex;
  stroke: ${props => props.theme.colors.secondary};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const ExternalLink = styled.a`
  display: block;
  margin-right: 30px;
  font-weight: normal;
  color: #fff;
  text-transform: uppercase;
  transition: opacity ${props => props.theme.transition.medium} ease;
  ${props => props.theme.fonts.openSansSemiBold};
  ${props => props.theme.fontSize.xxs};

  &:hover {
    opacity: 0.8;
  }
`;

export const Link = styled(NavLink)`
  display: block;
  margin-right: 30px;
  font-weight: normal;
  color: #fff;
  text-transform: uppercase;
  transition: opacity ${props => props.theme.transition.medium} ease;
  ${props => props.theme.fonts.openSansSemiBold};
  ${props => props.theme.fontSize.xxs};

  &:hover {
    opacity: 0.8;
  }
`;

export const UserInitials = styled.div`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: auto;
  width: 40px;
`;

export const Initials = styled.div`
  color: #fff;
  text-transform: uppercase;
`;
