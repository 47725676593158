// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { play, seek, load } from './../../../actions/PlayerActions';

// Components
import Waveform from './../../presentationals/waveform/Waveform';

// =============================
// Component
// =============================

function mapStateToProps({ player }) {
  return {
    playerState: player.playerState,
    currentTrack: player.currentTrack,
    currentTracklistId: player.currentTracklistId,
    getCurrentTime: player.getCurrentTime,
  };
}

export default connect(
  mapStateToProps,
  { play, seek, load },
)(Waveform);
