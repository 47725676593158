// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Components
import Chip from './../../chip/Chip';

// Styles
import { Highlight } from './../../../../themes/entityGrid';

// =============================
// Renderer
// =============================

export default (func = ({ value }) => value, onClick = () => {}) => (collection = [], object) =>
  collection.map((value, i) => {
    const node = (
      <Highlight dangerouslySetInnerHTML={{ __html: func({ value, object, collection }) }} />
    );
    // eslint-disable-next-line react/no-array-index-key
    return <Chip key={i} name={node} onClick={() => onClick({ value, object, collection })} />;
  });
