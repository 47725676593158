// Custom libraries
import { AzureB2CGuard, useAzureB2C } from '@bmat/auth-feature-azure-b2c-react';
import { AuthErrorPage, UnauthorizedErrorPage } from '@bmat/auth-feature-react-auth-pages';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
// Helpers
import { getCookieConfig } from '../../../helpers/helpers';
// Components
import Loader from './../../presentationals/loader/Loader';

// eslint-disable-next-line import/prefer-default-export
export function AuthGuard(props) {
  const { t } = useTranslation();
  const auth = useAzureB2C();

  const logoutOrLogin = () => {
    cookies.remove('x-auth', getCookieConfig(null, true));
    if (auth.isAuthenticated) {
      auth.logout();
    } else {
      auth.login();
    }
  };
  const fallBack = (<Loader fullscreen text={t('components.initializer.loading')} />);

  const onAuthError = authErrorProps => (
    <Suspense fallback={fallBack}>
      <AuthErrorPage error={authErrorProps.error} onLogout={logoutOrLogin} />
    </Suspense>
  );

  const onUnauthorized = () => (
    <Suspense fallback={fallBack}>
      <UnauthorizedErrorPage onLogout={logoutOrLogin} />
    </Suspense>
  );

  return (
    <AzureB2CGuard
      onAuthError={onAuthError}
      onUnauthorized={onUnauthorized}
      onLoading={() => <div>Loading</div>}
    >{props.children}
    </AzureB2CGuard>);
}


AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};


export const Protected = (WrappedComponent) => {
  function HOC(props) {
    return <AuthGuard><WrappedComponent {...props} /></AuthGuard>;
  }

  return HOC;
};
