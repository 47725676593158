// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Configs
import envConfig from '../../../../config/private/environment';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// HOC
import withConfirmation from './../../hoc/WithConfirmation';
import withI18n from '../../hoc/WithI18n';

// Themes
import { IconTrash } from './../../../themes/icons';
import { Flex } from './../../../themes/views';

// =============================
// Component
// =============================

class BatchDeleter extends Component {
  static propTypes = {
    batchDelete: PropTypes.func.isRequired,
    resetSelection: PropTypes.func.isRequired,
    entity: PropTypes.string.isRequired,
    ids: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string,
    onConfirm: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onConfirm: () => Promise.resolve(false),
    label: '',
  };

  state = {};

  batchDelete = () => {
    const { batchDelete, ids, entity, resetSelection, onConfirm, t } = this.props;

    onConfirm(t('components.batchDeleter.confirmationMessage'), {
      title: t('components.batchDeleter.confirmationTitle'),
      submit: t('components.batchDeleter.confirmationSubmit'),
    }).then((result) => {
      if (result) {
        const url = `${envConfig.apiUrl}/meta/${entity}`;
        batchDelete(ids, url);
        resetSelection();
      } else {
        resetSelection();
      }
    });
  };

  render() {
    const { label } = this.props;
    return (
      <Flex
        align="center"
        height="100%"
        width="100%"
        onClick={() => {
          this.batchDelete();
        }}
        {...getViewProps(this.props)}
      >
        <IconTrash size="18px" noHover />
        {label && (
          <Flex align="center" ml="7px">
            {label}
          </Flex>
        )}
      </Flex>
    );
  }
}

export default withI18n()(withConfirmation(BatchDeleter));
