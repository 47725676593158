// =============================
// Imports
// =============================

// External Dependencies
import { css } from 'styled-components';
import { lighten } from 'polished';

// Helpers

// =============================
// Variables
// =============================

const borderRadius = {
  element: '3px',
  box: '5px',
};

export const bp = {
  minHeight: 768,
  minWidth: 1024,
  sxga: 1280,
  wxgaplus: 1440,
  uxga: 1600,
  wuxga: 1920,
};

const media = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${bp[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const colors = {
  primary: '#c23d5c',
  secondary: '#c23d5c',
  tertiary: '#a82e4a',
  background: '#fafafa',
  error: '#EA2A2A',
  success: '#00CC99',
  info: '#4b6b85',
  orange: '#ff7416',
  text: {
    grey: '#8b8b8c',
    greyLight: '#ccc',
    greyDark: '#474748',
  },
  shadow: 'rgba(22, 41, 42, 0.4)',
  backdrop: 'rgba(0, 0, 0, 0.8)',
};

export const colorsHover = {
  primary: lighten(0.1, colors.primary),
  secondary: lighten(0.1, colors.secondary),
  tertiary: lighten(0.1, colors.tertiary),
  error: lighten(0.1, colors.error),
  success: lighten(0.1, colors.success),
  text: {
    grey: lighten(0.1, colors.text.grey),
    greyDark: lighten(0.1, colors.text.greyDark),
  },
};

export const fonts = {
  openSans: css`
    font-family: 'Open Sans';
    font-weight: 400;
  `,
  openSansSemiBold: css`
    font-family: 'Open Sans';
    font-weight: 600;
  `,
  openSansBold: css`
    font-family: 'Open Sans';
    font-weight: 700;
  `,
  openSansExtraBold: css`
    font-family: 'Open Sans';
    font-weight: 800;
  `,
  neutraDisplay: css`
    font-family: 'Neutra Display';
    font-weight: 700;
    text-transform: uppercase;
  `,
};

export const fontSize = {
  xxs: css`
    font-size: 11px;

    ${media.uxga`
      font-size: 13px;
    `}
  `,
  xs: css`
    font-size: 12px;

    ${media.uxga`
      font-size: 14px;
    `}
  `,
  s: css`
    font-size: 13px;
    line-height: 22px;

    ${media.uxga`
      font-size: 15px;
      line-height: 24px;
    `}
  `,
  m: css`
    font-size: 14px;

    ${media.uxga`
      font-size: 16px;
    `}
  `,
  l: css`
    font-size: 16px;
    line-height: 28px;

    ${media.uxga`
      font-size: 18px;
      line-height: 30px;
    `}
  `,
  xl: css`
    font-size: 24px;
  `,
  xxl: css`
    font-size: 48px;
    line-height: 48px;
  `,
};

const transition = {
  slow: '1000ms',
  medium: '300ms',
  fast: '100ms',
};

const zIndex = {
  header: 2000,
  popups: 2003,
  player: 2004,
  sidepanelveil: 2005,
  sidepanel: 2006,
  notifications: 2007,
  dropdownlist: 2008,
  dialog: 2010,
};

// =============================
// Theme Export
// =============================

const theme = {
  borderRadius,
  bp,
  colors,
  colorsHover,
  fonts,
  fontSize,
  media,
  transition,
  zIndex,
};

export default theme;
