// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import get from 'lodash/get';
import { CancelToken } from 'axios';
import debounce from 'lodash/debounce';

// Configs
import envConfig from '../../../../../config/private/environment';

// Helpers
import axios from './../../../../helpers/axios';
import { getViewProps } from '../../../../helpers/helpers';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import Chip from './../../chip/Chip';
import DropdownList from '../../dropdownlist/DropdownList';
import InputContainer from '../container/InputContainer';
import Input from '../input/Input';

// Theme
import { Div, Flex, FlexBreakWord, OnTheFlyPreview } from './../../../../themes/views';

// Styles
import { Item } from '../../dropdownlist/DropdownList.styles';


// =============================
// Component
// =============================

class ApiSelectInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entity: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    parseName: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['single', 'multiple']),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    error: PropTypes.string,
    sessionToken: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    openSidePanel: PropTypes.func.isRequired,
    panelName: PropTypes.string.isRequired,
    defaultField: PropTypes.string.isRequired,
    search: PropTypes.bool,
    skipByFields: PropTypes.arrayOf(PropTypes.string),
    onTheFlyCreationError: PropTypes.func,
    onFetchError: PropTypes.func,
    t: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    customData: PropTypes.object,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    type: 'single',
    data: {},
    values: [],
    error: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
    search: true,
    skipByFields: [],
    customData: null,
    onTheFlyCreationError: () => {},
    onFetchError: () => {},
  };

  getEntity = debounce((query) => {
    const { entity, search, sessionToken, onFetchError } = this.props;

    if (!query || !search) return null;

    // Cancel last axios request
    if (this.cancelAxios) this.cancelAxios.cancel('Request canceled by user.');

    // Generate new cancel token
    this.cancelAxios = CancelToken.source();
    this.cancelToken = this.cancelAxios.token;

    const url = `${envConfig.apiUrl}/${entity}/list`;
    const params = query ? {
      params: {
        search: query,
      },
    } : {};
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': sessionToken,
      },
      cancelToken: this.cancelToken,
      ...params,
    };

    return axios
      .get(url, config)
      .then((res) => {
        this.setState({
          items: res.data.results,
        });
      })
      .catch(onFetchError);
  }, 500);

  constructor(props) {
    super(props);

    this.state = {
      dropDownIsOpened: false,
      query: '',
      items: [],
      itemsLog: this.buildItemsLog(props),
      isEditing: false,
    };

    this.cancelAxios = CancelToken.source();
    this.cancelToken = this.cancelAxios.token;
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(nextProps.data, this.props.data)) return;
    this.setState(() => ({
      dropDownIsOpened: false,
      query: '',
      items: [],
      itemsLog: this.buildItemsLog(nextProps),
      isEditing: false,
    }));
  }

  buildItemsLog = (props) => {
    const itemsLog = [];

    if (this.isTypeSingle() && props.data && props.data.id) {
      itemsLog.push({ id: props.data.id, name: props.parseName(props.data) });
    } else if (!this.isTypeSingle() && props.data && props.data.length) {
      props.data.forEach((item) => {
        if (item.id) {
          itemsLog.push({ id: item.id, name: props.parseName(item) });
        }
      });
    }
    return itemsLog;
  };

  getDropdowRef = (e) => {
    this.dropDownRef = e;
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }

    this.setState({
      isEditing: false,
    });
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }

    this.setState({
      isEditing: true,
      dropDownIsOpened: true,
    });
  };

  // When user clicks outside of menu
  // close the menu
  handleClickOutside = () => {
    this.toggleDropDown(false);
  };

  // Open & Close Dropdown
  toggleDropDown = (state) => {
    this.setState({
      dropDownIsOpened: state,
    });
  };

  isTypeSingle = () => {
    const { type } = this.props;
    return type === 'single';
  };

  hasData = () => {
    const { values } = this.props;

    return this.isTypeSingle() ? values !== null : values.length > 0;
  };

  // Pick an item
  handlePickItem = (item) => {
    const { values, name, onChange, parseName } = this.props;

    this.setState({
      itemsLog: [
        ...this.state.itemsLog,
        {
          id: item.id,
          name: parseName(item),
        },
      ],
    });

    if (this.isTypeSingle()) {
      if (values === item.id) return this.removeItem(item);
      onChange(name, item.id, item);
    } else {
      if (find(values, e => e === item.id)) return this.removeItem(item);
      onChange(name, [...values, item.id]);
    }

    // if (this.isTypeSingle()) this.inputRef.focus();

    return this.setState({
      query: '',
      dropDownIsOpened: !this.isTypeSingle(),
    });
  };

  handleQueryChange = (name, value) => {
    if (this.props.onChange) {
      this.setState({ query: value });
      this.getEntity(value);
    }
  };

  handleOnTheFlyCreation = (query) => {
    const {
      defaultField,
      entity,
      sessionToken,
      onTheFlyCreationError,
      customData: custom } = this.props;

    this.setState({ query: '' }, () => {
      const url = `${envConfig.apiUrl}/${entity}`;

      const config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': sessionToken,
        },
      };
      let data = { [defaultField]: query, custom: {} };

      if (custom) data = { ...data, custom };

      return axios
        .post(url, data, config)
        .then((res) => {
          this.handlePickItem(res.data);
        })
        .catch(e => onTheFlyCreationError(e, data));
    });
  };

  handleInputKeyDown = (event) => {
    const { key } = event;
    const { values, onChange } = this.props;
    const { query } = this.state;

    // Open Dropdown on keyDown, except if user press tab
    this.toggleDropDown(key !== 'Tab');

    if (onChange) {
      switch (key) {
        case 'Backspace':
          // If query is present we only want to erase some part of it and search
          if (query) break;

          // Prevent Backspace from sending user to previous page
          event.preventDefault();

          // Check if we have some Chip to remove
          if (values && this.hasData()) {
            const lastChip = this.inputContainerRef.previousSibling;

            // First time user press Backspace: Focus the item to show user is about to delete it
            // Deletion is handled in <Chip /> component
            if (document.activeElement !== lastChip) {
              lastChip.focus();
            }
          }
          break;

        // Enter
        case 'Enter':
          if (query) {
            this.handleOnTheFlyCreation(query);
          }
          break;

        // Arrow Down: Give focus to Dropdown first item
        case 'ArrowDown':
          event.preventDefault();
          if (this.dropDownRef.firstChild) this.dropDownRef.firstChild.focus();
          break;

        default:
          break;
      }
    }
  };

  handleItemKeyDown = (e, item) => {
    const { key, target } = e;
    const { query } = this.state;

    switch (key) {
      // Tab
      case 'Tab':
        this.setState({
          dropDownIsOpened: false,
        });
        break;

      // Enter
      case 'Enter':
        if (item) {
          this.handlePickItem(item);
        } else if (query) {
          this.handleOnTheFlyCreation(query);
        }
        break;

      // Arrow Up: Go to next item
      case 'ArrowUp':
        e.preventDefault();
        if (!target.previousSibling) {
          this.dropDownRef.lastChild.focus();
          break;
        }
        target.previousSibling.focus();
        break;

      // Arrow Down: Go to previous item
      case 'ArrowDown':
        e.preventDefault();
        if (!target.nextSibling) {
          this.dropDownRef.firstChild.focus();
          break;
        }
        target.nextSibling.focus();
        break;

      // If user is not navigating nor saving, we assume he wants to add some query
      default:
        this.inputRef.focus();
        break;
    }
  };

  removeItem = (item) => {
    const { values, name, onChange } = this.props;

    if (this.isTypeSingle()) {
      onChange(name, null);
    } else {
      onChange(name, remove([...values], e => e !== item.id));
    }
  };

  renderItems = () => {
    const { values, parseName, t, skipByFields } = this.props;
    const { items, query } = this.state;

    const dropdownItems = [];

    if (items.length && query.length) {
      items.forEach((item) => {
        const isMustSkip = !!get(item, skipByFields);
        const birthYear = get(item, 'custom.birth_year', null);
        if (!isMustSkip) {
          dropdownItems.push(
            <Item
              height="40px"
              selected={this.isTypeSingle() ? values === item.id : values.indexOf(item.id) !== -1}
              key={item.id}
              onClick={() => {
                this.handlePickItem(item);
              }}
              onKeyDown={e => this.handleItemKeyDown(e, item)}
              tabIndex="-1"
            >
              <FlexBreakWord>{birthYear ? `${parseName(item)} ${birthYear}` : parseName(item)}</FlexBreakWord>
            </Item>,
          );
        }
      });
    }

    if (query) {
      dropdownItems.unshift(
        <Item
          borderBottom="1px solid #999"
          height="40px"
          key={`key-${query}`}
          onClick={() => {
            this.handleOnTheFlyCreation(query);
          }}
          onKeyDown={e => this.handleItemKeyDown(e)}
          tabIndex="-1"
        >
          {t('components.inputs.apiSelecInput.create')}&nbsp;
          <OnTheFlyPreview>
            <FlexBreakWord>{`"${query}"`}</FlexBreakWord>
          </OnTheFlyPreview>
        </Item>,
      );
    }

    return dropdownItems;
  };

  renderChips = () => {
    const { values, onChange, openSidePanel, panelName } = this.props;
    const { itemsLog } = this.state;

    if (!values) return null;

    const items = [];

    if (this.isTypeSingle()) {
      const item = find(itemsLog, e => e.id === values);
      if (item) {
        items.push(item);
      }
    } else {
      values.forEach((value) => {
        const item = find(itemsLog, e => e.id === value);
        if (item) {
          items.push(item);
        }
      });
    }

    return items.map(item => (
      <Chip
        key={item.id}
        name={item.name}
        onClick={() => {
          openSidePanel(panelName, item.id);
        }}
        handleDelete={
          onChange
            ? () => {
                this.removeItem(item);
              }
            : null
        }
        inputRef={this.inputRef}
        id={item.id}
        whiteSpace={this.isTypeSingle() ? 'nowrap' : 'wrap'}
      />
    ));
  };

  render() {
    const { name, label, values, placeholder, error, onChange } = this.props;
    const { dropDownIsOpened, query, isEditing } = this.state;

    return (
      <Div position="relative" {...getViewProps(this.props)}>
        <InputContainer
          label={label}
          placeholder={placeholder}
          active={isEditing || dropDownIsOpened}
          filled={isEditing || !!query || (this.isTypeSingle() ? !!values : !!values.length)}
          error={error}
        >
          <Flex direction="row" wrap={this.isTypeSingle() ? 'nowrap' : 'nowrap'}>
            {this.renderChips()}
            <Input
              name={name}
              placeholder={placeholder}
              value={query}
              getInputRef={(e) => {
                this.inputRef = e;
              }}
              getContainerRef={(e) => {
                this.inputContainerRef = e;
              }}
              onChange={this.handleQueryChange}
              onClick={() => this.toggleDropDown(true)}
              onKeyDown={e => this.handleInputKeyDown(e)}
              onBlur={() => this.handleBlur()}
              onFocus={() => this.handleFocus()}
              hasLabel={!!label}
              isEditing={isEditing}
              disabled={!onChange}
            />
          </Flex>
        </InputContainer>

        <DropdownList
          getRef={this.getDropdowRef}
          isOpen={dropDownIsOpened}
          items={() => this.renderItems()}
          handleClickOutside={this.handleClickOutside}
        />
      </Div>
    );
  }
}

export default withI18n()(ApiSelectInput);
