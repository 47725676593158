// =============================
// Imports
// =============================

// External Dependencies
import styled, { keyframes } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Styles
import { Div } from './../../../themes/views';

export const Container = styled(Div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${props => (props.fullscreen ? '100vh' : 'auto')};
  justify-content: center;
  width: ${props => (props.fullscreen ? '100vw' : 'auto')};
`;

export const LoadingIcon = styled(SVGInline)`
  display: inline-block;
  height: ${props => (props.size ? props.size : '100px')};
  width: ${props => (props.size ? props.size : '100px')};

  &:not(:only-child) {
    margin-bottom: ${props => (props.size ? `calc(${props.size} / 5)` : '20px')};
  }
`;

// keyframes returns a unique name based on a hash of the contents of the keyframes
const blink = keyframes`
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.2;
  }
`;

export const LoadingText = styled.p`
  ${props => props.theme.fontSize.xxs};
  animation: ${blink} 1s alternate infinite;
  color: ${props => props.theme.colors.primary};
`;
