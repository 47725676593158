// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

// Helpers
import { setLocale } from './../../../helpers/I18n';

// =============================
// Component
// =============================

function PrivateRoute(props) {
  const history = useHistory();

  useEffect(() => {
    if (!props.ready) {
      history.push('/');
    }
  }, [props.ready]);

  return props.ready ? props.children : null;
}

PrivateRoute.propTypes = {
  ready: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function mapStateToProps({ core, user }) {
  // Set locale to cookie by db config
  const language = get(user.userInfo.data, ['meta_ui_settings', 'language'], 'en');
  setLocale(language);

  return {
    ready: core.ready,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
