// =============================
// Imports
// =============================

// Constants
import * as types from './../constants/ActionTypes';
import * as states from './../constants/PlayerStates';
import * as requests from './../constants/PlayerRequests';

// =============================
// Initial state
// =============================

export const initialState = {
  previousPlayerState: null,
  playerState: states.STOPPED,
  show: false,
  detailsShown: false,
  getCurrentTime: null,
  currentTrack: null,
  currentTracklistId: null,
  currentTracklist: null,
  request: {
    [requests.LOAD_TRACK]: false,
    [requests.PLAY]: false,
    [requests.PAUSE]: false,
    [requests.SEEK]: false,
    [requests.PREV_TRACK]: false,
    [requests.NEXT_TRACK]: false,
  },
};

// =============================
// Reducer
// =============================

export default function player(state = initialState, action) {
  switch (action.type) {
    case types.REINITIALIZE_PLAYER:
      return {
        ...state,
        show: false,
        detailsShown: false,
        getCurrentTime: null,
        currentTrack: null,
        currentTracklistId: null,
        currentTracklist: null,
      };

    case types.SET_STATE:
      return {
        ...state,
        playerState: action.payload,
        previousPlayerState: state.playerState,
      };

    case types.SHOW_PLAYER:
      return {
        ...state,
        show: action.payload,
      };

    case types.TOGGLE_PLAYER_DETAILS_STATE:
      return {
        ...state,
        detailsShown: action.payload,
      };

    case types.EXPOSE_GET_CURRENT_TIME:
      return {
        ...state,
        getCurrentTime: action.payload,
      };

    case types.SET_CURRENT_TRACK: {
      return {
        ...state,
        currentTrack: action.payload,
      };
    }

    case types.SET_CURRENT_TRACKLIST: {
      return {
        ...state,
        currentTracklistId: action.payload.id,
        currentTracklist: action.payload.tracklist,
      };
    }

    case types.FULFILL_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload]: false,
        },
      };

    case types.REQUEST_LOAD_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.LOAD_TRACK]: true,
        },
      };

    case types.REQUEST_PLAY:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PLAY]: true,
        },
      };

    case types.REQUEST_PAUSE:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PAUSE]: true,
        },
      };

    case types.REQUEST_SET_SEEKING_TIME:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.SEEK]: action.payload,
        },
      };

    case types.REQUEST_GO_TO_PREV_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PREV_TRACK]: true,
        },
      };

    case types.REQUEST_GO_TO_NEXT_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.NEXT_TRACK]: true,
        },
      };

    default:
      return state;
  }
}
