// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles
import { resetInput } from './../inputs';

// =============================
// Styled Components
// =============================

export const Label = styled.div`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.l};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Input = styled(resetInput)`
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #efefef;
  color: ${props => (props.error ? props.theme.colors.error : '#000')};
  font-size: 16px;
  margin-top: 8px;
  padding-right: 24px;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
`;


export const IconSearch = styled(SVGInline)`
  height: 22px;
  position: absolute;
  right: 0;
  stroke: #999;
  top: 9px;
  width: 22px;
`;
