/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// Styles
import { resetInput } from './../';

import { fadeIn } from '../../../../themes/animations';

export const Container = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const Placeholder = styled.span`
  animation: ${fadeIn} ${props => props.theme.transition.medium} linear 1;
  bottom: 8px;
  color: ${props => props.theme.colors.text.grey};
  font-size: 16px;
  left: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity ${props => props.theme.transition.medium};
`;

export const InputBase = styled(resetInput)`
  background-color: transparent;
  border: 0;
  color: ${props => (props.error ? props.theme.colors.error : '#000')};
  font-size: 16px;
  margin-top: 8px;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;

  &::-webkit-input-placeholder {
    opacity: 0;
  }

  &::-moz-placeholder {
    opacity: 0;
  }

  &:-ms-input-placeholder {
    opacity: 0;
  }
`;
