// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Styles
import { Div } from './../../../themes/views';

export const Container = styled(Div)`
  ${props => props.theme.fontSize.xs};
  align-items: center;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.borderRadius.element};
  color: ${props => props.theme.colors.primary};
  display: inline-flex;
  margin: 4px 6px 6px 0;
  min-height: 24px;
  max-width: 100%;
  transition: background-color ${props => props.theme.transition.fast}, color ${props => props.theme.transition.fast};

  &:focus {
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
  }

  &:first-child:last-child {
    margin: 0;
  }

  &::first-line {
    margin-top: 0;
  }

  ${props => props.activeHover && css`
    &:hover {
      background-color: ${props.theme.colors.primary};
      border: 1px solid #666;
      color: #fff;
    }
  `};
`;

export const FlexBreakWord = styled(Div)`
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  max-width: 100%;
  overflow: auto;
  overflow-wrap: break-word;
  width: 100%;
`;

export const DeleteChip = styled(SVGInline)`
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  margin-right: 6px;
  stroke: ${props => props.theme.colors.text.greyDark};
  width: 10px;

  &:hover {
    opacity: 0.8;
    stroke: ${props => props.theme.colorsHover.text.greyDark};
  }

  & > svg > * {
    stroke-width: 3 !important;
  }
`;
