// =============================
// Imports
// =============================

// External dependencies
import { CancelToken, isCancel } from 'axios';
import uuidV4 from 'uuid';
import { Map } from 'immutable';
import * as n from 'normalize-strings';

// Helper
import axios from './../helpers/axios';
import { isObject } from '../helpers/helpers';
import { pickMetadata } from '../helpers/copyMetadata';

// Constants
import {
  OPEN_SIDE_PANEL,
  REMOVE_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SET_PANEL_DATA,
  COPY_PANEL_METADATA,
  PASTE_PANEL_METADATA_TYPE_ERROR,
  PASTE_PANEL_METADATA_FAILURE,
  DID_BATCH_DELETE_OPERATION,
  FETCH_BATCH_PANEL_ERROR,
  FETCH_PANEL_ERROR,
  SAVE_PANEL_ERROR,
  SAVE_BATCH_PANEL_ERROR,
  SAVE_ADD_PANEL_ERROR,
  DELETE_PANEL_FAILURE,
  FILE_UPLOAD_FAILURE,
  FILE_DELETE_FAILURE,
  BATCH_DELETE_OPERATION_FAILURE,
  DID_CHANGE_PLAYLIST_TRACKS_STATUS_OPERATION,
  CHANGE_PLAYLIST_TRACKS_STATUS_FAILURE,
} from '../constants/ActionTypes';

import { BATCH_TRACKS_PANEL } from '../constants/SidePanelTypes';

// =============================
// Actions
// =============================

/**
 * Open a new side panel
 * @param {string} panelName - Name of the panel
 * @param {string} id - Id of the document in db from the api
 */
export const open = (panelName, id = null) => ({
  type: OPEN_SIDE_PANEL,
  payload: {
    uuid: uuidV4(), // Generate a unique id for the panel
    name: panelName,
    id, // Id of the document in db
    isLoading: false, // Initial loading state
    isSaving: false, // Saving state
    timeoutSave: false, // Timeout before saving state
    data: null, // Panel data
    uploads: new Map(), // File uploads
  },
});

/**
 * Remove a single side panel
 * @param {string} uuid - Id of the panel
 */
export const remove = uuid => ({
  type: REMOVE_SIDE_PANEL,
  uuid,
});

/**
 * Close all side panels
 * @param {string} uuid - Id of the current panel
 */
export const close = uuid => (dispatch, getState) => {
  const panel = getState().sidepanel.panels.find(p => p.uuid === uuid);
  if (panel && (panel.isSaving || panel.timeoutSave)) return undefined;

  return dispatch({
    type: CLOSE_SIDE_PANEL,
  });
};

/**
 * Update data for a side panel
 * @param {Object} data - Any data to be updated in the side panel
 * @param {string} uuid - Id of the target panel
 */
export const setData = (data, uuid) => ({
  type: SET_PANEL_DATA,
  payload: {
    ...data,
    uuid,
  },
});

/**
 * Fetch batch documents for panel
 * @param {Object} panel - Target panel
 * @param {Object} fetchData - List of subsequent queries to be executed after fetching batch
 */
const fetchBatchPanelData = (panel, fetchData) => (dispatch, getState) => {
  const queries = isObject(fetchData) ? fetchData : {};

  // Set the state of isLoading to true while fetching data
  dispatch(setData({ isLoading: true }, panel.uuid));

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  };

  const url = `${panel.url}/retrieve`;

  // Add the panel data to the queries
  queries.data = url;

  // Getter key depends on type of batch panel
  // For now we only support batch tracks
  let getterKey;
  if (panel.name === BATCH_TRACKS_PANEL) {
    getterKey = 'tracks';
  }

  // List of all queries
  const keys = Object.keys(queries);

  return Promise.all(
    keys.map((key) => {
      // Get batch data
      if (key === 'data') {
        return axios.post(url, { [getterKey]: panel.id }, config);
      }

      // Get other data
      return axios.post(`${url}/${queries[key]}`, { [getterKey]: panel.id }, config);
    }),
  )
    .then((responses) => {
      const nextState = {};

      // Get the corresponding key for each response and store the data
      responses.forEach((response, i) => {
        nextState[keys[i]] = response.data;
      });

      // Reset isLoading state to false
      nextState.isLoading = false;

      // Set data
      return dispatch(setData(nextState, panel.uuid));
    })
    .catch(err =>
      dispatch({
        type: FETCH_BATCH_PANEL_ERROR,
        payload: {
          error: err,
          panel,
        },
      }),
    );
};

/**
 * Fetch document of panel
 * @param {Object} panel - Target panel
 * @param {Object} fetchData - List of subsequent queries to be executed after fetching document
 */
export const fetchPanelData = (panel, fetchData) => (dispatch, getState) => {
  // Call fetchBatchPanelData instead if we are on a batch panel
  if (panel.name === BATCH_TRACKS_PANEL) {
    return dispatch(fetchBatchPanelData(panel, fetchData));
  }

  const queries = isObject(fetchData) ? fetchData : {};

  // Set the state of isLoading to true while fetching data
  dispatch(setData({ isLoading: true }, panel.uuid));

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  };

  const url = `${panel.url}/${panel.id}`;

  return axios
    .get(url, config)
    .then(({ data }) => {
      const keys = Object.keys(queries).filter(k => data.owned_by_tenant || k !== 'tracksAgents');
      return Promise.all([
        data,
        keys,
        ...keys.map(key => axios.get(`${url}/${queries[key]}`, config)),
      ]);
    })
    .then(([data, keys, ...responses]) => {
      const nextState = { data };
      // Get the corresponding key for each response and store the data
      responses.forEach((response, i) => {
        nextState[keys[i]] = response.data;
      });

      // Reset isLoading state to false
      nextState.isLoading = false;

      // Set new data
      return dispatch(setData(nextState, panel.uuid));
    })
    .catch(err =>
      dispatch({
        type: FETCH_PANEL_ERROR,
        payload: {
          error: err,
          panel,
        },
      }),
    );
};

/**
 * Save documents of batch panel
 * @param {Object} data - New data to be saved
 * @param {Object} panel - Target panel
 * @param {Object} updateData - List of subsequent queries to be executed after saving document
 */
const saveBatchPanel = (data, panel, updateData) => (dispatch, getState) => {
  const queries = isObject(updateData) ? updateData : {};

  // Cancel previous request if it exists
  if (panel.cancelToken) {
    panel.cancelToken.cancel('Request canceled by user');
  }

  // Create new cancel token for next request
  const newToken = CancelToken.source();

  // Set state of isSaving to true
  dispatch(
    setData(
      {
        isSaving: true,
        cancelToken: newToken,
      },
      panel.uuid,
    ),
  );

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    cancelToken: newToken.token,
  };

  // Getter key depends on type of batch panel
  // For now we only support batch tracks
  let getterKey;
  if (panel.name === BATCH_TRACKS_PANEL) {
    getterKey = 'tracks';
  }

  const keys = Object.keys(queries);

  // Save documents
  return (
    axios
      .post(`${panel.url}/edit`, { ...data, [getterKey]: panel.id }, config)
      // Set state of isSaving to false
      .then(() => ({
        isSaving: false,
      }))
      .then((state) => {
        // If there are no additional requests to do, return the state
        if (!keys.length) {
          return state;
        }

        const nextState = state;
        const url = `${panel.url}/retrieve`;

        // Get post-update data
        return Promise.all(
          keys.map(key => axios.post(`${url}/${queries[key]}`, { [getterKey]: panel.id }, config)),
        ).then((responses) => {
          // Get the corresponding key for each response and store the data
          responses.forEach((response, i) => {
            nextState[keys[i]] = response.data;
          });

          return nextState;
        });
      })
      // Set the next state
      .then(nextState => dispatch(setData(nextState, panel.uuid)))
      .catch((err) => {
        // When error is not a cancel error
        if (!isCancel(err)) {
          // Set state of isSaving to false
          dispatch(setData({ isSaving: false }, panel.uuid));

          return dispatch({
            type: SAVE_BATCH_PANEL_ERROR,
            payload: {
              error: err,
              panel,
            },
          });
        }

        return Promise.resolve();
      })
  );
};

/**
 * Save document of panel
 * @param {Object} data - New data to be saved
 * @param {Object} panel - Target panel
 * @param {Object} updateData - List of subsequent queries to be executed after saving document
 */
export const savePanel = (data, panel, updateData) => (dispatch, getState) => {
  // Call saveBatchPanelData instead if we are on a batch panel
  if (panel.name === BATCH_TRACKS_PANEL) {
    return dispatch(saveBatchPanel(data, panel, updateData));
  }

  const queries = isObject(updateData) ? updateData : {};

  // Cancel previous request if it exists
  if (panel.cancelToken) {
    panel.cancelToken.cancel('Request canceled by user');
  }

  // Create new cancel token for next request
  const newToken = CancelToken.source();

  // Set state of isSaving to true
  dispatch(
    setData(
      {
        isSaving: true,
        cancelToken: newToken,
      },
      panel.uuid,
    ),
  );

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    cancelToken: newToken.token,
  };

  const keys = Object.keys(queries);

  // Save or modify the document depending on the existence of id
  return (
    axios({
      ...config,
      method: panel.id ? 'PUT' : 'POST',
      url: `${panel.url}${panel.id ? `/${panel.id}` : ''}`,
      data,
    })
      .then(response => ({
        data: response.data,
        id: response.data.id,
        isSaving: false,
        isMetadataSaved: true,
      }))
      .then((state) => {
        // If there are no additional requests to do, return the state
        if (!keys.length) {
          return state;
        }

        const nextState = state;
        const url = `${panel.url}/${state.id}`;

        // Get post-save/post-update data
        return Promise.all(keys.map(key => axios.get(`${url}/${queries[key]}`, config))).then(
          (responses) => {
            // Get the corresponding key for each response and store the data
            responses.forEach((response, i) => {
              nextState[keys[i]] = response.data;
            });

            return nextState;
          },
        );
      })
      // Set the next state
      .then(nextState => dispatch(setData(nextState, panel.uuid)))
      .catch((err) => {
        // When error is not a cancel error
        if (!isCancel(err)) {
          // Set state of isSaving to false
          dispatch(setData({ isSaving: false, isMetadataSaved: true }, panel.uuid));

          return dispatch({
            type: SAVE_PANEL_ERROR,
            payload: {
              error: err,
              panel,
            },
          });
        }

        return Promise.resolve();
      })
  );
};

// eslint-disable-next-line
export const saveBatchAdditionalPanelData = (data, panel, query, getQueries) => (dispatch, getState) => {
  const queries = isObject(getQueries) ? getQueries : {};

  // Cancel previous request if it exists
  if (panel.additionDataCancelToken) {
    panel.additionDataCancelToken.cancel('Request canceled by user');
  }

  // Create new cancel token for next request
  const newToken = CancelToken.source();

  // Even though we are no saving the current panel document
  // In order to block any other side panel request, we need to
  // change the state of isSaving to true
  dispatch(
    setData(
      {
        isSaving: true,
        additionDataCancelToken: newToken,
      },
      panel.uuid,
    ),
  );

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  };

  const keys = Object.keys(queries);

  // Save the document
  return axios({
    ...config,
    cancelToken: newToken.token,
    method: query.method,
    url: `${panel.url}/${query.url}`,
    // TODO: temp
    data: { ...data, tracks: panel.id },
  })
    .then(() => ({
      isSaving: false,
    }))
    .then((state) => {
      // If there are no additional requests to do, return the state
      if (!keys.length) {
        return state;
      }

      const nextState = state;

      // Get the post-save data
      return Promise.all(keys.map(key => axios({
        ...config,
        method: 'post',
        url: `${panel.url}/${queries[key]}`,
        // TODO: temp
        data: { tracks: panel.id },
      })))
        .then(
          (responses) => {
            // Get the corresponding key for each response and store the data
            responses.forEach((response, i) => {
              nextState[keys[i]] = response.data;
            });

            return nextState;
          },
        )
        // Set the next state
        .then(s => dispatch(setData(s, panel.uuid)))
        .catch((err) => {
          // When error is not a cancel error
          if (!isCancel(err)) {
            // Set state of isSaving to false
            dispatch(setData({ isSaving: false }, panel.uuid));

            return dispatch({
              type: SAVE_ADD_PANEL_ERROR,
              payload: {
                error: err,
                panel,
              },
            });
          }

          return Promise.resolve();
        });
    });
};

/**
 * Save document related to the current panel
 * @param {Object} data - New data to be saved
 * @param {Object} panel - Target panel
 * @param {Object} query - Query to be executed to save relation document
 * @param {Object} getQueries - List of subsequent queries to be executed after saving document
 */
export const saveAdditionalPanelData = (data, panel, query, getQueries) => (dispatch, getState) => {
  // Call saveBatchAdditionalPanelData instead if we are on a batch panel
  if (panel.name === BATCH_TRACKS_PANEL) {
    return dispatch(saveBatchAdditionalPanelData(data, panel, query, getQueries));
  }

  const queries = isObject(getQueries) ? getQueries : {};

  // Cancel previous request if it exists
  if (panel.additionDataCancelToken) {
    panel.additionDataCancelToken.cancel('Request canceled by user');
  }

  // Create new cancel token for next request
  const newToken = CancelToken.source();

  // Even though we are no saving the current panel document
  // In order to block any other side panel request, we need to
  // change the state of isSaving to true
  dispatch(
    setData(
      {
        isSaving: true,
        additionDataCancelToken: newToken,
      },
      panel.uuid,
    ),
  );

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  };

  const keys = Object.keys(queries);

  // Save the document
  return (
    axios({
      ...config,
      cancelToken: newToken.token,
      method: query.method,
      url: `${panel.url}/${panel.id}/${query.url}`,
      data,
    })
      .then(() => ({
        isSaving: false,
      }))
      .then((state) => {
        // If there are no additional requests to do, return the state
        if (!keys.length) {
          return state;
        }

        const nextState = state;
        const url = `${panel.url}/${panel.id}`;

        // Get the post-save data
        return Promise.all(keys.map(key => axios.get(`${url}/${queries[key]}`, config))).then(
          (responses) => {
            // Get the corresponding key for each response and store the data
            responses.forEach((response, i) => {
              nextState[keys[i]] = response.data;
            });

            return nextState;
          },
        );
      })
      // Set the next state
      .then(state => dispatch(setData(state, panel.uuid)))
      .catch((err) => {
        // When error is not a cancel error
        if (!isCancel(err)) {
          // Set state of isSaving to false
          dispatch(setData({ isSaving: false }, panel.uuid));

          return dispatch({
            type: SAVE_ADD_PANEL_ERROR,
            payload: {
              error: err,
              panel,
            },
          });
        }

        return Promise.resolve();
      })
  );
};

/**
 * Upload asset for document
 * @param {string} type - Type of file to be uploaded
 * @param {Object} file - File to be uploaded
 * @param {Object} panel - Target panel
 */
export const uploadFile = (type, file, panel) => (dispatch, getState) => {
  // Function to get the target panel
  function getPanel() {
    return getState().sidepanel.panels.find(p => p.uuid === panel.uuid);
  }

  {
    const statePanel = getPanel();

    // Set new upload
    dispatch(
      setData(
        {
          uploads: statePanel.uploads.set(type, { progress: 0 }),
        },
        panel.uuid,
      ),
    );
  }

  const form = new FormData();
  let name = '';
  const exceptionSymbols = ['+'];
  for (let i = 0; i < file.name.length; i += 1) {
    if (!exceptionSymbols.includes(file.name[i])) {
      name += file.name[i];
    }
  }

  form.append('file', file, n(name));

  // Upload asset
  return axios({
    method: 'POST',
    url: `${panel.url}/${panel.id}/${type}`,
    data: form,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    // Track the progress of upload
    onUploadProgress: (event) => {
      const statePanel = getPanel();
      const progress = event.total ? event.loaded / event.total : 0;

      // Update progress state
      dispatch(
        setData(
          {
            // wait until server finish all required operations(e.g. audio conversion)
            uploads: statePanel.uploads.set(type, { progress: progress === 1 ? 0.99 : progress }),
          },
          panel.uuid,
        ),
      );
    },
  })
    .then((response) => {
      const statePanel = getPanel();

      // Return new data and clear previous upload
      return dispatch(
        setData(
          {
            uploads: statePanel.uploads.delete(type),
            data: response.data,
          },
          panel.uuid,
        ),
      );
    })
    .catch((err) => {
      const statePanel = getPanel();

      // Clear previous upload
      dispatch(
        setData({
          uploads: statePanel.uploads.delete(type),
        }),
      );

      return dispatch({
        type: FILE_UPLOAD_FAILURE,
        payload: {
          error: err.response,
          panel,
          type,
        },
      });
    });
};

/**
 * Remove asset of document
 * @param {string} type - Type of file to be deleted
 * @param {Object} panel - Target panel
 */
export const removeFile = (type, panel) => (dispatch, getState) =>
  // Delete asset
  axios({
    method: 'DELETE',
    url: `${panel.url}/${panel.id}/${type}`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  })
    .then(response =>
      // Set new data
      dispatch(
        setData(
          {
            data: response.data,
          },
          panel.uuid,
        ),
      ),
    )
    .catch(err =>
      dispatch({
        type: FILE_DELETE_FAILURE,
        payload: {
          error: err,
          panel,
        },
      }),
    );

export const removeFileById = (type, panel, fileId) => (dispatch, getState) =>
  // Delete asset
  axios({
    method: 'DELETE',
    url: `${panel.url}/${panel.id}/${type}/${fileId}`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  })
    .then(response =>
      // Set new data
      dispatch(
        setData(
          {
            data: response.data,
          },
          panel.uuid,
        ),
      ),
    )
    .catch(err =>
      dispatch({
        type: FILE_DELETE_FAILURE,
        payload: {
          error: err,
          panel,
        },
      }),
    );

/**
 * Delete document of panel
 * @param {Object} panel - Target panel
 */
export const deletePanel = panel => (dispatch, getState) =>
  // Delete panel
  axios({
    method: 'DELETE',
    url: `${panel.url}/${panel.id}`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  })
    .then(() => {
      const nbPanels = getState().sidepanel.panels.length;

      // Remove current panel from store if there is more than one
      if (nbPanels > 1) {
        return dispatch(remove(panel.uuid));
      }

      // Close side panel if it was the only panel open
      return dispatch(close(panel.uuid));
    })
    .catch(err =>
      dispatch({
        type: DELETE_PANEL_FAILURE,
        payload: {
          error: err,
          panel,
        },
      }),
    );

/**
 * Delete a list of documents without going through a side panel
 * @param {string[]} ids - List of ids
 * @param {string} url - Url of the delete query
 */
export const batchDelete = (ids, url) => (dispatch, getState) =>
  axios({
    method: 'DELETE',
    url: `${url}`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    data: { ids },
  })
    .then(() =>
      dispatch({
        type: DID_BATCH_DELETE_OPERATION,
      }),
    )
    .catch(err =>
      dispatch({
        type: BATCH_DELETE_OPERATION_FAILURE,
        payload: {
          error: err,
        },
      }),
    );

/**
 * Change playlist tracks status
 * @param {Object} panel - Playlist panel
 * @param {boolean} status
 */
export const changePlaylistTracksStatus = (panel, status) => (dispatch, getState) =>
  axios({
    method: 'POST',
    url: `${panel.url}/${panel.id}/tracks/status`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
    data: { public: status },
  })
    .then(() =>
      dispatch({
        type: DID_CHANGE_PLAYLIST_TRACKS_STATUS_OPERATION,
      }),
    )
    .catch(err =>
      dispatch({
        type: CHANGE_PLAYLIST_TRACKS_STATUS_FAILURE,
        payload: {
          error: err,
        },
      }),
    );

/**
 * Copy metadata to localStorage
 * Uses in: Track, Multipart
 * @param panelName: String
 * @param data: Object
 * @returns {(function(*): void)|*}
 */
export const copyMetadata = (panelName, data) => (dispatch) => {
  // eslint-disable-next-line no-param-reassign
  delete data.isrc;
  localStorage.setItem('panel-metadata', JSON.stringify({
    panelName,
    data,
  }));
  dispatch({ type: COPY_PANEL_METADATA });
};

/**
 * Paste metadata from localStorage to panel
 * Uses in: Track, Multipart
 * @param panelName: String
 * @param panel: Object
 * @param copyPasteMetadataFields: Array
 * @returns {(function(*): void)|*}
 */
export const pasteMetadata = (panelName, panel, copyPasteMetadataFields) => (dispatch) => {
  const stringData = localStorage.getItem('panel-metadata');
  if (!stringData) {
    dispatch({ type: PASTE_PANEL_METADATA_FAILURE });
    return;
  }

  const { panelName: savedPanelName, data } = JSON.parse(stringData);
  if (savedPanelName !== panelName) {
    dispatch({ type: PASTE_PANEL_METADATA_TYPE_ERROR });
    return;
  }

  dispatch(setData({ isLoading: true }, panel.uuid));
  // need time for finish previous dispatch operation
  setTimeout(() => {
    dispatch(setData({
      data: {
        ...panel.data,
        ...pickMetadata(panelName, data, copyPasteMetadataFields),
      },
      id: panel.id,
      isLoading: false,
      isMetadataSaved: false,
    }, panel.uuid));
    // clear storage after paste
    localStorage.removeItem('panel-metadata');
  }, 1000);
};
