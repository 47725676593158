import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const catalogLabels = {
  name: 'Name',
  public: 'Public',
  showcase: 'Showcase',
  default_publisher: 'Default Publisher',
  default_label: 'Default Master owner',
};

const CatalogSchema = {
  name: Joi.string().label(catalogLabels.name).required(),
  public: Joi.boolean().label(catalogLabels.public),
  showcase: Joi.boolean().label(catalogLabels.showcase),
  agents: Joi.array().items(Joi.string()),
  default_publisher: Joi.string().label(catalogLabels.default_publisher).empty(null),
  default_label: Joi.string().label(catalogLabels.default_label).empty(null),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string(),
    value: Joi.string(),
  })),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const catalogCustomFields = getFieldsByCollection('catalog', validation_fields);
  if (!validation_fields || isEmpty(catalogCustomFields)) { // eslint-disable-line camelcase
    return CatalogSchema;
  }

  return createCustomSchema(catalogCustomFields, custom_fields, {
    defaultSchema: CatalogSchema,
    labels: catalogLabels,
  });
};
