// =============================
// Imports
// =============================

// Constants
import {
  TRACKS,
  MULTIPART_TRACKS,
  ALBUMS,
  PLAYLISTS,
  CATALOGS,
  ARTISTS,
} from './../constants/SearchPanelTypes';

import {
  GET_SEARCH_RESULTS_LOADING,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  SET_SEARCH_RESULTS,
  SET_SEARCH_BY_NAME_RESULTS,
  SET_SEARCH_PAGE_RESULTS,
  CHANGE_SEARCH_PAGE_LOADING,
  CHANGE_SEARCH_PAGE_SUCCESS,
  CHANGE_SEARCH_PAGE_FAILURE,
  SET_SEARCH_JOB_DATA,
  CLEAR_SEARCH_JOB_DATA,
  PENDING_SEARCH_TRIGGERED,
  SET_PENDING_SEARCH,
  PENDING_SEARCH_RESET,
} from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  cancelToken: null,
  isLoading: false,
  query: '',
  prefixes: {},

  pendingSearch: {
    isTriggered: false,
    updateIsPending: false,
  },

  inputSearchQuery: {
    [TRACKS]: [],
    [MULTIPART_TRACKS]: [],
    [ALBUMS]: [],
    [PLAYLISTS]: [],
    [CATALOGS]: [],
    [ARTISTS]: [],
  },

  job: {
    type: null,
    display: null,
    id: null,
    uploadProgress: 0,
    isTriggered: null,
    done: false,
    cancelToken: null,
  },
  [TRACKS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
  [MULTIPART_TRACKS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
  [ALBUMS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
  [PLAYLISTS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
  [CATALOGS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
  [ARTISTS]: {
    data: [],
    nbPages: 0,
    nbResults: 0,
    currentPage: 0,
    sort: {},
    isChangingPage: false,
  },
};

// =============================
// Reducer
// =============================

export default function search(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_RESULTS_LOADING:
      return {
        ...state,
        isLoading: true,
        cancelToken: action.payload,
      };

    case SET_SEARCH_RESULTS: {
      const { values } = action.payload;
      let nextState = {
        ...state,
        query: action.payload.query,
        prefixes: action.payload.prefixes,
      };

      const keys = Object.keys(values);

      keys.forEach((key) => {
        nextState = {
          ...nextState,
          [key]: {
            ...nextState[key],
            nbResults: values[key].total,
            data: values[key].hits,
            nbPages: values[key].nbPages,
            currentPage: action.payload.stayOnPage ? nextState[key].currentPage : 0,
          },
        };
      });

      return nextState;
    }

    case SET_SEARCH_BY_NAME_RESULTS: {
      const { values } = action.payload;
      let nextState = { ...state };

      const keys = Object.keys(values);
      keys.forEach((key) => {
        nextState = {
          ...nextState,
          inputSearchQuery: {
            ...nextState.inputSearchQuery,
            [key]: values[key],
          },
        };
      });

      return nextState;
    }

    case GET_SEARCH_RESULTS_SUCCESS:
    case GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        cancelToken: null,
      };

    case CHANGE_SEARCH_PAGE_LOADING:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isChangingPage: true,
        },
      };

    case SET_SEARCH_PAGE_RESULTS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentPage: action.payload.page,
          sort: action.payload.sort,
          nbResults: action.payload.values.total,
          nbPages: action.payload.values.nbPages,
          data: action.payload.values.hits,
        },
      };

    case CHANGE_SEARCH_PAGE_SUCCESS:
    case CHANGE_SEARCH_PAGE_FAILURE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isChangingPage: false,
        },
      };

    case SET_SEARCH_JOB_DATA:
      return {
        ...state,
        job: {
          ...state.job,
          ...action.job,
        },
      };

    case CLEAR_SEARCH_JOB_DATA:
      return {
        ...state,
        job: Object.assign({}, initialState.job),
      };

    case PENDING_SEARCH_TRIGGERED:
      return {
        ...state,
        pendingSearch: {
          ...state.pendingSearch,
          isTriggered: true,
        },
      };
    case SET_PENDING_SEARCH:
      return {
        ...state,
        pendingSearch: {
          isTriggered: false,
          updateIsNeeded: true,
        },
      };
    case PENDING_SEARCH_RESET:
      return {
        ...state,
        pendingSearch: {
          isTriggered: false,
          updateIsNeeded: false,
        },
      };

    default:
      return state;
  }
}
