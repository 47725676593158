/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';


// =============================
// Styled Components
// =============================

export const Pagination = styled.div`
  ${props => props.theme.fonts.openSansSemiBold};
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.box};
  cursor: ${props => (props.noLink ? 'default' : 'pointer')};
  color: ${props => props.theme.colors.text.grey};
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  transition: border ${props => props.theme.transition.medium}, color ${props => props.theme.transition.medium};
  width: 50px;
  ${props => !props.noLink && css`
    &:hover {
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    }
  `}
  ${props => props.active && css`
    border: 1px solid ${props.theme.colors.primary};
    color: ${props.theme.colors.primary};
  `};
`;
