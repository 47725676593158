/**
 * @author Kevin Siow k.siow@passerelle.co
 * @version 1.0
 * @module Notification
 * Visual representation of a notification
 */

// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonPrimary from './../buttons/buttonprimary/ButtonPrimary';

// Styles
import { Wrapper, NotificationIcon, Message } from './Notification.styles';

// Assets
import iconInfo from './../../../assets/images/icon-info-notif.svg';
import iconSuccess from './../../../assets/images/icon-check-notif.svg';
import iconError from './../../../assets/images/icon-warning-notif.svg';

// =============================
// Component
// =============================

class Notification extends Component {
  static propTypes = {
    // Type can be 'error', 'success' or 'info'
    type: PropTypes.string.isRequired,
    // The notification can stop showing after a while
    // Duration should be expressed in milliseconds
    duration: PropTypes.number,
    // Key of notification
    notificationKey: PropTypes.string.isRequired,
    // This component can clear notification on its own
    clearNotification: PropTypes.func.isRequired,
    // Message
    message: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        func: PropTypes.func,
      }),
    ),
  };

  static defaultProps = {
    duration: 6000,
    message: '',
    actions: [],
  };

  componentWillMount() {
    const { duration, notificationKey, clearNotification } = this.props;

    // Clear the notification after duration
    if (duration === Infinity) return;
    this.timeout = setTimeout(() => {
      clearNotification(notificationKey);
    }, duration);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hide = () => {
    const { notificationKey, clearNotification } = this.props;
    clearTimeout(this.timeout);
    clearNotification(notificationKey);
  };

  render() {
    const { type, message, actions } = this.props;

    return (
      <Wrapper onClick={this.hide}>
        {type === 'error' && <NotificationIcon svg={iconError} type={type} />}

        {type === 'info' && <NotificationIcon svg={iconInfo} type={type} />}

        {type === 'success' && <NotificationIcon svg={iconSuccess} type={type} />}

        <Message>
          <p>
            {message}
            {actions.map(({ name, func }) => (
              <ButtonPrimary
                onClick={(e) => {
                  e.stopPropagation();
                  func(this.hide);
                }}
                mt="10px"
                height="35px"
                width="auto"
                key={name}
              >
                {name}
              </ButtonPrimary>
            ))}
          </p>
        </Message>
      </Wrapper>
    );
  }
}

export default Notification;
