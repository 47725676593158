/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import axios from './../helpers/axios';

// Helpers
import { translateWithLocale as t } from './../helpers/I18n';
import { downloadBinaryFile, downloadJson } from './../helpers/helpers';

// Config
import env from './../../config/private/environment';

// Constants
import {
  EXPORT_CSV_LOADING,
  EXPORT_CSV_SUCCESS,
  EXPORT_CSV_FAILURE,
  EXPORT_JSON_LOADING,
  EXPORT_JSON_SUCCESS,
  EXPORT_JSON_FAILURE,
  EXPORT_BULK_JSON_LOADING,
  EXPORT_BULK_JSON_SUCCESS,
  EXPORT_BULK_JSON_FAILURE,
  DOWNLOAD_ARCHIVE_LOADING,
  DOWNLOAD_ARCHIVE_SUCCESS,
  DOWNLOAD_ARCHIVE_FAILURE,
  LINK_TRACKS_LOADING,
  LINK_TRACKS_SUCCESS,
  LINK_TRACKS_FAILURE, PRINT_DATA_SUCCESS, PRINT_DATA_LOADING, PRINT_TRACK_FAILURE,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * Download Archive
 */
export function downloadArchive(type, id) {
  const getError = (error) => {
    switch (true) {
      case error.status === 402:
        return t('actions.archiveNotInPlan');

      case error.data.key === 'no_tracks':
        return t('actions.archiveNoTracks');

      case error.data.key === 'too_many_tracks':
        return t('actions.archiveTooManyTracks');

      case error.data.key === 'already_has_job':
        return t('actions.archiveAlreadyHasJob');

      default:
        return t('actions.archiveFailedDefault');
    }
  };

  return (dispatch, getState) => {
    dispatch({
      type: DOWNLOAD_ARCHIVE_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/archive`;

    return axios
      .post(url, { type, id, is_bmat: true }, config)
      .then(() =>
        dispatch({
          type: DOWNLOAD_ARCHIVE_SUCCESS,
        }),
      )
      .catch(err =>
        dispatch({
          type: DOWNLOAD_ARCHIVE_FAILURE,
          payload: getError(err.response),
        }),
      );
  };
}

/**
 * Export CSV
 */
export function exportCsv(type, id) {
  const getError = (error) => {
    switch (error.status) {
      case 402:
        return t('actions.exportCsvNotInPlan');

      default:
        return t('actions.exportCsvFailedDefault');
    }
  };

  return (dispatch, getState) => {
    dispatch({
      type: EXPORT_CSV_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/export`;

    return axios
      .post(url, { type, id, format: 'CSV', is_bmat: true }, config)
      .then(() =>
        dispatch({
          type: EXPORT_CSV_SUCCESS,
        }),
      )
      .catch(err =>
        dispatch({
          type: EXPORT_CSV_FAILURE,
          payload: getError(err.response),
        }),
      );
  };
}

/**
 * Export json
 */
export function exportJson(type, id) {
  const getError = (error) => {
    switch (error.status) {
      case 402:
        return t('actions.exportJsonvNotInPlan');

      default:
        return t('actions.exportJsonFailedDefault');
    }
  };

  return (dispatch, getState) => {
    dispatch({
      type: EXPORT_JSON_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/export/json`;

    return axios
      .post(url, { type, id, format: 'JSON' }, config)
      .then(async ({ data }) => {
        dispatch({
          type: EXPORT_JSON_SUCCESS,
        });
        await downloadJson({ data, type });
      })
      .catch(err =>
        dispatch({
          type: EXPORT_JSON_FAILURE,
          payload: getError(err.response),
        }),
      );
  };
}

/**
 * Export bulk json
 */
export function exportBulkJson(type, ids) {
  const getError = (error) => {
    switch (error.status) {
      case 403:
        return t('actions.exportBulkJsonNotInPlan');

      default:
        return t('actions.exportBulkJsonFailedDefault');
    }
  };

  return (dispatch, getState) => {
    dispatch({
      type: EXPORT_BULK_JSON_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/export/bulk-json`;

    const body = { type, ids, format: 'JSON' };

    return axios
      .post(url, body, config)
      .then((res) => {
        dispatch({
          type: EXPORT_BULK_JSON_SUCCESS,
        });

        downloadBinaryFile(
          Buffer.from(JSON.stringify(res.data, null, 2)),
          `bmat_${Date.now()}.json`,
          'application/json',
        );
      })
      .catch(err =>
        dispatch({
          type: EXPORT_BULK_JSON_FAILURE,
          payload: getError(err.response),
        }),
      );
  };
}

/**
 * Link tracks
 */
export function linkTracks(ids) {
  return (dispatch, getState) => {
    dispatch({
      type: LINK_TRACKS_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/tracks/linkversions`;

    return axios
      .post(url, { ids }, config)
      .then(() =>
        dispatch({
          type: LINK_TRACKS_SUCCESS,
        }),
      )
      .catch(err =>
        dispatch({
          type: LINK_TRACKS_FAILURE,
          payload: err.response.data,
        }),
      );
  };
}


/**
 * Get Track
 */
export function getTrack(id) {
  return (dispatch, getState) => {
    dispatch({
      type: PRINT_DATA_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/tracks/${id}`;

    return axios
      .get(url, config)
      .then(response => dispatch({
        type: PRINT_DATA_SUCCESS,
        payload: response.data,
      }))
      .catch(err =>
        dispatch({
          type: PRINT_TRACK_FAILURE,
          payload: err.response.data,
        }),
      );
  };
}

/**
 * Get Album
 */
export function getAlbum(id) {
  return (dispatch, getState) => {
    dispatch({
      type: PRINT_DATA_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const albumUrl = `${env.apiUrl}/meta/albums/${id}`;
    const albumTracksUrl = `${env.apiUrl}/meta/albums/${id}/tracks`;
    const albumTagsUrl = `${env.apiUrl}/meta/albums/${id}/tracks/tags`;

    return axios
      .get(albumUrl, config)
      .then((response) => {
        const album = response.data;
        return Promise.all([
          axios.get(albumTracksUrl, config).then((res) => {
            album.tracks = res.data;
            return res;
          }),
          axios.get(albumTagsUrl, config).then((res) => {
            album.tags = res.data;
            return res;
          }),
        ])
          .then(() => dispatch({
            type: PRINT_DATA_SUCCESS,
            payload: album,
          }));
      })
      .catch(err =>
        dispatch({
          type: PRINT_TRACK_FAILURE,
          payload: err.response.data,
        }),
      );
  };
}

/**
 * Get Multipart
 */
export function getMultipart(id) {
  return (dispatch, getState) => {
    dispatch({
      type: PRINT_DATA_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const multipartUrl = `${env.apiUrl}/meta/multiparts/${id}`;
    const multipartTracksUrl = `${env.apiUrl}/meta/multiparts/${id}/tracks`;
    const multipartTagsUrl = `${env.apiUrl}/meta/multiparts/${id}/tracks/tags`;

    return axios
      .get(multipartUrl, config)
      .then((response) => {
        const multipart = response.data;
        return Promise.all([
          axios.get(multipartTracksUrl, config).then((res) => {
            multipart.tracks = res.data;
            return res;
          }),
          axios.get(multipartTagsUrl, config).then((res) => {
            multipart.tags = res.data;
            return res;
          }),
        ])
          .then(() => dispatch({
            type: PRINT_DATA_SUCCESS,
            payload: multipart,
          }));
      })
      .catch(err =>
        dispatch({
          type: PRINT_TRACK_FAILURE,
          payload: err.response.data,
        }),
      );
  };
}
