// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import InlineSVG from 'react-svg-inline';

import { Div } from '../../../../themes/views';

export const searchHeight = 50;

export const Container = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: auto;
  max-height: ${props => (props.height ? props.height : '200px')};
  position: relative;
`;

export const SearchRow = styled.div`
  height: ${searchHeight}px;
  left: 0;
  padding: 8px 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Input = styled.input`
  border: none;
  flex: 1 0 auto;
`;

export const ItemsContainer = styled.ul`
  max-height: ${props => (props.height ? props.height : '200px')};
  flex: 1 1 auto;
  overflow: auto;
  margin-top: ${props => (props.withSearch ? '50px' : '5px')};
`;

export const Icon = styled(InlineSVG)`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 5px;
  width: 20px;
`;

export const IconSearch = styled(Icon)`
  stroke: ${props => props.theme.colors.text.grey};
`;

export const IconCheck = styled(Icon)`
  fill: ${props => props.theme.colors.success};
`;

export const IconDot = styled(Icon)`
  fill: #000;
`;

export const IconMinus = styled(Icon)`
  fill: #000;
`;

export const Text = styled.span`
  width: calc(100% - 25px);
`;

export default ItemsContainer;
