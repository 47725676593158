// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import SidePanel from './../../containers/sidepanel/SidePanelContainer';
import Header from './../../containers/header/HeaderContainer';
import MenuTabs from './../../containers/menus/menutabs/MenuTabsContainer';

// Constants
import {
  HOME_PAGE_ROUTE,
  MULTIPART_TRACKS_PAGE_ROUTE,
  ALBUMS_PAGE_ROUTE,
  PLAYLISTS_PAGE_ROUTE,
  CATALOGS_PAGE_ROUTE,
  PROVIDERS_PAGE_ROUTE,
} from './../../../constants/Routes';

// Styles
import { GenericView, ContainerView } from './../../../themes/views';
import { colors } from './../../../themes/variables';

// =============================
// Component
// =============================

class SearchLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {};

  state = {};

  render() {
    const { children, location } = this.props;

    const classicRoutes = [
      HOME_PAGE_ROUTE,
      MULTIPART_TRACKS_PAGE_ROUTE,
      ALBUMS_PAGE_ROUTE,
      PLAYLISTS_PAGE_ROUTE,
      CATALOGS_PAGE_ROUTE,
      PROVIDERS_PAGE_ROUTE,
    ];

    if (classicRoutes.indexOf(location.pathname) === -1) {
      return (
        <GenericView>
          {children}
        </GenericView>
      );
    }

    return (
      <React.Fragment>
        <Header />
        <ContainerView bgColor={colors.background}>
          <MenuTabs />
        </ContainerView>
        <SidePanel />
        <GenericView padding="0 50px 40px">
          {children}
        </GenericView>
      </React.Fragment>
    );
  }
}

export default withRouter(SearchLayout);
