// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import Joi from 'joi-browser';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import Input from './../inputunderline/InputUnderline';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import { Label } from './DateInput.styles';
import { Div, Flex } from './../../../../themes/views';

// =============================
// Component
// =============================

class DateInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    date: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: undefined,
    date: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: this.dateStringToUnits(props.date),
      errors: {
        day: '',
        month: '',
        year: '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const thisPropsDateValues = this.dateStringToUnits(this.props.date);
    const nextPropsDateValues = this.dateStringToUnits(nextProps.date);
    const stateDateValues = this.dateStringToUnits(this.unitsToDateString(this.state.values));

    if (isEqual(thisPropsDateValues, nextPropsDateValues)) return;
    if (isEqual(stateDateValues, nextPropsDateValues)) return;

    this.setState({
      values: nextPropsDateValues,
      errors: {
        day: '',
        month: '',
        year: '',
      },
    });
  }

  dateStringToUnits = (dateString) => {
    let day = '';
    let month = '';
    let year = '';

    if (dateString) {
      const date = moment.utc(dateString);

      if (date) {
        day = date.date().toString();
        month = (date.month() + 1).toString(); // Moment = 0 to 11, new Date = 1 to 12
        year = date.year().toString();
      }
    }
    return { day, month, year };
  };

  unitsToDateString = ({ year, month, day }) => {
    if (this.validate({ year, month, day }).error) return null;
    if (!day && !month && !year) return null;

    return moment
      .utc([year, month - 1, day])
      .toDate()
      .toISOString();
  };

  validate = ({ day, month, year }) => {
    if (!day && !month && !year) return { error: undefined };

    const schema = {
      day: Joi.number()
        .min(1)
        .max(31)
        .required(),
      month: Joi.number()
        .min(1)
        .max(12)
        .required(),
      year: Joi.number()
        .min(-27000)
        .max(27000)
        .required(),
    };

    return Joi.validate({ day, month, year }, schema, { abortEarly: false });
  };

  validateAndSetError = () => {
    const { error } = this.validate(this.state.values);
    const errors = !error
      ? {}
      : error.details.reduce(
        (acc, { context: { key } }) => ({ ...acc, [key]: `Invalid ${key}` }),
        {},
      );

    this.setState(() => ({
      errors,
    }));

    return !error;
  };

  handleChange = (name, value) => {
    const { onChange } = this.props;

    if (!onChange) return;

    this.setState(
      {
        values: {
          ...this.state.values,
          [name]: value,
        },
      },
      () => {
        const { day, month, year } = this.state.values;

        if (!this.validateAndSetError()) return;

        const date = this.unitsToDateString({ day, month, year });
        onChange(this.props.name, date);
      },
    );
  };

  resetInput = () => {
    const { onChange } = this.props;

    if (!onChange) return;
    this.setState(
      {
        values: {
          day: '',
          month: '',
          year: '',
        },
        errors: {
          day: '',
          month: '',
          year: '',
        },
      },
      () => {
        onChange(this.props.name, null);
      },
    );
  };

  render() {
    const { label, onChange, onFocus, onBlur, t } = this.props;
    const { values, errors } = this.state;

    return (
      <Div {...getViewProps(this.props)}>
        <Label mt="-10px">{label || t('components.inputs.dateInput.label')}</Label>
        <Flex align="baseline">
          <Input
            name="day"
            placeholder="DD"
            onChange={onChange ? this.handleChange : null}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values.day}
            error={errors.day}
            width="65px"
            mr="10px"
          />
          <Input
            name="month"
            placeholder="MM"
            onChange={onChange ? this.handleChange : null}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values.month}
            error={errors.month}
            width="65px"
            mr="10px"
          />
          <Input
            name="year"
            placeholder="YYYY"
            onChange={onChange ? this.handleChange : null}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values.year}
            error={errors.year}
            width="65px"
            mr="15px"
          />
          {onChange}
        </Flex>
      </Div>
    );
  }
}

export default withI18n()(DateInput);
