/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// =============================
// Styled Components
// =============================

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex.dialog};
  background: ${props => props.theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogBody = styled.div`
  background: #fff;
  position: relative;
  border-radius: ${props => props.theme.borderRadius.box};
  padding: 60px;
  cursor: default;
`;

export const IconCross = styled(SVGInline)`
  position: absolute;
  width: 12px;
  height: 12px;
  right: 33px;
  top: 27px;
  stroke: #999;
  cursor: pointer;

  &:hover {
    stroke: #000;
  }
`;

export const Heading = styled.h2`
  font-weight: 700;
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.l};
  text-transform: uppercase;
  color: ${props => props.theme.colors.text.greyDark};
  margin-bottom: 20px;
`;
