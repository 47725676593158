// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getName } from './../../../../../helpers/I18n';

// HOC
import withI18n from './../../../../hoc/WithI18n';

// Components
import OwnershipDetails from './OwnershipDetails';

// Themes
import { IconLocation, IconTrash } from './../../../../../themes/icons';
import { Div, Flex } from './../../../../../themes/views';

// =============================
// Component
// =============================

class Ownership extends Component {
  static propTypes = {
    PercentageComponent: PropTypes.func.isRequired,
    OwnerInputComponent: PropTypes.func.isRequired,
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    territories: PropTypes.array.isRequired, // eslint-disable-line
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onRemove: PropTypes.func,
    instruments: PropTypes.array, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool,
    panelName: PropTypes.string.isRequired,
    sessionToken: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    hiddenFields: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    disabled: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
    onRemove: null,
    instruments: [],
    showAdditionalNotes: false,
    hiddenFields: [],
  };

  state = {
    detailsOpened: false,
  };

  toggleDetails = () => {
    this.setState(({ detailsOpened }) => ({ detailsOpened: !detailsOpened }));
  };

  render() {
    const {
      PercentageComponent,
      OwnerInputComponent,
      ownership,
      errors,
      disabled,
      territories,
      onChange,
      onFocus,
      onBlur,
      onRemove,
      locale,
      instruments,
      showAdditionalNotes,
      panelName,
      sessionToken,
      hiddenFields,
    } = this.props;
    const { detailsOpened } = this.state;
    const label = getName(ownership.rights_type, locale);

    return (
      <Div>
        <Flex align="baseline">
          <OwnerInputComponent
            ownership={ownership}
            label={label}
            errors={errors}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <Flex align="center" width="60px" justify="space-between" height="100%" ml="10px">
            <IconLocation onClick={this.toggleDetails} active={detailsOpened} size="22px" />
            {disabled || <IconTrash onClick={onRemove} size="22px" />}
          </Flex>
        </Flex>

        {detailsOpened && (
          <OwnershipDetails
            PercentageComponent={PercentageComponent}
            ownership={ownership}
            errors={errors}
            disabled={disabled}
            territories={territories}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            instruments={instruments}
            getInstrumentName={instrument => getName(instrument, locale)}
            showAdditionalNotes={showAdditionalNotes}
            panelName={panelName}
            sessionToken={sessionToken}
            hiddenFields={hiddenFields}
          />
        )}
      </Div>
    );
  }
}

export default withI18n()(Ownership);
