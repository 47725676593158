// =============================
// Imports
// =============================

// External Dependencies
import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';

// Styles
// import Common from './../';
import { Div } from './../../../../themes/views';

const Common = Div.extend`
  ${props => props.theme.fontSize.m};
  border-radius: ${props => props.theme.borderRadius.element};
  cursor: pointer;
  height: ${props => (props.height ? props.height : '50px')};
  padding: 0 10px;
  text-transform: uppercase;
  transition-duration: ${props => props.theme.transition.medium};
  width: ${props => (props.width ? props.width : '150px')};
`.withComponent('button');

export const Container = Common.extend`
  background-color: ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
  border: 1px solid ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
  color: #fff;

  &:hover {
    background-color: ${props => (props.warning ? props.theme.colorsHover.error : props.theme.colorsHover.primary)};
    border: 1px solid ${props => (props.warning ? props.theme.colorsHover.error : props.theme.colorsHover.primary)};
  }

  ${props => props.disabled && css`
    background-color: ${props.theme.colors.text.grey};
    border: 1px solid ${props.theme.colors.text.grey};
    cursor: not-allowed;

    &:hover {
      background-color: ${props.theme.colorsHover.text.grey};
      border: 1px solid ${props.theme.colorsHover.text.grey};
    }
  `}
`;

export const Button = Container.extend`
  position: relative;
`;

// keyframes returns a unique name based on a hash of the contents of the keyframes
const Progression = keyframes`
  0% {
    width: 0%;
  }

  20% {
    width: 20%;
  }

  40% {
    width: 40%;
  }

  80% {
    width: 40%;
  }

  100% {
    width: 100%;
  }
`;

export const ProgressBar = styled.div`
  animation: ${Progression} 2s linear infinite;
  background-color: ${props => props.theme.colors.tertiary};
  border-radius: ${props => props.theme.borderRadius.element};
  height: 3px;
  left: 0;
  position: absolute;
  transition: width ${props => props.theme.transition.medium};
  top: 0;
  width: 100%;
`;

export const Link = Container.extend`
  align-items: center;
  display: flex;
  justify-content: center;
`.withComponent(NavLink);

export const ExternalLink = Link.withComponent('a');

export default Button;
