// =============================
// Imports
// =============================

// Constants
import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * @description Triggers a notification
 *
 * @param {string} notificationKey - Notification key
 * @param {number} duration - Display duration of notification
 * @param {string} type - Notification type
 * @param {string} message - Notification message
 */
export function setNotification({ notificationKey, duration, type, message = '', actions }) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      notificationKey,
      duration,
      type,
      message,
      actions,
    },
  };
}

/**
 * @description Deletes a notification
 *
 * @param {string} notificationKey - Notification key
 */
export function clearNotification(notificationKey) {
  return {
    type: CLEAR_NOTIFICATION,
    payload: notificationKey,
  };
}
