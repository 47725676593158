// =============================
// Imports
// =============================

// Actions
import {
  showPlayer,
  setCurrentTrack,
  setCurrentTracklist,
  fulfillRequest,
  play,
} from '../actions/PlayerActions';

// Constants
import { REQUEST_LOAD_TRACK } from '../constants/ActionTypes';
import { LOAD_TRACK } from '../constants/PlayerRequests';

// =============================
// Middleware
// =============================

const playerMiddleware = store => next => (action) => {
  switch (true) {
    // When user loads a track
    case action.type === REQUEST_LOAD_TRACK: {
      const { show, currentTrack, currentTracklistId } = store.getState().player;
      // Show the player until end of session on first load track
      if (!show) {
        store.dispatch(showPlayer(true));
      }

      // Check if the tracks playlist is the current one
      if (currentTracklistId !== action.payload.tracklistId) {
        // If it is not, we need to change the current tracklist
        store.dispatch(setCurrentTracklist(
          action.payload.tracklistId,
          action.payload.tracklistDatas,
        ));
      }

      // Check if the track is the current track
      if (!currentTrack || currentTrack.id !== action.payload.trackDatas.id) {
        // If it is not, we need to change the current track
        store.dispatch(setCurrentTrack(action.payload.trackDatas));
      } else {
        // If it is, dispatch a play
        store.dispatch(play());
      }

      // Fulfill the request
      store.dispatch(fulfillRequest(LOAD_TRACK));

      return next(action);
    }

    default:
      return next(action);
  }
};

export default playerMiddleware;
