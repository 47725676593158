// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Helpers
import { getOrResetLocale } from '../../../../helpers/I18n';

// Action
import { fetchError } from './../../../../actions/ApiSelectInputActions';

// Components
import TranslateArea from './../../../presentationals/inputs/translatearea/TranslateArea';

// =============================
// Component
// =============================

function mapStateToProps({ options }) {
  return {
    languages: options.data.languages,
    isFetchingOptions: options.isFetching,
    defaultLanguage: getOrResetLocale(),
    onFetchError: fetchError,
  };
}

export default connect(mapStateToProps)(TranslateArea);
