// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getName } from './../../../helpers/I18n';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import TagListBody from './TagListBody';

// Styles
import { Div } from './../../../themes/views';
import { SubCategory } from './../sidepanel/SidePanel.styles';

// =============================
// Component
// =============================

class TagSubCategory extends Component {
  static propTypes = {
    filteredTags: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    subCategory: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    color: PropTypes.string,
    onChange: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: null,
    onChange: null,
  };

  render() {
    const { subCategory, locale, filteredTags, ...props } = this.props;
    const isShown = !!filteredTags.length;
    if (!isShown) return false;

    return (
      <div>
        <SubCategory>{getName(subCategory, locale)}</SubCategory>
        <Div mt="15px">
          <TagListBody tags={subCategory.tags} filteredTags={filteredTags} {...props} />
        </Div>
      </div>
    );
  }
}

export default withI18n()(TagSubCategory);
