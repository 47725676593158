// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { get } from 'lodash';

// Actions
import { exportCsv, downloadArchive, exportJson } from './../../../actions/GlobalActions';

// Helpers
import { getHiddenFieldsFromUserData, downloadAudiofileRAI } from './../../../helpers/helpers';

// Components
import TrackPanel from './../../presentationals/panels/track/Track';

// =============================
// Component
// =============================

function mapStateToProps({ options, user }) {
  return {
    mechanicalrightssocieties: options.data.mechanicalrightssocieties,
    performingrightssocieties: options.data.performingrightssocieties,
    neighboringrightssocieties: options.data.neighboringrightssocieties,
    territories: options.data.territories,
    trackversions: options.data.trackversions,
    isFetchingOptions: options.isFetching,
    rightstypes: options.data.rightstypes,
    languages: options.data.languages,
    tagCategories: options.data.tags,
    agents: user.agents.data.filter(a => a.active_as_agent === true),
    canDownload: user.canDownload,
    canDelete: user.canDelete,
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'track'),
    showAdditionalNotes: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'showAdditionalNotes'],
      false,
    ),
    customFieldsToRightSide: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'customFieldsToRightSide'],
      false,
    ),
    descriptionAutocomplete: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'descriptionAutocomplete'],
      false,
    ),
    transformDownloadAudiofileName: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'transformDownloadAudiofileName'],
      false,
    ),
    copyPasteMetadataFields: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'copyPasteMetadataFields'],
      [],
    ),
    hiddenPanelActions: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'hiddenPanelActions', 'track'],
      [],
    ),
  };
}

export default connect(mapStateToProps, {
  exportCsv,
  exportJson,
  downloadArchive,
  downloadAudiofileRAI,
})(TrackPanel);
