import { UserAgentApplication } from 'msal';

export const AUTH_AZURE_B2C_CONFIG = {
  auth: {
    clientId: process.env.BMAT_AUTH_CLIENT_ID || '2b78fe56-98d6-4bee-9461-80bc5fccc114',
    redirectUri: process.env.BMAT_AUTH_REDIRECT_URI || 'http://localhost:1337/login',
    authority:
      process.env.BMAT_AUTH_AUTHORITY ||
      'https://bmatb2c.b2clogin.com/21044fc0-a1b7-4aa2-969b-04ef175b5a7b/B2C_1A_SeamlessMigration_signuporsignin/',
    validateAuthority: false,
    resetPasswordPolicy:
      process.env.BMAT_AUTH_RESET_PASSWORD_POLICY ||
      'https://bmatb2c.b2clogin.com/21044fc0-a1b7-4aa2-969b-04ef175b5a7b/B2C_1A_SeamlessMigration_PasswordReset/',
    postLogoutRedirectUri: process.env.BMAT_AUTH_LOGOUT_URI || 'http://localhost:1337',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalClient = new UserAgentApplication(AUTH_AZURE_B2C_CONFIG);
