// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

// Components
import { Div } from '../../../../themes/views';

// =============================
// Component
// =============================

function formatDate(date, format) {
  return moment(date).format(format);
}

function parseDate(str, format) {
  const parsed = moment(str, format);
  if (parsed.isValid()) {
    return parsed.toDate();
  }

  return undefined;
}

const DateInput = ({
  field,
  form: { touched, errors, setFieldValue },
}) => (
  <Div>
    <DayPickerInput
      value={field.value}
      selectedDay={field.value}
      format="DD/M/YYYY"
      formatDate={formatDate}
      parseDate={parseDate}
      onDayChange={date => setFieldValue(field.name, date)}
    />
    {touched[field.name] &&
    errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </Div>
);

DateInput.propTypes = {
  field: PropTypes.shape.isRequired,
  form: PropTypes.shape.isRequired,
};

export default DateInput;
