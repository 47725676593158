// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './WithI18n';

// Components
import Dialog from './../presentationals/dialog/Dialog';
import ButtonPrimary from './../presentationals/buttons/buttonprimary/ButtonPrimary';
import ButtonOutline from './../presentationals/buttons/buttonoutline/ButtonOutline';

// Styles
import { Heading } from './../presentationals/dialog/Dialog.styles';
import { DialogForm } from './WithConfirmation.styles';
import { Flex } from './../../themes/views';

// =============================
// Component
// =============================

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withConfirmation(WrappedComponent, clickOutsideToClose = true) {
  class WithConfirmation extends Component {
    static displayName = `WithConfirmation(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      t: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
      super(props);
      this.state = this.defaultState();
    }

    componentWillMount() {
      document.addEventListener('keydown', this.handleKeypress, false);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeypress, false);
    }

    handleConfirm = (
      message,
      { title = this.defaultState().title, submit = this.defaultState().submit } = {},
    ) =>
      new Promise(resolve =>
        this.setState(() => ({
          isOpen: true,
          message,
          title,
          submit,
          resolve,
        })),
      );

    handleKeypress = ({ key }) => {
      if (key !== 'Enter') return;
      this.handleAccept();
    };

    handleDecline = () => {
      this.state.resolve(false);
      this.setState(() => this.defaultState());
    };

    handleAccept = (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.state.resolve(true);
      this.setState(() => this.defaultState());
    };

    defaultState = () => {
      const { t } = this.props;

      const submit = t('components.withConfirmation.ok');
      const cancel = t('components.withConfirmation.cancel');

      return {
        isOpen: false,
        message: '',
        title: '',
        submit,
        cancel,
        resolve: undefined,
      };
    };
    render() {
      const { ...passThroughProps } = this.props;
      const { isOpen, message, title, submit, cancel } = this.state;
      return [
        isOpen && (
          <Dialog onClose={this.handleDecline} clickOutsideToClose={clickOutsideToClose} key="0">
            <DialogForm onSubmit={this.handleAccept} className="ignore-react-onclickoutside">
              {title && <Heading>{title}</Heading>}
              {message}
              <Flex justify="space-between" mt="30px" width="100%">
                <ButtonOutline onClick={this.handleDecline} type="button">
                  {cancel}
                </ButtonOutline>
                <ButtonPrimary type="submit" warning>
                  {submit}
                </ButtonPrimary>
              </Flex>
            </DialogForm>
          </Dialog>
        ),
        <WrappedComponent {...passThroughProps} onConfirm={this.handleConfirm} key="1" />,
      ];
    }
  }
  return withI18n()(WithConfirmation);
}
