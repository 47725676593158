import envConfig from '../private/environment';

// Base configuration for our html page. We use react-helmet supported syntax
// for the values.
// @see https://github.com/nfl/react-helmet
export default {
  // Default title
  defaultTitle: envConfig.bmatWebsiteTitle,

  // Title template
  titleTemplate: `%s - ${envConfig.bmatWebsiteTitle}`,

  // Language of the website
  lang: 'en',

  meta: [
    // Default content encoding.
    { name: 'charset', content: 'utf-8' },
    // @see http://bit.ly/2f8IaqJ
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    // This is important to signify your application is mobile responsive!
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    // Progressive apps
    { name: 'theme-color', content: '#ffffff' },
  ],
};
