// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Components
import Chip from './../../chip/Chip';

// Styles
import { Highlight } from './../../../../themes/entityGrid';
import isBlank from '../../../../helpers/isBlank';

// =============================
// Renderer
// =============================

export default onClick => (name, object, rowIndex, highlightKey) => {
  let nameNode = <Highlight dangerouslySetInnerHTML={{ __html: name }} />;

  if (highlightKey && !isBlank(object.highlight[highlightKey])) {
    nameNode = (<Highlight
      dangerouslySetInnerHTML={{ __html: object.highlight[highlightKey][0] }}
    />);
  }

  return <Chip name={nameNode} originName={name} onClick={() => onClick(object.id)} />;
};
