// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getName } from '../../../../../helpers/I18n';

// HOC
import withI18n from './../../../../hoc/WithI18n';

// Components
import SelectInput from './../../selectinput/SelectInput';
import Ownership from './Ownership';

// Themes
import { ErrorText } from './../../../../../themes/views';

// =============================
// Component
// =============================

class OwnerInput extends Component {
  static propTypes = {
    PercentageComponent: PropTypes.func.isRequired,
    OwnerInputComponent: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.array, // eslint-disable-line
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // eslint-disable-line
    disabled: PropTypes.bool,
    territories: PropTypes.array, // eslint-disable-line
    rightstypes: PropTypes.array, // eslint-disable-line
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    instruments: PropTypes.array, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool,
    panelName: PropTypes.string.isRequired,
    sessionToken: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    hiddenFields: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    value: [],
    error: [],
    disabled: false,
    territories: [],
    rightstypes: [],
    instruments: [],
    showAdditionalNotes: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
    hiddenFields: [],
  };

  state = {};

  addOwnership = (rightsTypeId) => {
    const { name, value, rightstypes, onChange } = this.props;

    const ownership = {
      rights_type: rightstypes.find(v => v.id === rightsTypeId),
      territories: ['WW'],
    };

    onChange(name, [...value, ownership]);
  };

  removeOwnership = (index) => {
    const { name, value, onChange } = this.props;
    onChange(name, value.filter((v, i) => i !== index));
  };

  handleChange = (ownership, index) => {
    const { name, value, onChange } = this.props;

    onChange(
      name,
      value.map((o, i) => {
        if (i !== index) return o;
        return ownership;
      }),
    );
  };

  render() {
    const {
      PercentageComponent,
      OwnerInputComponent,
      onFocus,
      onBlur,
      value,
      disabled,
      territories,
      rightstypes,
      t,
      locale,
      instruments,
      showAdditionalNotes,
      panelName,
      sessionToken,
      error,
      hiddenFields,
    } = this.props;
    return (
      <div>
        {(value || []).map((ownership, i) => (
          <Ownership
            PercentageComponent={PercentageComponent}
            OwnerInputComponent={OwnerInputComponent}
            ownership={ownership}
            errors={error[i] || {}}
            disabled={disabled}
            territories={territories}
            onChange={o => this.handleChange(o, i)}
            onFocus={onFocus}
            onBlur={onBlur}
            onRemove={() => this.removeOwnership(i)}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            instruments={instruments}
            showAdditionalNotes={showAdditionalNotes}
            panelName={panelName}
            sessionToken={sessionToken}
            hiddenFields={hiddenFields}
          />
        ))}
        {disabled || (
          <SelectInput
            type="single"
            name="rights_type"
            label={t('components.inputs.ownerInput.labelAdd')}
            onChange={(name, rightsTypeId) => this.addOwnership(rightsTypeId)}
            onFocus={onFocus}
            onBlur={onBlur}
            items={rightstypes.map(v => ({
              id: v.id,
              name: getName(v, locale),
            }))}
            data={null}
            search
          />
        )}
        {typeof error === 'string' && <ErrorText>{error}</ErrorText>}
      </div>
    );
  }
}

export default withI18n()(OwnerInput);
