// =============================
// Imports
// =============================

// External Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import startsWith from 'lodash/startsWith';
import startCase from 'lodash/startCase';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import { Link } from 'react-router-dom';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from './../../sidepanel/loader/Loader';
import ImageLoader from './../../imageloader/ImageLoader';
import Tag from './../../tag/Tag';
import MusicItemControls from './../../../containers/musicitem/controls/MusicItemControlsContainer';

import InputBase from './../../inputs/inputunderline/InputUnderline';
import ApiSelectInputBase from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';
import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import DateInputBase from './../../inputs/dateinput/DateInput';
import MultipleSelectBase from './../../inputs/multipleselect/MultipleSelect';
import TerritoriesSelectBase from '../../inputs/territoriesselect/TerritoriesSelect';
import RatingInputBase from './../../inputs/ratinginput/RatingInput';
import SelectInputBase from './../../inputs/selectinput/SelectInput';
import SwitchInputBase from './../../inputs/switchinput/SwitchInput';
import ApiAutocompleteInputBase from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import DisplayArtistInputBase from './../../inputs/displayartistinput/DisplayArtistInput';
import TagListBase from './../../../containers/taglist/TagListContainer';
import TextareaBase from './../../inputs/textarea/Textarea';
import TranslateAreaBase from './../../../containers/inputs/translatearea/TranslateAreaContainer';
import FileUploadBase from './../../sidepanel/fileupload/FileUpload';
import AttachmentFileInputBase from './../../sidepanel/fileupload/AttachmentFileInput';
import ArtistsMasterOwnerInputBase from './../../inputs/ownerinput/ArtistsMasterOwnerInput';
import LabelsMasterOwnerInputBase from './../../inputs/ownerinput/LabelsMasterOwnerInput';
import ArtistsPublishingOwnerInputBase from './../../inputs/ownerinput/ArtistsPublishingOwnerInput';
import PublishersPublishingOwnerInputBase from './../../inputs/ownerinput/PublishersPublishingOwnerInput';
import MultipartTracks from './MultipartTracks';

// Constants
import { ALBUM_PANEL, TRACK_PANEL } from './../../../../constants/SidePanelTypes';

// Helpers
import { getTrackDuration, getTrackDurationObject } from './../../../../helpers/helpers';
import { getName } from './../../../../helpers/I18n';
import isBlank from './../../../../helpers/isBlank';

// Schema
import MultipartTrackSchema from './MultipartTrack.schema';

// Styles
import { MaiaLabel, PlayableCover, PlayerContainer } from './MultipartTrack.styles';
import { IconExportCsv, IconText, IconCopy, IconPaste } from './../../../../themes/icons';
import { Div, Flex } from './../../../../themes/views';
import { PanelContent, SubCategory as SubCategoryBase } from './../../sidepanel/SidePanel.styles';
import { Action } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const ApiSelectInput = hideable(ApiSelectInputBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const DateInput = hideable(DateInputBase);
const MultipleSelect = hideable(MultipleSelectBase);
const RatingInput = hideable(RatingInputBase);
const SelectInput = hideable(SelectInputBase);
const SwitchInput = hideable(SwitchInputBase);
const ApiAutocompleteInput = hideable(ApiAutocompleteInputBase);
const DisplayArtistInput = hideable(DisplayArtistInputBase);
const TagList = hideable(TagListBase);
const Textarea = hideable(TextareaBase);
const TranslateArea = hideable(TranslateAreaBase);
const FileUpload = hideable(FileUploadBase);
const AttachmentFileInput = hideable(AttachmentFileInputBase);
const SubCategory = hideable(SubCategoryBase);
const ArtistsMasterOwnerInput = hideable(ArtistsMasterOwnerInputBase);
const LabelsMasterOwnerInput = hideable(LabelsMasterOwnerInputBase);
const ArtistsPublishingOwnerInput = hideable(ArtistsPublishingOwnerInputBase);
const PublishersPublishingOwnerInput = hideable(PublishersPublishingOwnerInputBase);
const TerritoriesSelect = hideable(TerritoriesSelectBase);

// =============================
// Component
// =============================

const shortPanelName = 'multipart';
const customFieldSections = ['root', 'versions', 'master_info', 'publishing_info', 'lyrics', 'distribution', 'files'];

function getValidDurationObject(duration) {
  const { min, sec } = getTrackDurationObject(duration);
  return {
    min: min !== 0 && !isNaN(min) ? min : '',
    sec: sec !== 0 && !isNaN(sec) ? sec : '',
  };
}

function trackDurationReduceFunc(sum, track) {
  const duration = get(track, ['duration']);
  return sum + duration;
}

export class MultipartTrack extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      trackVersions: PropTypes.array, // eslint-disable-line
      tracks: PropTypes.array, // eslint-disable-line
      tracksTags: PropTypes.array, // eslint-disable-line
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    openSidePanel: PropTypes.func.isRequired,
    // exportCsv: PropTypes.func.isRequired,
    exportJson: PropTypes.func.isRequired,
    // downloadArchive: PropTypes.func.isRequired,
    trackversions: PropTypes.array.isRequired, // eslint-disable-line
    territories: PropTypes.array.isRequired, // eslint-disable-line
    neighboringrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    mechanicalrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    performingrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    agents: PropTypes.array.isRequired, // eslint-disable-line
    languages: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    rightstypes: PropTypes.array.isRequired, // eslint-disable-line
    tagCategories: PropTypes.array.isRequired, // eslint-disable-line
    canDownload: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool.isRequired,
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    copyMetadata: PropTypes.func.isRequired,
    pasteMetadata: PropTypes.func.isRequired,
    copyPasteMetadataFields: PropTypes.array.isRequired, // eslint-disable-line
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      durationMinutes: '',
      durationSeconds: '',
    };

    this.durationMinutesRef = React.createRef();
    this.durationSecondsRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps, this.props)) {
      return;
    }

    // update duration
    const prevTracks = get(prevProps, ['panel', 'tracks']) || [];
    const tracks = get(this.props, ['panel', 'tracks']) || [];

    const prevTracksDuration = reduce(prevTracks, trackDurationReduceFunc, 0);
    const currentTracksDuration = reduce(tracks, trackDurationReduceFunc, 0);

    const prevDuration = get(prevProps, ['panel', 'data', 'duration']);
    const currentDuration = get(this.props, ['panel', 'data', 'duration']);

    let duration = null;
    if (prevDuration !== currentDuration) {
      duration = currentDuration;
    } else if (prevTracksDuration !== currentTracksDuration) {
      duration = currentTracksDuration;
    }

    if (duration) {
      const { min, sec } = getValidDurationObject(duration);
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        durationMinutes: min,
        durationSeconds: sec,
      });
    }
  }

  onSelectChange = (name, value) => {
    if (value === '') {
      // We need to transform the empty value to null as the api
      // is not able to unset certain values with an empty string
      this.props.updateForm(name, null);
    } else {
      this.props.updateForm(name, value);
    }
  };

  onStateDurationChange = (name, value) => {
    this.setState({
      [name]: !value ? '' : Math.abs(Math.floor(toNumber(value))),
    });
  };

  onDurationChange = () => {
    const { updateForm, panel } = this.props;
    const { durationMinutes, durationSeconds } = this.state;

    this.durationMinutesRef.value = durationMinutes;
    this.durationSecondsRef.value = durationSeconds;

    const prevDuration = toNumber(get(panel, ['data', 'duration']));

    const minutes = Math.floor(toNumber(durationMinutes) * 60);
    const seconds = Math.floor(toNumber(durationSeconds));
    const duration = minutes + seconds;

    if (prevDuration === duration) {
      return;
    } else if (!durationMinutes && !durationSeconds) {
      updateForm('duration', null);
      return;
    }
    updateForm('duration', duration);
  };

  onVersionChange = (name, value) => {
    const { trackversions, updateForm } = this.props;
    // NOTE: if track set to original unset it's original_track value
    const original = trackversions.find(tv => tv.key === 'original');

    if (original && original.id === value) {
      updateForm('original_track', null).then(() => updateForm(name, value));
    } else if (!value) {
      updateForm('original_track', null).then(() => updateForm(name, null));
    } else {
      updateForm(name, value);
    }
  };

  updateLyrics = (name, value) => {
    const { form } = this.props;

    if (name === 'value' && !value) {
      this.props.updateForm('lyrics', {});
    } else if (name === 'locale' && !value) {
      const oldDatas = { ...form.values.lyrics };
      const newDatas = oldDatas.value ? { value: oldDatas.value } : {};

      this.props.updateForm('lyrics', newDatas);
    } else {
      let oldDatas = {};
      if (form.values.lyrics) {
        oldDatas = { ...form.values.lyrics };
      }

      this.props.updateForm('lyrics', { ...oldDatas, [name]: value });
    }
  };

  updateTags = (name, value) => {
    const { panel, updateAdditionalFormData } = this.props;

    const tracksTags = panel.tracksTags || [];
    const tagList = tracksTags.filter(t => t.status === 2).map(t => t.tag.id);

    if (tagList.indexOf(value) === -1) {
      // Add tag to list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'POST' },
        { tracksTags: 'tracks/tags' },
      );
    } else {
      // Remove tag from list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'DELETE' },
        { tracksTags: 'tracks/tags' },
      );
    }
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    const tracks = panel.tracks || [];

    const isOriginalTrack = !!(
      panel.data &&
      panel.data.version &&
      panel.data.version.key === 'original'
    );

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'track':
        return [
          'title',
          'fingerprint',
          'display_title',
          'duration',
          'version',
          'release_date',
          'track_number',
          'album',
          'track_ref',
          'display_artists',
        ].some(this.isShown);
      case 'versions':
        return true;
      case 'add_version':
        return this.checkIfOwned() && isOriginalTrack;
      case 'master_info':
        return ['isrc', 'artists_master_ownerships', 'master_ownerships', 'original_nrs'].some(
          this.isShown,
        );
      case 'publishing_info':
        return [
          'iswc',
          'cocv',
          'prs_tunecode',
          'artists_publishing_ownerships',
          'publishing_ownerships',
          'original_mrs',
          'original_prs',
        ].some(this.isShown);
      case 'distribution':
        return !!(this.checkIfOwned() && panel.data);
      case 'public':
        return !!(this.checkIfOwned() && panel.data);
      case 'showcase':
        return !!panel.data;
      case 'rating':
        return true;
      case 'files':
        return ['audiofile', 'stems'].some(this.isShown);
      case 'audiofile':
        return this.checkIfOwned() || !isBlank(panel.data && panel.data.audiofile);
      case 'stems':
        return this.checkIfOwned() || !isBlank(panel.data && panel.data.stems);
      case 'attachments':
        return this.checkIfOwned() || !isBlank(panel.data && panel.data.attachments);
      case 'tags':
        return !!(this.checkIfOwned() && panel.data && tracks.length > 0);
      case 'custom_fields':
        return !!this.checkIfOwned() || !get(panel.data, ['agents']);
      default:
        return this.checkIfOwned() || !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  getNestedHiddenFields = (prefix) => {
    const { hiddenFields } = this.props;
    // get fields by prefix and remove prefix from field name
    const nestedHiddenFields = hiddenFields.filter(field => startsWith(field, prefix))
      .map(field => field.split('.').filter((word, index) => index > 0).join('.'));
    return compact(nestedHiddenFields);
  };

  getInstruments = () => {
    const { tagCategories } = this.props;
    const instrumentCategory = tagCategories.find(category => category.key === 'instruments');
    const instruments = [];

    if (instrumentCategory) {
      instrumentCategory.subCategories.forEach(
        subCategory => instruments.push(...(subCategory.tags || [])),
      );
    }

    return instruments;
  };

  renderPlayerControls = () => {
    const { panel } = this.props;
    const isPlayable = !!(get(panel, ['data', 'audiofile', 'hd_mp3']));

    if (!isPlayable) return null;

    return <MusicItemControls trackDatas={panel.data} inheritColors />;
  };

  renderPanelTitle = () => {
    const { t, locale } = this.props;
    const { data } = this.props.panel;

    if (data && data.id) {
      const trackTitle = data.display_title || data.title;

      if (data.version) {
        return `${trackTitle} (${getName(data.version, locale)})`;
      }

      return trackTitle;
    }

    return t('components.panels.track.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;
    const player = this.renderPlayerControls();

    if (data && data.album && data.album.image.large) {
      imgLink = data.album.image.large.url;
    } else if (data && data.album && data.album.image.original) {
      imgLink = data.album.image.original.url;
    }

    if (player) {
      return (
        <PlayableCover>
          <PlayerContainer>{player}</PlayerContainer>
          {imgLink && <ImageLoader src={imgLink} />}
        </PlayableCover>
      );
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { data, tracksTags } = this.props.panel;
    const { tagCategories, locale } = this.props;

    const getTags = (tags) => {
      let tagsList = null;
      if (tags.length) {
        tagsList = tags.map((tag) => {
          // Find color
          let color;
          for (let c = 0, catLen = tagCategories.length; c < catLen; c += 1) {
            const category = tagCategories[c];
            if (find(category.tags, t => t.id === tag.id)) {
              ({ color } = category);
              break;
            }

            const { subCategories } = category;
            for (let s = 0, subLen = subCategories.length; s < subLen; s += 1) {
              const subCategory = subCategories[s];
              if (find(subCategory.tags, t => t.id === tag.id)) {
                ({ color } = category);
                break;
              }
            }
          }
          return (
            <Tag
              id={tag.id}
              color={color}
              key={tag.id}
              label={getName(tag, locale)}
              value={tag.id}
              status={2}
            />
          );
        });

        return (
          <Div height="100px" overflow="auto">
            {tagsList}
          </Div>
        );
      }

      return null;
    };

    if (data && data.tags) {
      const tags = data.tags.concat((tracksTags || []).map(t => t.tag));

      return (
        <Div height="100px" overflow="auto">
          {getTags(tags)}
        </Div>
      );
    }

    return null;
  };

  renderPanelActions = () => {
    const {
      canDownload,
      exportJson,
      panel: { data },
      panel,
      t,
      copyMetadata,
      pasteMetadata,
      copyPasteMetadataFields,
    } = this.props;
    const showMetadataActions = !isEmpty(copyPasteMetadataFields);

    return (
      <Flex direction="column">
        {data &&
        data.id && (
          <Action
            align="center"
          >
            <IconExportCsv inherit />
            <Div ml="4px">
              <Link
                to={`/print/multipart/${data.id}`}
                component={Action}
                align="center"
                target="_blank"
              >{t('components.print.openPrintableVersion')}
              </Link>
            </Div>
          </Action>
        )}
        {/* {data && */}
        {/*  data.id && ( */}
        {/*    <Action */}
        {/*      onClick={() => { */}
        {/*        downloadArchive('track', data.id); */}
        {/*      }} */}
        {/*      align="center" */}
        {/*    > */}
        {/*      <IconArchive inherit /> */}
        {/*      <Div ml="8px">{t('components.panels.track.downloadArchive')}</Div> */}
        {/*    </Action> */}
        {/*  )} */}
        {/* {data && */}
        {/*  data.id && ( */}
        {/*    <Action */}
        {/*      onClick={() => { */}
        {/*        exportCsv('track', data.id); */}
        {/*      }} */}
        {/*      align="center" */}
        {/*    > */}
        {/*      <IconExportCsv inherit /> */}
        {/*      <Div ml="4px">{t('components.panels.track.exportCsv')}</Div> */}
        {/*    </Action> */}
        {/*  )} */}
        {/* {data && */}
        {/*  data.id && ( */}
        {/*    <PlaylistAdder */}
        {/*      tracks={data.id} */}
        {/*      openText={t('components.panels.track.addToPlaylist')} */}
        {/*      closeText={t('components.panels.track.addToPlaylist')} */}
        {/*    /> */}
        {/*  )} */}
        {data &&
        data.id && (
          <Action
            onClick={() => {
              exportJson('multipart', data.id);
            }}
            align="center"
          >
            <IconExportCsv inherit />
            <Div ml="4px">{t('components.panels.track.exportJson')}</Div>
          </Action>
        )}
        {canDownload && (
          <div>
            {get(data, ['audiofile', 'hd_mp3']) && (
              <Action
                align="center"
                onClick={() => {
                  window.open(get(data, ['audiofile', 'hd_mp3']).url);
                }}
              >
                <IconText>MP3</IconText>
                <Div ml="4px">{t('components.panels.track.downloadMp3')}</Div>
              </Action>
            )}
            {get(data, ['audiofile', 'original']) && (
              <Action
                align="center"
                onClick={() => {
                  window.open(get(data, ['audiofile', 'original']).url);
                }}
              >
                <IconText>WAV</IconText>
                <Div ml="4px">{t('components.panels.track.downloadOriginal')}</Div>
              </Action>
            )}
          </div>
        )}
        {showMetadataActions && (
          <Fragment>
            {data && data.id && (
              <Action
                onClick={() => { copyMetadata(shortPanelName, data); }}
                align="center"
              >
                <IconCopy inherit />
                <Div ml="4px">{t('components.panels.track.copyMetadata')}</Div>
              </Action>
            )}
            {data && (
              <Action
                onClick={() => { pasteMetadata(shortPanelName, panel, copyPasteMetadataFields); }}
                align="center"
              >
                <IconPaste inherit />
                <Div ml="4px">{t('components.panels.track.pasteMetadata')}</Div>
              </Action>
            )}
          </Fragment>
        )}
      </Flex>
    );
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, locale, form, delaySave } = this.props;
    const readOnlyFields = ['CustomID'];
    const isReadOnlyField = readOnlyFields.includes(field.key);

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.track.false')}
            textTrue={t('components.panels.track.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned()
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() && !isReadOnlyField ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t, panel } = this.props;
    const { customFields } = this.props.user;

    let fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isMultipartCollection = c.collections.indexOf('multipart') >= 0;
        return !section
          ? isMultipartCollection
          : isMultipartCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!get(panel.data, ['agents'])) {
      fields = map(get(panel.data, ['custom']), (val, key) => ({ key, name: startCase(key), type: 'text' }));
    }

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.track.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      updateForm,
      updateAdditionalFormData,
      delaySave,
      deleteForm,
      canDelete,
      trackversions,
      territories,
      hiddenFields,
      showAdditionalNotes,
      descriptionAutocomplete,
      customFieldsToRightSide,
      neighboringrightssocieties,
      performingrightssocieties,
      mechanicalrightssocieties,
      agents,
      languages,
      rightstypes,
      t,
      locale,
      openSidePanel,
      canDownload,
      user,
    } = this.props;

    const { durationMinutes, durationSeconds } = this.state;

    function validateDurationInputKey(event) {
      const keyCode = get(event, ['keyCode']);

      // 69 - e | E
      if (keyCode && keyCode === 69) {
        event.preventDefault();
      }
    }

    function renderBpm() {
      if (panel.data && panel.data.bpm) {
        return (
          <Flex direction="column" mr="20px">
            <MaiaLabel>{t('components.panels.track.labelBpm')}</MaiaLabel>
            {Math.floor(panel.data.bpm)}
          </Flex>
        );
      }

      return null;
    }

    function renderDuration() {
      if (panel.data && panel.data.duration) {
        return (
          <Flex direction="column" mr="20px">
            <MaiaLabel>{t('components.panels.track.labelDuration')}</MaiaLabel>
            {getTrackDuration(panel.data.duration)}
          </Flex>
        );
      }

      return null;
    }

    function renderTempo() {
      if (panel.data && panel.data.tempo) {
        return (
          <Flex direction="column" mr="20px">
            <MaiaLabel>{t('components.panels.track.labelTempo')}</MaiaLabel>
            {getName(panel.data.tempo, locale)}
          </Flex>
        );
      }

      return null;
    }

    function renderTonalityKey() {
      if (panel.data && panel.data.tonality_key) {
        const value = `${panel.data.tonality_key.key} ${panel.data.tonality_key.tonality}`;
        return (
          <Flex direction="column" mr="20px">
            <MaiaLabel>{t('components.panels.track.labelTonalityKey')}</MaiaLabel>
            {value}
          </Flex>
        );
      }

      return null;
    }

    const tracksTags = panel.tracksTags || [];
    const tracks = panel.tracks || [];
    const trackAgents = form.values.agents || [];
    const listTrackVersions = panel.trackVersions || [];

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          title={this.renderPanelTitle()}
          closePanel={closePanel}
          actions={this.renderPanelActions()}
          panel={panel}
          content={this.renderPanelContent()}
          picture={this.renderPanelPic()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
          canDelete={canDelete}
        />
        <CollapsibleBox
          title={t('components.panels.album.boxTracks')}
          isOpen
          counter={tracks.length}
          show={this.isShown('tracks') && !this.isInHideList('tracks')}
        >
          <MultipartTracks
            canDownload={canDownload}
            trackListId={panel.data && panel.data.id}
            tracks={tracks}
            trackversions={trackversions}
            disabled={!this.checkIfOwned()}
            updateAdditionalFormData={updateAdditionalFormData}
            openSidePanel={openSidePanel}
            show={this.isShown('tracks')}
          />
        </CollapsibleBox>
        <PanelContent>
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.multipart.boxTrack')}
              isOpen
              show={this.isShown('track') && !this.isInHideList('track')}
            >
              <Input
                type="text"
                name="title"
                label={t('components.panels.track.labelTitle')}
                error={form.errors.title}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.title}
                show={this.isShown('title') && !this.isInHideList('title')}
              />
              <Input
                type="text"
                name="display_title"
                label={t('components.panels.track.labelDisplayTitle')}
                error={form.errors.display_title}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.display_title}
                show={this.isShown('display_title') && !this.isInHideList('display_title')}
              />
              <SubCategory show={this.isShown('duration') && !this.isInHideList('duration')}>
                {t('components.panels.track.duration')}
              </SubCategory>
              <Flex align="center" justify="space-between">
                <Input
                  type="number"
                  name="durationMinutes"
                  label={t('components.panels.track.duration_minutes')}
                  onChange={this.checkIfOwned() ? this.onStateDurationChange : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => {
                    delaySave(false);
                    if (this.checkIfOwned()) {
                      this.onDurationChange();
                    }
                  }}
                  width="48%"
                  show={this.isShown('duration') && !this.isInHideList('duration')}
                  value={durationMinutes}
                  onKeyDown={validateDurationInputKey}
                  getInputRef={(innerRef) => {
                    this.durationMinutesRef = innerRef;
                  }}
                />
                <Input
                  type="number"
                  name="durationSeconds"
                  label={t('components.panels.track.duration_seconds')}
                  onChange={this.checkIfOwned() ? this.onStateDurationChange : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => {
                    delaySave(false);
                    if (this.checkIfOwned()) {
                      this.onDurationChange();
                    }
                  }}
                  width="48%"
                  show={this.isShown('duration') && !this.isInHideList('duration')}
                  value={durationSeconds}
                  onKeyDown={validateDurationInputKey}
                  getInputRef={(innerRef) => {
                    this.durationSecondsRef = innerRef;
                  }}
                />
              </Flex>
              <SelectInput
                type="single"
                name="version"
                label={t('components.panels.track.labelVersion')}
                onChange={this.checkIfOwned() ? this.onVersionChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={trackversions.map(v => ({
                    id: v.id,
                    name: getName(v, locale),
                  }))}
                data={form.values.version}
                error={form.errors.version}
                search
                show={this.isShown('version') && !this.isInHideList('version')}
              />
              <DateInput
                name="release_date"
                date={form.values.release_date}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('release_date') && !this.isInHideList('release_date')}
              />
              <Input
                type="text"
                name="track_number"
                label={t('components.panels.track.labelTrackNumber')}
                error={form.errors.track_number}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.track_number}
                show={this.isShown('track_number') && !this.isInHideList('track_number')}
              />
              <ApiSelectInput
                name="album"
                parseName={e => e.title}
                label={t('components.panels.track.labelAlbum')}
                entity="meta/albums"
                panelName={ALBUM_PANEL}
                defaultField="title"
                type="single"
                data={panel.data && panel.data.album}
                values={form.values.album || null}
                error={form.errors.album}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('album') && !this.isInHideList('album')}
              />
              <Input
                type="text"
                name="track_ref"
                label={t('components.panels.track.labelInternalTrackReference')}
                error={form.errors.track_ref}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.track_ref}
                show={this.isShown('track_ref') && !this.isInHideList('track_ref')}
              />
              <SubCategory
                show={(
                  this.isShown('display_artists')
                  && !this.isInHideList('display_artists')
                )}
              >
                {t('components.panels.track.subCategoryDisplayArtist')}
              </SubCategory>
              <DisplayArtistInput
                name="display_artists"
                data={form.values.display_artists}
                error={form.errors.display_artists}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('display_artists') && !this.isInHideList('display_artists')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxVersions')}
              mt="5px"
              show={this.isShown('versions') && !this.isInHideList('versions')}
            >
              {listTrackVersions.map(trackVersion => (
                <ApiSelectInput
                  name="track"
                  parseName={e => `${e.title} (${getName(trackVersion.version, locale)})`}
                  label={t('components.panels.track.labelTrack')}
                  entity="meta/tracks"
                  panelName={TRACK_PANEL}
                  defaultField="title"
                  type="single"
                  data={trackVersion}
                  values={trackVersion.id}
                  onChange={null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  key={trackVersion.id}
                />
                ))}
              {!listTrackVersions.length && <p>{t('components.panels.track.noVersions')}</p>}
              <SelectInput
                type="single"
                name="version"
                label={t('components.panels.track.addVersion')}
                onChange={(name, value) => {
                    updateAdditionalFormData(
                      name,
                      value,
                      { url: 'versions', method: 'POST' },
                      { trackVersions: 'versions' },
                    );
                  }}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={trackversions
                    .filter(v => v.key !== 'original')
                    .map(v => ({ id: v.id, name: getName(v, locale) }))}
                data={null}
                search
                show={this.isShown('add_version') && !this.isInHideList('add_version')}
              />
              {this.renderCustomFields('versions')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxMasterInfo')}
              mt="5px"
              show={this.isShown('master_info') && !this.isInHideList('master_info')}
            >
              <SelectInput
                type="single"
                name="original_nrs"
                label={t('components.panels.track.labelOriginalNeighboringRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={neighboringrightssocieties.map(nrs => ({ id: nrs.id, name: nrs.name }))}
                data={form.values.original_nrs}
                error={form.errors.original_nrs}
                search
                show={this.isShown('original_nrs') && !this.isInHideList('original_nrs')}
              />
              <Input
                type="text"
                name="isrc"
                label={t('components.panels.track.labelIsrc')}
                error={form.errors.isrc}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.isrc}
                show={this.isShown('isrc') && !this.isInHideList('isrc')}
              />
              <SubCategory
                show={(
                  this.isShown('artists_master_ownerships')
                  && !this.isInHideList('artists_master_ownerships')
                )}
              >
                {t('components.panels.track.subCategoryArtistsMaster')}
              </SubCategory>
              <ArtistsMasterOwnerInput
                name="artists_master_ownerships"
                value={form.values.artists_master_ownerships}
                error={form.errors.artists_master_ownerships}
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('artists_master_ownerships')
                  && !this.isInHideList('artists_master_ownerships')
                )}
                instruments={this.getInstruments()}
                showAdditionalNotes={showAdditionalNotes}
                panelName={TRACK_PANEL}
                sessionToken={this.props.user.sessionToken}
                hiddenFields={this.getNestedHiddenFields('artists_master_ownerships')}
              />
              <SubCategory
                show={(
                  this.isShown('master_ownerships')
                  && !this.isInHideList('master_ownerships')
                )}
              >
                {t('components.panels.track.subCategoryLabels')}
              </SubCategory>
              <LabelsMasterOwnerInput
                name="master_ownerships"
                value={form.values.master_ownerships}
                error={form.errors.master_ownerships}
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('master_ownerships') && !this.isInHideList('master_ownerships')}
              />
              {this.renderCustomFields('master_info')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxPublishingInfo')}
              mt="5px"
              show={this.isShown('publishing_info') && !this.isInHideList('publishing_info')}
            >
              <SelectInput
                type="single"
                name="original_prs"
                label={t('components.panels.track.labelOriginalPerformingRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={performingrightssocieties.map(prs => ({ id: prs.id, name: prs.name }))}
                data={form.values.original_prs}
                error={form.errors.original_prs}
                search
                show={this.isShown('original_prs') && !this.isInHideList('original_prs')}
              />
              <SelectInput
                type="single"
                name="original_mrs"
                label={t('components.panels.track.labelOriginalMechanicalRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={mechanicalrightssocieties.map(mrs => ({ id: mrs.id, name: mrs.name }))}
                data={form.values.original_mrs}
                error={form.errors.original_mrs}
                search
                show={this.isShown('original_mrs') && !this.isInHideList('original_mrs')}
              />
              <Input
                type="text"
                name="iswc"
                label={t('components.panels.track.labelIswc')}
                error={form.errors.iswc}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.iswc}
                show={this.isShown('iswc') && !this.isInHideList('iswc')}
              />
              <Flex align="center" justify="space-between">
                <Input
                  type="text"
                  name="cocv"
                  label={t('components.panels.track.labelCocv')}
                  error={form.errors.cocv}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  value={form.values.cocv}
                  width="48%"
                  show={this.isShown('cocv') && !this.isInHideList('cocv')}
                />
                <Input
                  type="text"
                  name="prs_tunecode"
                  label={t('components.panels.track.labelPrsTunecode')}
                  error={form.errors.prs_tunecode}
                  onChange={this.checkIfOwned() ? updateForm : null}
                  onFocus={() => { delaySave(true); }}
                  onBlur={() => { delaySave(false); }}
                  value={form.values.prs_tunecode}
                  width="48%"
                  show={this.isShown('prs_tunecode') && !this.isInHideList('prs_tunecode')}
                />
              </Flex>
              <SubCategory
                show={(
                  this.isShown('artists_publishing_ownerships')
                  && !this.isInHideList('artists_publishing_ownerships')
                )}
              >
                {t('components.panels.track.subCategoryArtistsPublishing')}
              </SubCategory>
              <ArtistsPublishingOwnerInput
                name="artists_publishing_ownerships"
                value={form.values.artists_publishing_ownerships}
                error={form.errors.artists_publishing_ownerships}
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('artists_publishing_ownerships')
                  && !this.isInHideList('artists_publishing_ownerships')
                )}
                showAdditionalNotes={showAdditionalNotes}
                hiddenFields={this.getNestedHiddenFields('artists_publishing_ownerships')}
              />
              <SubCategory
                show={(
                  this.isShown('publishing_ownerships')
                  && !this.isInHideList('publishing_ownerships')
                )}
              >
                {t('components.panels.track.subCategoryPublishers')}
              </SubCategory>
              <PublishersPublishingOwnerInput
                name="publishing_ownerships"
                value={form.values.publishing_ownerships}
                error={form.errors.publishing_ownerships}
                disabled={!this.checkIfOwned()}
                territories={territories}
                rightstypes={rightstypes}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={(
                  this.isShown('publishing_ownerships')
                  && !this.isInHideList('publishing_ownerships')
                )}
              />
              {this.renderCustomFields('publishing_info')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxLyrics')}
              mt="5px"
              show={this.isShown('lyrics') && !this.isInHideList('lyrics')}
            >
              <SelectInput
                type="single"
                name="locale"
                label={t('components.panels.track.labelLyricsLanguage')}
                onChange={this.checkIfOwned() ? this.updateLyrics : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={Object.keys(languages).map(key => ({ id: key, name: languages[key].name }))}
                data={form.values.lyrics ? form.values.lyrics.locale : ''}
                error={form.errors.lyrics ? form.errors.lyrics.locale : []}
                search
                show={this.isShown('lyrics')}
              />
              <Textarea
                name="value"
                placeholder={t('components.panels.track.labelLyrics')}
                disabled={!this.checkIfOwned()}
                onChange={this.updateLyrics}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.lyrics ? form.values.lyrics.value : ''}
                error={form.errors.lyrics ? form.errors.lyrics.value : ''}
                show={this.isShown('lyrics')}
              />
              {this.renderCustomFields('lyrics')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxDistribution')}
              mt="5px"
              show={this.isShown('distribution') && !this.isInHideList('distribution')}
            >
              <TerritoriesSelect
                label={t('components.panels.track.labelAvailability')}
                name="territories"
                value={panel.data && panel.data.id && form.values.territories}
                territories={territories}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('distribution')}
              />
              <MultipleSelect
                label={t('components.panels.track.labelAgents')}
                displayText={{
                    one: t('components.panels.track.oneAgent'),
                    multiple: t('components.panels.track.multipleAgents'),
                    none: t('components.panels.track.noneAgents'),
                    all: t('components.panels.track.allAgents'),
                  }}
                name="agents"
                values={trackAgents.map(agent => ({
                    value: agent,
                    status: 2,
                  }))}
                data={agents.map(agent => ({
                    label: agent.company_name || `${agent.first_name} ${agent.last_name}`,
                    value: agent.id,
                  }))}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('distribution')}
              />
              {this.renderCustomFields('distribution')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.track.boxFiles')}
              mt="5px"
              show={this.isShown('files') && !this.isInHideList('files')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Audiofile"
                panel={panel}
                type="audiofile"
                file={get(panel, ['data', 'audiofile', 'original'])}
                hdMp3={get(panel, ['data', 'audiofile', 'hd_mp3'])}
                conversion_state={get(panel, ['data', 'audiofile', 'is_converting'])}
                show={this.isShown('audiofile') && !this.isInHideList('audiofile')}
              />
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Stems"
                panel={panel}
                type="stems"
                file={get(panel, ['data', 'stems', 'original'])}
                show={this.isShown('stems') && !this.isInHideList('stems')}
              />
              <AttachmentFileInput
                disabled={!this.checkIfOwned()}
                filetype="Attachments"
                panel={panel}
                type="attachments"
                files={get(panel, ['data', 'attachments'])}
                show={this.isShown('attachments') && !this.isInHideList('attachments')}
              />
              {this.renderCustomFields('files')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <SwitchInput
              name="public"
              label={t('components.panels.track.labelPublic')}
              onChange={updateForm}
              value={form.values.public}
              error={form.errors.public}
              textFalse={t('components.panels.track.publicFalse')}
              textTrue={t('components.panels.track.publicTrue')}
              show={this.isShown('public') && !this.isInHideList('public')}
            />
            <SwitchInput
              name="showcase"
              label={t('components.panels.track.labelShowcase')}
              onChange={updateForm}
              value={form.values.showcase}
              error={form.errors.showcase}
              textFalse={t('components.panels.track.showcaseFalse')}
              textTrue={t('components.panels.track.showcaseTrue')}
              show={this.isShown('showcase') && !this.isInHideList('showcase')}
            />
            <SwitchInput
              name="fingerprint"
              label={t('components.panels.track.fingerprint')}
              disabled
              onChange={noop}
              value={get(panel, ['data', 'fingerprint'])}
              textFalse={t('components.panels.track.fingerprintFalse')}
              textTrue={t('components.panels.track.fingerprintTrue')}
              show={this.isShown('fingerprint') && !this.isInHideList('fingerprint')}
            />
            <RatingInput
              name="rating"
              label={t('components.panels.track.labelRating')}
              onClick={updateForm}
              value={form.values.rating}
              error={form.errors.rating}
              mt="10px"
              show={this.isShown('rating') && !this.isInHideList('rating')}
            />
            <TranslateArea
              name="descriptions"
              label={t('components.panels.track.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              mt="10px"
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            <Flex justify="space-between" mt="20px">
              {renderDuration()}
              {renderTonalityKey()}
              {renderBpm()}
              {renderTempo()}
            </Flex>
            {customFieldsToRightSide && this.renderCustomFields()}
            <TagList
              data={tracksTags.map(trackTag => ({
                status: trackTag.status,
                value: trackTag.tag.id,
              }))}
              onChange={this.checkIfOwned() ? this.updateTags : null}
              show={this.isShown('tags') && !this.isInHideList('tags')}
              tagHideList={hiddenFields.filter(field => field.startsWith('categories'))}
            />
          </Div>
        </PanelContent>
      </PanelLoader>
    );
  }
}

function ownershipToRequestTransformer(ownerships) {
  /* eslint-disable camelcase */
  return (
    ownerships &&
    ownerships.map(
      ({
        artist,
        alias,
        label,
        publisher,
        rights_type,
        territories,
        mechanical_percentage,
        performing_percentage,
        ownership_percentage,
        sync_percentage,
        download_percentage,
        rent_percentage,
        chain_of_title,
        instruments,
        additional_notes,
      }) => ({
        artist: artist && artist.id,
        alias,
        label: label && label.id,
        publisher: publisher && publisher.id,
        rights_type: rights_type.id,
        territories,
        mechanical_percentage,
        performing_percentage,
        ownership_percentage,
        sync_percentage,
        download_percentage,
        rent_percentage,
        chain_of_title,
        instruments: instruments ? map(instruments, i => i.id || i) : undefined,
        additional_notes,
      }),
    )
  );
  /* eslint-enable camelcase */
}

export default formPanel(
  MultipartTrackSchema,
  {
    original_nrs: nrs => nrs.id,
    original_mrs: mrs => mrs.id,
    original_prs: prs => prs.id,
    album: album => album.id,
    original_track: track => track.id,
    version: version => version.id,
    tags: tags => tags.map(tag => tag.id),
    agents: agents => agents.map(agent => agent.id),
    display_artists: displayArtists =>
      displayArtists.map(da => ({
        full_name: da.artist.full_name,
        id: da.artist.id,
        aliases: da.artist.aliases,
        alias: da.alias,
      })),
  },
  {
    tracks: 'tracks',
    trackVersions: 'versions',
    tracksTags: 'tracks/tags',
  },
  null,
  (
    {
      display_artists, // eslint-disable-line camelcase
      artists_master_ownerships, // eslint-disable-line camelcase
      artists_publishing_ownerships, // eslint-disable-line camelcase
      publishing_ownerships, // eslint-disable-line camelcase
      master_ownerships, // eslint-disable-line camelcase
      ...values
    },
    panel,
  ) => {
    if (get(panel, 'data.owned_by_tenant') === false) {
      return {
        showcase: values.showcase,
        rating: values.rating,
      };
    }

    return {
      ...values,
      display_artists:
        // eslint-disable-next-line camelcase
        display_artists &&
        display_artists.map(({ id, alias }) => ({
          artist: id,
          alias,
        })),
      artists_master_ownerships: ownershipToRequestTransformer(artists_master_ownerships),
      artists_publishing_ownerships: ownershipToRequestTransformer(artists_publishing_ownerships),
      publishing_ownerships: ownershipToRequestTransformer(publishing_ownerships),
      master_ownerships: ownershipToRequestTransformer(master_ownerships),
    };
  },
)(withI18n()(MultipartTrack));
