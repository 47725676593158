import React, { Component } from 'react';
import PropTypes from 'prop-types';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// NOTE: if show property would collide, make it's naming dynamic i.e. by option
//   { key: "hideableShow" }

export default function hideable(WrappedComponent) {
  return class extends Component {
    static displayName = `Hideable(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      show: PropTypes.bool,
    };

    static defaultProps = {
      show: true,
    }

    render() {
      const { show, ...passThroughProps } = this.props;
      if (show === false) return null;
      return <WrappedComponent {...passThroughProps} />;
    }
  };
}
