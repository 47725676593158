// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import { Container, DeleteTag } from './Tag.styles';

// Assets
import iconCross from './../../../assets/images/icon-cross.svg';

// =============================
// Component
// =============================

class Tag extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    color: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    color: '',
    onChange: null,
    onDelete: null,
  };

  state = {};

  render() {
    const { value, id, label, status, color, onChange, onDelete } = this.props;

    return (
      <Container
        categoryColor={color}
        status={status}
        onClick={onChange ? () => { onChange(value); } : null}
      >
        {label}
        {onDelete &&
          <DeleteTag
            onClick={(e) => { e.stopPropagation(); onDelete(value, id); }}
            svg={iconCross}
            height="8px"
            width="8px"
          />
        }
      </Container>
    );
  }
}

export default Tag;
