// =============================
// Component
// =============================
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 600px;
  height: 40px;
  ${props => props.theme.media.uxga`
    width: 1150px;
  `};
`;

export const Input = styled.input`
  ${props => props.theme.fontSize.l};
  background-color: ${props => props.theme.colors.background};
  border: 0;
  border-bottom: 1px solid #ccc;
  color: ${props => props.theme.colors.text.greyDark};
  height: 100%;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 115px;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  &::-webkit-input-placeholder {
    color: ${props => props.theme.colors.text.grey};
    opacity: 1;
  }

  &::-moz-placeholder {
    color: ${props => props.theme.colors.text.grey};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${props => props.theme.colors.text.grey};
    opacity: 1;
  }

  &:-moz-placeholder {
    color: ${props => props.theme.colors.text.grey};
    opacity: 1;
  }
`;
export const SearchIcons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  top: 6px;
  width: 65px;
`;

export const SearchHelpers = styled.div`
  ${props => props.theme.fontSize.s};
  ${props => props.theme.fonts.openSansSemiBold};
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
`;

export const Helper = styled.span`
  color: ${props => props.theme.colors.text.grey};
  cursor: pointer;
  transition: color ${props => props.theme.transition.medium};

  &:hover {
    color: ${props => props.theme.colors.text.greyDark};
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const MaiaNotice = styled.div`
  color: ${props => props.theme.colors.primary};
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const Faded = styled.strong`
  color: #888;
  font-style: italic;
`;
