import { useAzureB2C } from '@bmat/auth-feature-azure-b2c-react';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { HOME_PAGE_ROUTE, LOGIN, LOGOUT } from '../../../constants/Routes';
import { getCookieConfig } from '../../../helpers/helpers';
import { getBmatUserData } from '../../../actions/AuthActions';
import Loader from './../../presentationals/loader/Loader';
// Configs
import env from './../../../../config/private/environment';

export function BaseLogin(props) {
  const auth = useAzureB2C();
  const { t } = useTranslation();
  const [redirectHandled, setRedirectHandled] = useState(null);

  useEffect(() => {
    cookies.remove('x-auth', getCookieConfig(null, true));
    auth.handleRedirectCallback()
      .then(page => auth
        .getTokenSilently([env.bmatAuthScope])
        .then((bmatToken) => {
          props.getBmatUserData(bmatToken.accessToken);
          setRedirectHandled(page && page !== LOGIN && page !== LOGOUT ? page : HOME_PAGE_ROUTE);
        }))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  return redirectHandled == null ? (
    <Suspense fallback={<Loader fullscreen text="Loading" />}>
      <Loader fullscreen text={t('components.initializer.loading', 'Loading')} />
    </Suspense>
    ) : <Redirect to={redirectHandled} />;
}

BaseLogin.propTypes = {
  getBmatUserData: PropTypes.func.isRequired,
};

export const Login = connect(
  () => ({}),
  { getBmatUserData },
)(BaseLogin);
