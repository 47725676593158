// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

// Constants
import { ENABLED_TYPES } from './../../../constants/EntityGrid';

// Helpers
import { getName } from './../../../helpers/I18n';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import BatchDeleter from './../../containers/batchdeleter/BatchDeleterContainer';
import SearchEntityGrid from './../../presentationals/entitygrid/SearchEntityGrid';
import MusicItemControls from './../../containers/musicitem/controls/MusicItemControlsContainer';
import Waveform from './../../containers/waveform/WaveformContainer';
import TagsChunk from './../../presentationals/tagschunk/TagsChunk';
import RatingInput from './../../presentationals/inputs/ratinginput/RatingInput';
import PlaylistAdderDropdown from './../../containers/playlistadder/PlaylistAdderDropdownContainer';

// Styles
import { Item, SubItem } from './../../presentationals/dropdownlist/DropdownList.styles';
import { IconMagicWand, IconEdit, IconExportJson, IconPlaylist } from './../../../themes/icons';
import { Div, Flex } from './../../../themes/views';

// Constants
import {
  TRACK_PANEL,
  BATCH_TRACKS_PANEL,
  ARTIST_PANEL,
  LABEL_PANEL,
  PUBLISHER_PANEL,
} from './../../../constants/SidePanelTypes';

import { TRACK } from './../../../constants/ExportBulkJsonTypes';

// Renderers
import renderName from './../../presentationals/entitygrid/renderers/renderName';
import renderImg from './../../presentationals/entitygrid/renderers/renderImg';
import renderHighlight from './../../presentationals/entitygrid/renderers/renderHighlight';
import renderDescriptions from './../../presentationals/entitygrid/renderers/renderDescriptions';
import renderArtists from './../../presentationals/entitygrid/renderers/renderArtists';
import renderCollection from './../../presentationals/entitygrid/renderers/renderCollection';
import renderYear from './../../presentationals/entitygrid/renderers/renderYear';
import renderDate from './../../presentationals/entitygrid/renderers/renderDate';
import renderBoolean from './../../presentationals/entitygrid/renderers/renderBoolean';
import render3V from './../../presentationals/entitygrid/renderers/render3V';

// =============================
// Helpers
// =============================

export function getQueryParams() {
  const pl = /\+/g;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(pl, ' '));

  const query = window.location.search.substring(1);
  const urlParams = new Map();
  for (let match = search.exec(query); match !== null; match = search.exec(query)) {
    urlParams.set(decode(match[1]), decode(match[2]));
  }

  return urlParams;
}

// =============================
// Component
// =============================
const { NO, ALWAYS } = ENABLED_TYPES;

class TracksPage extends Component {
  static propTypes = {
    tagCategories: PropTypes.array.isRequired, // eslint-disable-line
    openSidePanel: PropTypes.func.isRequired,
    searchByTrack: PropTypes.func.isRequired,
    linkTracks: PropTypes.func.isRequired,
    exportBulkJson: PropTypes.func.isRequired,
    canExportBulkJson: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    searchData: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      sort: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.array,
      nbPages: PropTypes.number,
      nbResults: PropTypes.number,
      currentPage: PropTypes.number,
      isChangingPage: PropTypes.bool,
    }).isRequired,
    preferences: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    search: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    onPageChange: PropTypes.func,
    onUpdatePreferences: PropTypes.func,

    agents: PropTypes.array.isRequired, // eslint-disable-line
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    preferences: {},
    search: {
      query: '',
      isLoading: false,
      job: undefined,
    },
    onPageChange: () => {},
    onUpdatePreferences: () => {},
  };

  constructor(props) {
    super(props);
    const { openSidePanel, agents, t, locale } = props;
    this.columns = [
      {
        key: 'audiofile',
        name: '',
        enabled: ALWAYS,
        render: this.renderControls,
        HeadColumnComponent: () => <Div width="50px" />,

        pl: '0',
        width: '50px',
        minWidth: '50px',
        height: '50px',
        overflow: 'hidden',
        position: 'relative',

        justify: 'center',
      },
      {
        key: 'search_by_track',
        name: '',
        enabled: ALWAYS,
        render: this.renderSearchByTrack,

        overflow: 'hidden',
        pl: '0',
        width: '40px',
        minWidth: '40px',
        justify: 'center',
      },
      {
        key: 'title',
        sortKey: 'track',
        highlightKey: 'title',
        name: t('components.pages.tracks.title'),
        enabled: ALWAYS,
        sortable: true,
        render: renderName(id => openSidePanel(TRACK_PANEL, id)),

        width: '320px',
      },
      /*
      {
        key: 'waveform',
        name: t('components.pages.tracks.waveform'),
        render: this.renderWaveform,

        width: '110px',
      },
      */
      {
        key: 'catalog',
        name: t('components.pages.tracks.catalog'),
        sortable: true,
        render: renderHighlight(({ object }) => get(object, 'catalog.name', '')),

        width: '140px',
      },
      {
        key: 'version',
        name: t('components.pages.tracks.version'),
        render: renderHighlight(({ value }) => getName(value, locale)),

        width: '120px',
      },
      {
        key: 'duration',
        name: t('components.pages.tracks.duration'),
        enabled: NO,
        render: this.renderDuration,

        width: '110px',
      },
      {
        key: 'tags',
        name: t('components.pages.tracks.tags'),
        enabled: NO,
        render: this.renderTags,

        width: '250px',
      },
      {
        key: 'descriptions',
        highlightKey: 'descriptions',
        name: t('components.pages.tracks.descriptions'),

        enabled: NO,
        render: renderDescriptions(locale),

        width: '350px',
      },
      {
        key: 'album',
        name: t('components.pages.tracks.album'),
        enabled: NO,
        sortable: true,
        render: renderHighlight(({ value }) => value && value.title),

        width: '150px',
      },
      {
        key: 'track_number',
        name: t('components.pages.tracks.trackNumber'),
        enabled: NO,

        width: '110px',
        justify: 'center',
      },
      {
        key: 'display_artists',
        name: t('components.pages.tracks.displayArtists'),
        enabled: NO,
        render: renderArtists(({ value }) => openSidePanel(ARTIST_PANEL, value.artist.id)),

        width: '350px',
      },
      {
        key: 'performers',
        name: t('components.pages.tracks.performers'),
        enabled: NO,
        render: this.renderMasterOwnershipAprtists('main-performer'),

        width: '350px',
      },
      {
        key: 'feats',
        name: t('components.pages.tracks.feats'),
        enabled: NO,
        render: this.renderMasterOwnershipAprtists('featuring-artist'),

        width: '350px',
      },
      {
        key: 'remixers',
        name: t('components.pages.tracks.remixers'),
        enabled: NO,
        render: this.renderMasterOwnershipAprtists('remixer'),

        width: '350px',
      },
      {
        key: 'authors',
        name: t('components.pages.tracks.authors'),
        enabled: NO,
        render: this.renderPublishingOwnershipAprtists('author'),

        width: '350px',
      },
      {
        key: 'composers',
        name: t('components.pages.tracks.composers'),
        enabled: NO,
        render: this.renderPublishingOwnershipAprtists('composer'),

        width: '350px',
      },
      {
        key: 'arrangers',
        name: t('components.pages.tracks.arrangers'),
        enabled: NO,
        render: this.renderPublishingOwnershipAprtists('arranger'),

        width: '350px',
      },
      {
        key: 'release_date',
        name: t('components.pages.tracks.releaseDate'),
        enabled: NO,
        sortable: true,
        render: renderYear(),

        width: '130px',
      },
      {
        key: 'created_at',
        name: t('components.pages.tracks.createdAt'),
        sortable: true,
        render: renderDate(),

        width: '135px',
      },
      {
        key: 'ingested_at',
        name: t('components.pages.tracks.ingestedAt'),
        sortable: true,
        render: renderDate(),

        width: '135px',
      },
      {
        key: 'lyrics',
        name: t('components.pages.tracks.lyrics'),
        enabled: NO,
        highlightKey: 'lyrics',
        render: renderHighlight(({ value }) => (value && value.value ? value.value : '')),

        width: '110px',
      },
      {
        key: 'tonality_key',
        name: t('components.pages.tracks.tonalityKey'),
        enabled: NO,
        render: renderHighlight(({ value }) => (value ? `${value.key} ${value.tonality}` : '')),

        width: '110px',
      },
      {
        key: 'bpm',
        name: t('components.pages.tracks.bpm'),
        enabled: NO,
        render: bpm => parseInt(bpm, 10) || '',

        width: '110px',
      },
      {
        key: 'tempo',
        name: t('components.pages.tracks.tempo'),
        enabled: NO,
        render: renderHighlight(({ value }) => getName(value, locale)),

        width: '110px',
      },
      {
        key: 'rating',
        name: t('components.pages.tracks.rating'),
        enabled: NO,
        sortable: true,
        render: (_, track) => <RatingInput name={track.id} value={track.rating} />,

        width: '200px',
      },
      {
        key: 'isrc',
        highlightKey: 'isrc',
        name: t('components.pages.tracks.isrc'),
        enabled: NO,
        render: renderHighlight(),

        width: '130px',
      },
      {
        key: 'iswc',
        highlightKey: 'iswc',
        name: t('components.pages.tracks.iswc'),
        enabled: NO,
        render: renderHighlight(),

        width: '130px',
      },
      {
        key: 'labels',
        name: t('components.pages.tracks.labels'),
        enabled: NO,
        render: this.renderLabels,

        width: '350px',
      },
      {
        key: 'publishers',
        name: t('components.pages.tracks.publishers'),
        enabled: NO,
        render: this.renderPublishers,

        width: '350px',
      },
      {
        key: 'has_file',
        name: t('components.pages.tracks.hasFile'),
        sortable: true,
        render: renderBoolean(({ object }) => !!(object.audiofile && object.audiofile.original)),

        width: '100px',
        justify: 'center',
      },
      {
        key: 'public',
        name: t('components.pages.tracks.public'),
        sortable: true,
        render: renderBoolean(),

        width: '130px',
        justify: 'center',
      },
      {
        key: 'showcase',
        name: t('components.pages.tracks.showcase'),
        sortable: true,
        render: renderBoolean(),

        width: '130px',
        justify: 'center',
      },
      {
        key: 'agents',
        name: t('components.pages.tracks.agents'),
        sortable: true,
        sortKey: 'distribution',
        render: render3V(agents),

        width: '120px',
        justify: 'center',
      },
      {
        key: 'fingerprint',
        name: t('components.pages.tracks.fingerprint'),
        sortable: true,
        render: renderBoolean(),

        width: '120px',
        justify: 'center',
      },
      {
        key: 'music_work_type',
        name: t('components.pages.tracks.musicWorkType'),
        enabled: NO,
        render: renderHighlight(({ object }) =>
          object.custom && object.custom.MusicWorkType ? object.custom.MusicWorkType : '',
        ),

        width: '150px',
        justify: 'center',
      },
      {
        key: 'music_usage',
        name: t('components.pages.tracks.musicUsage'),
        enabled: NO,
        render: renderHighlight(({ object }) =>
          object.custom && object.custom.MusicUsage ? object.custom.MusicUsage : '',
        ),

        width: '120px',
        justify: 'center',
      },
    ];
  }

  state = {
    playlistDropdownIsOpen: false,
  };

  componentDidMount() {
    if (getQueryParams().get('track')) {
      this.props.openSidePanel(TRACK_PANEL, getQueryParams().get('track'));
    }
  }

  handleAddEntity = () => this.props.openSidePanel(TRACK_PANEL);
  handleUpdateEnabledColumns = enabledColumns => this.props.onUpdatePreferences({ enabledColumns });

  handleOpenPlaylistDropdown = () => this.setState(() => ({ playlistDropdownIsOpen: true }));
  handleClosePlaylistDropdown = () => this.setState(() => ({ playlistDropdownIsOpen: false }));

  getFileState = track => !!track.audiofile.hd_mp3;

  renderControls = (hdMp3, track) => {
    const { searchData } = this.props;

    // const hasImage = !!(track.album && track.album.image);
    if (!this.getFileState(track)) return null;

    const tracklistId = searchData.data.map(t => t.id).join('-');
    return [
      renderImg({ underlay: this.getFileState(track) })(undefined, track.album),
      this.getFileState(track) && (
        <Div zIndex="1" key="controls" position="absolute">
          <MusicItemControls
            trackDatas={track}
            tracklistId={tracklistId}
            tracklistDatas={{ tracks: searchData.data.filter(t => !!t.audiofile.hd_mp3) }}
            white={this.getFileState(track)}
          />
        </Div>
      ),
    ];
  };

  renderSearchByTrack = (_, track) => {
    const { searchByTrack } = this.props;
    if (!track.maia_id || !this.getFileState(track)) return null;
    return (
      <IconMagicWand
        onClick={() => {
          searchByTrack(track);
        }}
      />
    );
  };

  renderDuration = (duration) => {
    if (!duration) return null;
    let sec = parseInt(duration % 60, 10);
    let min = Math.floor(duration / 60);

    if (sec < 10) sec = `0${sec}`;
    if (min < 10) min = `0${min}`;

    return `${min}:${sec}`;
  };

  renderWaveform = (waveform, track) => {
    const { searchData } = this.props;
    const waveformLink = waveform ? waveform.small || waveform.large || null : null;

    const tracklistId = searchData.data.map(t => t.id).join('-');

    return (
      waveformLink && (
        <Waveform
          trackDatas={track}
          tracklistId={tracklistId}
          tracklistDatas={{ tracks: searchData.data.filter(t => !!t.audiofile.hd_mp3) }}
          alt="waveform"
          svg={waveformLink.url}
          width="90px"
        />
      )
    );
  };

  renderTags = (tags) => {
    const { tagCategories } = this.props;
    return <TagsChunk tagCategories={tagCategories} tags={tags} />;
  };

  getOwnershipsByKey = (collection, key) =>
    collection.filter(
      o => o.rights_type.key === key || isEqual(o.rights_type.key, [`<em>${key}</em>`]),
    );

  renderMasterOwnershipAprtists = key => (_, object) => {
    const { openSidePanel } = this.props;
    const artists = this.getOwnershipsByKey(object.artists_master_ownerships, key);
    return renderArtists(({ value }) => openSidePanel(ARTIST_PANEL, value.artist.id))(artists);
  };

  renderPublishingOwnershipAprtists = key => (_, object) => {
    const { openSidePanel } = this.props;
    const artists = this.getOwnershipsByKey(object.artists_publishing_ownerships, key);
    return renderArtists(({ value }) => openSidePanel(ARTIST_PANEL, value.artist.id))(artists);
  };

  renderLabels = (_, object) => {
    const { openSidePanel } = this.props;
    const labels = this.getOwnershipsByKey(object.master_ownerships, 'label');
    return renderCollection(
      ({ value }) => value.label.label_name,
      ({ value }) => openSidePanel(LABEL_PANEL, value.label.id),
    )(labels);
  };

  renderPublishers = (_, object) => {
    const { openSidePanel } = this.props;
    const publishers = this.getOwnershipsByKey(object.publishing_ownerships, 'publisher');
    return renderCollection(
      ({ value }) => value.publisher.publisher_name,
      ({ value }) => openSidePanel(PUBLISHER_PANEL, value.publisher.id),
    )(publishers);
  };

  renderBatchDropdownItems = (items, { onSubmit }) => {
    const {
      openSidePanel,
      linkTracks,
      canExportBulkJson,
      exportBulkJson,
      canDelete,
      t,
    } = this.props;
    const { playlistDropdownIsOpen } = this.state;
    //
    return [
      <Item
        onClick={() => {
          openSidePanel(BATCH_TRACKS_PANEL, items.map(item => item.id));
          onSubmit();
        }}
        key="0"
      >
        <IconEdit noHover size="18px" />
        <Flex align="center" ml="7px">
          {t('components.pages.tracks.batchEdit')}
        </Flex>
      </Item>,
      <Item
        onClick={() => {
          linkTracks(items.map(item => item.id));
          onSubmit();
        }}
        key="1"
      >
        <IconEdit noHover size="18px" />
        <Flex align="center" ml="7px">
          {t('components.pages.tracks.batchLink')}
        </Flex>
      </Item>,
      <Item
        padding="0"
        key="2"
        onMouseEnter={this.handleOpenPlaylistDropdown}
        onMouseLeave={this.handleClosePlaylistDropdown}
      >
        <SubItem>
          <PlaylistAdderDropdown
            trackIds={items.map(item => item.id)}
            isOpen={playlistDropdownIsOpen}
            verticalPosition="verytop"
          />
        </SubItem>
        <Flex align="center" px="10px" height="50px" width="100%">
          <IconPlaylist size="18px" noHover />
          <Flex align="center" ml="7px">
            {t('components.pages.tracks.batchAddToPlaylist')}
          </Flex>
        </Flex>
      </Item>,
      canExportBulkJson && (
        <Item
          onClick={() => {
            exportBulkJson(TRACK, items.map(item => item.id));
            onSubmit();
          }}
          key="3"
        >
          <IconExportJson noHover size="18px" />
          <Flex align="center" ml="7px">
            {t('components.pages.tracks.batchExportJson')}
          </Flex>
        </Item>
      ),
      canDelete && (
        <Item padding="0" key="4">
          <BatchDeleter
            ids={items.map(item => item.id)}
            entity="tracks"
            resetSelection={onSubmit}
            label={t('components.pages.tracks.batchDelete')}
            height="50px"
            px="10px"
          />
        </Item>
      ),
    ];
  };

  renderEmpty = () => {
    const { search, t } = this.props;

    if (search.query) {
      return <p>{t('components.pages.tracks.noSearchResults')}</p>;
    }

    if (get(search, 'job.type')) {
      return [
        <p key="1">{t('components.pages.tracks.noMaiaResults')}</p>,
        <p key="2">{t('components.pages.tracks.noMaiaResults2')}</p>,
      ];
    }

    return [
      <p key="1">{t('components.pages.tracks.noResults')}</p>,
      <p key="2">{t('components.pages.tracks.noResults2')}</p>,
    ];
  };

  render() {
    const {
      searchData: { data, currentPage, nbPages, sort },
      preferences: { enabledColumns },
      search,
      onPageChange,
      t,
    } = this.props;

    return (
      <Div>
        <Helmet title={t('components.pages.tracks.pageTitle')} />
        <SearchEntityGrid
          sort={sort}
          value={data}
          isLoading={search.isLoading}
          columns={this.columns}
          enabledColumns={enabledColumns}
          page={currentPage}
          pageCount={nbPages}
          onAddEntity={this.handleAddEntity}
          onUpdateEnabledColumns={this.handleUpdateEnabledColumns}
          onGet={onPageChange}
          renderBatchDropdownItems={this.renderBatchDropdownItems}
          renderEmpty={this.renderEmpty}
        />
      </Div>
    );
  }
}

export default withI18n()(TracksPage);
