// External Dependencies
import React from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import { Div, Flex } from '../../../../themes/views';
import { Button } from '../../buttons/buttonprimary/ButtonPrimary.styles';
import DateFilterTypeInput from './DateFilterTypeInput';
import DateInput from './DateInput';

// =============================
// Component
// =============================

const SearchBarFilterForm = ({ onSubmit, data, t }) => (
  <Formik
    enableReinitialize
    initialValues={data}
    onSubmit={(values, actions) => {
      setTimeout(() => {
        onSubmit(values);
        actions.setSubmitting(false);
      }, 1000);
    }}
  >{() => (
    <Form>
      <Div padding="16px">
        <Div>{t('components.searchBar.filter.dateFilter.label')}</Div>
        <Flex align="center" justify="space-between" mt="8px">
          <Field name="dateFilter.dateFilter" component={DateFilterTypeInput} />
          <Flex justify="space-between">
            <Field name="dateFilter.startedAt" type="date" component={DateInput} />
            <Field name="dateFilter.endedAt" type="date" component={DateInput} />
          </Flex>
        </Flex>
      </Div>
      <Flex justify="flex-end" padding="16px">
        <Button minWidth="76px" height="32px" type="submit">
          {t('components.searchBar.filter.submit')}
        </Button>
      </Flex>
    </Form>
  )}
  </Formik>
);

SearchBarFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
  t: PropTypes.func.isRequired,
};

export default withI18n()(SearchBarFilterForm);
