// =============================
// Imports
// =============================

// Constants
import {
  SET_INGESTION,
  SET_PAGE,
  //
  SET_INGESTIONS,
  //
  GET_INGESTIONS_LOADING,
  GET_INGESTIONS_SUCCESS,
  GET_INGESTIONS_FAILURE,
  //
  CREATE_INGESTION_LOADING,
  CREATE_INGESTION_SUCCESS,
  CREATE_INGESTION_FAILURE,
  //
  INGESTION_UPLOAD_FILE_LOADING,
  INGESTION_UPLOAD_FILE_SUCCESS,
  INGESTION_UPLOAD_FILE_FAILURE,
  INGESTION_UPLOAD_FILE_PROGRESS,
  INGESTION_UPLOAD_FILE_RESET,
  //
  SET_BINARIES_LOADING,
  SET_BINARIES_SUCCESS,
  SET_BINARIES_FAILURE,
  //
  SET_TAG_MATCHES_LOADING,
  SET_TAG_MATCHES_SUCCESS,
  SET_TAG_MATCHES_FAILURE,
  //
  GENERATE_CSV_TEMPLATE_LOADING,
  GENERATE_CSV_TEMPLATE_SUCCESS,
  GENERATE_CSV_TEMPLATE_FAILURE,
} from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  page: 'list',
  ingestion: undefined,

  collection: [],
  limit: 0,
  offset: 0,
  total: 0,

  getIsLoading: false,
  getCancelToken: undefined,

  createIsLoading: false,
  createCancelToken: undefined,

  uploadFileIsLoading: false,
  uploadFileCancelToken: undefined,
  uploadFileProgress: 0,
  uploadState: 'initial',

  setBinariesIsLoading: false,
  setBinariesCancelToken: undefined,

  setTagMatchesIsLoading: false,
  setTagMatchesCancelToken: undefined,

  generateCsvTemplateIsLoading: false,
  generateCsvTemplateCancelToken: undefined,
};

// =============================
// Reducer
// =============================

export default function ingestions(state = initialState, action) {
  switch (action.type) {
    case SET_INGESTION: {
      return {
        ...state,
        ingestion: action.payload,
      };
    }

    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case SET_INGESTIONS: {
      return {
        ...state,
        collection: action.payload.results,
        limit: action.payload.limit,
        offset: action.payload.offset,
        total: action.payload.total,
      };
    }

    case GET_INGESTIONS_LOADING:
      return {
        ...state,
        getIsLoading: true,
        getCancelToken: action.payload,
      };

    case GET_INGESTIONS_SUCCESS:
    case GET_INGESTIONS_FAILURE:
      return {
        ...state,
        getIsLoading: false,
      };

    case CREATE_INGESTION_LOADING:
      return {
        ...state,
        createIsLoading: true,
        createCancelToken: action.payload,
      };

    case CREATE_INGESTION_SUCCESS:
    case CREATE_INGESTION_FAILURE:
      return {
        ...state,
        createIsLoading: false,
        createCancelToken: null,
      };

    case INGESTION_UPLOAD_FILE_LOADING:
      return {
        ...state,
        uploadFileIsLoading: true,
        uploadFileCancelToken: action.payload,
        uploadFileProgress: 0,
        uploadState: 'loading',
      };

    case INGESTION_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFileIsLoading: false,
        uploadFileCancelToken: null,
        uploadState: 'success',
      };

    case INGESTION_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFileIsLoading: false,
        uploadFileCancelToken: null,
        uploadState: 'failure',
        uploadError: action.payload,
      };

    case INGESTION_UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        uploadFileProgress: action.payload,
      };

    case INGESTION_UPLOAD_FILE_RESET:
      return {
        ...state,
        uploadFileIsLoading: false,
        uploadFileCancelToken: undefined,
        uploadFileProgress: 0,
        uploadState: 'initial',
      };

    case SET_BINARIES_LOADING:
      return {
        ...state,
        setBinariesIsLoading: true,
        setBinariesCancelToken: action.payload,
      };

    case SET_BINARIES_SUCCESS:
    case SET_BINARIES_FAILURE:
      return {
        ...state,
        setBinariesIsLoading: false,
        setBinariesCancelToken: null,
      };

    case SET_TAG_MATCHES_LOADING:
      return {
        ...state,
        setTagMatchesIsLoading: true,
        setTagMatchesCancelToken: action.payload,
      };

    case SET_TAG_MATCHES_SUCCESS:
    case SET_TAG_MATCHES_FAILURE:
      return {
        ...state,
        setTagMatchesIsLoading: false,
        setTagMatchesCancelToken: null,
      };

    case GENERATE_CSV_TEMPLATE_LOADING:
      return {
        ...state,
        setTagMatchesIsLoading: true,
        setTagMatchesCancelToken: action.payload,
      };

    case GENERATE_CSV_TEMPLATE_SUCCESS:
    case GENERATE_CSV_TEMPLATE_FAILURE:
      return {
        ...state,
        setTagMatchesIsLoading: false,
        setTagMatchesCancelToken: null,
      };

    default:
      return state;
  }
}
