// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import DropdownList from './../dropdownlist/DropdownList';
import DropdownCheckbox from './../dropdownlist/dropdowncheckbox/DropdownCheckbox';

// =============================
// Component
// =============================

class PlaylistAdderDropdown extends Component {
  static propTypes = {
    playlists: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    values: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    isOpen: PropTypes.bool,
    direction: PropTypes.string,
    verticalPosition: PropTypes.string,

    onChange: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    playlists: [],
    values: [],
    isOpen: false,
    verticalPosition: 'top',
    direction: 'right',

    onChange: () => {},
    onClose: () => {},
  };

  state = {};

  render() {
    const {
      playlists,
      values,
      isOpen,
      direction,
      verticalPosition,
      onChange,
      onClose,
    } = this.props;

    return (
      <DropdownList
        handleClickOutside={onClose}
        outsideClickIgnoreClass="playlistAdder"
        isOpen={isOpen}
        pt="0"
        overflow="hidden"
        verticalPosition={verticalPosition}
        direction={direction}
        width="auto"
        minWidth="220px"
      >
        <DropdownCheckbox
          height="200px"
          name="playlist"
          values={values.map(id => ({
            value: id,
            status: 2,
          }))}
          data={playlists.map(playlist => ({
            label: playlist.title,
            value: playlist.id,
          }))}
          search={false}
          selectAll={false}
          onChange={onChange}
        />
      </DropdownList>
    );
  }
}

export default PlaylistAdderDropdown;
