// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import hideable from './../../../hoc/Hideable';

// Components
import OwnerInput from './core/OwnerInput';
import MasterPercentageBase from './percentage/MasterPercentage';
import OwnerArtistInput from './input/OwnerArtistInput';

// =============================
// Set up
// =============================

const MasterPercentage = hideable(MasterPercentageBase);

// =============================
// Component
// =============================

class ArtistsMasterOwnerInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.array, // eslint-disable-line
    error: PropTypes.array, // eslint-disable-line
    disabled: PropTypes.bool,
    territories: PropTypes.array, // eslint-disable-line
    rightstypes: PropTypes.array, // eslint-disable-line
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    instruments: PropTypes.array, // eslint-disable-line
    showAdditionalNotes: PropTypes.bool,
    panelName: PropTypes.string.isRequired,
    sessionToken: PropTypes.string.isRequired,
    hiddenFields: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    value: [],
    error: [],
    disabled: false,
    territories: [],
    rightstypes: [],
    instruments: [],
    showAdditionalNotes: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
    hiddenFields: [],
  };

  state = {};

  render() {
    const { rightstypes, ...props } = this.props;
    return (
      <OwnerInput
        PercentageComponent={MasterPercentage}
        OwnerInputComponent={OwnerArtistInput}
        rightstypes={rightstypes.filter(v => v.type === 'master-artist')}
        {...props}
      />
    );
  }
}

export default ArtistsMasterOwnerInput;
