// =============================
// Imports
// =============================

// External Dependencies
import 'rc-time-picker/assets/index.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import defaults from 'lodash/defaults';
import debounce from 'lodash/debounce';
import Joi from 'joi-browser';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

// Constants
import { TRACK_PANEL, ARTIST_PANEL } from './../../../../constants/SidePanelTypes';

// Helpers
import { getName } from './../../../../helpers/I18n';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import MusicItemControls from './../../../containers/musicitem/controls/MusicItemControlsContainer';
import Chip from './../../chip/Chip';
import SelectInput from '../../inputs/selectinput/SelectInput';
import Input from '../../inputs/inputunderline/InputUnderline';
import ApiSelectInput from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Styles
import { Div, Flex } from './../../../../themes/views';
import { GridRow, HeadColumn, MediaColumn, Column } from './../../../../themes/entityGrid';
import { IconTrash, Circle, IconText } from './../../../../themes/icons';
import './MultipartTracks.css';


// =============================
// Component
// =============================

class MultipartTracks extends Component {
  static propTypes = {
    tracks: PropTypes.array, // eslint-disable-line
    tracklistId: PropTypes.string,
    trackversions: PropTypes.array.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    openSidePanel: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    canDownload: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tracks: [],
    tracklistId: null,
    disabled: false,
  };

  constructor(props) {
    super(props);
    const sorted = sortBy(props.tracks, [
      t => get(t, ['custom', 'disc_number']),
      t => (t.track_number > 0 ? Number(t.track_number) : Infinity),
    ]);
    this.state = {
      originalTracks: sorted,
        errors: {
        trackNumbers: {},
        timeRange: {},
        custom: {
          discNumbers: {},
        },
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.props.tracks, nextProps.tracks)) return;
    if (isEqual(this.state.tracks, nextProps.tracks)) return;

    const sorted = sortBy(nextProps.tracks, [
      t => get(t, ['custom', 'disc_number']),
      t => (t.track_number > 0 ? Number(t.track_number) : Infinity),
    ]);

    this.setState(() => ({
      originalTracks: sorted,
      errors: {
        trackNumbers: {},
        timeRange: {},
        custom: {
          discNumbers: {},
        },
      },
    }));
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return !isEqual(nextState.originalTracks, this.state.originalTracks);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.originalTracks, this.state.originalTracks)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        originalTracks: this.state.originalTracks,
      });
    }
  }

  handleDiscNumberChange = (name, value, track) => {
    this.setState(({ originalTracks }) => ({
      originalTracks: originalTracks.map((t) => {
        if (t.id !== track.id) return t;
        return {
          ...t,
          custom: {
            ...t.custom,
            [name]: value,
          },
        };
      }),
    }));

    this.handleDiscNumberChangeDebouncer(name, value, track);
  };

    // eslint-disable-next-line react/sort-comp
    handleDiscNumberChangeDebouncer = debounce((name, value, track) => {
      const { updateAdditionalFormData } = this.props;
      if (!this.validateAndSetDiscNumberError(track.id, value)) return;

      const url = track.multipart ? `../../multiparts/${track.id}` : `../../tracks/${track.id}`;

      const { custom } = pick(track, ['custom']);
      updateAdditionalFormData(
        'custom',
        {
          ...custom,
          [name]: value || null,
        },
        { url, method: 'PUT' },
        {
          tracks: 'tracks',
        },
      );
    }, 100e100);

  handleTrackNumberChange = (name, value, track) => {
    this.setState(({ originalTracks }) => ({
      originalTracks: originalTracks.map((t) => {
        if (t.id !== track.id) return t;
        return { ...t, [name]: value, custom: t.custom ? { ...t.custom } : {} };
      }),
    }));

    if (!this.validateAndSetTrackNumberError(track.id, value)) return;

    this.handleTrackNumberChangeDebouncer(name, value, track);
  };

  // eslint-disable-next-line react/sort-comp
  handleTrackNumberChangeDebouncer = debounce((name, value, track) => {
    const { updateAdditionalFormData } = this.props;
        updateAdditionalFormData(
          name,
          value || null,
          { url: `../../tracks/${track.id}`, method: 'PUT' }, // TODO: fix url
          {
            tracks: 'tracks',
          },
          track.custom ? track.custom : {},
        );
  }, 100e100);

  handleTimeRangeUpdate = (name, value, track) => {
    const { updateAdditionalFormData } = this.props;

    this.setState(({ tracks }) => ({
      tracks: tracks.map((t) => {
        if (t.id !== track.id) return t;
        return {
          ...t,
          time_range: {
            ...t.time_range,
            [name]: value,
          } };
      }),
    }), () => {
      const newTrack = this.state.tracks.find(t => t.id === track.id);

      if (!newTrack) return;

      // if (!this.validateAndSetTimeRangeError(track.id, newTrack.time_range)) return;

      updateAdditionalFormData(
        'time_range',
        newTrack.time_range || null,
        { url: `../../tracks/${track.id}`, method: 'PUT' }, // TODO: fix url
        {
          tracks: 'tracks',
        },
        newTrack.custom ? newTrack.custom : {},
      );
    });
  };

  validateAndSetDiscNumberError = (id, value) => {
    const { t } = this.props;
    const schema = Joi.string()
      .label(t('components.panels.multipart.tracks.tracksDiscNumber'))
      .empty(null);
    const { error } = Joi.validate(value || null, schema);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        custom: {
          discNumbers: {
            ...this.state.errors.custom.discNumbers,
            [id]: error && error.details[0].message,
          },
        },
      },
    }));
    return !error;
  };

  validateAndSetTrackNumberError = (id, value) => {
    const { t } = this.props;
    const schema = Joi.number()
      .label(t('components.panels.multipart.tracks.tracksTrackNumber'))
      .empty(null);
    const { error } = Joi.validate(value || null, schema);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        trackNumbers: {
          ...prevState.errors.trackNumbers,
          [id]: error && error.details[0].message,
        },
      },
    }));
    return !error;
  };

  validateAndSetTimeRangeError = (id, timeRange) => {
    const schema = {
      start: Joi.date().iso()
        .required(),
      end: Joi.date().iso()
        .greater(Joi.ref('start'))
        .required(),
    };
    const { error } = Joi.validate(timeRange, schema);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        timeRange: {
          ...prevState.errors.timeRange,
          [id]: error && 'End time should be greater then start time',
        },
      },
    }));
    return !error;
  };


  renderTimeEditingInput(track) {
    const format = 'HH:mm:ss';
    const timeRange = defaults({}, track.time_range, {
      start: moment().utc().startOf('day').toDate(),
      end: moment().utc().startOf('day').toDate(),
    });

    return (
      <React.Fragment>
        <Flex>
          <TimePicker
            format={format}
            value={moment(get(timeRange, 'start')).utc()}
            showSecond
            clearText=""
            className="time-picker-input"
            popupClassName="time-picker"
            popupStyle={{ zIndex: 10000 }}
            onChange={v => this.handleTimeRangeUpdate('start', v.toDate(), track)}
          />
          <TimePicker
            format={format}
            value={moment(get(timeRange, 'end')).utc()}
            showSecond
            className="time-picker-input"
            popupClassName="time-picker"
            popupStyle={{ zIndex: 10000 }}
            onChange={v => this.handleTimeRangeUpdate('end', (v).utc().toDate(), track)}
          />
        </Flex>
      </React.Fragment>
    );
  }

  render() {
    const {
      tracklistId,
      updateAdditionalFormData,
      trackversions,
      disabled,
      openSidePanel,
      canDownload,
      t,
      locale,
    } = this.props;
    const custom = {
      finnish_content: 'no',
      yle_master: 'no',
    };
    const { errors, originalTracks } = this.state;
    const tracks = originalTracks;
    const normalizedTracklistId = tracklistId || tracks.map(tt => tt.id).toString();

    function getPlayButton(track) {
      if (track.audiofile.hd_mp3) {
        return (
          <MusicItemControls
            trackDatas={track}
            tracklistId={normalizedTracklistId}
            tracklistDatas={{
              tracks: tracks.filter(tt => !!tt.audiofile.hd_mp3),
            }}
          />
        );
      }
      return null;
    }

    function getVersion(track) {
      if (track.version) {
        return getName(track.version, locale);
      }

      return t('components.panels.multipart.tracks.tracksNoVersion');
    }

    function getDisplayArtists(track) {
      const displayArtists = track.display_artists || [];

      return displayArtists.map(da => (
        <Chip
          key={da.alias ? `${da.artist.full_name}-${da.alias}` : da.artist.full_name}
          name={da.alias || da.artist.full_name}
          onClick={() => {
            openSidePanel(ARTIST_PANEL, da.artist.id);
          }}
        />
      ));
    }

    function getStatus(track) {
      if (!track.audiofile || track.maia_conversion_state) {
        return false;
      }

      return true;
    }

    const trackList = tracks.map(track => (
      <GridRow
        key={track.id}
        floor={track.version && !disabled ? '2' : '1'}
        direction={
          track.version && !disabled ? 'column' : 'row'
        }
      >
        <GridRow noBorder>
          <MediaColumn width="50px">{getPlayButton(track)}</MediaColumn>
          <Column width="75px" justify="center">
            {(get(track, ['custom', 'disc_number']) || !disabled) && (
              <Input
                type="text"
                name="disc_number"
                error={errors.custom.discNumbers[track.id]}
                onChange={
                  !disabled
                    ? (name, value) => this.handleDiscNumberChange(name, value, track)
                    : null
                }
                onBlur={() => this.handleDiscNumberChangeDebouncer.flush()}
                value={get(track, ['custom', 'disc_number']) || ''}
                width="35px"
                mt="0"
                hideErrorText
              />
            )}
          </Column>
          <Column width="75px" justify="center">
            {(track.track_number || !disabled) && (
              <Input
                type="text"
                name="track_number"
                error={errors.trackNumbers[track.id]}
                onChange={
                  !disabled
                    ? (name, value) => this.handleTrackNumberChange(name, value, track)
                    : null
                }
                onBlur={() => this.handleTrackNumberChangeDebouncer.flush()}
                value={track.track_number || ''}
                width="35px"
                mt="0"
                hideErrorText
              />
            )}
          </Column>
          <Column width="150px">
            <Chip
              name={trim(track.display_title) || trim(track.title)}
              onClick={() => {
                openSidePanel(TRACK_PANEL, track.id);
              }}
            />
          </Column>
          <Column width="150px">{this.renderTimeEditingInput(track)}</Column>
          <Column width="150px">{getVersion(track)}</Column>
          <Column width="200px">{getDisplayArtists(track)}</Column>
          <Column width="100px" justify="center" mr="auto">
            <Circle color={getStatus(track) ? 'success' : 'error'} />
          </Column>
          {canDownload && (
          <div>
            <Column width="30px" pl="4px">
              {!!track.audiofile.hd_mp3 && (
              <a
                href={track.audiofile.hd_mp3.url}
                title={t('components.panels.multipart.tracks.tracksDownloadHdMp3')}
                target="_blank"
                rel="noopener noreferrer"
                download
                key="0"
              >
                <IconText>MP3</IconText>
              </a>
                )}
            </Column>
            <Column width="30px" pl="4px">
              {!!track.audiofile.original && (
              <a
                href={track.audiofile.original.url}
                title={t('components.panels.multipart.tracks.tracksDownloadRaw')}
                target="_blank"
                rel="noopener noreferrer"
                download
                key="1"
              >
                <IconText>WAV</IconText>
              </a>
                )}
            </Column>
          </div>
           )}
          <Column width="30px" pl="0">
            {!disabled && (
              <IconTrash
                onClick={() => {
                  updateAdditionalFormData(
                    null,
                    null,
                    { url: `tracks/${track.id}`, method: 'DELETE' },
                    { tracks: 'tracks' },
                  );
                }}
                size="18px"
              />
            )}
          </Column>
        </GridRow>

        {track.version &&
          !disabled && (
            <GridRow noBorder>
              <MediaColumn width="50px" />
              <Column width="150px" />
              <Column width="150px" mb="50px" overflow="visible">
                <Chip
                  name={t('components.panels.multipart.tracks.tracksCopy')}
                  onClick={() => {
                    updateAdditionalFormData(
                      'version',
                      track.version.id,
                      { url: `tracks/${track.id}/copy`, method: 'POST' },
                      {
                        tracks: 'tracks',
                      },
                      track.custom ? track.custom : {},
                    );
                  }}
                />
              </Column>
              {track.version.key === 'original' && (
                <Column width="150px" mb="50px" overflow="visible">
                  <SelectInput
                    type="single"
                    name="version"
                    label={t('components.panels.multipart.tracks.tracksAddVersion')}
                    onChange={(name, value) => {
                      updateAdditionalFormData(
                        name,
                        value,
                        { url: `tracks/${track.id}/versions`, method: 'POST' },
                        {
                          tracks: 'tracks',
                        },
                      );
                    }}
                    items={trackversions
                      .filter(v => v.key !== 'original')
                      .map(v => ({ id: v.id, name: getName(v, locale) }))}
                    data={null}
                    search
                    width="100%"
                  />
                </Column>
              )}
            </GridRow>
          )}
      </GridRow>
    ));

    return (
      <div>
        {tracks.length > 0 && (
          <Div>
            <MediaColumn width="50px" />
            <HeadColumn width="75px" justify="center">
              №
            </HeadColumn>
            <HeadColumn width="75px" justify="center">
              #
            </HeadColumn>
            <HeadColumn width="150px">{t('components.panels.multipart.tracks.tracksTitle')}</HeadColumn>
            <HeadColumn width="150px">{t('components.panels.multipart.tracks.tracksTime')}</HeadColumn>
            <HeadColumn width="150px">{t('components.panels.multipart.tracks.tracksVersion')}</HeadColumn>
            <HeadColumn width="200px">
              {t('components.panels.multipart.tracks.tracksDisplayArtists')}
            </HeadColumn>
            <HeadColumn width="100px" justify="center" mr="auto">
              {t('components.panels.multipart.tracks.tracksFiles')}
            </HeadColumn>
            <HeadColumn width="30px" pl="0" />
            <HeadColumn width="30px" pl="0" />
            <HeadColumn width="30px" pl="0" />
          </Div>
        )}
        {trackList}
        {!disabled && (
          <ApiSelectInput
            name="track"
            parseName={e => e.title}
            label={t('components.panels.multipart.tracks.tracksAddTrack')}
            entity="meta/tracks"
            panelName={TRACK_PANEL}
            defaultField="title"
            type="single"
            data={null}
            values={null}
            search
            onChange={(name, value) => {
              updateAdditionalFormData(
                null,
                null,
                { url: `tracks/${value}`, method: 'POST' },
                { tracks: 'tracks', data: '' },
              );
            }}
            customData={custom}
          />
        )}
      </div>
    );
  }
}

export default withI18n()(MultipartTracks);
