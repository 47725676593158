// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import parseInt from 'lodash/parseInt';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Components
import Chip from './../../chip/Chip';
import DropdownList from '../../dropdownlist/DropdownList';
import InputContainer from '../container/InputContainer';
import Input from '../input/Input';

// Theme
import { Div, Flex } from './../../../../themes/views';

// Styles
import { Item } from '../../dropdownlist/DropdownList.styles';

// =============================
// Component
// =============================

class SelectInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['single', 'multiple']),
    search: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    error: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    type: 'single',
    search: false,
    data: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
    error: [],
  };

  state = {
    dropDownIsOpened: false,
    filter: '',
    isEditing: false,
  }

  getDropdowRef = (e) => {
    this.dropDownRef = e;
  }

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }

    this.setState({
      isEditing: false,
    });
  }

  handleFocus = () => {
    const { onChange, onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }

    if (onChange) {
      this.setState({
        isEditing: true,
        dropDownIsOpened: true,
      });
    }
  }

  // When user clicks outside of menu
  // close the menu
  handleClickOutside = () => {
    this.toggleDropDown(false);
  }

  // Open & Close Dropdown
  toggleDropDown = (state) => {
    if (this.props.onChange) {
      this.setState({
        dropDownIsOpened: state,
      });
    }
  }

  isTypeSingle = () => {
    const { type } = this.props;
    return type === 'single';
  }

  // Pick an item
  handlePickItem = (id) => {
    const { data, name, onChange } = this.props;

    if (!onChange) return null;

    if (this.isTypeSingle()) {
      if (data === id) return this.removeItem(id);
      onChange(name, id);
    } else {
      if (data.indexOf(id) !== -1) return this.removeItem(id);
      if (data === null) onChange(name, [id]);
      else onChange(name, [...data, id]);
    }

    if (this.isTypeSingle()) this.inputRef.focus();

    return this.setState({
      filter: '',
      dropDownIsOpened: !this.isTypeSingle(),
    });
  }

  handleFilterChange = (name, value) => this.setState({ filter: value });

  handleInputKeyDown = (event) => {
    const { key } = event;
    const { data, onChange } = this.props;
    const { filter } = this.state;

    if (onChange) {
      // Open Dropdown on keyDown, except if user press tab
      this.toggleDropDown(key !== 'Tab');

      switch (key) {
        // Backspace
        case 'Backspace':
          // If filter is present we only want to erase some part of it
          if (filter) break;

          // Prevent Backspace from sending user to previous page
          event.preventDefault();

          // Check if we have some Chip to remove
          if (data && data.length) {
            const lastChip = this.inputContainerRef.previousSibling;

            // First time user press Backspace: Focus the item to show user is about to delete it
            // Deletion is handled in <Chip /> component
            if (document.activeElement !== lastChip) {
              lastChip.focus();
            }
          }
          break;

        // Arrow Down: Give focus to Dropdown first item
        case 'ArrowDown':
          event.preventDefault();
          if (this.dropDownRef.firstChild) this.dropDownRef.firstChild.focus();
          break;

        default:
          break;
      }
    }
  }

  handleItemKeyDown = (e) => {
    const { key, target } = e;

    switch (key) {
      // Tab
      case 'Tab':
        this.setState({
          dropDownIsOpened: false,
        });
        break;

      // Enter: Save item
      case 'Enter':
        this.handlePickItem(target.dataset.id);
        break;

      // Arrow Up: Go to next item
      case 'ArrowUp':
        e.preventDefault();
        if (!target.previousSibling) {
          this.dropDownRef.lastChild.focus();
          this.dropDownRef.lastChild.scrollIntoView();
          break;
        }
        target.previousSibling.focus();
        target.previousSibling.scrollIntoView();
        break;

      // Arrow Down: Go to previous item
      case 'ArrowDown':
        e.preventDefault();
        if (!target.nextSibling) {
          this.dropDownRef.firstChild.focus();
          this.dropDownRef.firstChild.scrollIntoView();
          break;
        }
        target.nextSibling.focus();
        target.nextSibling.scrollIntoView();
        break;

      // If user is not navigating nor saving, we assume he wants to add some filter
      default:
        this.inputRef.focus();
        break;
    }
  }

  removeItem = (id) => {
    const { data, name, onChange } = this.props;
    if (this.isTypeSingle()) onChange(name, null);
    else {
      onChange(
        name,
        remove([...data], e => e !== id),
      );
    }
  }

  transformError = () => {
    const { data, error } = this.props;
    let errorString = '';

    if (error) {
      error.forEach((e) => {
        const start = e.indexOf('"');
        const end = e.lastIndexOf('"');
        const valueIndex = parseInt(e.substring(start + 1, end));
        if (errorString) {
          errorString += '. ';
        }
        errorString += `${e.replace(/"[0-9]"/, `"${data[valueIndex]}"`)}`;
      });
    }

    return errorString;
  };

  renderItem = () => {
    const { data, items, search } = this.props;
    const { filter } = this.state;

    let itemsToBeRendered = items;

    // We filter items
    if (search && filter) {
      itemsToBeRendered = items.filter(
        item => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
      );
    }

    // Sort items
    itemsToBeRendered = sortBy(itemsToBeRendered, i => i.name);

    return itemsToBeRendered.map(
      item => (
        <Item
          height="40px"
          selected={this.isTypeSingle() ? data === item.id : data.indexOf(item.id) !== -1}
          key={item.id}
          data-id={item.id}
          onClick={() => {
            this.handlePickItem(item.id);
          }}
          onKeyDown={e => this.handleItemKeyDown(e)}
          tabIndex="-1"
        >
          {item.name}
        </Item>
      ),
    );
  }

  renderChips = () => {
    const { data, items, onChange } = this.props;
    let chipsToBeRendered;

    if (data === null) return null;

    if (Array.isArray(data)) {
      chipsToBeRendered = data.map(id => ({
        ...find(items, item => item.id === id),
      }));
    } else if (data !== '') {
      chipsToBeRendered = [{
        ...find(items, item => item.id === data),
      }];
    } else chipsToBeRendered = [];

    return chipsToBeRendered.map(chip => (
      <Chip
        key={chip.id}
        name={chip.name}
        handleDelete={onChange ? () => { this.removeItem(chip.id); } : null}
        inputRef={this.inputRef}
        id={chip.id}
        whiteSpace={this.isTypeSingle() ? 'nowrap' : 'wrap'}
      />
    ));
  }

  render() {
    const {
      name,
      label,
      data,
      placeholder,
      onChange,
    } = this.props;
    const { dropDownIsOpened, filter, isEditing } = this.state;

    return (
      <Div position="relative" {...getViewProps(this.props)}>
        <InputContainer
          label={label}
          placeholder={placeholder}
          active={isEditing || dropDownIsOpened}
          filled={isEditing || !!filter || (!!data && data.length > 0)}
          error={this.transformError()}
        >
          <Flex direction="row" wrap={this.isTypeSingle() ? 'nowrap' : 'wrap'}>
            {this.renderChips()}
            <Input
              name={name}
              placeholder={placeholder}
              value={filter}
              getInputRef={(e) => { this.inputRef = e; }}
              getContainerRef={(e) => { this.inputContainerRef = e; }}
              onChange={onChange ? this.handleFilterChange : null}
              onClick={() => this.toggleDropDown(true)}
              onKeyDown={e => this.handleInputKeyDown(e)}
              onBlur={() => this.handleBlur()}
              onFocus={() => this.handleFocus()}
              hasLabel={!!label}
              isEditing={isEditing}
              disabled={!onChange}
            />
          </Flex>
        </InputContainer>

        <DropdownList
          getRef={this.getDropdowRef}
          isOpen={dropDownIsOpened}
          items={() => this.renderItem()}
          handleClickOutside={this.handleClickOutside}
        />
      </Div>
    );
  }
}

export default SelectInput;
