// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Components
import ImageLoader from './../../imageloader/ImageLoader';

// Styles
import { Div } from './../../../../themes/views';
import { ImageLoaderWrapper } from './../../../../themes/entityGrid';

// =============================
// Renderer
// =============================

export default ({ underlay = false } = {}) => (_, object) => {
  const imgLink = object?.image?.large || object?.image?.original || null;
  return imgLink ? (
    <ImageLoaderWrapper underlay={underlay} key="img">
      <ImageLoader height="50px" width="50px" src={imgLink.url} />
    </ImageLoaderWrapper>
  ) : (
    <Div height="50px" width="50px" bgColor="#eee" position="relative" key="img" />
  );
};
