// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import remove from 'lodash/remove';
import parseInt from 'lodash/parseInt';

// Components
import Chip from './../../chip/Chip';
import DropdownList from './../../dropdownlist/DropdownList';
import InputContainer from './../container/InputContainer';

// Helpers
import { getViewProps } from './../../../../helpers/helpers';

// Theme
import { Div, Flex, FlexBreakWord, OnTheFlyPreview } from './../../../../themes/views';

// Styles
import { Input } from './ChipInput.styles';
import { Item } from './../../dropdownlist/DropdownList.styles';

// =============================
// Component
// =============================

class ChipInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    data: [],
    onChange: null,
    onFocus: null,
    onBlur: null,
    error: [],
  };

  state = {
    isEditing: false,
    dropDownIsOpened: false,
    value: '',
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }

    this.setState({
      isEditing: false,
    });
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }

    this.setState({
      isEditing: true,
      dropDownIsOpened: true,
    });
  };

  handleClickOutside = () => {
    this.toggleDropDown(false);
  };

  handleChange = (name, value) => {
    if (this.props.onChange) {
      this.setState({ value });
    }
  };

  handleCreate = () => {
    const { data, name, onChange } = this.props;
    const { value } = this.state;

    if (value === '') return;
    // Can not add the same value 2 times
    if (data.indexOf(value) !== -1) return;

    onChange(name, [...data, value]);

    this.setState({
      value: '',
    });

    this.inputRef.focus();
  };

  handleInputKeyDown = (event) => {
    const { key } = event;
    const { data, onChange } = this.props;
    const { value } = this.state;

    this.toggleDropDown(key !== 'Tab');

    if (onChange) {
      switch (key) {
        // Backspace
        case 'Backspace':
          // If value is present we only want to erase some part of it
          if (value) break;

          // Prevent Backspace from sending user to previous page
          event.preventDefault();

          // Check if we have some Chip to remove
          if (data && data.length) {
            const lastChip = this.containerRef.previousSibling;

            // First time user press Backspace: Focus the item to show user is about to delete it
            // Deletion is handled in <Chip /> component
            if (document.activeElement !== lastChip) {
              lastChip.focus();
            }
          }
          break;

        // Enter
        case 'Enter':
          // Can not add empty value
          this.handleCreate();
          break;

        case 'ArrowDown':
          event.preventDefault();
          if (this.dropDownRef.firstChild) this.dropDownRef.firstChild.focus();
          break;

        default:
          break;
      }
    }
  };

  handleItemKeyDown = (e) => {
    const { key } = e;

    switch (key) {
      // Tab
      case 'Tab':
        this.setState({
          dropDownIsOpened: false,
        });
        break;

      // Enter
      case 'Enter':
        this.handleCreate();
        break;

      // If user is not navigating nor saving, we assume he wants to add some query
      default:
        this.inputRef.focus();
        break;
    }
  };

  toggleDropDown = (state) => {
    this.setState({
      dropDownIsOpened: state,
    });
  };

  removeChip = (value) => {
    const { data, name, onChange } = this.props;

    if (onChange) {
      onChange(name, remove([...data], e => e !== value));
    }
  };

  transformError = () => {
    const { data, error } = this.props;
    let errorString = '';

    if (error) {
      error.forEach((e) => {
        const start = e.indexOf('"');
        const end = e.lastIndexOf('"');
        const valueIndex = parseInt(e.substring(start + 1, end));
        if (errorString) {
          errorString += '. ';
        }
        errorString += `${e.replace(/"[0-9]"/, `"${data[valueIndex]}"`)}`;
      });
    }

    return errorString;
  };

  renderChips = () => {
    const { data, onChange } = this.props;

    return data.map(chip => (
      <Chip
        id={chip}
        name={chip}
        handleDelete={
          onChange
            ? () => {
                this.removeChip(chip);
              }
            : null
        }
        inputRef={this.inputRef}
        key={chip}
      />
    ));
  };

  renderItems = () => {
    const { value } = this.state;
    if (!value) return undefined;

    return (
      <Item
        borderBottom="1px solid #999"
        height="40px"
        onClick={() => {
          this.handleCreate();
        }}
        onKeyDown={e => this.handleItemKeyDown(e)}
        tabIndex="-1"
      >
        + create&nbsp;
        <OnTheFlyPreview>
          <FlexBreakWord>{`"${value}"`}</FlexBreakWord>
        </OnTheFlyPreview>
      </Item>
    );
  };

  render() {
    const { name, label, data, placeholder } = this.props;
    const { isEditing, value, dropDownIsOpened } = this.state;
    const error = this.transformError();

    return (
      <Div position="relative" {...getViewProps(this.props)}>
        <InputContainer
          filled={!!value || (data && data.length > 0)}
          active={isEditing}
          label={label}
          error={error}
        >
          <Flex flex="1" wrap="wrap">
            {this.renderChips()}
            <Input
              getContainerRef={(e) => {
                this.containerRef = e;
              }}
              getInputRef={(e) => {
                this.inputRef = e;
              }}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={this.handleChange}
              onClick={() => this.toggleDropDown(true)}
              onKeyDown={this.handleInputKeyDown}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              isEditing={isEditing || (data && data.length > 0)}
              hasLabel={!!label}
            />
          </Flex>
        </InputContainer>

        <DropdownList
          getRef={(el) => {
            this.dropDownRef = el;
          }}
          isOpen={dropDownIsOpened}
          items={() => this.renderItems()}
          handleClickOutside={this.handleClickOutside}
        />
      </Div>
    );
  }
}

export default ChipInput;
