// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import ImageLoader from '../../imageloader/ImageLoader';

import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import InputBase from '../../inputs/inputunderline/InputUnderline';
import FileUploadBase from '../../sidepanel/fileupload/FileUpload';
import GooglePlacesInputBase from '../../inputs/googleplacesinput/GooglePlacesInput';
import SelectInputBase from '../../inputs/selectinput/SelectInput';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import TranslateAreaBase from '../../../containers/inputs/translatearea/TranslateAreaContainer';
import TerritoriesSelectBase from '../../inputs/territoriesselect/TerritoriesSelect';

// Helpers
import isBlank from './../../../../helpers/isBlank';

// Schema
import LabelSchema from './Label.schema';

// Styles
import { Div, Flex } from './../../../../themes/views';
import { SubCategory as SubCategoryBase } from './../../sidepanel/SidePanel.styles';
import { Counter, CounterDesc, Count } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const FileUpload = hideable(FileUploadBase);
const GooglePlacesInput = hideable(GooglePlacesInputBase);
const SelectInput = hideable(SelectInputBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const SubCategory = hideable(SubCategoryBase);
const TerritoriesSelect = hideable(TerritoriesSelectBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'contacts', 'picture'];

export class Label extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      nbOwnerships: PropTypes.number,
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    neighboringrightssocieties: PropTypes.array.isRequired, // eslint-disable-line
    territories: PropTypes.array.isRequired, // eslint-disable-line
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {};

  onSelectChange = (name, value) => {
    if (value === '') {
      // We need to transform the empty value to null as the api
      // is not able to unset certain values with an empty string
      this.props.updateForm(name, null);
    } else {
      this.props.updateForm(name, value);
    }
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    const { data } = this.props.panel;
    return data && data.id ? data.label_name : t('components.panels.label.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { t } = this.props;
    const { nbOwnerships } = this.props.panel;

    if (nbOwnerships && nbOwnerships >= 0) {
      return (
        <Counter>
          <CounterDesc>{t('components.panels.label.recordings')}</CounterDesc>
          <Count>{nbOwnerships}</Count>
        </Counter>
      );
    }

    return null;
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    if (this.checkIfOwned()) return true;

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'picture':
        return !isBlank(get(panel, ['data', 'image', 'original']));
      case 'recording_info':
        return ['neighboring_rights_society', 'ipn', 'label_code', 'address', 'territories'].some(
          this.isShown,
        );
      case 'label':
        return ['label_name', 'recording_info'].some(this.isShown);
      case 'contacts':
        return ['contact_name', 'contact_email'].some(this.isShown);
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, locale, form, delaySave } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.label.false')}
            textTrue={t('components.panels.label.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned()
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isLabelCollection = c.collections.indexOf('label') >= 0;
        return !section
          ? isLabelCollection
          : isLabelCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.label.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      updateForm,
      delaySave,
      deleteForm,
      neighboringrightssocieties,
      territories,
      t,
      customFieldsToRightSide,
      descriptionAutocomplete,
      user,
    } = this.props;

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          panel={panel}
          title={this.renderPanelTitle()}
          picture={this.renderPanelPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
        />

        <Flex justify="space-between">
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.label.boxLabel')}
              isOpen
              show={this.isShown('label') && !this.isInHideList('label')}
            >
              <Input
                type="text"
                name="label_name"
                label={t('components.panels.label.labelName')}
                error={form.errors.label_name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.label_name}
                show={this.isShown('label_name') && !this.isInHideList('label_name')}
              />

              <SubCategory
                show={(
                  this.isShown('recording_info')
                  && !this.isInHideList('recording_info')
                )}
              >
                {t('components.panels.label.subCategoryRecordingInfo')}
              </SubCategory>
              <SelectInput
                type="single"
                name="neighboring_rights_society"
                label={t('components.panels.label.labelNeighboringRightsSociety')}
                onChange={this.checkIfOwned() ? this.onSelectChange : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                items={neighboringrightssocieties.map(nrs => ({ id: nrs.id, name: nrs.name }))}
                data={form.values.neighboring_rights_society}
                error={form.errors.neighboring_rights_society}
                search
                show={(
                  this.isShown('neighboring_rights_society')
                  && !this.isInHideList('neighboring_rights_society')
                )}
              />
              <Input
                type="text"
                name="ipn"
                label={t('components.panels.label.labelIpn')}
                error={form.errors.ipn}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.ipn}
                show={this.isShown('ipn') && !this.isInHideList('ipn')}
              />
              <Input
                type="text"
                name="label_code"
                label={t('components.panels.label.labelLabelCode')}
                error={form.errors.label_code}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.label_code}
                show={this.isShown('label_code') && !this.isInHideList('label_code')}
              />
              <GooglePlacesInput
                name="address"
                label={t('components.panels.label.labelAddress')}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                data={form.values.address}
                error={form.errors.address}
                show={this.isShown('address') && !this.isInHideList('address')}
              />
              <TerritoriesSelect
                label={t('components.panels.label.labelHeadquarters')}
                name="territories"
                value={form.values.territories || []}
                territories={territories}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('territories') && !this.isInHideList('territories')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.label.boxContacts')}
              mt="5px"
              show={this.isShown('contacts') && !this.isInHideList('contacts')}
            >
              <Input
                type="text"
                name="contact_name"
                label={t('components.panels.label.labelContactName')}
                error={form.errors.contact_name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.contact_name}
                show={this.isShown('contact_name') && !this.isInHideList('contact_name')}
              />
              <Input
                type="text"
                name="contact_email"
                label={t('components.panels.label.labelContactEmail')}
                error={form.errors.contact_email}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.contact_email}
                show={this.isShown('contact_email') && !this.isInHideList('contact_email')}
              />
              {this.renderCustomFields('contacts')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.label.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <TranslateArea
              name="descriptions"
              label={t('components.panels.label.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
          </Div>
        </Flex>
      </PanelLoader>
    );
  }
}

export default formPanel(
  LabelSchema,
  {
    neighboring_rights_society: nrs => nrs.id,
  },
  {
    nbOwnerships: 'count/ownerships',
  },
)(withI18n()(Label));
