// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ApiSelectInput from './../../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Constants
import { PUBLISHER_PANEL } from './../../../../../constants/SidePanelTypes';

// =============================
// Component
// =============================

class OwnerPublisherInput extends Component {
  static propTypes = {
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { ownership, errors, disabled, label, onChange, onFocus, onBlur } = this.props;

    const { publisher } = ownership;

    return (
      <ApiSelectInput
        name="publisher"
        parseName={e => e.publisher_name}
        label={label}
        entity="meta/publishers"
        panelName={PUBLISHER_PANEL}
        defaultField="publisher_name"
        type="single"
        data={publisher}
        values={publisher ? publisher.id : null}
        onChange={
          disabled
            ? null
            : (name, value, newPublisher) => onChange({ ...ownership, publisher: newPublisher })
        }
        onFocus={onFocus}
        onBlur={onBlur}
        error={errors.publisher}
        width="calc(100% - 70px)"
      />
    );
  }
}

export default OwnerPublisherInput;
