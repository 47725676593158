// =============================
// Imports
// =============================

// External Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual, isEmpty, get } from 'lodash';

// HOC
import withI18n from './../../hoc/WithI18n';

// Actions
import { generateAlbumCustomID, checkAlbumCustomID } from './../../../actions/CustomFieldsActions';

// Components
import InputBase from './../inputs/inputunderline/InputUnderline';
import ButtonPrimary from './../buttons/buttonprimary/ButtonPrimary';

// Styles
import { Container, IconCrossContainer, IconCross } from './CustomIdGenerator.styles';

// Assets
import iconCheck from './../../../assets/images/icon-check.svg';
import iconTrash from './../../../assets/images/icon-trash.svg';

// =============================
// Component
// =============================

const allowedPrefixList = [
  'CD',
  'CDY',
  'DIGI',
  'DIGY',
];

const isDisableGenerateButton = (prefix = '') => {
  let isDisabled = false;

  if (isEmpty(prefix)) {
    isDisabled = true;
  }

  if (!allowedPrefixList.includes(prefix)) {
    isDisabled = true;
  }

  return isDisabled;
};

class CustomIdGenerator extends Component {
  static propTypes = {
    key: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
    generateCustomID: PropTypes.func.isRequired,
    checkCustomID: PropTypes.func.isRequired,
    panel: PropTypes.string.isRequired,
    albumId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    key: 'CustomIdGeneratorKey',
    name: '',
    type: 'text',
    label: '',
    value: '',
    error: '',
    onChange: null,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      generatedString: '',
    };
  }

  componentDidMount() {
    // set initial value
    const generatedString = get(this.props, ['value']);
    this.setState({ generatedString }); // eslint-disable-line react/no-did-mount-set-state
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps, this.props)) {
      return;
    }

    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        generatedString: value,
      });
    }
  }

  onInputChange = (name, value) => {
    this.setState({ generatedString: value });
  };

  onGenerateClick = () => {
    const { generateCustomID, panel, albumId } = this.props;
    const { generatedString: prefix } = this.state;

    generateCustomID(panel, {prefix, albumId})
      .then((data) => {
        const CustomID = get(data, ['CustomID']);
        this.setState({ generatedString: isEmpty(CustomID) ? prefix : CustomID });
      });
  };

  onReset = () => {
    const { value } = this.props;
    const { generatedString } = this.state;
    const stringArr = generatedString.split('-');

    // reset value to prefix or origin value
    const newGeneratedString = stringArr[1] ? stringArr[0] : value;
    this.setState({ generatedString: newGeneratedString });
  };

  onAccept = () => {
    const { name, onChange, panel, checkCustomID, albumId } = this.props;
    const { generatedString } = this.state;
    checkCustomID(panel, generatedString, albumId)
      .then((data) => {
        const error = get(data, ['payload', 'error']);
        if (!error && onChange) {
          onChange(name, generatedString);
        }
      });
  };

  render() {
    const {
      key,
      type,
      name,
      label,
      value,
      error,
      disabled,
      t,
    } = this.props;
    const { generatedString } = this.state;
    const isOriginValue = isEqual(value, generatedString);

    return (
      <Fragment>
        <InputBase
          key={key}
          type={type}
          name={name}
          label={label}
          onChange={(disabled || !isEmpty(value)) ? null : this.onInputChange}
          value={generatedString}
          error={error}
        />
        <Container>
          <IconCrossContainer>
            {!isOriginValue && (
              <Fragment>
                <IconCross svg={iconTrash} width="100%" height="100%" onClick={this.onReset} />
                <IconCross svg={iconCheck} width="100%" height="100%" onClick={this.onAccept} />
              </Fragment>
            )}
          </IconCrossContainer>
          {isEmpty(value) && (
            <ButtonPrimary
              width="auto"
              height="auto"
              onClick={this.onGenerateClick}
              disabled={disabled || isDisableGenerateButton(generatedString)}
            >
              {t('components.inputs.custom.generateCustomId')}
            </ButtonPrimary>
          )}
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps({ sidepanel }) {
  return {
    panel: get(sidepanel, ['panels', '0', 'url']),
  };
}

export default connect(
  mapStateToProps,
  {
    generateCustomID: generateAlbumCustomID,
    checkCustomID: checkAlbumCustomID,
  },
)(withI18n()(CustomIdGenerator));
