// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import ImageLoader from '../../imageloader/ImageLoader';

import ApiSelectInputBase from './../../../containers/inputs/apiselectinput/ApiSelectInputContainer';
import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import InputBase from '../../inputs/inputunderline/InputUnderline';
import CollapsibleBoxBase from '../../collapsiblebox/CollapsibleBox';
import FileUploadBase from '../../sidepanel/fileupload/FileUpload';
import MultipleSelectBase from './../../inputs/multipleselect/MultipleSelect';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import TranslateAreaBase from '../../../containers/inputs/translatearea/TranslateAreaContainer';
import SelectInputBase from '../../inputs/selectinput/SelectInput';

// Constants
import { PUBLISHER_PANEL, LABEL_PANEL } from '../../../../constants/SidePanelTypes';

// Helpers
import isBlank from './../../../../helpers/isBlank';

// Schema
import CatalogSchema from './Catalog.schema';

// Styles
import { Div, Flex } from './../../../../themes/views';
import { Action, Counter, CounterDesc, Count } from './../../sidepanel/header/Header.styles';
import { IconExportCsv, IconArchive } from './../../../../themes/icons';

// =============================
// Set up
// =============================

const ApiSelectInput = hideable(ApiSelectInputBase);
const Input = hideable(InputBase);
const SelectInput = hideable(SelectInputBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const FileUpload = hideable(FileUploadBase);
const MultipleSelect = hideable(MultipleSelectBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'distribution', 'picture'];

export class Catalog extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      nbTracks: PropTypes.number,
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    exportCsv: PropTypes.func.isRequired,
    downloadArchive: PropTypes.func.isRequired,
    agents: PropTypes.array.isRequired, // eslint-disable-line
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {};

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    const { data } = this.props.panel;
    return data && data.id ? data.name : t('components.panels.catalog.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { t } = this.props;
    const { nbTracks } = this.props.panel;

    if (nbTracks && nbTracks >= 0) {
      return (
        <Counter>
          <CounterDesc>{t('components.panels.catalog.tracks')}</CounterDesc>
          <Count>{nbTracks}</Count>
        </Counter>
      );
    }

    return null;
  };

  renderPanelActions = () => {
    const {
      exportCsv,
      downloadArchive,
      panel: { data },
      t,
    } = this.props;

    if (!data || !data.id) return null;

    return (
      <Flex direction="column">
        <Action
          onClick={() => {
            downloadArchive('catalog', data.id);
          }}
          align="center"
        >
          <IconArchive inherit />
          <Div ml="8px">{t('components.panels.catalog.downloadArchive')}</Div>
        </Action>
        <Action
          onClick={() => {
            exportCsv('catalog', data.id);
          }}
          align="center"
        >
          <IconExportCsv inherit />
          <Div ml="4px">{t('components.panels.catalog.exportCsv')}</Div>
        </Action>
      </Flex>
    );
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'distribution':
        return !!(this.checkIfOwned() && panel.data);
      case 'public':
        return !!(this.checkIfOwned() && panel.data);
      case 'showcase':
        return !!panel.data;
      case 'picture':
        return this.checkIfOwned() || !isBlank(get(panel, ['data', 'image', 'original']));
      case 'catalog':
        return ['name', 'default_publisher', 'default_label'].some(this.isShown);
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return this.checkIfOwned() || !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { form, delaySave, t, locale } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.catalog.false')}
            textTrue={t('components.panels.catalog.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned()
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isCatalogCollection = c.collections.indexOf('catalog') >= 0;
        return !section
          ? isCatalogCollection
          : isCatalogCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.catalog.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      updateForm,
      delaySave,
      deleteForm,
      agents,
      t,
      customFieldsToRightSide,
      descriptionAutocomplete,
      user,
    } = this.props;

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          actions={this.renderPanelActions()}
          panel={panel}
          title={this.renderPanelTitle()}
          picture={this.renderPanelPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
        />

        <Flex justify="space-between">
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.catalog.boxCatalog')}
              isOpen
              show={this.isShown('catalog') && !this.isInHideList('catalog')}
            >
              <Input
                type="text"
                name="name"
                label={t('components.panels.catalog.labelName')}
                error={form.errors.name}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.name}
                show={this.isShown('name') && !this.isInHideList('name')}
              />
              <ApiSelectInput
                name="default_publisher"
                parseName={e => e.publisher_name}
                label={t('components.panels.catalog.labelPublisher')}
                entity="meta/publishers"
                panelName={PUBLISHER_PANEL}
                defaultField="publisher_name"
                type="single"
                data={panel.data && panel.data.default_publisher}
                values={form.values.default_publisher || null}
                error={form.errors.default_publisher}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('default_publisher') && !this.isInHideList('default_publisher')}
              />
              <ApiSelectInput
                name="default_label"
                parseName={e => e.label_name}
                label={t('components.panels.catalog.labelLabel')}
                entity="meta/labels"
                panelName={LABEL_PANEL}
                defaultField="label_name"
                type="single"
                data={panel.data && panel.data.default_label}
                values={form.values.default_label || null}
                error={form.errors.default_label}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                show={this.isShown('default_label') && !this.isInHideList('default_label')}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.catalog.boxDistribution')}
              mt="5px"
              show={this.isShown('distribution') && !this.isInHideList('distribution')}
            >
              <MultipleSelect
                label={t('components.panels.catalog.labelAgents')}
                displayText={{
                  one: t('components.panels.catalog.oneAgent'),
                  multiple: t('components.panels.catalog.multipleAgents'),
                  none: t('components.panels.catalog.noneAgents'),
                  all: t('components.panels.catalog.allAgents'),
                }}
                name="agents"
                selectAll
                values={(form.values.agents || []).map(id => ({
                  value: id,
                  status: 2,
                }))}
                data={agents.map(agent => ({
                  label: agent.company_name || `${agent.first_name} ${agent.last_name}`,
                  value: agent.id,
                }))}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
              />
              {this.renderCustomFields('distribution')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.catalog.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <SwitchInput
              name="public"
              label={t('components.panels.catalog.labelPublic')}
              onChange={updateForm}
              value={form.values.public}
              error={form.errors.public}
              textFalse={t('components.panels.catalog.publicFalse')}
              textTrue={t('components.panels.catalog.publicTrue')}
              show={this.isShown('public') && !this.isInHideList('public')}
            />
            <SwitchInput
              name="showcase"
              label={t('components.panels.catalog.labelShowcase')}
              onChange={updateForm}
              value={form.values.showcase}
              error={form.errors.showcase}
              textFalse={t('components.panels.catalog.showcaseFalse')}
              textTrue={t('components.panels.catalog.showcaseTrue')}
              show={this.isShown('showcase') && !this.isInHideList('showcase')}
            />
            <TranslateArea
              name="descriptions"
              label={t('components.panels.catalog.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              mt="10px"
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
          </Div>
        </Flex>
      </PanelLoader>
    );
  }
}

Catalog.propTypes = {
  closePanel: PropTypes.func.isRequired,
};

export default formPanel(
  CatalogSchema,
  {
    default_label: label => label.id,
    default_publisher: publisher => publisher.id,
    agents: agents => agents.map(agent => agent.id),
  },
  { nbTracks: 'count/tracks' },
  {},
  (values, panel) => {
    if (get(panel, 'data.owned_by_tenant') === false) {
      return {
        showcase: values.showcase,
      };
    }

    return values;
  },
)(withI18n()(Catalog));
