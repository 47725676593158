import axios from 'axios';
import cookies from 'js-cookie';
import * as acts from '../constants/ActionTypes';
import { getApiUrl, getCookieConfig } from '../helpers/helpers';

export function getBmatUserData(bmatToken) {
  return (dispatch) => {
    dispatch({
      type: acts.GET_USER_INFO_LOADING,
    });
    let isYle;
    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/session/bmat'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${bmatToken}`,
      },
    }).then((response) => {
      const token = response.headers['x-auth'];
      isYle = response.headers['is-yle'] ? response.headers['is-yle'] : false;
      console.log(getCookieConfig(15, true));
      cookies.set('x-auth', token, getCookieConfig(15, true));
      console.log(token);
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, ' ');

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: token,
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: response.data,
      });
      dispatch({
        type: acts.SET_YLE_BOOL,
        payload: {
          isYle,
        },
      });

      return dispatch({
        type: acts.GET_USER_INFO_SUCCESS,
      });
    }).catch((error) => {
      if (error.response && error.response.headers) {
        isYle = error.response.headers['is-yle'];
        if (isYle) {
          dispatch({
            type: acts.AUTH_YLE_LOGOUT,
          });
        } else {
          dispatch({
            type: acts.GET_USER_INFO_FAILURE,
          });
        }
      }
    });
  };
}

export function logout() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.LOGOUT_USER_LOADING,
    });

    // Regardless of the consequence of this action, we will
    // Treat it as a success
    return axios({
      method: 'delete',
      url: getApiUrl('dashboard/users/session'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    }).finally(() => {
      cookies.remove('x-auth', getCookieConfig(null, true));
      dispatch({
        type: acts.LOGOUT_USER_SUCCESS,
      });
    });
  };
}
