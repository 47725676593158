// =============================
// Imports
// =============================

// External Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from './../../../helpers/helpers';

// Styles
import {
  Container,
  Title,
  NoWrap,
  Count,
  Arrow,
  ArrowEl,
} from './CollapsibleBox.styles';

// Themes
import { Flex, Div, ErrorText } from './../../../themes/views';

// =============================
// Component
// =============================

class CollapsibleBox extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    counter: PropTypes.number,
    light: PropTypes.bool,
    ultraLight: PropTypes.bool,
    isOpen: PropTypes.bool,
    error: PropTypes.string,
  }
  static defaultProps = {
    counter: null,
    light: false,
    ultraLight: false,
    isOpen: false,
    error: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen === true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.isOpen !== this.props.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
      });
    }
  }

  toggleBox = isOpen => this.setState({ isOpen });

  render() {
    const { title, children, counter, light, ultraLight, error: originError } = this.props;
    const { isOpen } = this.state;
    const error = originError && typeof originError === 'string' ? originError : '';

    return (
      <Fragment>
        <Container isOpen={isOpen} ultraLight={ultraLight} {...getViewProps(this.props)}>
          <Title light={light} ultraLight={ultraLight} onClick={() => this.toggleBox(!isOpen)}>
            <Flex align="center" width="calc(100% - 25px)">
              <NoWrap>{title}</NoWrap>
              {counter !== null && <Count>{counter}</Count>}
            </Flex>
            <Arrow>
              <ArrowEl isOpen={isOpen} />
              <ArrowEl isOpen={isOpen} />
              <ArrowEl isOpen={isOpen} />
              <ArrowEl isOpen={isOpen} />
              <ArrowEl isOpen={isOpen} />
            </Arrow>
          </Title>
          <Div hide={!isOpen} px="8px" width="100%">
            {children}
          </Div>
        </Container>
        {error && <ErrorText>{error}</ErrorText>}
      </Fragment>
    );
  }
}

export default CollapsibleBox;
