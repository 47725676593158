const { isNil, isArray, isEmpty, each, pick, omit } = require('lodash');

/**
 * Pick needed metadata from data
 * @param panelName: String
 * @param data: Object
 * @param metadataFields: Array
 * @returns {{}}
 */
function pickMetadata(panelName, data, metadataFields) {
  const panelObject = ((isNil(metadataFields) || !isArray(metadataFields)) ? [] : metadataFields)
    .find(item => item.panel === panelName);
  let pickedData = {};

  if (panelObject) {
    const { fields = [] } = panelObject;
    each(fields, (field) => {
      const { name, exclude = [] } = field;
      const pickedField = pick(data, [name]);

      if (isEmpty(pickedField)) {
        return;
      }

      pickedData = {
        ...pickedData,
        ...omit(pickedField, exclude),
      };
    });
  }

  return pickedData;
}

module.exports = {
  pickMetadata,
};
