export const ALBUM_PUBLIC_CHANGE_ERROR = 'ALBUM_PUBLIC_CHANGE_ERROR';
export const TRACK_PUBLIC_CHANGE_ERROR = 'TRACK_PUBLIC_CHANGE_ERROR';
export const GENERATE_CUSTOM_ID_ERROR = 'GENERATE_CUSTOM_ID_ERROR';
export const CHECK_CUSTOM_ID_ERROR = 'CHECK_CUSTOM_ID_ERROR';
export const SET_PAGE_TAGS_STILL_FINDING_INFO = 'SET_PAGE_TAGS_STILL_FINDING_INFO';
export const PLAYER_LOAD_ERROR = 'PLAYER_LOAD_ERROR';
export const SET_MATCHES_INFO = 'SET_MATCHES_INFO';
export const REACHED_DOC_QUOTA_ERROR = 'REACHED_DOC_QUOTA_ERROR';
export const DID_BATCH_DELETE_OPERATION_INFO = 'DID_BATCH_DELETE_OPERATION_INFO';
export const SEARCH_UPDATE_NEEDED_INFO = 'SEARCH_UPDATE_NEEDED_INFO';
export const EXPORT_CSV_SUCCESS_INFO = 'EXPORT_CSV_SUCCESS_INFO';
export const EXPORT_CSV_FAILURE_INFO = 'EXPORT_CSV_FAILURE_INFO';
export const EXPORT_JSON_SUCCESS_INFO = 'EXPORT_JSON_SUCCESS_INFO';
export const EXPORT_JSON_FAILURE_INFO = 'EXPORT_JSON_FAILURE_INFO';
export const EXPORT_BULK_JSON_SUCCESS_INFO = 'EXPORT_BULK_JSON_SUCCESS_INFO';
export const EXPORT_BULK_JSON_FAILURE_INFO = 'EXPORT_BULK_JSON_FAILURE_INFO';
export const DOWNLOAD_ARCHIVE_SUCCESS_INFO = 'DOWNLOAD_ARCHIVE_SUCCESS_INFO';
export const DOWNLOAD_ARCHIVE_FAILURE_INFO = 'DOWNLOAD_ARCHIVE_FAILURE_INFO';
export const LINK_TRACKS_SUCCESS_INFO = 'LINK_TRACKS_SUCCESS_INFO';
export const LINK_TRACKS_FAILURE_INFO = 'LINK_TRACKS_FAILURE_INFO';
export const DID_CHANGE_PLAYLIST_TRACKS_STATUS_INFO = 'DID_CHANGE_PLAYLIST_TRACKS_STATUS_INFO';
export const NO_PLAYLIST_DISTRIBUTION = 'NO_PLAYLIST_DISTRIBUTION';
export const SEARCH_RESULT_ERROR_INFO = 'SEARCH_RESULT_ERROR_INFO';
export const STEMS_UPLOAD_FAILURE_INFO = 'STEMS_UPLOAD_FAILURE_INFO';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';
export const DOC_BINARIES_UPLOAD_FAILURE_INFO = 'DOC_BINARIES_UPLOAD_FAILURE_INFO';
export const FETCH_PANEL_ERROR_INFO = 'FETCH_PANEL_ERROR_INFO';
export const COPY_PANEL_METADATA_SUCCESS_INFO = 'COPY_PANEL_METADATA_SUCCESS_INFO';
export const PASTE_PANEL_METADATA_TYPE_ERROR_INFO = 'PASTE_PANEL_METADATA_TYPE_ERROR_INFO';
export const PASTE_PANEL_METADATA_ERROR_INFO = 'PASTE_PANEL_METADATA_ERROR_INFO';
export const MANUAL_SYNC_ARTIST_SUCCESS_INFO = 'MANUAL_SYNC_ARTIST_SUCCESS_INFO';
export const MANUAL_SYNC_ARTIST_ERROR_INFO = 'MANUAL_SYNC_ARTIST_ERROR_INFO';
