// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import CheckBox from './../../presentationals/checkbox/CheckBox';
import DropdownList from './../../presentationals/dropdownlist/DropdownList';

// Styles
import { Dropdown, DropdownTitle, IconArrowDown } from './../../../themes/entityGrid';

// Assets
import iconDropdownArrowDown from './../../../assets/images/icon-dropdown-arrow.svg';

// =============================
// Component
// =============================

class CheckBoxHeadCell extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    active: PropTypes.bool,

    onSelectAll: PropTypes.func,

    renderItems: PropTypes.func,
  };

  static defaultProps = {
    checked: false,
    active: false,

    onSelectAll: () => {},

    renderItems: () => {},
  };

  state = {
    isOpen: false,
  };

  toggleDropdown = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    const { checked, active, onSelectAll, renderItems } = this.props;
    const { isOpen } = this.state;

    return [
      <CheckBox check={checked} onClick={onSelectAll} key="0" />,
      active && (
        <Dropdown ml="0" mr="auto" key="1">
          <DropdownTitle onClick={this.toggleDropdown} className="ignore-react-onclickoutside">
            <IconArrowDown svg={iconDropdownArrowDown} height="100%" width="100%" />
          </DropdownTitle>
          <DropdownList
            handleClickOutside={this.toggleDropdown}
            disableOnClickOutside={!isOpen}
            height="260px"
            width="220px"
            isOpen={isOpen}
            overflow="visible"
          >
            {renderItems({ onSubmit: this.toggleDropdown })}
          </DropdownList>
        </Dropdown>
      ),
    ];
  }
}

export default CheckBoxHeadCell;
