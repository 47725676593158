// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';

// Styles
import { Title } from '../../presentationals/sidepanel/header/Header.styles';
import { Field, FieldLabel, FieldValue, Label } from './PrintContainer.styles';
import { Div } from '../../../themes/views';

class PrintContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any,
        renderer: PropTypes.func,
      })),
    })).isRequired,
    getData: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.props.getData(this.props.id);
  }

  renderFields(fields) {
    return fields.map(field => (
      <Field>
        <FieldLabel>{field.name}</FieldLabel>
        {
          isArray(field.value)
            ? <Div>{this.renderFields(field.value)}</Div>
            : field.value && <FieldValue>{field.value}</FieldValue>
        }
      </Field>
    ));
  }

  render() {
    const { title, data } = this.props;
    return (
      <Div>
        <Title>{title}</Title>
        {data.map(section => (
          <Div mt="8px">
            <Label>{section.label}</Label>
            <Div>
              {this.renderFields(section.fields)}
            </Div>
          </Div>
        ))}
      </Div>
    );
  }
}

export default PrintContainer;
