/* eslint-disable global-require */

// =============================
// Imports
// =============================

// External dependencies
import 'babel-polyfill';

import { AzureB2CProvider } from '@bmat/auth-feature-azure-b2c-react';
import React, { memo } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';

// Auth page styles
// TODO: uncomment when it would exist
// import '@bmat/auth-feature-react-auth-pages/auth-feature-react-auth-pages.esm.css';

// Components
import ScrollToTop from './../shared/components/presentationals/scrolltotop/ScrollToTop';

// Components
import App from '../shared/components/App';
import InitializerContainer from './../shared/components/containers/auth/Initializer';

// Store
import configureStore from './../shared/store/configureStore';

// Themes variables
import theme from './../shared/themes/variables';

// Config
import setupSentry from '../config/private/sentry';
import { msalClient } from '../config/private/msal-client';
import i18n from '../config/private/i18next';

// =============================
// Client
// =============================

// Setup sentry
setupSentry();

// Get the DOM Element that will host our React application.
const container = document.querySelector('#app');

// Init store
const store = configureStore();

function renderApp(TheApp) {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <AzureB2CProvider msalClient={msalClient} options={{}}>
                <InitializerContainer>
                  <ScrollToTop>
                    <TheApp />
                  </ScrollToTop>
                </InitializerContainer>
              </AzureB2CProvider>
            </I18nextProvider>
          </ThemeProvider>
        </AppContainer>
      </BrowserRouter>
    </Provider>,
    container,
  );
}

if (module.hot) {
  // Accept changes to this file for hot reloading.
  module.hot.accept('./index.js');
  // Any changes to our App will cause a hot reload re-render.
  module.hot.accept(
    './../shared/components/App',
    () => renderApp(memo(require('./../shared/components/App').default)),
  );
}

const MemoizedApp = memo(App);
// Execute the first render of our app.
renderApp(MemoizedApp);
