// =============================
// Imports
// =============================

// External Dependencies
import React, { Component, createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validate } from 'joi-browser';
import debounce from 'lodash/debounce';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash';
import WithI18n from './WithI18n';
import { translateWithLocale as t } from '../../helpers/I18n';

// Helpers
import { validatePercentages } from '../../helpers/helpers';

// Actions
import {
  fetchPanelData,
  savePanel,
  saveAdditionalPanelData,
  deletePanel,
  setData,
  copyMetadata,
  pasteMetadata,
} from './../../actions/SidePanelActions';

// =============================
// Component
// =============================

export const SubjectCtx = createContext();

export function useFormSubject(propertyKey) {
  const subject = useContext(SubjectCtx);
  const subscription = useRef();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (subscription.current) {
      subscription.current.unsubscribe();
    }

    if (subject) {
      subscription.current = subject.pipe(
        map((val) => {
          let partial = null;
          if (propertyKey) {
            const propertyPath = propertyKey.split('.');
            if (propertyPath.length === 1) {
              partial = val.values[propertyKey];
            } else {
              partial = val.values[propertyPath[0]] != null ? val.values[propertyPath[0]] : null;
              for (let i = 1; i < propertyPath.length && partial !== null; i += 1) {
                partial = partial[propertyPath[i]] || null;
              }
            }
          }

          const retValue = partial != null ? partial : '';
          return {
            value: retValue,
            error: _.get(val.errors, propertyKey),
          };
        }),
        distinctUntilChanged((a, b) => a.value === b.value && a.error === b.error),
      ).subscribe({ next: val => setValue(val) });
    }

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [subject, setValue, propertyKey]);

  return value;
}

export const ObservableInput = (WrappedComponent) => {
  function ObservableInputHOC(props) {
    const { propertyKey, value, error, valuesMapping, valuesTransform, ...otherProps } = props;
    const subjectValue = useFormSubject(propertyKey);

    const retvalue = valuesTransform ? valuesTransform(subjectValue.value) : subjectValue.value;

    const valueErrorProps = valuesMapping ? {
      [valuesMapping('value')]: retvalue,
      [valuesMapping('error')]: subjectValue.error,
    } : {
      value: retvalue,
      error: subjectValue.error,
    };

    return (
      <WrappedComponent
        {...otherProps}
        {...valueErrorProps}
      />
    );
  }

  ObservableInputHOC.propTypes = {
    propertyKey: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    valuesMapping: PropTypes.func,
    valuesTransform: PropTypes.func,
  };

  ObservableInputHOC.defaultProps = {
    value: '',
    error: null,
    valuesMapping: x => x,
    valuesTransform: x => x,
  };

  return ObservableInputHOC;
};

const getValidSchema = (user, schema) => {
  if (typeof schema === 'function') {
    const customFields = _.get(user, ['customFields', 'data', 'fields'], []);
    return schema(user.userInfo.data.validation_fields, customFields);
  }
  return schema;
};

const getDefaultCustomValues = (user) => {
  const customFields = _.get(user, ['customFields', 'data', 'fields'], []);
  return _.filter(customFields, customField => !_.isNil(customField.defaultValue));
};

export function formPanelPresentational(
  schema,
  panelToFormValuesTransformer = null,
  fetchData = null,
  updateData = null,
  formValuesToRequestTransformer = null,
) {
  return (WrappedComponent) => {
    class HOC extends Component {
      pendingSave = false;

      formSubject = new BehaviorSubject({ values: {}, errors: {}, stopSave: false, saved: true });

      constructor(props) {
        super(props);

        this.state = {
          form: {
            values: {},
            errors: {},
          },
        };

        this.save = debounce(this.save.bind(this), 2000);
      }

      componentWillMount() {
        // When mounting, if the panel has an id, it is an existing document
        // Thus, we need to fetch the data once
        if (this.props.panel.id) {
          this.props.fetchPanelData(this.props.panel, fetchData);
        }
      }

      componentWillReceiveProps(nextProps) {
        const { panel } = this.props;
        const { panel: nextPanel } = nextProps;

        if (_.get(nextProps, 'panel.isMetadataSaved', null) === false) this.save();
        // No data, i.e. when error
        if (!nextPanel.data) return;
        if (
          // Not fetching finished
          !(panel.isLoading && !nextPanel.isLoading) &&
          // Not saving finished
          !(panel.isSaving && !nextPanel.isSaving)
          // No nextPanel, i.e. error
        ) {
          return;
        }
        // If fetching or saving is done

        const data = this.doFormMutation(nextPanel);
        const validSchema = getValidSchema(nextProps.user, nextProps.schema);
        // We go through Joi to strip unknown values
        validate(data, validSchema, { stripUnknown: true }, (err, values) => {
          this.formSubject.next({ ...this.formSubject.value, values, saved: true });
        });
      }

      /**
       * Check if there are errors in form values using Joi
       */
      getErrors = () => {
        const validSchema = getValidSchema(this.props.user, this.props.schema);
        const result = validate(this.formSubject.value.values, validSchema, {
          abortEarly: false,
          allowUnknown: true,
        });
        const { values: newValues } = this.formSubject.getValue();
        const errors = { };
        const exceptionSigns = ['$'];
        const exceptionFields = ['title', 'full_name'];
        exceptionFields.forEach((field) => {
          if (newValues[field]) {
            exceptionSigns.forEach((sign) => {
              if (newValues[field].indexOf(sign) !== -1) {
                _.set(errors, field, `${t('notifications.exceptionString') } - $`);
              }
            });
          }
        });
        let countErrors = Object.keys(errors).length;

        if (result.error !== null) {
          const { error: { details } } = result;

          for (let i = 0; i < details.length; i += 1) {
            countErrors += 1;
            _.set(errors, details[i].path, details[i].message);
          }
        }
        // Return errors if they exist
        return countErrors > 0 ? errors : false;
      };

      saveErrors = (errors) => {
        this.formSubject.next({
          ...this.formSubject.value,
          errors: {
            ...this.formSubject.value.errors,
            ...errors,
          },
          saved: true,
        });
      };

      /**
       * Update the current document
       * @param {string} name - Key of the value to be updated
       * @param {string} value - Key of the value to be updated
       */
      updateForm = (name, value) => {
        if (_.isEmpty(this.formSubject.value.values)) {
          const { user } = this.props;
          const newValues = { ...this.formSubject.value.values };
          const defaultValuesForCustomFields = getDefaultCustomValues(user);

          // init custom object field if not exist
          if (!_.get(newValues, ['custom']) && !_.isEmpty(defaultValuesForCustomFields)) {
            newValues.custom = {};
          }

          defaultValuesForCustomFields.forEach((defaultObject) => {
            if (_.isNil(newValues.custom[defaultObject.key])) {
              newValues.custom[defaultObject.key] = defaultObject.defaultValue;
            }
          });

          this.formSubject.next({ values: newValues });
        }

        this.formSubject.next({
          values: {
            ...this.formSubject.value.values,
            [name]: value,
          },
          errors: {
            ...this.formSubject.value.errors,
            // Reset error for value
            [name]: '',
            publishing_ownerships: '',
            master_ownerships: '',
            artists_publishing_ownerships: '',
          },
          saved: false,
        });

        const errors = this.getErrors();
        if (errors) {
          this.saveErrors(errors);
        } else {
          this.save();
        }
      }

      /**
       * Saves the document
       * with delay in 1000ms
       */
      save = () => {
        setTimeout(() => {
          const { panel, savePanel: propsSavePanel } = this.props;
          const { stopSave } = this.formSubject.value;

          if (stopSave) {
            return;
          }

          const someErrors = this.getErrors();
          if (!someErrors) {
            propsSavePanel(
              formValuesToRequestTransformer(this.formSubject.value.values, panel),
              panel,
              updateData,
            );
          } else {
            this.saveErrors(someErrors);
          }
        }, 1000);
      };

      delaySave = (isStop = false) => {
        const { saved } = this.formSubject.value;
        this.formSubject.next({ ...this.formSubject.value, stopSave: isStop });
        if (!isStop && !saved) {
          this.save();
        }
      };

      /**
       * Mutate form so it becomes usable by the react components
       * @param {Object} form - data retrieved by the api
       */
      doFormMutation = (panel) => {
        const form = panel.data;
        if (panelToFormValuesTransformer) {
          const nextData = {};

          Object.keys(form).forEach((name) => {
            const valueMutator = panelToFormValuesTransformer[name];

            if (valueMutator && typeof valueMutator === 'function' && form[name] !== undefined) {
              nextData[name] = valueMutator(form[name]);
            } else {
              nextData[name] = form[name];
            }
          });

          return nextData;
        }

        return form;
      };

      /**
       * Function used to edit documents which are linked to the current document
       * @param {string} name - Key of value to be updated
       * @param {string} value - Value to be updated
       * @param {Object} query - Query to be executed
       * @param {Object} getQueries - Queries to be executed after update
       */
      updateAdditionalFormData = (name, value, query, getQueries) => {
        const { panel } = this.props;
        const data = name ? { [name]: value } : null;

        if (panel.id) {
          this.props.saveAdditionalPanelData(data, panel, query, getQueries);
        }
      };
      /**
       * Delete document
       */
      deleteForm = () => {
        const { panel } = this.props;

        // Delete can only be called if document exists
        if (panel.id) {
          this.props.deletePanel(panel);
        }
      };

      render() {
        // Strip unnecessary values from props
        const { fetchPanelData: _1, savePanel: _2, ...rest } = this.props;

        return (
          <SubjectCtx.Provider value={this.formSubject}>
            <WrappedComponent
              updateForm={this.updateForm}
              updateAdditionalFormData={this.updateAdditionalFormData}
              delaySave={this.delaySave}
              deleteForm={this.deleteForm}
              form={this.state.form}
              {...rest}
            />
          </SubjectCtx.Provider>
        );
      }
    }

    HOC.propTypes = {
      panel: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          // For batch edit of tracks, we need to specify array of ids
          PropTypes.arrayOf(PropTypes.string),
        ]),
        isSaving: PropTypes.bool.isRequired,
        uuid: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        data: PropTypes.object,
        isLoading: PropTypes.bool,
      }).isRequired,
      // Joi validation schema
      schema: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired, // eslint-disable-line
      fetchPanelData: PropTypes.func.isRequired,
      savePanel: PropTypes.func.isRequired,
      setData: PropTypes.func.isRequired,
      copyMetadata: PropTypes.func.isRequired,
      pasteMetadata: PropTypes.func.isRequired,
      saveAdditionalPanelData: PropTypes.func.isRequired,
      deletePanel: PropTypes.func.isRequired,
      locale: PropTypes.string.isRequired,
    };

    return HOC;
  };
}

export function formPanel(
  schema,
  panelToFormValuesTransformer = null,
  fetchData = null,
  updateData = null,
  formValuesToRequestTransformer = v => v,
) {
  return (WrappedComponent) => {
    const HOC = WithI18n()(formPanelPresentational(
      schema,
      panelToFormValuesTransformer,
      fetchData,
      updateData,
      formValuesToRequestTransformer,
    )(WrappedComponent));
    function mapStateToProps(state) {
      const { user } = state;
      return {
        user,
        schema,
      };
    }

    return connect(mapStateToProps, {
      fetchPanelData,
      savePanel,
      saveAdditionalPanelData,
      setData,
      copyMetadata,
      pasteMetadata,
      deletePanel,
    })(HOC);
  };
}

export default formPanel;
