// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trim, isNull } from 'lodash';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Styles
import {
  Container,
  DeleteChip,
  FlexBreakWord,
} from './Chip.styles';

// Assets
import iconCross from './../../../assets/images/icon-cross.svg';

// =============================
// Component
// =============================

class Chip extends Component {
  deleteChip(e) {
    const { handleDelete, inputRef } = this.props;
    // Backspace
    if (e.key === 'Backspace') {
      e.preventDefault();

      if (document.activeElement === e.target) {
        handleDelete();
      }
    }

    // Give focus back to input (or next backspace would result going to previous page)
    inputRef.focus();
  }

  handleClick(event) {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event);
    }
  }

  render() {
    const { name, originName, handleDelete, onClick, whiteSpace, ...rest } = this.props;
    let title = name;
    if (!isNull(originName) && !trim(originName)) {
      title = null;
    } else if (title && title === ' ') {
      title = null;
    }

    return (
      <Container
        {...getViewProps(rest)}
        tabIndex={handleDelete ? '-1' : null}
        onKeyDown={e => this.deleteChip(e)}
        activeHover={!!onClick}
      >
        <FlexBreakWord
          onClick={onClick ? (e) => { this.handleClick(e); } : null}
          innerRef={(e) => { this.accessChipRef = e; }}
          whiteSpace={whiteSpace}
          padding="0 6px"
        >
          {title || <span>&nbsp;</span>}
        </FlexBreakWord>

        {handleDelete &&
          <DeleteChip
            onClick={(e) => { e.stopPropagation(); handleDelete(); }}
            svg={iconCross}
            height="8px"
            width="8px"
          />
        }
      </Container>
    );
  }
}

Chip.propTypes = {
  name: PropTypes.node.isRequired,
  originName: PropTypes.string,
  handleDelete: PropTypes.func,
  onClick: PropTypes.func,
  inputRef: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  whiteSpace: PropTypes.string,
};

Chip.defaultProps = {
  originName: null,
  handleDelete: null,
  onClick: null,
  inputRef: null,
  whiteSpace: '',
};

export default Chip;
