import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../actions/AuthActions';
import { yleLogout } from '../../../actions/YleActions';


function LogoutPage(props) {
  useEffect(() => {
    const { yle } = props;
    if (yle) {
      props.yleLogout();
    } else {
      props.logout();
    }
  }, []);

  return null;
}

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired,
  yleLogout: PropTypes.func.isRequired,
  yle: PropTypes.bool.isRequired,
};


function mapStateToProps({ yle }) {
  return {
    yle: yle.isYle,
  };
}

export default connect(() => (mapStateToProps), { logout, yleLogout })(LogoutPage);
