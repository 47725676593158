/* eslint-disable import/prefer-default-export */

// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// Other styles
import { playerHeight } from './../player/Player.styles';

// =============================
// Styled Components
// =============================

const spacerWithPlayer = parseInt(playerHeight, 10) + 20;

export const Wrapper = styled.div`
  bottom: ${props => (props.player ? spacerWithPlayer : 20)}px;
  left: 20px;
  position: fixed;
  z-index: ${props => props.theme.zIndex.notifications};
`;
