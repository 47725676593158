/* eslint-disable no-multi-spaces */

import Joi from 'joi-browser';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import values from 'lodash/values';
import first from 'lodash/first';

// =============================
// Custom schema validation helpers
// =============================

/**
 * Types which have no parameters
 * @type {string[]}
 */
const types = ['required', 'string', 'number', 'boolean', 'any'];

const specialTypes = {
  REGEX: 'regex',
};

export function getFieldsByCollection(schemaName, data = []) {
  if (!data) return [];
  return data.filter(field =>
    field.collections && isArray(field.collections) && field.collections.includes(schemaName),
  );
}

/**
 * Get custom field label if exist or default
 * @param rules: Array
 * @param defaultLabel: String
 * @returns {*}
 */
function getRuleLabel(rules, defaultLabel) {
  let label = defaultLabel;
  rules.forEach((rule) => {
    if (rule && rule.label) {
      const { label: ruleLabel } = rule;
      label = ruleLabel;
    }
  });
  return label;
}

/**
 * Get all field names of object field in schema
 * @param defaultSchema: TrackSchema, AlbumSchema, etc
 * @param key
 * @returns {*}
 */
function getDefaultObjectSchemaFields(defaultSchema, key) {
  return defaultSchema[key]._inner.children.map(child => child.key); // eslint-disable-line
}

/**
 * Get existing object fields of schema
 * @param defaultSchema: TrackSchema, AlbumSchema, etc
 * @param key
 * @param fields: Array
 * @returns {[]}
 */
function getExistingObjectSchemaFields(defaultSchema, key, fields = []) {
  return defaultSchema[key]._inner.children.filter(child => fields.includes(child.key)); // eslint-disable-line
}

/**
 * Get all field names of array field in schema
 * @param defaultSchema: TrackSchema, AlbumSchema, etc
 * @param key
 * @returns {[]}
 */
function getDefaultArraySchemaFields(defaultSchema, key) {
  const allKeys = [];
  defaultSchema[key]._inner.items.forEach(item => { // eslint-disable-line
    item._inner.children.forEach(child => allKeys.push(child.key)); // eslint-disable-line
  });
  return allKeys;
}

/**
 * Get existing array fields of schema
 * @param defaultSchema: TrackSchema, AlbumSchema, etc
 * @param key
 * @param fields: Array
 * @returns {[]}
 */
function getExistingArraySchemaFields(defaultSchema, key, fields = []) {
  const schemaFields = [];
  defaultSchema[key]._inner.items.forEach(item => { // eslint-disable-line
    item._inner.children.filter(child => fields.includes(child.key)) // eslint-disable-line
      .forEach(child => schemaFields.push(child));
  });
  return schemaFields;
}

function getCustomKeys(fields = []) {
  return fields.map(field => field.key);
}

function ruleIsType(rule) {
  return types.includes(rule);
}

/**
 * Create Joi object
 * @param originJoi: Object -> root Joi object
 * @param rules: Array
 * @param key: String
 * @param parentKey: String
 * @param labels: Array
 */
function createJoi(originJoi, { rules, key, parentKey = null, labels }) {
  let joi = originJoi;
  rules.forEach(rule => Object.keys(rule).forEach((ruleKey) => {
    if (ruleIsType(ruleKey)) {
      joi = joi[ruleKey]();
    } else if (specialTypes.REGEX === ruleKey) {
      const regex = new RegExp(rule[ruleKey]);
      joi = joi[ruleKey](regex).options({
        language: {
          string: {
            regex: {
              base: [
                ...new Map([
                  ['de', 'enthält unzulässige Zeichen'],
                  ['en', 'contains illegal characters'],
                  ['fi', 'sisältää kiellettyjä merkkejä'],
                  ['it', 'contiene caratteri illegali'],
                  ['sv', 'innehåller otillåtna tecken'],
                ]).values(),
              ].join(' / '),
            },
          },
        },
      });
    } else {
      joi = joi[ruleKey](rule[ruleKey]);
    }
  }));

  let label = parentKey ? get(labels, [parentKey][key]) : labels[key];
  if (typeof label !== 'string') {
    label = get(label, ['default'], '');
  }

  if (label) {
    joi = joi.label(getRuleLabel(rules, label));
  }

  return joi;
}

/**
 * Create Joi rules for field
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @param parentKey -> if field are nested
 * @returns {{}}
 */
function getFieldRule(field, opt, parentKey = null) {
  const { labels } = opt;
  const { key, type, rules = [] } = field;

  try {
    if (type === 'array') {
      const joi = createJoi(Joi, { rules, parentKey, key, labels });
      return {
        [key]: Joi.array().items(joi),
      };
    }
    if (type === 'object') {
      const joi = createJoi(Joi, { rules, parentKey, key, labels });
      return {
        [key]: Joi.object().keys(joi),
      };
    }
    if (type === 'custom') {
      return {
        [key]: createJoi(Joi, { rules, parentKey, key, labels }),
      };
    }
    return {
      [key]: createJoi(Joi, { rules, parentKey, key, labels }),
    };
  } catch (e) {
    return {};
  }
}

/**
 * Get field rules for field with type 'object' or 'array'
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @param fieldIsArray: boolean
 * @returns {{}}
 */
function getFieldRules(field, opt, fieldIsArray = false) {
  const { key, fields } = field;
  const { defaultSchema } = opt;

  const allKeys = fieldIsArray
    ? getDefaultArraySchemaFields(defaultSchema, key)
    : getDefaultObjectSchemaFields(defaultSchema, key);
  const allCustomKeys = getCustomKeys(fields);
  const diff = difference(allKeys, allCustomKeys);

  // set custom field schemas
  let fieldRules = {};
  fields.forEach((customField) => {
    fieldRules = {
      ...fieldRules,
      ...getFieldRule(customField, opt, key),
    };
  });

  // set origin field schemas which not describes in custom fields
  const defaultFields = fieldIsArray
    ? getExistingArraySchemaFields(defaultSchema, key, diff)
    : getExistingObjectSchemaFields(defaultSchema, key, diff);
  defaultFields.forEach((defaultField) => {
    fieldRules = {
      ...fieldRules,
      [defaultField.key]: defaultField.schema,
    };
  });

  return fieldRules;
}

function createRootJoiWithRules(field, opt, isUseArrayType = false) {
  const { key, rules = [] } = field;
  const { labels } = opt;
  const joi = isUseArrayType ? Joi.array() : Joi.object();

  return createJoi(joi, { rules, key, labels });
}

/**
 * Get field object of Joi object rules
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @returns {{}}
 */
function objectRules(field, opt) {
  const { key } = field;

  const rootJoi = createRootJoiWithRules(field, opt, false);
  const fieldRules = getFieldRules(field, opt);

  return {
    [key]: rootJoi.keys({ ...fieldRules }),
  };
}

/**
 * Get field object of Joi array rules
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @returns {{}}
 */
function arrayRules(field, opt) {
  const { key } = field;
  const rootJoi = createRootJoiWithRules(field, opt, true);
  const fieldRules = getFieldRules(field, opt, true);

  return {
    [key]: rootJoi.items(Joi.object().keys({ ...fieldRules })),
  };
}

/**
 * Get rule for custom field
 * Can be simple field or array of type. Need property isArray = true
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @returns {{}}
 */
function customFieldRule(field, opt) {
  const { key, isArray: fieldIsArray } = field;
  if (!fieldIsArray) {
    return getFieldRule(field, opt, key);
  }

  const arrayObject = getFieldRule(field, opt, key);
  return {
    [key]: Joi.array().items(first(values(arrayObject))),
  };
}

/**
 * Get field rule by types: array, object, etc
 * @param field: Object
 * @param opt: Object -> defaultSchema, labels
 * @returns {{}|undefined}
 */
function identifyField(field, opt = {}) {
  const { type } = field;

  if (!type) {
    return {};
  }

  const fieldType = type.toLowerCase();

  // to prevent any wrong data for field
  try {
    if (fieldType === 'array') {
      return arrayRules(field, opt);
    }
    if (fieldType === 'object') {
      return objectRules(field, opt);
    }
    return getFieldRule(field, opt);
  } catch (e) {
    return {};
  }
}

/**
 * Create custom schema for validation
 * @param fields: Array
 * @param custom_fields: Array
 * @param opt: Object -> defaultSchema, labels
 */
export function createCustomSchema(fields = [], custom_fields = [], opt = {}) {
  const { defaultSchema } = opt;
  let schema = { ...defaultSchema };

  fields.forEach((field) => {
    // skip fields with type custom
    if (field.type === 'custom') {
      return;
    }
    schema = {
      ...schema,
      ...identifyField(field, opt),
    };
  });

  // add custom fields to schema
  const customFields = fields.filter(field => field.type === 'custom');
  if (!isEmpty(customFields)) {
    let custom = {};

    custom_fields.forEach((field) => {
      custom[field.key] = Joi.any();
    });

    customFields.forEach((field) => {
      const customObjectRule = customFieldRule(field, opt);
      custom = {
        ...custom,
        [first(keys(customObjectRule))]: first(values(customObjectRule)),
      };
    });

    schema = {
      ...schema,
      custom: Joi.object().keys(custom).required(),
    };
  }

  return schema;
}
