// =============================
// Imports
// =============================

// External Dependencies
import styled, { keyframes } from 'styled-components';
import SVGInline from 'react-svg-inline';

import { Div, Flex } from '../../../themes/views';


export const InlineFlex = styled(Flex)`
  display: inline-flex;
`;

// Wave
const waveAnim = keyframes`
  from {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }

  to {
    stroke-dashoffset: -133;
    transform: translate3d(-90px, 0, 0);
  }
`;

export const Wave = styled(SVGInline)`
  path {
    animation: ${waveAnim} 2.5s linear infinite;
  }
`;

// Image
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ImageTag = styled(Div)`
  animation: ${fadeIn} 0.5s linear 1;
  background-image: url(${props => `"${props.src}"`});
  background-size: cover;
  background-position: center;
  height: ${props => (props.height ? props.height : '100%')};
  width: ${props => (props.width ? props.width : '100%')};
`;
