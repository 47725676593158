// =============================
// Imports
// =============================

// External Dependencies
import { useAzureB2C } from '@bmat/auth-feature-azure-b2c-react';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { Switch } from 'react-router';
// Configs
import env from './../../../../config/private/environment';
import { getBmatUserData } from '../../../actions/AuthActions';
// Actions
import { getUserInfos } from '../../../actions/UserActions';
// HOC
import withI18n from './../../hoc/WithI18n';
// Components
import Loader from './../../presentationals/loader/Loader';
// Constants
import * as routes from './../../../constants/Routes';
import { Login } from '../../pages/auth/login';
import { getCookieConfig } from '../../../helpers/helpers';

// =============================
// Helper
// =============================

export function getQueryParams() {
  const pl = /\+/g;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(pl, ' '));

  const query = window.location.search.substring(1);
  const urlParams = new Map();
  for (let match = search.exec(query); match !== null; match = search.exec(query)) {
    urlParams.set(decode(match[1]), decode(match[2]));
  }

  return urlParams;
}

// =============================
// Component
// =============================

function Initializer(props) {
  const { t } = props;
  const auth = useAzureB2C();

  useEffect(() => {
    const queryToken = getQueryParams().get('token');
    const token = cookies.get('x-auth');
    console.log(token);
    console.log(queryToken);
    console.log(env.appEnv);
    console.log(auth);
    if (queryToken && env.appEnv !== 'prod') {
      props.getUserInfos(queryToken);
    } else if (!auth.isAuthenticated && !auth.isLoading) {
      cookies.remove('x-auth', getCookieConfig(null, true));
      auth.login();
    } else if (token) {
      props.getUserInfos(token);
    }

    if (auth.isAuthenticated) {
      auth.getTokenSilently([env.bmatAuthScope])
        .then(b2cToken => props.getBmatUserData(b2cToken.accessToken))
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    }
  }, []);

  const fallBack = (<Loader fullscreen text={t('components.initializer.loading')} />);

  let next = fallBack;
  if (props.ready) {
    next = props.children;
  }

  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.LOGIN} component={Login} />
      </Switch>
      { next }
    </Fragment>
  );
}


Initializer.propTypes = {
  children: PropTypes.node.isRequired,
  ready: PropTypes.bool.isRequired,
  getUserInfos: PropTypes.func.isRequired,
  getBmatUserData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


function mapStateToProps({ core }) {
  return {
    ready: core.ready,
  };
}

export default withI18n()(
  withRouter(
    connect(
      mapStateToProps,
      { getUserInfos, getBmatUserData },
    )(Initializer),
  ),
);
