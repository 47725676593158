/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Themes
import { Div } from './../../../../themes/views';


// =============================
// Styled Components
// =============================

export const Container = styled(Div)`
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 12px;
  display: flex;
  height: 26px;
  width: 56px;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;
  margin-bottom: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Text = styled.div`
  ${props => props.theme.fontSize.l};
  color: ${props => (props.active ? '#000' : props.theme.colors.text.grey)};
`;

export const Switch = styled.div`
  align-items: center;
  background-color: #b3b3b3;
  border-radius: 50%;
  display: flex;
  height: 26px;
  justify-content: center;
  transition: background-color ${props => props.theme.transition.medium}, transform ${props => props.theme.transition.medium};
  width: 26px;
  ${props => props.checked && css`
    background-color: #0c9;
    transform: translate3d(31px, 0, 0);
  `};
`;

export const IconCheck = styled(SVGInline)`
  stroke: #b3b3b3;
  transition: stroke ${props => props.theme.transition.medium};
  ${props => props.checked && css`
    stroke: #fff;
  `};
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
