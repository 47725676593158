/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// Constants
import { INITIALIZE_APP } from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * Initialize the application
 */
export function initializeApp() {
  return {
    type: INITIALIZE_APP,
  };
}
