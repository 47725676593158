// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Constants
import {
  HOME_PAGE_ROUTE,
  MULTIPART_TRACKS_PAGE_ROUTE,
  ALBUMS_PAGE_ROUTE,
  PLAYLISTS_PAGE_ROUTE,
  CATALOGS_PAGE_ROUTE,
  PROVIDERS_PAGE_ROUTE,
} from './../../../../constants/Routes';

// Constants
import {
  TRACKS,
  ALBUMS,
  PLAYLISTS,
  CATALOGS,
  MULTIPART_TRACKS,
} from './../../../../constants/SearchPanelTypes';

// Styles
import {
  Container,
  Entity,
  EntityName,
  EntitiesNb,
} from './MenuTabs.styles';

// =============================
// Component
// =============================

const MenuTabs = ({ searchData, t }) => (
  <Container>
    <Entity exact to={HOME_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.tracks')}</EntityName>
      <EntitiesNb>{searchData[TRACKS].nbResults}</EntitiesNb>
    </Entity>
    <Entity to={MULTIPART_TRACKS_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.multiparts')}</EntityName>
      <EntitiesNb>{searchData[MULTIPART_TRACKS].nbResults}</EntitiesNb>
    </Entity>
    <Entity to={ALBUMS_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.albums')}</EntityName>
      <EntitiesNb>{searchData[ALBUMS].nbResults}</EntitiesNb>
    </Entity>
    <Entity to={PLAYLISTS_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.playlists')}</EntityName>
      <EntitiesNb>{searchData[PLAYLISTS].nbResults}</EntitiesNb>
    </Entity>
    <Entity to={CATALOGS_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.catalogs')}</EntityName>
      <EntitiesNb>{searchData[CATALOGS].nbResults}</EntitiesNb>
    </Entity>
    <Entity to={PROVIDERS_PAGE_ROUTE}>
      <EntityName>{t('components.menus.menuTabs.providers')}</EntityName>
    </Entity>
  </Container>
);

MenuTabs.propTypes = {
  searchData: PropTypes.object.isRequired, // eslint-disable-line
  t: PropTypes.func.isRequired,
};

export default withI18n()(MenuTabs);
