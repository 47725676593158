/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

export const Toggler = styled.div`
  cursor: pointer;
  padding: 8px 0;
  font-size: 16px;
`;
