// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import { generate as randomString } from 'randomstring';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  margin-right: auto;
  margin-top: 30px;
  width: 100%;
`;

export const activeClassName = `active-${randomString(6)}`;
export const Entity = styled(NavLink).attrs({
  activeClassName,
})`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.l};
  color: ${props => props.theme.colors.text.grey};
  cursor: pointer;
  display: flex;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    & > div {
      color: ${props => props.theme.colors.text.greyDark};
    }
  }

  &.${activeClassName} {
    & > div {
      color: ${props => props.theme.colors.text.greyDark};
      border-bottom: 5px solid ${props => props.theme.colors.text.greyDark};
    }
  }
`;

export const EntityName = styled.div`
  margin-right: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
`;

export const EntitiesNb = styled.span`
  ${props => props.theme.fonts.openSansSemiBold};
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  color: ${props => props.theme.colors.primary};
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0 7px;
  position: relative;
  top: 4px;
`;
