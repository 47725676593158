// =============================
// Imports
// =============================

// External Dependencies
import axios from './../helpers/axios';

// Config
import env from './../../config/private/environment';

// Constants
import {
  AUTH_YLE_LOGOUT,
  MANUAL_SYNC_ARTIST_FAILURE,
  MANUAL_SYNC_ARTIST_LOADING,
  MANUAL_SYNC_ARTIST_SUCCESS,
} from '../constants/ActionTypes';

/**
 * Log out the user from yle
 */
export function yleLogout() {
  return (dispatch) => {
    dispatch({
      type: AUTH_YLE_LOGOUT,
    });
  };
}

/**
 * Manual sync trig artist update
 * @param {string} artistId - Artist ID
 */
export function syncArtist(artistId) {
  return (dispatch, getState) => {
    dispatch({
      type: MANUAL_SYNC_ARTIST_LOADING,
    });

    return (
      axios({
        method: 'post',
        url: `${env.apiUrl}/meta/sync-yle/trig-artist-sync/${artistId}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.sessionToken,
        },
      })
        .then(() =>
          dispatch({
            type: MANUAL_SYNC_ARTIST_SUCCESS,
          }),
        )
        // The only error possible is a invalid_token
        // Since we are going through the axios helper, user will be redirected to dashboard
        .catch(() =>
          dispatch({
            type: MANUAL_SYNC_ARTIST_FAILURE,
          }),
        )
    );
  };
}
