// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import Button from './ButtonOutline.styles';

// =============================
// Component
// =============================

const ButtonOutline = ({ children, warning, ...props }) => (
  <Button warning={warning} {...props}>{children}</Button>
);

ButtonOutline.propTypes = {
  children: PropTypes.node.isRequired,
  warning: PropTypes.bool,
};

ButtonOutline.defaultProps = {
  warning: false,
};

export default ButtonOutline;
