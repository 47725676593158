// =============================
// Imports
// =============================

// External Dependencies
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// All of our reducers
import * as reducers from './../reducers';

// All of our middlewares
import authMiddleware from './../middlewares/authMiddleware';
import sidePanelMiddleware from './../middlewares/sidePanelMiddleware';
import searchMiddleware from './../middlewares/searchMiddleware';
import playerMiddleware from './../middlewares/playerMiddleware';
import notificationsMiddleware from './../middlewares/notificationsMiddleware';

// =============================
// configureStore
// =============================

// Combine all reducers
const reducer = combineReducers(reducers);

// List of all our middlewares
const middlewares = [
  authMiddleware,
  sidePanelMiddleware,
  searchMiddleware,
  playerMiddleware,
  notificationsMiddleware,
  thunk,
];

export default function configureStore(initialState = {}) {
  return createStore(reducer, initialState, compose(
    applyMiddleware(...middlewares),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  ));
}
