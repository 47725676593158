// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// =============================
// Styled Components
// =============================

export const Container = styled.div`
  height: 13px;
  overflow: hidden;
  position: relative;
  width: 9px;
  margin-left: 10px;
  top: 2px;
`;

export const IconArrowDown = styled(SVGInline)`
  position: absolute;
  bottom: 0;
  align-items: center;
  display: inline-flex;
  fill: #999;
  justify-content: center;
  height: 4px;
  width: 9px;
  ${props =>
    props.status === 'ascending' &&
    css`
      fill: ${props.theme.colors.primary};
    `};
`;

export const IconArrowUp = styled(SVGInline)`
  position: absolute;
  top: 0;
  align-items: center;
  display: inline-flex;
  fill: #999;
  justify-content: center;
  margin: 0;
  height: 4px;
  width: 9px;
  transform: rotate(180deg);
  ${props =>
    props.status === 'descending' &&
    css`
      fill: ${props.theme.colors.primary};
    `};
`;
