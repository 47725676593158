// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { generate as randomString } from 'randomstring';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Theme
import { Flex } from './../../../../themes/views';

// Styles
import { Rating, Label, ErrorText } from './RatingInput.styles';

// =============================
// Component
// =============================

function handleClick(name, value, onClick) {
  if (onClick) {
    onClick(name, parseInt(value, 10));
  }
}

const RatingInput = ({ name, label, onClick, value, error, ...props }) => {
  const idPrefix = randomString();
  return (
    <Flex align="flex-start" direction="column" {...getViewProps(props)}>
      {label && <Label>{label}</Label>}
      <Rating
        activeHover={!!onClick}
        onClick={(e) => { handleClick(name, e.target.value, onClick); }}
      >
        <input type="radio" id={`${idPrefix}star5`} name={`${idPrefix}-${name}`} value="10" checked={value === 10} onChange={() => null} />
        <label className="full" htmlFor={`${idPrefix}star5`} />
        <input type="radio" id={`${idPrefix}star4half`} name={`${idPrefix}-${name}`} value="9" checked={value === 9} onChange={() => null} />
        <label className="half" htmlFor={`${idPrefix}star4half`} />

        <input type="radio" id={`${idPrefix}star4`} name={`${idPrefix}-${name}`} value="8" checked={value === 8} onChange={() => null} />
        <label className="full" htmlFor={`${idPrefix}star4`} />
        <input type="radio" id={`${idPrefix}star3half`} name={`${idPrefix}-${name}`} value="7" checked={value === 7} onChange={() => null} />
        <label className="half" htmlFor={`${idPrefix}star3half`} />

        <input type="radio" id={`${idPrefix}star3`} name={`${idPrefix}-${name}`} value="6" checked={value === 6} onChange={() => null} />
        <label className="full" htmlFor={`${idPrefix}star3`} />
        <input type="radio" id={`${idPrefix}star2half`} name={`${idPrefix}-${name}`} value="5" checked={value === 5} onChange={() => null} />
        <label className="half" htmlFor={`${idPrefix}star2half`} />

        <input type="radio" id={`${idPrefix}star2`} name={`${idPrefix}-${name}`} value="4" checked={value === 4} onChange={() => null} />
        <label className="full" htmlFor={`${idPrefix}star2`} />
        <input type="radio" id={`${idPrefix}star1half`} name={`${idPrefix}-${name}`} value="3" checked={value === 3} onChange={() => null} />
        <label className="half" htmlFor={`${idPrefix}star1half`} />

        <input type="radio" id={`${idPrefix}star1`} name={`${idPrefix}-${name}`} value="2" checked={value === 2} onChange={() => null} />
        <label className="full" htmlFor={`${idPrefix}star1`} />
        <input type="radio" id={`${idPrefix}starhalf`} name={`${idPrefix}-${name}`} value="1" checked={value === 1} onChange={() => null} />
        <label className="half" htmlFor={`${idPrefix}starhalf`} />
      </Rating>
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  );
};

RatingInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.number,
  error: PropTypes.string,
};

RatingInput.defaultProps = {
  label: '',
  onClick: null,
  value: 1,
  error: '',
};

export default RatingInput;
