// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

// styles
import { Flex } from '../../../../themes/views';

export const Container = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding-bottom: 20px;

  .image {
    height: 100px;
    width: 100px;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  background: #fff;
  overflow: hidden;
  top: 0;
  padding-top:30px;
  z-index: ${props => props.theme.zIndex.sidepanel + 1};
`;

export const Title = styled.span`
  ${props => props.theme.fontSize.l};
  ${props => props.theme.fonts.openSansBold};
  display: inline-block;
  max-width: 520px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Image = styled(Flex)`
  background-color: #eee;
  height: 100px;
  margin-right: 30px;
  width: 100px;
`;

export const Actions = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.borderRadius.box};
  margin-right: 30px;
  height: 100px;
  padding: 10px 20px;
  width: 180px;
`;

export const Action = styled.div`
  ${props => props.theme.fontSize.xs};
  align-items: center;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  stroke: #999;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child):not(:first-child) {
    margin-bottom: 3px;
  }
`;

export const ActionIcon = styled(SVGInline)`
  align-items: center;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  margin-right: 8px;
  stroke: #999;
  width: 18px;
`;

export const Errors = styled.div`
  ${props => props.theme.fontSize.m};
  align-items: center;
  color: ${props => props.theme.colors.error};
  display: flex;
`;

export const WarningIcon = styled(SVGInline)`
  align-items: center;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  margin-right: 5px;
  stroke: ${props => props.theme.colors.error};
  width: 18px;
`;

export const PanelContent = styled.div`
  align-items: center;
  align-content: normal;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 30px;
  overflow: auto;
  padding-left: 20px;
  width: 600px;
`;

export const PanelActions = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.borderRadius.box};
  display: flex;
  height: auto;
  min-height: 100px;
  justify-content: space-around;
  margin-right: 30px;
  padding: 15px;
  width: 210px;
  flex-shrink: 0;
`;

export const Counter = styled.div`
  ${props => props.theme.fonts.neutraDisplay};
  align-items: center;
  display: flex;
  height: 30px;
  text-transform: uppercase;
`;

export const CounterDesc = styled.div`
  ${props => props.theme.fonts.openSans};
  text-transform: none;
`;

export const Count = styled.div`
  ${props => props.theme.fonts.openSans};
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  color: #fff;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 10px;
  padding: 0 8px;
`;

export const TimeInfo = styled.div`
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
`;

export const TimeInfoContainer = styled.div`
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  //flex-wrap: wrap;
  flex-direction: column;
`;
