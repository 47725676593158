// =============================
// Imports
// =============================

// External Dependencies
import get from 'lodash/get';
import { get as getCookie, set as setCookie, remove as removeCookie } from 'js-cookie';
import { getCookieConfig } from '../helpers/helpers';

// Locales
import en from '../locales/en.json';
import de from '../locales/de.json';
import it from '../locales/it.json';
import fi from '../locales/fi.json';
import sv from '../locales/sv.json';

// =============================
// Helpers
// =============================

const locales = {
  en,
  de,
  it,
  fi,
  sv,
};

export function findByLocale(array, locale = 'en') {
  return array.find(e => e.locale === locale) || array.find(e => e.locale === 'en');
}

export function getName(object, locale) {
  if (!object || !object.names) return '';
  return findByLocale(object.names, locale).value;
}

export function translate(key, locale = 'en') {
  return get(locales[locale], key, `Missing translation: ${locale}.${key}!`);
}

export function setLocale(locale) {
  removeCookie('locale');
  const cookieConfig = getCookieConfig(15, true);

  if (locale && Object.keys(locales).includes(locale)) {
    setCookie('locale', locale, cookieConfig);
    return;
  }
  setCookie('locale', 'en', cookieConfig);
}

export function getOrResetLocale() {
  // TODO: can be removed after some time.
  // remove wrongly set cookie
  removeCookie('locale');

  const cookieConfig = getCookieConfig(15, true);
  const locale = getCookie('locale', cookieConfig);

  if (locale && Object.keys(locales).includes(locale)) return locale;
  setCookie('locale', 'en', cookieConfig);
  return 'en';
}

// NOTE: when used in actions if it would be needed to be improved, could be passed
// through 3rd argument of thunk,this way it could get locale from store
export function translateWithLocale(key) {
  return translate(key, getOrResetLocale());
}
