// =============================
// Imports
// =============================

// External Dependencies
import { CancelToken } from 'axios';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import union from 'lodash/union';

// Helpers
import axios from './../helpers/axios';
import { toText } from './../helpers/highlight';

// Config
import env from './../../config/private/environment';

// Constants
import {
  TRACKS,
  ALBUMS,
  PLAYLISTS,
  CATALOGS,
  MULTIPART_TRACKS,
  ARTISTS,
} from './../constants/SearchPanelTypes';

import {
  SET_SEARCH_RESULTS,
  SET_SEARCH_BY_NAME_RESULTS,
  GET_SEARCH_RESULTS_LOADING,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  SET_SEARCH_PAGE_RESULTS,
  CHANGE_SEARCH_PAGE_LOADING,
  CHANGE_SEARCH_PAGE_SUCCESS,
  CHANGE_SEARCH_PAGE_FAILURE,
  SET_SEARCH_JOB_DATA,
  CLEAR_SEARCH_JOB_DATA,
  PENDING_SEARCH_TRIGGERED,
  SET_PENDING_SEARCH,
  PENDING_SEARCH_RESET,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

function isCurrentTab(key) {
  const path = {
    [TRACKS]: '/',
    [MULTIPART_TRACKS]: '/multiparts',
    [ALBUMS]: '/albums',
    [PLAYLISTS]: '/playlists',
    [CATALOGS]: '/catalogs',
  }[key];

  return window.location.pathname === path;
}

function filterResponseDataBySearchQuery(response, { key, prefixes = {} }) {
  const defaultEmptyData = {
    total: 0,
    nbPages: 0,
    page: 0,
    hits: [],
  };

  // omit queries by some prefixes
  const prefixAlbumRef = get(prefixes, ['album_ref'], []);
  const prefixEan = get(prefixes, ['ean'], []);
  const prefixIsrc = get(prefixes, ['isrc'], []);

  if (key === TRACKS || key === MULTIPART_TRACKS) {
    if (isEmpty(prefixIsrc) && (!isEmpty(prefixAlbumRef) || !isEmpty(prefixEan))) {
      return defaultEmptyData;
    }
  } else if (key === ALBUMS) {
    if (isEmpty(prefixAlbumRef) && isEmpty(prefixEan) && !isEmpty(prefixIsrc)) {
      return defaultEmptyData;
    }
  }

  return response.data;
}

const setPendingSearch = (key, data) => (dispatch, getState) => {
  if (isEqual(
    data.hits.map(d => ({ id: d.id, updated_at: d.updated_at })),
    getState().search[key].data.map(d => ({ id: d.id, updated_at: d.updated_at })),
  )) return;

  dispatch({
    type: SET_PENDING_SEARCH,
    payload: { resetPage: data.hits.length === 0, searchState: getState().search, key },
  });
};

export function updateThePage(key, resetPage, searchState) {
  return (dispatch, getState) => {
    dispatch({
      type: PENDING_SEARCH_RESET,
    });

    const { job: searchJob } = searchState;
    const { cancelToken } = getState().search;

    // If there is an existing request we need to cancel it first
    if (cancelToken) {
      cancelToken.cancel('Request canceled by user');
    }

    dispatch({
      type: GET_SEARCH_RESULTS_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta`;
    const queries = {
      [TRACKS]: 'tracks/search',
      [MULTIPART_TRACKS]: 'multiparts/search',
      [ALBUMS]: 'albums/search',
      [PLAYLISTS]: 'playlists/search',
      [CATALOGS]: 'catalogs/search',
    };

    const page = !resetPage ? searchState[key].currentPage : 0;

    const { prefixes, query } = searchState;

    const params = {
      options: {
        page,
        prefix: {
          ...prefixes,
          albums: (prefixes.albums || []).map(v => v.id),
          catalogs: (prefixes.catalogs || []).map(v => v.id),
          providers: (prefixes.providers || []).map(v => v.id),
        },
        sort: searchState[key].sort,
      },
      query,
    };

    // If there is an existing similarity search, we need to specify the searchId
    if ((key === TRACKS || key === MULTIPART_TRACKS) && searchJob.done && searchJob.id) {
      params.options.searchId = searchJob.id;
    }

    return axios.post(`${url}/${queries[key]}`, params, config)
      .then((response) => {
        dispatch({
          type: SET_SEARCH_PAGE_RESULTS,
          payload: {
            values: response.data,
            page,
            sort: searchState[key].sort,
            type: [key],
          },
        });

        return dispatch({
          type: GET_SEARCH_RESULTS_SUCCESS,
        });
      })
      .catch(err =>
        dispatch({
          type: GET_SEARCH_RESULTS_FAILURE,
          payload: err.response,
        }),
      );
  };
}

export function getSearchResults(value, { stayOnPage = false } = {}, prefixes = {}) {
  return (dispatch, getState) => {
    if (getState().search.pendingSearch.updateIsNeeded) {
      dispatch({
        type: PENDING_SEARCH_RESET,
      });
    }
    const { cancelToken, job: searchJob } = getState().search;

    // If there is an existing request we need to cancel it first
    if (cancelToken) {
      cancelToken.cancel('Request canceled by user');
    }

    // Create an set new cancel token
    const newCancelToken = CancelToken.source();

    dispatch({
      type: GET_SEARCH_RESULTS_LOADING,
      payload: newCancelToken,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
      cancelToken: newCancelToken.token,
    };

    const url = `${env.apiUrl}/meta`;
    const queries = {
      [TRACKS]: 'tracks/search',
      [MULTIPART_TRACKS]: 'multiparts/search',
      [ALBUMS]: 'albums/search',
      [PLAYLISTS]: 'playlists/search',
      [CATALOGS]: 'catalogs/search',
      [ARTISTS]: 'artists/search',
    };

    const keys = Object.keys(queries);

    return Promise.all(
      keys.map((key) => {
        const page = (stayOnPage && isCurrentTab(key))
          ? getState().search[key].currentPage
          : 0;

        // When doing a new search, we always retrieve the results of the first page
        const params = {
          options: {
            page,
            prefix: {
              ...prefixes,
              albums: (prefixes.albums || []).map(v => v.id),
              catalogs: (prefixes.catalogs || []).map(v => v.id),
              providers: (prefixes.providers || []).map(v => v.id),
            },
            sort: getState().search[key].sort,
          },
          query: value,
        };

        // If there is an existing similarity search, we need to specify the searchId
        if ((key === TRACKS || key === MULTIPART_TRACKS) && searchJob.done && searchJob.id) {
          params.options.searchId = searchJob.id;
        }

        return axios.post(`${url}/${queries[key]}`, params, config);
      }),
    )
      .then((responses) => {
        const nextState = {};
        // Get the corresponding key for each response and store the data
        responses.forEach((response, i) => {
          const key = keys[i];

          if (stayOnPage && isCurrentTab(key)) {
            dispatch(setPendingSearch(key, response.data));
            return;
          }

          nextState[key] = filterResponseDataBySearchQuery(response, { key, prefixes });
        });

        dispatch({
          type: SET_SEARCH_RESULTS,
          payload: {
            values: nextState,
            query: value,
            prefixes,
            stayOnPage,
          },
        });

        return dispatch({
          type: GET_SEARCH_RESULTS_SUCCESS,
        });
      })
      .catch(err =>
        dispatch({
          type: GET_SEARCH_RESULTS_FAILURE,
          payload: err.response,
        }),
      );
  };
}

const SEARCH_FETCHING_INTERVAL = 15000;
export function getPendingSearchResults() {
  return (dispatch, getState) => {
    if (getState().search.pendingSearch.isTriggered) {
      return;
    }

    dispatch({
      type: PENDING_SEARCH_TRIGGERED,
    });

    const makeSearch = () => {
      if (!getState().search.pendingSearch.isTriggered) {
        return;
      }

      dispatch(getSearchResults(
        getState().search.query,
        { stayOnPage: true },
        getState().search.prefixes,
      )).then(() => {
        setTimeout(makeSearch, SEARCH_FETCHING_INTERVAL);
      });
    };

    makeSearch();
  };
}

export function changeSearchPage(type, page, sort = {}) {
  return (dispatch, getState) => {
    if (getState().search.pendingSearch.updateIsNeeded) {
      dispatch({
        type: PENDING_SEARCH_RESET,
      });
    }

    const { query: currentQuery, job: searchJob, [type]: searchPanel } = getState().search;

    if (
      searchPanel.isChangingPage ||
      page < 0 ||
      page > searchPanel.nbPages ||
      (page === searchPanel.currentPage && isEqual(sort, searchPanel.sort))
    ) {
      return null;
    }

    dispatch({
      type: CHANGE_SEARCH_PAGE_LOADING,
      payload: type,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta`;
    const queries = {
      [TRACKS]: 'tracks/search',
      [MULTIPART_TRACKS]: 'multiparts/search',
      [ALBUMS]: 'albums/search',
      [PLAYLISTS]: 'playlists/search',
      [CATALOGS]: 'catalogs/search',
    };

    const params = {
      query: currentQuery,
      options: {
        page,
        prefix: getState().search.prefixes,
        sort,
      },
    };

    if (type === TRACKS && searchJob.done && searchJob.id) {
      params.options.searchId = searchJob.id;
    }

    return axios
      .post(`${url}/${queries[type]}`, params, config)
      .then((response) => {
        dispatch({
          type: SET_SEARCH_PAGE_RESULTS,
          payload: {
            values: response.data,
            page,
            sort,
            type,
          },
        });

        return dispatch({
          type: CHANGE_SEARCH_PAGE_SUCCESS,
          payload: type,
        });
      })
      .catch(() => {
        dispatch({
          type: CHANGE_SEARCH_PAGE_FAILURE,
          payload: type,
        });
      });
  };
}

function checkMaiaProcess(jobId, getState, dispatch) {
  return setTimeout(() => {
    axios
      .get(`${env.apiUrl}/meta/jobs/${jobId}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.sessionToken,
        },
      })
      .then((response) => {
        const job = response.data;

        dispatch({
          type: SET_SEARCH_JOB_DATA,
          job: {
            done: !!job.result,
            processing: job.result ? null : checkMaiaProcess(jobId, getState, dispatch),
          },
        });
      });
  }, 1000);
}

function handleSearchRequest(type, dispatch, getState, customConfig) {
  const config = {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.sessionToken,
    },
  };

  Object.assign(config, customConfig);

  return axios(config)
    .then((response) => {
      const jobId = response.data;
      const job = {
        id: jobId,
        cancelToken: null,
        processing: checkMaiaProcess(jobId, getState, dispatch),
      };

      if (type === 'upload') {
        job.uploadProgress = 1;
      }

      return dispatch({
        type: SET_SEARCH_JOB_DATA,
        job,
      });
    })
    .catch(() => {
      dispatch({
        type: CLEAR_SEARCH_JOB_DATA,
      });
    });
}

export function uploadAudioFile(file) {
  return (dispatch, getState) => {
    const type = 'upload';
    const { job } = getState().search;
    const newCancelToken = CancelToken.source();

    if (job.cancelToken) {
      job.cancelToken.cancel('Upload canceled by new upload');
    }

    if (job.processing) {
      clearTimeout(job.processing);
    }

    dispatch({
      type: SET_SEARCH_JOB_DATA,
      job: {
        type,
        id: null,
        display: file.name,
        uploadProgress: 0,
        processing: null,
        done: false,
        cancelToken: newCancelToken,
      },
    });

    const form = new FormData();

    form.append('file', file);

    return handleSearchRequest(type, dispatch, getState, {
      url: `${env.apiUrl}/meta/search/upload`,
      cancelToken: newCancelToken.token,
      data: form,
      onUploadProgress: (event) => {
        const uploadProgress = event.total ? event.loaded / event.total : 0;

        dispatch({
          type: SET_SEARCH_JOB_DATA,
          job: {
            uploadProgress,
          },
        });
      },
    });
  };
}

export function searchLink(link) {
  return (dispatch, getState) => {
    const type = 'link';
    const { job } = getState().search;
    const newCancelToken = CancelToken.source();

    if (job.cancelToken) {
      job.cancelToken.cancel('Search canceled by user');
    }

    if (job.processing) {
      clearTimeout(job.processing);
    }

    dispatch({
      type: SET_SEARCH_JOB_DATA,
      job: {
        type,
        id: null,
        display: link,
        uploadProgress: 0,
        processing: null,
        done: false,
        cancelToken: newCancelToken,
      },
    });

    return handleSearchRequest(type, dispatch, getState, {
      url: `${env.apiUrl}/meta/search/text`,
      cancelToken: newCancelToken.token,
      data: {
        link,
      },
    });
  };
}

export function searchByAlbum(albumName, albumId) {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;

    const prefixes = {
      ...getState().search.prefixes,
      albums: [{ id: albumId, name: albumName }],
    };

    return dispatch(
      getSearchResults(currentQuery, { stayOnPage: true }, prefixes),
    );
  };
}

export function removeAlbum() {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;

    const prefixes = {
      ...getState().search.prefixes,
      albums: [],
    };

    return dispatch(
      getSearchResults(currentQuery, { stayOnPage: true }, prefixes),
    );
  };
}

export function searchByCatalog(catalogName, catalogId) {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;

    const prefixes = {
      ...getState().search.prefixes,
      catalogs: [{ id: catalogId, name: catalogName }],
    };

    return dispatch(
      getSearchResults(currentQuery, {}, prefixes),
    );
  };
}


export function searchByProvider(providerName, providerId) {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;

    const prefixes = {
      ...getState().search.prefixes,
      // providers: [{ id: providerId, name: providerName }],
    };
    prefixes.providers = prefixes.providers || [];
    prefixes.providers.push({ id: providerId, name: providerName });
    return dispatch(
      getSearchResults(currentQuery, {}, prefixes),
    );
  };
}

export function removeCatalog() {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;

    const prefixes = {
      ...getState().search.prefixes,
      catalogs: [],
    };

    return dispatch(
      getSearchResults(currentQuery, {}, prefixes),
    );
  };
}

export function removeProvider(providerId) {
  return (dispatch, getState) => {
    const { query: currentQuery } = getState().search;


    const prefixes = {
      ...getState().search.prefixes,
    };

    prefixes.providers = prefixes.providers || [];
    prefixes.providers = prefixes.providers.filter(el => (el.id !== providerId));

    return dispatch(
      getSearchResults(currentQuery, {}, prefixes),
    );
  };
}

export function searchByTrack(track) {
  return (dispatch, getState) => {
    const type = 'track';
    const { job } = getState().search;
    const newCancelToken = CancelToken.source();

    if (job.cancelToken) {
      job.cancelToken.cancel('Search canceled by user');
    }

    if (job.processing) {
      clearTimeout(job.processing);
    }

    dispatch({
      type: SET_SEARCH_JOB_DATA,
      job: {
        type,
        id: null,
        display: toText(track.title),
        uploadProgress: 0,
        processing: null,
        done: false,
        cancelToken: newCancelToken,
      },
    });

    return handleSearchRequest(type, dispatch, getState, {
      url: `${env.apiUrl}/meta/search/text`,
      cancelToken: newCancelToken.token,
      data: {
        trackId: track.maia_id,
      },
    });
  };
}


export function searchByMultipartTrack(track) {
  return (dispatch, getState) => {
    const type = 'multipart_track';
    const { job } = getState().search;
    const newCancelToken = CancelToken.source();

    if (job.cancelToken) {
      job.cancelToken.cancel('Search canceled by user');
    }

    if (job.processing) {
      clearTimeout(job.processing);
    }

    dispatch({
      type: SET_SEARCH_JOB_DATA,
      job: {
        type,
        id: null,
        display: toText(track.title),
        uploadProgress: 0,
        processing: null,
        done: false,
        cancelToken: newCancelToken,
      },
    });

    return handleSearchRequest(type, dispatch, getState, {
      url: `${env.apiUrl}/meta/search/text`,
      cancelToken: newCancelToken.token,
      data: {
        trackId: track.maia_id,
      },
    });
  };
}

export function clearSearchJob() {
  return (dispatch, getState) => {
    const { job } = getState().search;

    if (job.processing) {
      clearTimeout(job.processing);
    }

    if (job.cancelToken) {
      job.cancelToken.cancel('User cleared search job');
    }

    return dispatch({
      type: CLEAR_SEARCH_JOB_DATA,
    });
  };
}

export function searchByName(value) {
  return (dispatch, getState) => {
    if (getState().search.pendingSearch.updateIsNeeded) {
      dispatch({
        type: PENDING_SEARCH_RESET,
      });
    }
    const { cancelToken } = getState().search;

    // If there is an existing request we need to cancel it first
    if (cancelToken) {
      cancelToken.cancel('Request canceled by user');
    }

    // Create an set new cancel token
    const newCancelToken = CancelToken.source();

    dispatch({
      type: GET_SEARCH_RESULTS_LOADING,
      payload: newCancelToken,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
      cancelToken: newCancelToken.token,
    };

    const url = `${env.apiUrl}/meta`;
    const queries = {
      [TRACKS]: 'tracks/search-name',
      [MULTIPART_TRACKS]: 'multiparts/search-name',
      [ALBUMS]: 'albums/search-name',
      [PLAYLISTS]: 'playlists/search-name',
      [CATALOGS]: 'catalogs/search-name',
      [ARTISTS]: 'artists/search-name',
    };

    const keys = Object.keys(queries);

    return Promise.all(
      keys.map(key => axios.post(`${url}/${queries[key]}`, { name: value }, config)),
    )
      .then((responses) => {
        const nextState = {};
        responses.forEach((response, i) => {
          nextState[keys[i]] = union(
            getState().search.inputSearchQuery[keys[i]],
            get(response.data, ['hits'], []),
          );
        });

        dispatch({
          type: SET_SEARCH_BY_NAME_RESULTS,
          payload: {
            values: nextState,
            query: value,
          },
        });

        return dispatch({
          type: GET_SEARCH_RESULTS_SUCCESS,
        });
      })
      .catch(err =>
        dispatch({
          type: GET_SEARCH_RESULTS_FAILURE,
          payload: err.response,
        }),
      );
  };
}
