// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { get } from 'lodash';

// Helpers
import { getHiddenFieldsFromUserData } from './../../../helpers/helpers';

// Actions
import {
  open as openSidePanel,
} from './../../../actions/SidePanelActions';
import { exportCsv, downloadArchive, exportJson } from './../../../actions/GlobalActions';

// Components
import MultipartTrackPanel from './../../presentationals/panels/multipart-track/MultipartTrack';

// =============================
// Component
// =============================

function mapStateToProps({ options, user }) {
  return {
    mechanicalrightssocieties: options.data.mechanicalrightssocieties,
    performingrightssocieties: options.data.performingrightssocieties,
    neighboringrightssocieties: options.data.neighboringrightssocieties,
    territories: options.data.territories,
    trackversions: options.data.trackversions,
    isFetchingOptions: options.isFetching,
    rightstypes: options.data.rightstypes,
    languages: options.data.languages,
    tagCategories: options.data.tags,
    agents: user.agents.data.filter(a => a.active_as_agent === true),
    canDownload: user.canDownload,
    canDelete: user.canDelete,
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'multipart'),
    showAdditionalNotes: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'showAdditionalNotes'],
      false,
    ),
    customFieldsToRightSide: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'customFieldsToRightSide'],
      false,
    ),
    descriptionAutocomplete: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'descriptionAutocomplete'],
      false,
    ),
    copyPasteMetadataFields: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'copyPasteMetadataFields'],
      [],
    ),
  };
}

export default connect(mapStateToProps, {
  openSidePanel,
  exportCsv,
  downloadArchive,
  exportJson,
})(MultipartTrackPanel);
