/* Routes */
export const HOME_PAGE_ROUTE = '/'; // List of tracks
export const MULTIPART_TRACKS_PAGE_ROUTE = '/multiparts';
export const ALBUMS_PAGE_ROUTE = '/albums';
export const PLAYLISTS_PAGE_ROUTE = '/playlists';
export const CATALOGS_PAGE_ROUTE = '/catalogs';
export const PROVIDERS_PAGE_ROUTE = '/providers';
export const UPLOADS_PAGE_ROUTE = '/uploads';
export const LOGOUT = '/logout';
export const PRINT_TRACK_PAGE = '/print/tracks/:id';
export const PRINT_MULTIPART_PAGE = '/print/multipart/:id';
export const PRINT_ALBUM_PAGE = '/print/albums/:id';
export const LOGIN = '/login';
