// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

// Components
import DropdownList from './../dropdownlist/DropdownList';

// =============================
// Component
// =============================

class SearchBarAutocomplete extends Component {
  static propTypes = {
    value: PropTypes.array, // eslint-disable-line react/forbid-prop-types

    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    onClickOutside: PropTypes.func,
    onSelectedIndex: PropTypes.func,

    renderItem: PropTypes.func,
  };

  static defaultProps = {
    value: [],

    onSelect: () => {},
    onClose: () => {},
    onClickOutside: () => {},
    onSelectedIndex: () => {},

    renderItem: () => {},
  };

  state = {
    selectedIndex: -1,
    selectedValue: null,
  };

  componentWillMount() {
    document.addEventListener('keydown', this.handleKeydown, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: prevValue } = prevProps;
    const { value: newValue, onSelectedIndex } = this.props;
    if (!isEqual(prevValue, newValue)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedIndex: -1, selectedValue: null });
    }

    if (prevState.selectedIndex !== this.state.selectedIndex) {
      onSelectedIndex(this.state.selectedIndex);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, false);
  }

  setDropdownRef = (e) => {
    this.dropDownRef = e;
  };

  handleKeydown = (event) => {
    const targetId = get(event, ['target', 'id'], null);
    if (targetId && targetId.startsWith('textarea-')) {
      return;
    }

    const { value, onSelect, onClose } = this.props;
    const { selectedIndex, selectedValue } = this.state;
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        if (selectedValue) {
          onSelect(selectedValue);
          this.setState({ selectedIndex: -1, selectedValue: null });
        }
        break;
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();
        onClose();
        break;

      case 'ArrowUp':
        event.preventDefault();
        event.stopPropagation();
        if (this.dropDownRef) {
          let newIndex = selectedIndex - 1;
          if (newIndex < 0) {
            newIndex = value.length - 1;
          }
          const newValue = value[newIndex];
          this.dropDownRef.children[newIndex].scrollIntoView();
          this.setState({
            selectedIndex: newIndex,
            selectedValue: newValue,
          });
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        event.stopPropagation();
        if (this.dropDownRef) {
          let newIndex = selectedIndex + 1;
          if (newIndex > value.length - 1) {
            newIndex = 0;
          }
          const newValue = value[newIndex];
          this.dropDownRef.children[newIndex].scrollIntoView();
          this.setState({
            selectedIndex: newIndex,
            selectedValue: newValue,
          });
        }
        break;
      default:
        break;
    }
  };

  renderItems = () => {
    const { value, renderItem } = this.props;
    const { selectedIndex } = this.state;
    return value.map((item, i) => renderItem({ item, isSelected: i === selectedIndex }));
  };

  render() {
    const { value, renderItem, onSelect, onClose, onClickOutside, ...props } = this.props;
    return (
      <DropdownList
        items={this.renderItems}
        {...props}
        getRef={this.setDropdownRef}
        handleClickOutside={onClickOutside}
      />
    );
  }
}

export default SearchBarAutocomplete;
