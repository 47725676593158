/* Core */
export const INITIALIZE_APP = 'INITIALIZE_APP';

/* Global */
export const EXPORT_CSV_LOADING = 'EXPORT_CSV_LOADING';
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export const EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE';
export const EXPORT_JSON_LOADING = 'EXPORT_JSON_LOADING';
export const EXPORT_JSON_SUCCESS = 'EXPORT_JSON_SUCCESS';
export const EXPORT_JSON_FAILURE = 'EXPORT_JSON_FAILURE';
export const EXPORT_BULK_JSON_LOADING = 'EXPORT_BULK_JSON_LOADING';
export const EXPORT_BULK_JSON_SUCCESS = 'EXPORT_BULK_JSON_SUCCESS';
export const EXPORT_BULK_JSON_FAILURE = 'EXPORT_BULK_JSON_FAILURE';
export const DOWNLOAD_ARCHIVE_LOADING = 'DOWNLOAD_ARCHIVE_LOADING';
export const DOWNLOAD_ARCHIVE_SUCCESS = 'DOWNLOAD_ARCHIVE_SUCCESS';
export const DOWNLOAD_ARCHIVE_FAILURE = 'DOWNLOAD_ARCHIVE_FAILURE';
export const LINK_TRACKS_LOADING = 'LINK_TRACKS_LOADING';
export const LINK_TRACKS_SUCCESS = 'LINK_TRACKS_SUCCESS';
export const LINK_TRACKS_FAILURE = 'LINK_TRACKS_FAILURE';
export const PRINT_DATA_LOADING = 'PRINT_DATA_LOADING';
export const PRINT_DATA_SUCCESS = 'PRINT_DATA_SUCCESS';
export const PRINT_TRACK_FAILURE = 'PRINT_DATA_FAILURE';

/* SidePanel */
export const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
export const REMOVE_SIDE_PANEL = 'REMOVE_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
export const SET_PANEL_DATA = 'SET_PANEL_DATA';
export const COPY_PANEL_METADATA = 'COPY_PANEL_METADATA';
/* SidePanel Errors */
export const FETCH_BATCH_PANEL_ERROR = 'FETCH_BATCH_PANEL_ERROR';
export const FETCH_PANEL_ERROR = 'FETCH_PANEL_ERROR';
export const SAVE_PANEL_ERROR = 'SAVE_PANEL_ERROR';
export const SAVE_ADD_PANEL_ERROR = 'SAVE_ADD_PANEL_ERROR';
export const SAVE_BATCH_PANEL_ERROR = 'SAVE_BATCH_PANEL_ERROR';
export const DELETE_PANEL_FAILURE = 'DELETE_PANEL_FAILURE';
export const PASTE_PANEL_METADATA_TYPE_ERROR = 'PASTE_PANEL_METADATA_TYPE_ERROR';
/* SidePanel Other */
export const DID_BATCH_DELETE_OPERATION = 'DID_BATCH_DELETE_OPERATION';
export const BATCH_DELETE_OPERATION_FAILURE = 'BATCH_DELETE_OPERATION_FAILURE';
export const DID_CHANGE_PLAYLIST_TRACKS_STATUS_OPERATION = 'DID_CHANGE_PLAYLIST_TRACKS_STATUS_OPERATION';
export const CHANGE_PLAYLIST_TRACKS_STATUS_FAILURE = 'CHANGE_PLAYLIST_TRACKS_STATUS_FAILURE';
export const GENERATE_CUSTOM_ID_FAILURE = 'GENERATE_CUSTOM_ID_FAILURE';
export const CHECK_CUSTOM_ID_FAILURE = 'CHECK_CUSTOM_ID_FAILURE';
export const PASTE_PANEL_METADATA_FAILURE = 'PASTE_PANEL_METADATA_FAILURE';

/* Options */
export const GET_OPTIONS_LOADING = 'GET_OPTIONS_LOADING';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';
export const SET_OPTIONS = 'SET_OPTIONS';

/* User */
/* UserInfo */
export const GET_USER_INFO_LOADING = 'GET_USER_INFO_LOADING';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_SESSION_TOKEN = 'SET_USER_SESSION_TOKEN';

/* UserPermissions */
export const GET_USER_PERMISSIONS_LOADING = 'GET_USER_PERMISSIONS_LOADING';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_FAILURE = 'GET_USER_PERMISSIONS_FAILURE';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

/* UserAgents */
export const GET_USER_AGENTS_LOADING = 'GET_USER_AGENTS_LOADING';
export const GET_USER_AGENTS_SUCCESS = 'GET_USER_AGENTS_SUCCESS';
export const GET_USER_AGENTS_FAILURE = 'GET_USER_AGENTS_FAILURE';
export const SET_USER_AGENTS = 'SET_USER_AGENTS';

/* UserProviders */
export const GET_USER_PROVIDERS_LOADING = 'GET_USER_PROVIDERS_LOADING';
export const GET_USER_PROVIDERS_SUCCESS = 'GET_USER_PROVIDERS_SUCCESS';
export const GET_USER_PROVIDERS_FAILURE = 'GET_USER_PROVIDERS_FAILURE';
export const SET_USER_PROVIDERS = 'SET_USER_PROVIDERS';

/* CustomFieldsConfig */
export const GET_CUSTOM_FIELDS_CONFIG_LOADING = 'GET_CUSTOM_FIELDS_CONFIG_LOADING';
export const GET_CUSTOM_FIELDS_CONFIG_SUCCESS = 'GET_CUSTOM_FIELDS_CONFIG_SUCCESS';
export const GET_CUSTOM_FIELDS_CONFIG_FAILURE = 'GET_CUSTOM_FIELDS_CONFIG_FAILURE';
export const SET_CUSTOM_FIELDS_CONFIG = 'SET_CUSTOM_FIELDS_CONFIG';

/* CustomFieldsConfig */
export const SET_CUSTOM_PREFERENCES_LOADING = 'GET_CUSTOM_PREFERENCES_LOADING';
export const SET_CUSTOM_PREFERENCES_SUCCESS = 'GET_CUSTOM_PREFERENCES_SUCCESS';
export const SET_CUSTOM_PREFERENCES_FAILURE = 'GET_CUSTOM_PREFERENCES_FAILURE';
export const SET_CUSTOM_PREFERENCES = 'SET_CUSTOM_PREFERENCES';

/* Logout */
export const LOGOUT_USER_LOADING = 'LOGOUT_USER_LOADING';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

/* FileUpload */
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

/* ApiSelectInput */
export const ON_THE_FLY_CREATION_ERROR = 'ON_THE_FLY_CREATION_ERROR';
export const API_SELECT_INPUT_FETCH_ERROR = 'API_SELECT_INPUT_FETCH_ERROR';

/* Search */
export const GET_SEARCH_RESULTS_LOADING = 'GET_SEARCH_RESULTS_LOADING';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_BY_NAME_RESULTS = 'SET_SEARCH_BY_NAME_RESULTS';
export const SET_SEARCH_PAGE_RESULTS = 'SET_SEARCH_PAGE_RESULTS';
export const CHANGE_SEARCH_PAGE_LOADING = 'CHANGE_SEARCH_PAGE_LOADING';
export const CHANGE_SEARCH_PAGE_SUCCESS = 'CHANGE_SEARCH_PAGE_SUCCESS';
export const CHANGE_SEARCH_PAGE_FAILURE = 'CHANGE_SEARCH_PAGE_FAILURE';
export const SET_SEARCH_JOB_DATA = 'SET_SEARCH_JOB_DATA';
export const CLEAR_SEARCH_JOB_DATA = 'CLEAR_SEARCH_JOB_DATA';
export const SET_PENDING_SEARCH = 'SET_PENDING_SEARCH';
export const PENDING_SEARCH_TRIGGERED = 'PENDING_SEARCH_TRIGGERED';
export const PENDING_SEARCH_RESET = 'PENDING_SEARCH_RESET';

/* Player */
export const REINITIALIZE_PLAYER = 'REINITIALIZE_PLAYER';
export const SET_STATE = 'SET_STATE';
export const SHOW_PLAYER = 'SHOW_PLAYER';
export const TOGGLE_PLAYER_DETAILS_STATE = 'TOGGLE_PLAYER_DETAILS_STATE';
export const EXPOSE_GET_CURRENT_TIME = 'EXPOSE_GET_CURRENT_TIME';
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK';
export const SET_CURRENT_TRACKLIST = 'SET_CURRENT_TRACKLIST';
export const FULFILL_REQUEST = 'FULFILL_REQUEST';
export const REQUEST_LOAD_TRACK = 'REQUEST_LOAD_TRACK';
export const REQUEST_PLAY = 'REQUEST_PLAY';
export const REQUEST_PAUSE = 'REQUEST_PAUSE';
export const REQUEST_GO_TO_PREV_TRACK = 'REQUEST_GO_TO_PREV_TRACK';
export const REQUEST_GO_TO_NEXT_TRACK = 'REQUEST_GO_TO_NEXT_TRACK';
export const REQUEST_SET_SEEKING_TIME = 'REQUEST_SET_SEEKING_TIME';

/* Notifications */
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

/* Yle */
export const SET_YLE_BOOL = 'SET_YLE_BOOL';
export const AUTH_YLE_LOGOUT = 'AUTH_YLE_LOGOUT';
export const MANUAL_SYNC_ARTIST_LOADING = 'MANUAL_SYNC_ARTIST_LOADING';
export const MANUAL_SYNC_ARTIST_SUCCESS = 'MANUAL_SYNC_ARTIST_SUCCESS';
export const MANUAL_SYNC_ARTIST_FAILURE = 'MANUAL_SYNC_ARTIST_FAILURE';

/* Ingestions */
export const SET_INGESTION = 'SET_INGESTION';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_TAGS_STILL_FINDING = 'SET_PAGE_TAGS_STILL_FINDING';
export const SET_INGESTIONS = 'SET_INGESTIONS';
export const GET_INGESTIONS_LOADING = 'GET_INGESTIONS_LOADING';
export const GET_INGESTIONS_SUCCESS = 'GET_INGESTIONS_SUCCESS';
export const GET_INGESTIONS_FAILURE = 'GET_INGESTIONS_FAILURE';
export const CREATE_INGESTION_LOADING = 'CREATE_INGESTION_LOADING';
export const CREATE_INGESTION_SUCCESS = 'CREATE_INGESTION_SUCCESS';
export const CREATE_INGESTION_FAILURE = 'CREATE_INGESTION_FAILURE';
export const INGESTION_UPLOAD_FILE_LOADING = 'INGESTION_UPLOAD_FILE_LOADING';
export const INGESTION_UPLOAD_FILE_SUCCESS = 'INGESTION_UPLOAD_FILE_SUCCESS';
export const INGESTION_UPLOAD_FILE_FAILURE = 'INGESTION_UPLOAD_FILE_FAILURE';
export const INGESTION_UPLOAD_FILE_PROGRESS = 'INGESTION_UPLOAD_FILE_PROGRESS';
export const INGESTION_UPLOAD_FILE_RESET = 'INGESTION_UPLOAD_FILE_RESET';
export const SET_BINARIES_LOADING = 'SET_BINARIES_LOADING';
export const SET_BINARIES_SUCCESS = 'SET_BINARIES_SUCCESS';
export const SET_BINARIES_FAILURE = 'SET_BINARIES_FAILURE';
export const SET_TAG_MATCHES_LOADING = 'SET_TAG_MATCHES_LOADING';
export const SET_TAG_MATCHES_SUCCESS = 'SET_TAG_MATCHES_SUCCESS';
export const SET_TAG_MATCHES_FAILURE = 'SET_TAG_MATCHES_FAILURE';
export const GENERATE_CSV_TEMPLATE_LOADING = 'GENERATE_CSV_TEMPLATE_LOADING';
export const GENERATE_CSV_TEMPLATE_SUCCESS = 'GENERATE_CSV_TEMPLATE_SUCCESS';
export const GENERATE_CSV_TEMPLATE_FAILURE = 'GENERATE_CSV_TEMPLATE_FAILURE';
export const SET_TAG_MATCHES_DRAFT_SAVED = 'SET_TAG_MATCHES_DRAFT_SAVED';
export const SET_VERSION_MATCHES_LOADING = 'SET_VERSION_MATCHES_LOADING';
export const SET_VERSION_MATCHES_SUCCESS = 'SET_VERSION_MATCHES_SUCCESS';
export const SET_VERSION_MATCHES_FAILURE = 'SET_VERSION_MATCHES_FAILURE';
export const SET_VERSION_MATCHES_DRAFT_SAVED = 'SET_VERSION_MATCHES_DRAFT_SAVED';
