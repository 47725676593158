import Joi from 'joi-browser';
import isEmpty from 'lodash/isEmpty';
import {
  getFieldsByCollection,
  createCustomSchema,
} from '../../../../helpers/customSchemaValidationHelpers';

const albumLabels = {
  public: 'Public',
  showcase: 'Showcase',
  album_ref: 'Album ref',
  title: 'Title',
  isrc: 'ISRC',
  iswc: 'ISWC',
  cocv: 'COCV',
  prs_tunecode: 'PRS Tunecode',
  release_date: 'Release date',
  catalog: 'Catalog',
  upc: 'UPC',
  ean: 'EAN',
  album_type: 'Album type',
  display_artists: {
    default: 'Display Artists',
  },
  descriptions: {
    default: 'Descriptions',
  },
  original_nrs: 'Original neighbouring rights society',
  original_prs: 'Original performing rights society',
  original_mrs: 'Original mechanical rights society',
  artists_master_ownerships: {
    default: 'Artists Master Ownerships',
    artist: 'Artist',
    alias: 'Alias',
    rights_type: 'Rights Type',
    ownership_percentage: 'Owner Percentage',
    sync_percentage: 'Sync Percentage',
    territories: 'Territories',
    additional_notes: 'Additional Notes',
  },
  artists_publishing_ownerships: {
    default: 'Artists Publishing Ownerships',
    artist: 'Artist',
    alias: 'Alias',
    rights_type: 'Rights Type',
    performing_percentage: 'Performing Percentage',
    mechanical_percentage: 'Mechanical Percentage',
    sync_percentage: 'Sync Percentage',
    download_percentage: 'Download Percentage',
    rent_percentage: 'Rent Percentage',
    territories: 'Territories',
    additional_notes: 'Additional Notes',
  },
  master_ownerships: {
    default: 'Master Ownerships',
    label: 'Label',
    rights_type: 'Rights Type',
    ownership_percentage: 'Owner Percentage',
    sync_percentage: 'Sync Percentage',
    territories: 'Territories',
  },
  publishing_ownerships: {
    default: 'Publishing Ownerships',
    publisher: 'Publisher',
    rights_type: 'Rights Type',
    performing_percentage: 'Performing Percentage',
    mechanical_percentage: 'Mechanical Percentage',
    sync_percentage: 'Sync Percentage',
    download_percentage: 'Download Percentage',
    rent_percentage: 'Rent Percentage',
    territories: 'Territories',
  },
};

const percentageValidation = Joi.alternatives().try(Joi.string().regex(/^\d+(?:\.\d+)?\/\d+(?:\.\d+)?$/), Joi.number().min(0).max(100));
const allowClearPercentageValidation = Joi.alternatives().try(
  Joi.string().allow('').regex(/^$/),
  Joi.string().regex(/^\d+(?:\.\d+)?\/\d+(?:\.\d+)?$/),
  Joi.number().min(0).max(100));

const AlbumSchema = {
  public: Joi.boolean().label(albumLabels.public),
  showcase: Joi.boolean().label(albumLabels.showcase),
  agents: Joi.array().items(Joi.string()),
  album_ref: Joi.string().label(albumLabels.album_ref).empty(''),
  title: Joi.string().label(albumLabels.title).required(),
  isrc: Joi.string().label(albumLabels.isrc).empty(''),
  iswc: Joi.string().label(albumLabels.iswc).empty(''),
  cocv: Joi.string().label(albumLabels.cocv).empty(''),
  prs_tunecode: Joi.string().label(albumLabels.prs_tunecode).empty(''),
  release_date: Joi.string().label(albumLabels.release_date).empty(null),
  catalog: Joi.string().label(albumLabels.catalog).empty(null),
  upc: Joi.string().label(albumLabels.upc).empty(''),
  ean: Joi.string().label(albumLabels.ean).empty(''),
  album_type: Joi.string().label(albumLabels.album_type).empty(null),
  display_artists: Joi.array().items(Joi.object().keys({
    full_name: Joi.string(),
    id: Joi.string().required(),
    aliases: Joi.array().items(Joi.string()),
    alias: Joi.string().empty(''),
  })).label(albumLabels.display_artists.default),
  descriptions: Joi.array().items(Joi.object().keys({
    locale: Joi.string().empty(''),
    value: Joi.string(),
  })).label(albumLabels.descriptions.default),
  original_nrs: Joi.string().label(albumLabels.original_nrs).empty(null),
  original_prs: Joi.string().label(albumLabels.original_prs).empty(null),
  original_mrs: Joi.string().label(albumLabels.original_mrs).empty(null),
  artists_master_ownerships: Joi.array().items(
    Joi.object().keys({
      artist: Joi.any().required().label(albumLabels.artists_master_ownerships.artist),
      alias: Joi.string().empty('').label(albumLabels.artists_master_ownerships.alias),
      rights_type: Joi.any().required().label(albumLabels.artists_master_ownerships.rights_type),
      ownership_percentage:
        percentageValidation.label(albumLabels.artists_master_ownerships.ownership_percentage),
      sync_percentage:
        percentageValidation.label(albumLabels.artists_master_ownerships.sync_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(albumLabels.artists_master_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
      instruments: Joi.array().items(Joi.any()),
      additional_notes: Joi.string().allow(null).allow('')
        .label(albumLabels.artists_master_ownerships.additional_notes),
    }),
  ).label(albumLabels.artists_master_ownerships.default),
  artists_publishing_ownerships: Joi.array().items(
    Joi.object().keys({
      artist: Joi.any().required().label(albumLabels.artists_publishing_ownerships.artist),
      alias: Joi.string().empty('').label(albumLabels.artists_publishing_ownerships.alias),
      rights_type:
        Joi.any().required().label(albumLabels.artists_publishing_ownerships.rights_type),
      performing_percentage: percentageValidation
        .label(albumLabels.artists_publishing_ownerships.performing_percentage),
      mechanical_percentage: percentageValidation
        .label(albumLabels.artists_publishing_ownerships.mechanical_percentage),
      sync_percentage:
        percentageValidation.label(albumLabels.artists_publishing_ownerships.sync_percentage),
      download_percentage: allowClearPercentageValidation
        .label(albumLabels.artists_publishing_ownerships.download_percentage),
      rent_percentage: allowClearPercentageValidation
        .label(albumLabels.artists_publishing_ownerships.rent_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(albumLabels.artists_publishing_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
      additional_notes: Joi.string().allow(null).allow('')
        .label(albumLabels.artists_publishing_ownerships.additional_notes),
    }),
  ).label(albumLabels.artists_publishing_ownerships.default),
  master_ownerships: Joi.array().items(
    Joi.object().keys({
      label: Joi.any().required().label(albumLabels.master_ownerships.label),
      rights_type: Joi.any().required().label(albumLabels.master_ownerships.rights_type),
      ownership_percentage:
        percentageValidation.label(albumLabels.master_ownerships.ownership_percentage),
      sync_percentage: percentageValidation.label(albumLabels.master_ownerships.sync_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(albumLabels.master_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
    }),
  ).label(albumLabels.master_ownerships.default),
  publishing_ownerships: Joi.array().items(
    Joi.object().keys({
      publisher: Joi.any().required().label(albumLabels.publishing_ownerships.publisher),
      rights_type: Joi.any().required().label(albumLabels.publishing_ownerships.rights_type),
      performing_percentage:
        percentageValidation.label(albumLabels.publishing_ownerships.performing_percentage),
      mechanical_percentage:
        percentageValidation.label(albumLabels.publishing_ownerships.mechanical_percentage),
      sync_percentage:
        percentageValidation.label(albumLabels.publishing_ownerships.sync_percentage),
      download_percentage: allowClearPercentageValidation
        .label(albumLabels.artists_publishing_ownerships.download_percentage),
      rent_percentage: allowClearPercentageValidation
        .label(albumLabels.artists_publishing_ownerships.rent_percentage),
      territories: Joi.array().min(1).items(Joi.string())
        .label(albumLabels.publishing_ownerships.territories),
      chain_of_title: Joi.string().regex(/^[a-z]+$/i).max(1).empty(''),
    }),
  ).label(albumLabels.publishing_ownerships.default),
  custom: Joi.object(),
};

export default (validation_fields = null, custom_fields = []) => {
  const albumCustomFields = getFieldsByCollection('album', validation_fields);
  if (!validation_fields || isEmpty(albumCustomFields)) { // eslint-disable-line camelcase
    return AlbumSchema;
  }

  return createCustomSchema(albumCustomFields, custom_fields, {
    defaultSchema: AlbumSchema,
    labels: albumLabels,
  });
};
