/* eslint-disable no-multi-spaces */

// =============================
// Imports
// =============================

// External Dependencies
import {
  get,
  set,
  isEmpty,
  each,
  filter,
  startsWith,
  isEqual,
  last,
  compact,
  includes } from 'lodash';
// Helpers
import axios from './../helpers/axios';
import { translate } from './I18n';

// =============================
// Helpers
// =============================

/**
 * Promisify react setState
 * @param {Object} that - React Component instance
 * @param {Object} newState - Values to be applied to next state
 */
export function setStatePromise(that, newState) {
  return new Promise((resolve) => {
    that.setState(newState, resolve);
  });
}

// Assert if website is https or not
export function isHttps() {
  return window.location.protocol === 'https:';
}

export const getApiUrl = suffix => `${process.env.API_URL}/${suffix}`;

// Get website origin
export function getSiteOrigin() {
  return typeof window === 'undefined'
    ? global.LOCATION_ORIGIN
    : window.location.origin;
}

export const getSiteDomain = () => {
  if (typeof window === 'undefined') {
    return global.LOCATION_DOMAIN;
  }

  const hostName = window.location.hostname;
  return hostName.indexOf('.') >= 0
    ? hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1))
    : hostName;
};

// Get cookie config
export const getCookieConfig = (expiryDays = null, withDomain) => {
  const opts = {};

  if (withDomain) opts.domain = getSiteDomain();
  if (isHttps()) opts.secure = true;
  if (expiryDays) opts.expires = expiryDays;

  return opts;
};


/**
 * Pass view props to component
 * @param {Object} props - Set of key value pairs corresponding to the property and the value
 * @returns {Object} - Returns only the necessary props
 */
export function getViewProps(props) {
  const viewProps = [
    // Div
    'bgColor',      // background color
    'whiteSpace',   // White space (wrap, nowrap)
    'position',     // Position (absolute, relative, etc)
    'hide',         // Hide/Show element
    'height',       // Height
    'minHeight',    // Min Height
    'maxHeight',    // Max Height
    'width',        // Width
    'minWidth',     // Min Width
    'maxWidth',     // Max Width
    'overflow',     // Overflow
    'overflowWrap', // Overflow wrap
    'order',        // Flex order
    'margin',       // Margin
    'mx',           // Horizontal margin
    'ml',           // margin-left
    'mr',           // margin-right
    'my',           // Vertical margin
    'mt',           // margin-top
    'mb',           // margin-bottom
    'padding',      // Padding
    'px',           // Horizontal padding
    'pl',           // padding-left
    'pr',           // padding-right
    'py',           // Vertical padding
    'pt',           // padding-top
    'pb',           // padding-bottom

    // Flex
    'flex',         // flex
    'align',        // align-items
    'justify',      // justify-content
    'direction',    // flex-direction
    'wrap',         // flex-wrap
    'flexBasis',    // flex-basis
  ];

  const retProps = {};

  Object.keys(props).forEach((key) => {
    if (viewProps.indexOf(key) >= 0) {
      retProps[key] = props[key];
    }
  });

  return retProps;
}

/**
 * Check if value is of type Object
 * @param {mixed} value - Value to be checked
 * @returns {boolean}
 */
export function isObject(value) {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
}

/**
 * Get formatted duration for track
 * @param {number} duration - Duration in seconds
 * @return {string} - Formatted duration
 */
export function getTrackDuration(duration) {
  let sec = Math.floor(duration % 60);
  let min = Math.floor(duration / 60);

  if (sec < 10) sec = `0${sec}`;
  if (min < 10) min = `0${min}`;

  return `${min}:${sec}`;
}

/**
 * Get duration data in object
 * @param duration - Duration in seconds
 * @returns {{sec: number, min: number}}
 */
export function getTrackDurationObject(duration) {
  const sec = Math.floor(duration % 60);
  const min = Math.floor(duration / 60);

  return { min, sec };
}

export function disableGlobalScroll() {
  document.documentElement.classList.add('Html--no-scroll');
}

export function enableGlobalScroll() {
  document.documentElement.classList.remove('Html--no-scroll');
}

export function downloadBinaryFile(binary, filename, mimeType) {
  // saves received file
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const bytes = new TextEncoder().encode(binary);
  const blob = new Blob([bytes], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export async function downloadJson({ data, type }) {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${type}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Change audiofile name with provided RAI template
 * Template: [TRACK_TITLE] - [FIRST COMPOSER'S NAME AND SURNAME] (Alb [ALBUM_TITLE]
 * - Ediz [FIRST PUBLISHER'S NAME] - Cod cat [ALBUM_REF] Lab [LABEL_NAME]
 * - Esec [FIRST PERFORMER'S NAME AND SURNAME])
 * Example: U’POETA – DANIELE CESTANA (Alb Marathon – Ediz RaiCom
 * – Cod cat CDRT2003 Lab Raicom – Esec Daniele Cestana).mp3
 * @param trackData: Object
 * @param isOriginal: Boolean
 * @returns {Promise<void>}
 */
export async function downloadAudiofileRAI(trackData = {}, isOriginal = false) {
  const getOwnershipValue = (data, path, key, valuePath) => {
    const dataValue = get(data, path, []);
    const ownership = dataValue.find(o => o.rights_type.key === key) || get(dataValue, ['0'], {});
    return get(ownership, valuePath, '');
  };

  // Initialize data
  const trackTitle = get(trackData, ['title'], ' ');
  const album = get(trackData, ['multipart', 'album'], get(trackData, ['album'], {}));

  const albumTitle = get(album, ['title'], '');
  const albumRef = get(album, ['album_ref'], '');

  const firstComposerFullName = getOwnershipValue(
    trackData,
    ['artists_publishing_ownerships'],
    'composer',
    ['artist', 'full_name'],
  );
  const firstPublisherName = getOwnershipValue(
    album,
    ['publishing_ownerships'],
    'publisher',
    ['publisher', 'publisher_name'],
  );
  const firstLabelName = getOwnershipValue(
    album,
    ['master_ownerships'],
    'label',
    ['label', 'label_name'],
  );
  const firstPerformerFullName = getOwnershipValue(
    album,
    ['artists_master_ownerships'],
    'performer',
    ['artist', 'full_name'],
  );

  // Name creation
  let fileName = trackTitle;

  if (firstComposerFullName) {
    fileName += ` - ${firstComposerFullName}`;
  }

  let subDetail = '';
  if (albumTitle) {
    subDetail += `Alb ${albumTitle}`;
  }
  if (firstPublisherName) {
    subDetail += `${albumTitle && ' - '}Ediz ${firstPublisherName}`;
  }

  if (albumRef || firstLabelName) {
    // eslint-disable-next-line prefer-template
    subDetail += ` -${albumRef && ' Cod cat ' + albumRef}${firstLabelName && ' Lab ' + firstLabelName}`;
  }

  if (firstPerformerFullName) {
    subDetail += ` - Esec ${firstPerformerFullName}`;
  }

  // Update fileName with subDetail
  if (subDetail) {
    fileName += ` (${subDetail})`;
  }

  const audiofilePath = isOriginal ? 'original' : 'hd_mp3';
  const audiofileData = get(trackData, ['audiofile', audiofilePath], {});

  const blob = await axios.get(audiofileData.url, { responseType: 'blob' })
    .then(response => response.data);

  const href = await URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.${isOriginal ? 'wav' : 'mp3'}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export function getHiddenFieldsFromUserData(userData, panel) {
  return ((get(userData, ['hidden_fields']) || [])
    .find(field => field.panel === panel) || {}).fields || [];
}

function percentageToNumber(percentage) {
  if (percentage) {
    if (typeof percentage === 'string') {
      return percentage.includes('/') ?
        percentage.split('/')[0] / percentage.split('/')[1]
        : percentage / 100;
    }
    // eslint-disable-next-line no-nested-ternary
    return percentage % 1 !== 0 ? percentage : percentage % 1 === 0 ? percentage / 100 : 0;
  }
  return 0;
}

function checkTotalPercentages(ownerships) {
  let isValid = true;
  let key;
  const percentages = ownerships.reduce((accumulator, currentValue) => ({
    mechanical_percentage:
      accumulator.mechanical_percentage +
      percentageToNumber(currentValue.mechanical_percentage),
    performing_percentage:
      accumulator.performing_percentage +
      percentageToNumber(currentValue.performing_percentage),
    sync_percentage:
      accumulator.sync_percentage + percentageToNumber(currentValue.sync_percentage),
    ownership_percentage:
      accumulator.ownership_percentage + percentageToNumber(currentValue.ownership_percentage),
  }), {
    mechanical_percentage: 0,
    performing_percentage: 0,
    sync_percentage: 0,
    ownership_percentage: 0,
  });

  Object.keys(percentages).forEach((k) => {
    if (percentages[k] > 1 && isValid) {
      isValid = false;
      key = k;
    }
  });

  return {
    isValid,
    key,
  };
}

function checkPercentagesByRoles(ownerships) {
  let isValid = true;
  let key;
  if (ownerships && ownerships.length) {
    const ownershipsByRoles = {};
    ownerships.forEach((ownership) => {
      const ownershipKey = ownership.rights_type.key;
      if (ownershipsByRoles[ownershipKey]) {
        ownershipsByRoles[ownershipKey].push(ownership);
      } else {
        ownershipsByRoles[ownershipKey] = [ownership];
      }
    });
    const roleKeys = Object.keys(ownershipsByRoles);
    roleKeys.forEach((roleKey) => {
      const percentages = ownershipsByRoles[roleKey].reduce((accumulator, currentValue) => ({
        mechanical_percentage:
          accumulator.mechanical_percentage +
          percentageToNumber(currentValue.mechanical_percentage),
        performing_percentage:
          accumulator.performing_percentage +
          percentageToNumber(currentValue.performing_percentage),
        sync_percentage:
          accumulator.sync_percentage + percentageToNumber(currentValue.sync_percentage),
        ownership_percentage:
          accumulator.ownership_percentage + percentageToNumber(currentValue.ownership_percentage),
      }), {
        mechanical_percentage: 0,
        performing_percentage: 0,
        sync_percentage: 0,
        ownership_percentage: 0,
      });

      Object.keys(percentages).forEach((k) => {
        if (percentages[k] > 1 && isValid) {
          isValid = false;
          key = k;
        }
      });
    });
  }
  return {
    isValid,
    key,
  };
}

export function validatePercentages(newValues, locale, errors = {}) {
  const exceptionKeys = ['artists_publishing_ownerships', 'publishing_ownerships', 'master_ownerships'];
  const publishers = newValues.publishing_ownerships ? newValues.publishing_ownerships
    .filter(owner => owner.rights_type.key === 'publisher') : [];
    exceptionKeys.forEach((k) => {
      if (newValues[k]) {
        if (k === 'artists_publishing_ownerships') {
          const { isValid, key } = checkTotalPercentages(newValues[k].concat(publishers));
          if (!isValid) {
            alert(translate(`components.percentageErrors.${key}`));
            set(errors, k, translate(`components.percentageErrors.${key}`, locale));
          }
        } else if (k === 'publishing_ownerships') {
          const { isValid, key } = checkPercentagesByRoles(newValues[k]
            .filter(owner => publishers.indexOf(owner) < 0));
          if (!isValid) {
            alert(translate(`components.percentageErrors.${key}`));
            set(errors, k, translate(`components.percentageErrors.${key}`, locale));
          }
        } else {
          const { isValid, key } = checkPercentagesByRoles(newValues[k]);
          if (!isValid) {
            alert(translate(`components.percentageErrors.${key}`));
            set(errors, k, translate(`components.percentageErrors.${key}`, locale));
          }
        }
      }
    });
  return errors;
}

export function filterTagCategory(tagCategories, tagHideList) {
  const data = {
    hideAll: false,
    filteredCategories: [],
  };

  if (!isEmpty(tagHideList)) {
    each(tagCategories, (tagCategory) => {
      const category = { ...tagCategory };
      const prefixKey = `categories.${tagCategory.key}`;
      // filter hide tags by prefixKey
      console.log(tagHideList);
      const hideTags = filter(tagHideList, hideTagValue => startsWith(hideTagValue, prefixKey));

      if (isEmpty(hideTags)) {
        data.filteredCategories.push(category);
        return;
      }
      // one of hide tags is full section name
      if (hideTags.find(hideTag => isEqual(hideTag, prefixKey))) {
        return;
      }

      const subKeyList = compact(hideTags.map((tag) => {
        const parts = tag.split('.');
        return parts.length === 3 ? last(parts) : null;
      }));

      category.subCategories = filter(tagCategory.subCategories, subCategory =>
        !includes(subKeyList, subCategory.key),
      );
      category.tags = filter(tagCategory.tags, subTag =>
        !includes(subKeyList, subTag.key),
      );

      // to include category to filtered list
      // must be not empty subCategories or tags of category object
      if (!isEmpty(category.subCategories) || !isEmpty(category.tags)) {
        data.filteredCategories.push(category);
      }
    });

    if (isEmpty(data.filteredCategories)) {
      data.hideAll = true;
    }
  } else {
    data.filteredCategories = [...tagCategories];
  }

  return data;
}
