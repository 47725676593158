/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

// Import styles

// =============================
// Styled Components
// =============================

export const Content = styled.div`
  ${props => props.theme.fonts.openSansSemiBold};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
`;
