// =============================
// Imports
// =============================

// External dependencies
import { set as setCookie /* ,  remove as removeCookie */ } from 'js-cookie';
import Raven from 'raven-js';

// Config
// import env from '../../config/private/environment';

// Constants
import {
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOGOUT_USER_SUCCESS,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAILURE,
  GET_USER_AGENTS_SUCCESS,
  GET_USER_AGENTS_FAILURE,
  GET_USER_PROVIDERS_SUCCESS,
  GET_USER_PROVIDERS_FAILURE,
  SET_USER_INFO,
  GET_CUSTOM_FIELDS_CONFIG_SUCCESS,
  SET_USER_SESSION_TOKEN,
  AUTH_YLE_LOGOUT,
} from './../constants/ActionTypes';

// Actions
import { initializeApp } from '../actions/CoreActions';
import { getOptions } from '../actions/OptionsActions';
import { getUserAgents, getUserPermissions, getCustomFieldsConfig, getUserProviders } from '../actions/UserActions';

// Helpers
import { getCookieConfig } from '../helpers/helpers';
import { msalClient } from '../../config/private/msal-client';
import environment from '../../config/private/environment';

// =============================
// Middleware
// =============================

const authMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === SET_USER_SESSION_TOKEN:
      setCookie('x-auth', action.payload, getCookieConfig(15, true));
      return next(action);

    case action.type === SET_USER_INFO:
      if (Raven.isSetup()) {
        Raven.setUserContext({
          email: action.payload.email,
          id: action.payload.id,
        });
      }
      return next(action);

    case action.type === GET_USER_INFO_SUCCESS:
      store.dispatch(getOptions());
      store.dispatch(getUserPermissions());
      break;

    case action.type === GET_USER_PERMISSIONS_SUCCESS:
      store.dispatch(getCustomFieldsConfig());
      break;

    case action.type === GET_CUSTOM_FIELDS_CONFIG_SUCCESS:
      store.dispatch(getUserAgents());
      break;

    case action.type === GET_USER_AGENTS_SUCCESS:
      store.dispatch(getUserProviders());
      break;

    case action.type === GET_USER_PROVIDERS_SUCCESS:
      store.dispatch(initializeApp());
      break;

    case action.type === GET_USER_PERMISSIONS_FAILURE:
    case action.type === GET_USER_AGENTS_FAILURE:
    case action.type === GET_USER_PROVIDERS_FAILURE:
    case action.type === GET_USER_INFO_FAILURE:
      if (store.getState().yle.isYle) {
        store.dispatch({
          type: AUTH_YLE_LOGOUT,
        });
      } else {
        msalClient.logout();
      }
      break;
    case action.type === LOGOUT_USER_SUCCESS:
      // TODO: Remove LOGOUT ACTIONS
      // if (Raven.isSetup()) {
      //   Raven.setUserContext();
      // }

      // removeCookie('x-auth', getCookieConfig(null, true));
      msalClient.logout();
      break;
    case action.type === AUTH_YLE_LOGOUT:
      // eslint-disable-next-line no-restricted-globals
      location.href = `https://fs.yle.fi/adfs/ls/?wa=wsignout1.0&wreply=https://${environment.host}/logout`;
      break;
    default:
      break;
  }

  return next(action);
};

export default authMiddleware;
