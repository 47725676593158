// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  Container,
  Loading,
  FloatingProgress,
} from './Status.styles';

// Assets
import loadingSVG from '../../../../../assets/images/spinner.svg';

// =============================
// Component
// =============================

const Status = ({ progress, status }) => {
  const loading = progress > 0 && progress < 1;

  return (
    <Container status={status} loading={loading}>
      {loading && <Loading svg={loadingSVG} />}
      {loading && <FloatingProgress>{`${Math.round(progress * 100)} %`}</FloatingProgress>}
    </Container>
  );
};

Status.propTypes = {
  status: PropTypes.bool,
  progress: PropTypes.number,
};

Status.defaultProps = {
  status: false,
  progress: 0,
};

export default Status;
