// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import cloneDeep from 'lodash/cloneDeep';

// Components
import DropdownList from '../../dropdownlist/DropdownList';
import SearchBarFilterForm from './SearchBarFilterForm';

// Styles
import { Dropdown, DropdownTitle, IconArrowDown } from '../../../../themes/entityGrid';

// Assets
import iconDropdownArrowDown from '../../../../assets/images/icon-dropdown-arrow.svg';

export const DEFAULT_DATE_FILTER_VALUE = {
  dateFilter: 'created_at',
  startedAt: new Date(),
  endedAt: new Date(),
};

class SearchBarFilter extends Component {
  static propTypes = {
    data: PropTypes.oneOf([PropTypes.shape, PropTypes.object]),
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {
      dateFilter: Object.create(DEFAULT_DATE_FILTER_VALUE),
    },
  };

  state = {
    isOpen: false,
  };

  toggleDropdown = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  handleFormSubmit = (data) => {
    const { onSubmit } = this.props;

    this.setState({
      isOpen: false,
    }, () => onSubmit(data));
  }

  render() {
    const { data } = this.props;
    const { isOpen } = this.state;

    // Setup default values for filters
    const formData = defaults(cloneDeep(data), {
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });

    return (
      <Dropdown ml="15">
        <DropdownTitle className="customize-view" onClick={this.toggleDropdown}>
          <IconArrowDown svg={iconDropdownArrowDown} height="100%" width="100%" />
        </DropdownTitle>
        <DropdownList
          direction="right"
          height="220px"
          width="570px"
          overflow="visible"
          isOpen={isOpen}
          handleClickOutside={this.toggleDropdown}
          outsideClickIgnoreClass="customize-view"
        >
          <SearchBarFilterForm data={formData} onSubmit={this.handleFormSubmit} />
        </DropdownList>
      </Dropdown>
    );
  }
}

export default SearchBarFilter;
