// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

export const resetInput = styled.input`
  font-family: inherit;
  font-weight: inherit;
  outline: 0;
  text-transform: inherit;
`;

export const resetTextarea = styled.textarea`
  border: 0;
  font-family: inherit;
  font-size: 100%;
  outline: none;
  overflow: auto;
  resize: none;

  &:focus,
  &:active {
    outline: none;
  }
`;
