// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

// Components
import Header from './../../presentationals/header/Header';

// =============================
// Component
// =============================

function mapStateToProps({ user }) {
  return {
    userInfo: user.userInfo.data,
    canUseUploadManager: get(user, ['permissions', 'data', 'MANAGE_DOC_BINARIES', 'active'], true),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
