// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { get } from 'lodash';

// Components
import Tracks from './../../pages/tracks/Tracks';

// Actions
import { open as openSidePanel } from './../../../actions/SidePanelActions';
import { changeSearchPage, searchByTrack } from './../../../actions/SearchActions';
import { linkTracks, exportBulkJson } from './../../../actions/GlobalActions';
import { setUserPreferences } from '../../../actions/UserActions';

// Constants
import { TRACKS } from './../../../constants/SearchPanelTypes';

// =============================
// Component
// =============================

function mapStateToProps({ options, search, user }) {
  return {
    searchData: search[TRACKS],
    search,
    tagCategories: options.data.tags,
    agents: user.agents.data.filter(a => a.active_as_agent === true),
    canExportBulkJson: get(user.userInfo.data, ['meta_ui_settings', 'showBulkJsonExport'], false),
    canDelete: user.canDelete,
    preferences: {
      enabledColumns: get(user.userInfo.data, ['meta_ui_settings', 'preferences', TRACKS], []),
    },
  };
}

const onPageChange = (page, sort) => changeSearchPage(TRACKS, page, sort);
const onUpdatePreferences = setUserPreferences(TRACKS);

export default connect(mapStateToProps, {
  onPageChange,
  openSidePanel,
  searchByTrack,
  linkTracks,
  exportBulkJson,
  onUpdatePreferences,
})(Tracks);
