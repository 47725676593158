// TODO
// =============================
// Imports
// =============================

// Constants
import {
  AUTH_YLE_LOGOUT,
  MANUAL_SYNC_ARTIST_LOADING,
  MANUAL_SYNC_ARTIST_FAILURE,
  MANUAL_SYNC_ARTIST_SUCCESS,
  SET_YLE_BOOL,
} from '../constants/ActionTypes';

// =============================
// Initial State
// =============================

const initialState = {
  isYle: false,
  manualSyncArtist: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
};

// =============================
// Reducer
// =============================

export default function yle(state = initialState, action) {
  switch (action.type) {
    // call logout yle
    case AUTH_YLE_LOGOUT:
      return {
        ...state,
        isYle: false,
      };
    // set yle user or no
    case SET_YLE_BOOL:
      return {
        ...state,
        isYle: action.payload.isYle,
      };
    case MANUAL_SYNC_ARTIST_LOADING:
      return {
        ...state,
        manualSyncArtist: {
          ...state.manualSyncArtist,
          isLoading: true,
        },
      };
    case MANUAL_SYNC_ARTIST_FAILURE:
      return {
        ...state,
        manualSyncArtist: {
          ...state.manualSyncArtist,
          isError: true,
        },
      };
    case MANUAL_SYNC_ARTIST_SUCCESS:
      return {
        ...state,
        manualSyncArtist: {
          ...state.manualSyncArtist,
          isLoading: false,
          isSuccess: true,
        },
      };
    default:
      return state;
  }
}
