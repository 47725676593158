// =============================
// Imports
// =============================

// Constants
import {
  OPEN_SIDE_PANEL,
  REMOVE_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SET_PANEL_DATA,
} from './../constants/ActionTypes';
import { TRACK_PANEL } from '../constants/SidePanelTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isOpened: false,
  panels: [],
};

// =============================
// Reducer
// =============================

export default function sidepanel(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDE_PANEL: {
      const panel = state.panels[0] || null;

      if (panel && (panel.isSaving || panel.timeoutSave)) {
        return state;
      }

      return {
        ...state,
        isOpened: true,
        panels: [
          action.payload,
          ...state.panels,
        ],
      };
    }

    case REMOVE_SIDE_PANEL: {
      const panel = state.panels.find(p => p.uuid === action.uuid);

      if (panel && (panel.isSaving || panel.timeoutSave)) {
        return state;
      }

      return {
        ...state,
        panels: state.panels.slice(1),
      };
    }

    case CLOSE_SIDE_PANEL: {
      return initialState;
    }

    case SET_PANEL_DATA: {
      const payload = { ...action.payload };
      const { uuid } = action.payload;
      const panel = state.panels.find(p => p.uuid === uuid);
      if (panel) {
        return {
          ...state,
          panels: state.panels.map((p) => {
            if (p.uuid !== uuid) {
              return p;
            }
            if (p.name === TRACK_PANEL && p.data && payload.data) {
              if (p.data.custom) {
                if (p.data.custom.TrackInternalID) {
                  payload.data.custom.TrackInternalID = p.data.custom.TrackInternalID;
                }
                if (p.data.custom.MusicWorkType) {
                  payload.data.custom.MusicWorkType = p.data.custom.MusicWorkType;
                }
                if (p.data.custom.CustomID) {
                  payload.data.custom.CustomID = p.data.custom.CustomID;
                }
                if (p.data.custom.Object_id) {
                  payload.data.custom.Object_id = p.data.custom.Object_id;
                }
                if (p.data.custom.Dmgu_id) {
                  payload.data.custom.Dmgu_id = p.data.custom.Dmgu_id;
                }
                if (p.data.custom.Object_id_secondary) {
                  payload.data.custom.Object_id_secondary = p.data.custom.Object_id_secondary;
                }
                if (p.data.custom.Dmgu_id_secondary) {
                  payload.data.custom.Dmgu_id_secondary = p.data.custom.Dmgu_id_secondary;
                }
              }
            }
            return {
              ...p,
              ...payload,
            };
          }),
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
