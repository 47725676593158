// =============================
// Imports
// =============================

// Renderers
import renderCollection from './renderCollection';

// =============================
// Renderer
// =============================

export default onClick =>
  renderCollection(({ value }) => value.alias || value.artist.full_name, onClick);
