// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import get from 'lodash/get';

// Actions
import { exportCsv, downloadArchive } from './../../../actions/GlobalActions';

// Helpers
import { getHiddenFieldsFromUserData } from './../../../helpers/helpers';

// Components
import CatalogPanel from './../../presentationals/panels/catalog/Catalog';

// =============================
// Component
// =============================

function mapStateToProps({ user }) {
  return {
    agents: user.agents.data.filter(a => a.active_as_agent === true),
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'catalog'),
    descriptionAutocomplete: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'descriptionAutocomplete'],
      false,
    ),
    customFieldsToRightSide: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'customFieldsToRightSide'],
      false,
    ),
  };
}

export default connect(mapStateToProps, { exportCsv, downloadArchive })(CatalogPanel);
