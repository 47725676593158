// =============================
// Imports
// =============================

// Styles
import Common from '../';

const Button = Common.extend`
  background-color: transparent;
  border: 1px solid ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
  border: 1px solid ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
  color: ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};

  &:hover {
    background-color: ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
    border: 1px solid ${props => (props.warning ? props.theme.colors.error : props.theme.colors.primary)};
    color: #fff;
  }
`;

export default Button;
