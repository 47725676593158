// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Import styles

// Assets
import iconArchive from './../assets/images/icon-archive.svg';
import iconCross from './../assets/images/icon-cross.svg';
import iconDownload from './../assets/images/icon-download.svg';
import iconEdit from './../assets/images/icon-edit.svg';
import iconExport from './../assets/images/icon-export.svg';
import iconLocation from './../assets/images/icon-location.svg';
import iconNext from './../assets/images/icon-next.svg';
import iconMagicWand from './../assets/images/icon-magic-wand.svg';
import iconPause from './../assets/images/icon-pause.svg';
import iconPlay from './../assets/images/icon-play.svg';
import iconPlaylist from './../assets/images/icon-add-playlist.svg';
import iconPrev from './../assets/images/icon-prev.svg';
import iconSearch from './../assets/images/icon-search.svg';
import iconReset from './../assets/images/icon-reset.svg';
import iconTrash from './../assets/images/icon-trash.svg';
import iconTripleDot from './../assets/images/icon-triple-dot.svg';
import iconUpload from './../assets/images/icon-logout.svg';
import iconCopy from './../assets/images/icon-copy.svg';
import iconPaste from './../assets/images/icon-paste.svg';
import iconClose from './../assets/images/icon-close.svg';

// =============================
// Styled Components
// =============================

// Common styles
export const SVGIcon = styled(({ noHover, inherit, ...rest }) => <SVGInline {...rest} />)`
  align-items: center;
  display: flex;
  height: ${props => props.height};
  justify-content: center;
  transition: fill ${props => props.theme.transition.medium},
    stroke ${props => props.theme.transition.medium};
  width: ${props => props.width};

  & > svg {
    height: 100% !important;
    width: 100% !important;
  }

  ${props =>
    props.inherit &&
    css`
      fill: inherit !important;
      stroke: inherit !important;

      &:hover {
        fill: inherit !important;
        stroke: inherit !important;
      }
    `};

  ${props =>
    props.size &&
    css`
      height: ${props.size};
      width: ${props.size};
    `};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.fill &&
    props.fill in props.theme.colors &&
    css`
      fill: ${props.theme.colors[props.fill]};
    `};
`;

// = Archive Icon
export const IconArchive = styled(SVGIcon)`
  stroke: #999;

  &:hover {
    stroke: #000;
  }
`;

IconArchive.defaultProps = {
  svg: iconArchive,
  height: '19px',
  width: '14px',
};

// = Copy Icon
export const IconCopy = styled(SVGIcon)`
  stroke: #999;

  &:hover {
    stroke: #000;
  }
`;

IconCopy.defaultProps = {
  svg: iconCopy,
  height: '19px',
  width: '19px',
};

// = Paste Icon
export const IconPaste = styled(SVGIcon)`
  fill: #999;

  &:hover {
    stroke: #000;
  }
`;

IconPaste.defaultProps = {
  svg: iconPaste,
  height: '19px',
  width: '19px',
};

// = Circle icon
export const Circle = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: 50%;
  height: ${props => props.size};
  width: ${props => props.size};
`;

Circle.defaultProps = {
  color: 'error',
  size: '8px',
};

// = Cross icon
export const IconCross = styled(({ light, ...rest }) => <SVGIcon {...rest} />)`
  stroke: #999;

  &:hover {
    stroke: #000;
  }

  ${props =>
    props.light &&
    css`
      stroke: #666;

      &:hover {
        stroke: #ededed;
      }
    `};
`;

IconCross.defaultProps = {
  svg: iconCross,
  height: '12px',
  width: '12px',
};

// = Download icon
export const IconDownload = SVGIcon.extend`
  stroke: #999;

  &:hover {
    stroke: #000;
  }
`;

IconDownload.defaultProps = {
  svg: iconDownload,
  height: '19px',
  width: '19px',
};

// = Edit icon
export const IconEdit = SVGIcon.extend`
  stroke: #999;

  ${props =>
    !props.noHover &&
    css`
      &:hover {
        stroke: #000;
      }
    `};
`;

IconEdit.defaultProps = {
  svg: iconEdit,
  height: '19px',
  width: '19px',
};

// = Export to CSV Icon
export const IconExportCsv = styled(SVGIcon)`
  stroke: #999;

  &:hover {
    stroke: #000;
  }
`;

IconExportCsv.defaultProps = {
  svg: iconExport,
  height: '19px',
  width: '19px',
};

export const IconExportJson = styled(SVGIcon)`
  stroke: #999;

  &:hover {
    stroke: #000;
  }
`;

IconExportJson.defaultProps = {
  svg: iconExport,
  height: '19px',
  width: '19px',
};

// = Location icon
export const IconLocation = styled(({ active, ...rest }) => <SVGIcon {...rest} />)`
  stroke: #999;

  &:hover {
    stroke: ${props => props.theme.colors.primary};
  }

  ${props =>
    props.active &&
    css`
      stroke: ${props.theme.colors.primary};
    `};
`;

IconLocation.defaultProps = {
  svg: iconLocation,
  height: '19px',
  width: '19px',
};

// = MagicWand icon
export const IconMagicWand = SVGIcon.extend`
  cursor: pointer;
  fill: #999;

  &:hover {
    fill: #000;
  }
`;

IconMagicWand.defaultProps = {
  svg: iconMagicWand,
  height: '18px',
  width: '18px',
};

// = Player next icon
export const IconNext = SVGIcon.extend`
  fill: #666;

  &:hover {
    fill: #ededed;
  }

  ${props =>
    !props.onClick &&
    css`
      fill: #424148;

      &:hover {
        fill: #424148;
      }
    `};
`;

IconNext.defaultProps = {
  svg: iconNext,
  height: '17px',
  width: '17px',
  onClick: null,
};

// = Pause icon
export const IconPause = styled(({ light, white, ...rest }) => <SVGIcon {...rest} />)`
  fill: #666;

  &:hover {
    fill: #000;
  }

  ${props =>
    props.light &&
    css`
      fill: #666;

      &:hover {
        fill: #ededed;
      }
    `} ${props =>
  props.white &&
    css`
      fill: white;

      &:hover {
        fill: white;
      }
    `};
`;

IconPause.defaultProps = {
  svg: iconPause,
  height: '30px',
  width: '30px',
};

// = Play icon
export const IconPlay = styled(({ light, white, ...rest }) => <SVGIcon {...rest} />)`
  fill: #666;

  &:hover {
    fill: #000;
  }

  ${props =>
    props.light &&
    css`
      fill: #666;

      &:hover {
        fill: #ededed;
      }
    `} ${props =>
  props.white &&
    css`
      fill: white;

      &:hover {
        fill: white;
      }
    `};
`;

IconPlay.defaultProps = {
  svg: iconPlay,
  height: '30px',
  width: '30px',
};

// = Playlist icon
export const IconPlaylist = styled(({ light, ...rest }) => <SVGIcon {...rest} />)`
  stroke: #999;

  ${props =>
    !props.noHover &&
    css`
      &:hover {
        stroke: #000;
      }
    `} ${props =>
  props.light &&
    css`
      stroke: #666;

      &:hover {
        stroke: #ededed;
      }
    `};
`;

IconPlaylist.defaultProps = {
  svg: iconPlaylist,
  height: '18px',
  width: '18px',
};

// = Player previous icon
export const IconPrev = SVGIcon.extend`
  fill: #666;

  &:hover {
    fill: #ededed;
  }

  ${props =>
    !props.onClick &&
    css`
      fill: #424148;

      &:hover {
        fill: #424148;
      }
    `};
`;

IconPrev.defaultProps = {
  svg: iconPrev,
  height: '17px',
  width: '17px',
  onClick: null,
};

export const IconClose = styled(({ light, white, ...rest }) => <SVGIcon {...rest} />)`
  fill: #666;

  &:hover {
    fill: #000;
  }

  ${props =>
    props.light &&
    css`
      fill: #666;

      &:hover {
        fill: #ededed;
      }
    `} ${props =>
  props.white &&
    css`
      fill: white;

      &:hover {
        fill: white;
      }
    `};
`;

IconClose.defaultProps = {
  svg: iconClose,
  height: '40px',
  width: '40px',
};

// = Search icon
export const IconSearch = styled(SVGIcon)`
  stroke: ${props => props.theme.colors.text.grey};

  &:hover {
    stroke: ${props => props.theme.colors.text.greyDark};
  }
`;

IconSearch.defaultProps = {
  svg: iconSearch,
  size: '15px',
};

// = Trash icon
export const IconTrash = SVGIcon.extend`
  stroke: #999;

  ${props =>
    !props.noHover &&
    css`
      &:hover {
        stroke: ${props.theme.colors.error};
      }
    `};
`;

IconTrash.defaultProps = {
  svg: iconTrash,
  height: '20px',
  width: '19px',
};

// = Triple dot icon
export const IconTripleDot = styled(({ light, ...rest }) => <SVGIcon {...rest} />)`
  fill: #999;

  ${props =>
    !props.noHover &&
    css`
      &:hover {
        fill: ${props.theme.colors.primary};
      }
    `};

  ${props =>
    props.light &&
    css`
      fill: #666;

      &:hover {
        fill: #ededed;
      }
    `};
`;

IconTripleDot.defaultProps = {
  svg: iconTripleDot,
  height: '4px',
  width: '15px',
};

// = Upload file icon
export const IconUpload = styled(SVGIcon)`
  stroke: ${props => props.theme.colors.text.grey};
  transform: rotate(-90deg);

  &:hover {
    stroke: ${props => props.theme.colors.text.greyDark};
  }
`;

IconUpload.defaultProps = {
  svg: iconUpload,
  size: '15px',
};

const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const IconReset = styled(SVGIcon)`
  align-items: center;
  animation: ${rotateAnim} 2000ms linear infinite;
  animation-play-state: paused;
  fill: ${props => props.theme.colors.text.grey};
  &:hover {
    animation-play-state: running;
    fill: ${props => props.theme.colors.text.greyDark};
  }
`;

IconReset.defaultProps = {
  svg: iconReset,
  size: '25px',
};

export const IconText = styled.span`
  width: ${props => props.size};
  height: ${props => props.size};
  font-size: ${props => props.fontSize};
  font-weight: bold;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: ${props => props.theme.colors.text.grey};
  &:hover {
    color: ${props => props.theme.colors.text.greyDark};
  }
`;

IconText.defaultProps = {
  size: '18px',
  fontSize: '9px', // NOTE: if generalized 10/18 ratio can be used
};
