// =============================
// Imports
// =============================

// Constants
import { INITIALIZE_APP } from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  ready: false,
};

// =============================
// Reducer
// =============================

export default function core(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_APP:
      return {
        ...state,
        ready: true,
      };

    default:
      return state;
  }
}
