// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import get from 'lodash/get';

// Helpers
import { getHiddenFieldsFromUserData } from './../../../helpers/helpers';

// Components
import ArtistPanel from './../../presentationals/panels/artist/Artist';

// Actions
import { syncArtist } from '../../../actions/YleActions';

// =============================
// Component
// =============================

function mapStateToProps({ options, user }) {
  return {
    neighboringrightssocieties: options.data.neighboringrightssocieties,
    mechanicalrightssocieties: options.data.mechanicalrightssocieties,
    performingrightssocieties: options.data.performingrightssocieties,
    isFetchingOptions: options.isFetching,
    hiddenFields: getHiddenFieldsFromUserData(user.userInfo.data, 'artist'),
    descriptionAutocomplete: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'descriptionAutocomplete'],
      false,
    ),
    customFieldsToRightSide: get(
      user.userInfo.data,
      ['meta_ui_shared_settings', 'customFieldsToRightSide'],
      false,
    ),
  };
}

export default connect(mapStateToProps, { syncArtist })(ArtistPanel);
