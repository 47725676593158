// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Styles
import { Highlight } from './../../../../themes/entityGrid';
import isBlank from '../../../../helpers/isBlank';

// =============================
// Renderer
// =============================

export default (func = ({ value }) => value) => (value, object, rowIndex, highlightKey) => {
  if (highlightKey && !isBlank(object.highlight[highlightKey])) {
    return <Highlight dangerouslySetInnerHTML={{ __html: object.highlight[highlightKey][0] }} />;
  }

  return <Highlight dangerouslySetInnerHTML={{ __html: func({ value, object }) }} />;
};
