// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';

// Styles
import { Circle } from './../../../../themes/icons';

// =============================
// Renderer
// =============================

export default (collection = []) => (value, object) => {
  if (!object.owned_by_tenant) {
    return <Circle color="success" />;
  }

  if (collection.length === value.length) {
    return <Circle color="success" />;
  } else if (value.length > 0 && value.length < collection.length) {
    return <Circle color="orange" />;
  }

  return <Circle color="error" />;
};
