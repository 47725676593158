/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
// Import styles

// =============================
// Styled Components
// =============================

export const Label = styled.div`
  color: ${props => props.theme.colors.text.gray};
  margin-right: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${props => props.theme.colors.text.gray};
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Field = styled.div`
  margin-left: 8px;
`;

export const FieldLabel = styled.div`
  color: ${props => props.theme.colors.text.gray};
  margin-right: 4px;
  font-size: 12px;
  line-height: 28px;
  font-weight: 700;
`;

export const FieldValue = styled.div`
  color: ${props => props.theme.colors.text.gray};
  margin-right: 4px;
  font-size: 12px;
  line-height: 28px;
`;
