// =============================
// Imports
// =============================

// Constants
import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../constants/ActionTypes';

// =============================
// Initial State
// =============================

export const initialState = [];

// =============================
// Reducer
// =============================

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION: {
      // Check if notification already exists in state
      const notifIndex = state.findIndex(x => x.notificationKey === action.payload.notificationKey);

      // If it does, no need to add it to list
      if (notifIndex >= 0) {
        return [
          ...state,
        ];
      }

      // If it doesn't, add to list
      return [
        ...state,
        {
          type: action.payload.type,
          duration: action.payload.duration,
          notificationKey: action.payload.notificationKey,
          message: action.payload.message || '',
          actions: action.payload.actions,
        },
      ];
    }

    case CLEAR_NOTIFICATION: {
      // Check if notification exists in state
      const notifIndex = state.findIndex(x => x.notificationKey === action.payload);

      // If it doesn't, no need to clear anything
      if (notifIndex < 0) {
        return [
          ...state,
        ];
      }

      // If it does, we need to delete it
      const nextState = state;
      nextState.splice(notifIndex, 1);

      return [
        ...nextState,
      ];
    }

    default:
      return state;
  }
}
