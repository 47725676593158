// =============================
// Imports
// =============================

// External Dependencies
import axios from './../helpers/axios';

// Config
import env from './../../config/private/environment';

// Constants
import {
  GET_USER_INFO_LOADING,
  SET_USER_INFO,
  SET_USER_SESSION_TOKEN,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOGOUT_USER_LOADING,
  LOGOUT_USER_SUCCESS,
  GET_USER_PERMISSIONS_LOADING,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAILURE,
  SET_USER_PERMISSIONS,
  GET_USER_AGENTS_LOADING,
  GET_USER_AGENTS_SUCCESS,
  GET_USER_AGENTS_FAILURE,
  SET_USER_AGENTS,
  GET_USER_PROVIDERS_LOADING,
  GET_USER_PROVIDERS_SUCCESS,
  GET_USER_PROVIDERS_FAILURE,
  SET_USER_PROVIDERS,
  GET_CUSTOM_FIELDS_CONFIG_LOADING,
  GET_CUSTOM_FIELDS_CONFIG_SUCCESS,
  GET_CUSTOM_FIELDS_CONFIG_FAILURE,
  SET_CUSTOM_FIELDS_CONFIG,
  SET_CUSTOM_PREFERENCES_LOADING,
  SET_CUSTOM_PREFERENCES,
  SET_CUSTOM_PREFERENCES_SUCCESS,
  SET_CUSTOM_PREFERENCES_FAILURE,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * Get user info from api
 * @param {string} token - User token
 */
export function getUserInfos(token) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_INFO_LOADING,
    });

    return axios({
      method: 'get',
      url: `${env.apiUrl}/dashboard/users`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': token,
      },
    })
      .then((response) => {
        dispatch({
          type: SET_USER_INFO,
          payload: response.data,
        });

        dispatch({
          type: SET_USER_SESSION_TOKEN,
          payload: token,
        });

        return dispatch({
          type: GET_USER_INFO_SUCCESS,
        });
      })
      // The only error possible is a invalid_token
      // Since we are going through the axios helper, user will be redirected to dashboard
      .catch(() => dispatch({
        type: GET_USER_INFO_FAILURE,
      }));
  };
}

/**
 * Get user permissions from api
 */
export function getUserPermissions() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_PERMISSIONS_LOADING,
    });

    return axios({
      method: 'get',
      url: `${env.apiUrl}/dashboard/users/permissions`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    })
      .then((response) => {
        dispatch({
          type: SET_USER_PERMISSIONS,
          payload: response.data,
        });

        return dispatch({
          type: GET_USER_PERMISSIONS_SUCCESS,
        });
      })
      // The only error possible is a invalid_token
      // Since we are going through the axios helper, user will be redirected to dashboard
      .catch(() => dispatch({
        type: GET_USER_PERMISSIONS_FAILURE,
      }));
  };
}

/**
 * Get user agents from api
 */
export function getUserAgents() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_AGENTS_LOADING,
    });

    return axios({
      method: 'get',
      url: `${env.apiUrl}/dashboard/users/agents?max=9999`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    })
      .then((response) => {
        dispatch({
          type: SET_USER_AGENTS,
          payload: response.data.data,
        });

        return dispatch({
          type: GET_USER_AGENTS_SUCCESS,
        });
      })
      // The only error possible is a invalid_token
      // Since we are going through the axios helper, user will be redirected to dashboard
      .catch(() => dispatch({
        type: GET_USER_AGENTS_FAILURE,
      }));
  };
}


export function getUserProviders() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_PROVIDERS_LOADING,
    });

    return axios({
      method: 'get',
      url: `${env.apiUrl}/dashboard/users/providers`,
      params: {
        max: 9999,
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    })
      .then((response) => {
        dispatch({
          type: SET_USER_PROVIDERS,
          payload: response.data.data,
        });

        return dispatch({
          type: GET_USER_PROVIDERS_SUCCESS,
        });
      })
      // The only error possible is a invalid_token
      // Since we are going through the axios helper, user will be redirected to dashboard
      .catch(() => dispatch({
        type: GET_USER_PROVIDERS_FAILURE,
      }));
  };
}


/**
 * Get Custom Fields Config
 */
export function getCustomFieldsConfig() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CUSTOM_FIELDS_CONFIG_LOADING,
    });

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    };

    const url = `${env.apiUrl}/meta/custom-fields-config`;

    return axios.get(url, config)
      .then((response) => {
        dispatch({
          type: SET_CUSTOM_FIELDS_CONFIG,
          payload: response.data,
        });

        return dispatch({
          type: GET_CUSTOM_FIELDS_CONFIG_SUCCESS,
        });
      })
      .catch(() => dispatch({
        type: GET_CUSTOM_FIELDS_CONFIG_FAILURE,
      }));
  };
}

/**
 * Set user preferences
 */
export function setUserPreferences(entity) {
  return preferences => (dispatch, getState) => {
      dispatch({
        type: SET_CUSTOM_PREFERENCES_LOADING,
      });

      const { enabledColumns } = preferences;

      const config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.sessionToken,
        },
      };
      const url = `${env.apiUrl}/meta/users/preferences`;

      return axios
        .post(url, { entity, enabledColumns }, config)
        .then((response) => {
          dispatch({
            type: SET_CUSTOM_PREFERENCES,
            payload: { [entity]: response.data },
          });
          dispatch({
            type: SET_CUSTOM_PREFERENCES_SUCCESS,
          });
        })
        .catch(() =>
          dispatch({
            type: SET_CUSTOM_PREFERENCES_FAILURE,
          }),
        );
    };
}
/**
 * Log out the user
 */
export function logout() {
  return (dispatch, getState) => {
    dispatch({
      type: LOGOUT_USER_LOADING,
    });

    return axios({
      method: 'delete',
      url: `${env.apiUrl}/dashboard/users/session`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.sessionToken,
      },
    })
      // No matter what the response is, we can log out the user
      .then(() => dispatch({
        type: LOGOUT_USER_SUCCESS,
      }));
  };
}
