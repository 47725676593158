// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from './../../../helpers/helpers';
import { getColumnName } from './../../../helpers/entitygrid';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import LinkPaginator from '../linkpaginator/LinkPaginator';
import SortIndicatior from './SortIndicator';

// Styles
import {
  GridRow,
  HeadColumn,
  Column,
  NoEntity,
  Wrapper,
  WrapperChild,
} from './../../../themes/entityGrid';
import { Flex, Div } from './../../../themes/views';

// =============================
// Component
// =============================

class EntityGrid extends Component {
  static propTypes = {
    value: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    columns: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    isLoading: PropTypes.bool,

    page: PropTypes.number,
    pageCount: PropTypes.number,
    defaultSort: PropTypes.array, // eslint-disable-line react/forbid-prop-types

    RowComponent: PropTypes.func,
    renderEmpty: PropTypes.func,
    getId: PropTypes.func,

    onGet: PropTypes.func,
    initOnGet: PropTypes.bool,

    overflow: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: [],
    columns: undefined,
    isLoading: false,

    page: 0,
    pageCount: 1,
    defaultSort: undefined,

    renderEmpty: () => {},
    RowComponent: GridRow,
    getId: undefined,

    onGet: () => {},
    initOnGet: false,

    overflow: 'visible',
  };

  constructor(props) {
    const { defaultSort } = props;
    super(props);
    this.state = {
      sort: defaultSort,
    };
  }

  componentDidMount() {
    const { initOnGet, onGet } = this.props;

    if (initOnGet) {
      onGet();
    }
  }

  changePage = (page) => {
    const { onGet } = this.props;
    const { sort } = this.state;

    onGet({ page, sort });
  };

  toggleSort = (column) => {
    const { onGet } = this.props;

    const nextDirection =
      this.getSortForColumn(column) === 'ascending' ? 'descending' : 'ascending';
    const sort = [column.key, nextDirection];

    onGet({ page: 0, sort })
      .then(() => {
        this.setState(() => ({
          sort,
        }));
      })
      .catch(() => {});
  };

  defaultColumns = () => {
    const { value, t } = this.props;
    if (!value.length) return [];
    if (typeof value[0] === 'object') {
      const keys = Object.keys(value[0]).slice(0, 6);
      if (keys.length) {
        return keys.map(key => ({
          key,
          width: `${Math.floor(100 / keys.length)}%`,
          render: v => v.toString(),
        }));
      }
    }
    return [
      {
        key: 'name',
        name: t('components.entityGrid.name'),
        width: '100%',
        render: (_, object) => object.toString(),
      },
    ];
  };

  getSortForColumn = (column) => {
    const { sort } = this.state;
    if (sort && sort[0] === column.key) return sort[1];
    return undefined;
  };

  getId = (object, id) => (this.props.getId ? this.props.getId(object) : object.id || id);

  renderHeadColumn = (column, index) => {
    const { sortable, renderHead, HeadColumnComponent } = column;

    const UsedHeadColumnComponent = HeadColumnComponent || HeadColumn;
    return (
      <UsedHeadColumnComponent
        key={index}
        onClick={sortable && (() => this.toggleSort(column))}
        {...getViewProps(column)}
      >
        {renderHead ? renderHead() : getColumnName(column)}
        {column.sortable && <SortIndicatior status={this.getSortForColumn(column)} />}
      </UsedHeadColumnComponent>
    );
  };

  renderColumn = (column, columnIndex, object, rowIndex) => {
    const { key, highlightKey, render, ColumnComponent } = column;
    const UsedColumnComponent = ColumnComponent || Column;
    return (
      <UsedColumnComponent key={columnIndex} {...getViewProps(column)}>
        {render ? render(object[key], object, rowIndex, highlightKey) : object[key]}
      </UsedColumnComponent>
    );
  };

  render() {
    const { value, isLoading, renderEmpty, RowComponent, overflow, t } = this.props;
    const columns = this.props.columns || this.defaultColumns();
    const { page, pageCount } = this.props;

    if (isLoading && value.length === 0) {
      return (
        <Flex justify="center" align="center" height="400px">
          {t('components.entityGrid.loading')}
        </Flex>
      );
    }
    if (value.length === 0) return <NoEntity>{renderEmpty()}</NoEntity>;

    return (
      <Div>
        <Wrapper overflow={overflow}>
          <WrapperChild>
            <GridRow>{columns.map(this.renderHeadColumn)}</GridRow>
            {value.map((object, id) => (
              <RowComponent key={this.getId(object, id)}>
                {columns.map((column, i) => this.renderColumn(column, i, object, id))}
              </RowComponent>
            ))}
          </WrapperChild>
        </Wrapper>
        {pageCount > 1 && (
          <Flex justify="center" mb="40px">
            <LinkPaginator
              currentPage={page}
              pageCount={pageCount}
              changeSearchPage={(_, newPage) => this.changePage(newPage)}
            />
          </Flex>
        )}
      </Div>
    );
  }
}
export default withI18n()(EntityGrid);
