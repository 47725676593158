// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import { Container, IconArrowDown, IconArrowUp } from './SortIndicator.styles';

// Assets
import iconDropdownArrowDown from './../../../assets/images/icon-dropdown-arrow.svg';

// =============================
// Component
// =============================

class SortIndicatior extends Component {
  static propTypes = {
    status: PropTypes.oneOf(['ascending', 'descending', undefined]),
  };

  static defaultProps = {
    status: undefined,
  };

  state = {};

  render() {
    const { status } = this.props;

    return (
      <Container>
        <IconArrowDown svg={iconDropdownArrowDown} status={status} />
        <IconArrowUp svg={iconDropdownArrowDown} status={status} />
      </Container>
    );
  }
}

export default SortIndicatior;
