// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// Styles
import { Container, Label, Text, Switch, IconCheck, ErrorText } from './SwitchInput.styles';
import { Flex } from './../../../../themes/views';

// Assets
import iconCheck from './../../../../assets/images/icon-check.svg';

// =============================
// Component
// =============================
const SwitchInput = ({ name, label, value, onChange, textFalse, textTrue, mt, error, ...rest }) => (
  <Flex direction="column" mt={mt || '16px'} disabled={rest.disabled} {...getViewProps(rest)}>
    <Label>{label}</Label>
    <Flex align="center" justify="flex-start">
      <Text active={!value}>{textFalse}</Text>
      <Container onClick={() => onChange(name, !value)} mx="8px">
        <Switch checked={value}>
          <IconCheck
            checked={value}
            height="11px"
            width="13px"
            svg={iconCheck}
          />
        </Switch>
      </Container>
      <Text active={value}>{textTrue}</Text>
    </Flex>
    {error && <ErrorText>{error}</ErrorText>}
  </Flex>
);

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  textFalse: PropTypes.string,
  textTrue: PropTypes.string,
  mt: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

SwitchInput.defaultProps = {
  value: false,
  textFalse: '',
  textTrue: '',
  mt: '',
  disabled: false,
  error: '',
};

export default SwitchInput;
