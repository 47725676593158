// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';

// =============================
// Styled Components
// =============================

export const playerHeight = '80';
export const playerHeightExpanded = '280';

export const Container = styled.div`
  align-items: center;
  background-color: #1e2028;
  bottom: 0;
  color: #fff;
  display: flex;
  height: ${playerHeight}px;
  left: 0;
  min-width: ${props => props.theme.bp.minWidth}px;
  padding: 0 50px;
  position: fixed;
  width: 100%;
  z-index: ${props => props.theme.zIndex.player};
`;

export const PlayerPlaceholder = styled.div`
  height: ${playerHeight}px;
`;

export const CoverContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${props => (props.size ? props.size : playerHeight)}px;
  justify-content: center;
  width: ${props => (props.size ? props.size : playerHeight)}px;
  ${props => props.mr && css`
    margin-right: ${props.mr};
  `};
`;

export const TrackTitle = styled.div`
  ${props => props.theme.fontSize.l};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const ArtistName = styled.div`
  ${props => props.theme.fontSize.xs};
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const PlayerDetails = styled.div`
  background-color: #2a2b36;
  bottom: ${playerHeight}px;
  display: flex;
  height: ${playerHeightExpanded - playerHeight}px;
  left: 0;
  position: absolute;
  width: 100%;
  ${props => props.hide && css`
    display: none;
  `};
`;

export const PlayerDetailsColumn = styled.div`
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  align-content: flex-start;
  display: inline-flex;
  height: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: auto;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Label = styled.div`
  ${props => props.theme.fontSize.xs};
  margin-right: 10px;
  min-width: 70px;
  width: 70px;
`;

export const Value = styled.div`
  ${props => props.theme.fontSize.xs};
  align-items: center;
  align-content: normal;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: calc(100% - 70px - 10px); /* Label width + Label mr */
  ${props => props.hover && css`
    cursor: pointer;

    &:hover {
      color: ${props.theme.colors.primary};
    }
  `};
`;
