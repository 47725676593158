// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// HOC
import withConfirmation from './../../../hoc/WithConfirmation';
import withI18n from './../../../hoc/WithI18n';

// Constants
import {
  TRACK_PANEL,
  ALBUM_PANEL,
  ARTIST_PANEL,
  PLAYLIST_PANEL,
  CATALOG_PANEL,
  PUBLISHER_PANEL,
  LABEL_PANEL,
  MULTIPART_TRACK_PANEL,
} from './../../../../constants/SidePanelTypes';

// Styles
import { Flex } from '../../../../themes/views';

import {
  BackButton,
  Loading,
  DeleteEntity,
  DeleteIcon,
  ResendIcon,
  ResendEntity,
} from './../../sidepanel/SidePanel.styles';

import {
  Container,
  StickyContainer,
  Title,
  PanelActions,
  PanelContent,
  Image,
  TimeInfo,
  TimeInfoContainer,
} from './Header.styles';

// Assets
import iconArrowLeft from './../../../../assets/images/icon-arrow-left.svg';
import iconLoading from './../../../../assets/images/icon-loading.svg';
import iconTrash from './../../../../assets/images/icon-trash.svg';
import iconReset from './../../../../assets/images/icon-reset.svg';
import { Circle } from '../../../../themes/icons';

// =============================
// Helpers
// =============================

const fromNowOrNow = (time, { t }) => {
  if (Math.abs(moment().diff(time)) < 10000) {
    return t('components.sidePanel.justNow');
  }

  return `${t('components.sidePanel.on')} ${time.format('DD/MM/YYYY')} ${t(
    'components.sidePanel.at',
  )} ${time.format('HH:mm')}`;
};

export const getEntityName = (panelName, { t }) => {
  switch (panelName) {
    // Constants
    case TRACK_PANEL:
      return t('components.sidePanel.track');
    case MULTIPART_TRACK_PANEL:
      return t('components.sidePanel.multipart');
    case ALBUM_PANEL:
      return t('components.sidePanel.album');
    case ARTIST_PANEL:
      return t('components.sidePanel.artist');
    case PLAYLIST_PANEL:
      return t('components.sidePanel.playlist');
    case CATALOG_PANEL:
      return t('components.sidePanel.catalog');
    case PUBLISHER_PANEL:
      return t('components.sidePanel.publisher');
    case LABEL_PANEL:
      return t('components.sidePanel.label');
    default:
      return '';
  }
};

const yleTenant = '5f3551ff3142f200334ff41f';

// =============================
// Component
// =============================

class PanelHeader extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      uuid: PropTypes.string,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }).isRequired,
    title: PropTypes.string.isRequired,
    isOwned: PropTypes.bool.isRequired,
    closePanel: PropTypes.func.isRequired,
    deleteForm: PropTypes.func,
    back: PropTypes.bool,
    picture: PropTypes.node,
    actions: PropTypes.node,
    content: PropTypes.node,
    onConfirm: PropTypes.func,
    updateAdditionalFormData: PropTypes.func,
    isBatch: PropTypes.bool,
    canDelete: PropTypes.bool,
    t: PropTypes.func.isRequired,
    syncArtist: PropTypes.func.isRequired,
  };

  static defaultProps = {
    back: false,
    actions: null,
    content: null,
    picture: null,
    isBatch: false,
    canDelete: true,
    deleteForm: () => Promise.resolve(false),
    onConfirm: () => Promise.resolve(false),
    updateAdditionalFormData: () => Promise.resolve(false),
  };

  constructor(props) {
    super(props);
    // Timer to refresh the last update display
    this.interval = setInterval(() => this.setState({}), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  deleteDocument = () => {
    const { panel, deleteForm, onConfirm, t } = this.props;
    if (panel.name === ALBUM_PANEL || panel.name === CATALOG_PANEL) {
      return onConfirm(t('components.sidePanel.deleteConfirmationMessage'), {
        title: t('components.sidePanel.deleteConfirmationTitle'),
        submit: t('components.sidePanel.deleteConfirmationSubmit'),
      }).then((result) => {
        if (result) {
          return deleteForm();
        }

        return null;
      });
    }

    return deleteForm();
  };

  render() {
    const { panel, picture, actions, content, isOwned, isBatch, canDelete, t, syncArtist } =
      this.props;

    const getDeleteButton = () => {
      // Can't delete if it doesn't exist or if it's not owned or if it's saving
      if (!panel.id || !isOwned || panel.isSaving || isBatch || !canDelete) return null;

      return (
        <Flex align="center" ml="auto">
          &nbsp;/&nbsp;
          <DeleteEntity
            onClick={() => {
              this.deleteDocument();
            }}
          >
            {t('components.sidePanel.delete')} {getEntityName(panel.name, { t })}
            <DeleteIcon svg={iconTrash} height="100%" width="100%" />
          </DeleteEntity>
        </Flex>
      );
    };

    const getResendSyncButton = () => {
      if (!panel.id || !isOwned || panel.isSaving || isBatch || panel.name !== ARTIST_PANEL) {
        return null;
      }

      return (
        <div
          style={{
            display: 'flex',
            borderBottom: '1px solid white',
            marginLeft: 0,
            alignSelf: 'baseline',
          }}
        >
          <ResendEntity
            onClick={() => {
              syncArtist(panel.id);
            }}
          >
            {t('components.sidePanel.yleSync')} {getEntityName(panel.name, { t })}
            <ResendIcon svg={iconReset} height="100%" width="100%" />
          </ResendEntity>
        </div>
      );
    };

    const getLastTimeUpdated = () => {
      if (panel.isSaving) {
        // When document is in isSaving state
        return (
          <Flex align="center" inline ml="5px">
            <Loading svg={iconLoading} height="100%" width="100%" />&nbsp;Saving
          </Flex>
        );
      } else if (panel.data) {
        // When document exists
        return `${t('components.sidePanel.saved')} ${fromNowOrNow(moment(panel.data.updated_at), {
          t,
        })}`;
      }

      // When document does not exist
      return t('components.sidePanel.notSaved');
    };

    const isYleTenant = panel.data && panel.data.tenant === yleTenant;
    const isSyncedPanel =
      panel.data &&
      [TRACK_PANEL, MULTIPART_TRACK_PANEL, ALBUM_PANEL].includes(panel.name) &&
      isYleTenant;

    const getLastTimeSynced = () => {
      if (
        panel.data.synced_at &&
        moment(panel.data.synced_at).isAfter(moment(panel.data.updated_at).subtract(1, 'seconds'))
      ) {
        return (
          <Circle
            color="success"
            size="14px"
            title={`${t('components.sidePanel.synced')} ${fromNowOrNow(
              moment(panel.data.synced_at),
              { t },
            )}`}
          />
        );
      }
      return <Circle color="error" size="14px" title="Not ES-synced" />;
    };

    const isHasLastTimeSyncedAudio =
      panel.data &&
      [TRACK_PANEL, MULTIPART_TRACK_PANEL].includes(panel.name) &&
      panel.data.audiofile.original &&
      panel.data.tenant === yleTenant &&
      ['wav', 'flac', 'aac', 'bwf', 'm4a'].some(val =>
        panel.data.audiofile.original.file_name.endsWith(val),
      );

    const getLastTimeSyncedAudio = () => {
      if (panel.data.audio_uploaded_at) {
        if (panel.data.metro_sent_at) {
          if (moment(panel.data.metro_sent_at).isAfter(panel.data.audio_uploaded_at)) {
            return (
              <Circle
                color="success"
                size="14px"
                title={`${t('components.sidePanel.metro')} ${fromNowOrNow(
                  moment(panel.data.metro_sent_at),
                  { t },
                )}`}
              />
            );
          }
        }
        return <Circle color="orange" size="14px" title="Audiofile is sending to METRO" />;
      }
      return <Circle color="error" size="14px" title="Waiting for Yle-controller" />;
    };

    return (
      <React.Fragment>
        <StickyContainer>
          <Flex align="center" direction="row" height="24px" mb="20px">
            {this.props.back && (
              <BackButton
                svg={iconArrowLeft}
                onClick={() => {
                  this.props.closePanel(panel.uuid);
                }}
                height="100%"
                width="100%"
              />
            )}
            <Title>{this.props.title}</Title>
            <TimeInfoContainer>
              <TimeInfo>
                {getLastTimeUpdated()} {getDeleteButton()}
              </TimeInfo>
              {(isSyncedPanel || isHasLastTimeSyncedAudio) && (
                <div
                  style={{
                    // marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'baseline',
                    alignItems: 'start',
                  }}
                >
              {isSyncedPanel && (
                    <TimeInfo style={{ marginLeft: 0 }}>
                  <p style={{ marginRight: 3 }}>ES status:</p>
                  {getLastTimeSynced()}
                </TimeInfo>
              )}
              {isHasLastTimeSyncedAudio && (
                    <TimeInfo style={{ marginLeft: 0 }}>
                  <p style={{ marginRight: 3 }}>Metro status: </p>
                  {getLastTimeSyncedAudio()}
                </TimeInfo>
              )}
                </div>
              )}
              {isYleTenant && getResendSyncButton()}
            </TimeInfoContainer>
          </Flex>
        </StickyContainer>
        <Container>
          <Flex direction="row" align="flex-start" justify="flex-start" mb="20px">
            {/* Picture */}
            <Image align="center" justify="center">
              {picture}
            </Image>

            {/* Actions */}
            {actions && <PanelActions>{actions}</PanelActions>}

            {/* Content */}
            {content && <PanelContent>{content}</PanelContent>}
          </Flex>
        </Container>
      </React.Fragment>
    );
  }
}

export default withI18n()(withConfirmation(PanelHeader));
