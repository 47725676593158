// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';
import SVGInline from 'react-svg-inline';

// Styles
import { Div } from './../../../themes/views';

export const BlackVeil = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: opacity ${props => props.theme.transition.medium};
  width: 100%;
  z-index: ${props => props.theme.zIndex.sidepanelveil};
  ${props => props.isOpened && css`
    opacity: 1;
    pointer-events: auto;
  `};
`;

export const ExtraLayout = styled.div`
  background-color: #fff;
  box-shadow: -1px 0 5px 0 ${props => props.theme.colors.shadow};
  height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 1000px;
  top: 0;
  width: 20px;
  z-index: ${props => props.theme.zIndex.sidepanel};

  &:nth-of-type(2) {
    right: 1020px;
  }

  &:nth-of-type(3) {
    right: 1040px;
  }

  &:nth-of-type(4) {
    right: 1060px;
  }
`;

export const ExtraBlackVeil = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Container = styled(Div)`
  background: #fff;
  box-shadow: -1px 0 5px 0 ${props => props.theme.colors.shadow};
  height: 100vh;
  padding: 30px;
  padding-top: 0;
  position: fixed;
  overflow: auto;
  right: 0;
  top: 0;
  transition: transform ${props => props.theme.transition.medium};
  transform: translate3d(1000px, 0, 0);
  width: 1000px;
  @media (max-width: 1000px) {
    width: 100%;
  }
  z-index: ${props => props.theme.zIndex.sidepanel};
  ${props => props.isOpened && css`
    transform: translate3d(0, 0, 0);
  `}
`;

export const BackButton = styled(SVGInline)`
  cursor: pointer;
  height: 19px;
  margin-right: 30px;
  stroke: ${props => props.theme.colors.text.grey};
  width: 23px;
`;

export const Title = styled.div`
  ${props => props.theme.fonts.openSansSemiBold};
  text-transform: uppercase;
`;

export const SubCategory = styled(Div)`
  ${props => props.theme.fonts.neutraDisplay};
  ${props => props.theme.fontSize.l};
  margin-bottom: -10px;
  margin-top: 20px;
  text-transform: uppercase;

  &:nth-of-type(1) {
    margin-top: 10px;
  }
`;

export const PanelContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const Loading = styled(SVGInline)`
  align-items: center;
  display: inline-flex;
  height: 15px;
  justify-content: center;
  stroke: ${props => props.theme.colors.primary};
  width: 15px;
`;

export const DeleteEntity = styled(Div)`
  align-items: center;
  cursor: pointer;
  color: #000;
  display: inline-flex;
  justify-content: center;
  stroke: #000;

  &:hover {
    border-bottom: 1px solid ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.error};
    stroke: ${props => props.theme.colors.error};
  }
`.withComponent('p');

export const ResendEntity = styled(Div)`
  align-items: center;
  cursor: pointer;
  color: #000;
  display: inline-flex;
  justify-content: center;
  stroke: #000;

  &:hover {
    color: ${props => props.theme.colors.error};
    stroke: ${props => props.theme.colors.error};
  }
`.withComponent('p');

export const DeleteIcon = styled(SVGInline)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: center;
  margin-left: 4px;
  width: 12px;
`;

export const ResendIcon = styled(SVGInline)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: center;
  margin-left: 4px;
  width: 12px;
`;
