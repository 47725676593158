// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { batchDelete } from '../../../actions/SidePanelActions';

// Components
import BatchDeleter from '../../presentationals/batchdeleter/BatchDeleter';

export default connect(
  null,
  { batchDelete },
)(BatchDeleter);
