// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import InputContainer from '../container/InputContainer';
import Input from '../input/Input';

// Helpers
import { getViewProps } from '../../../../helpers/helpers';

// =============================
// Component
// =============================

class InputUnderline extends Component {
  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    getContainerRef: PropTypes.func,
    getInputRef: PropTypes.func,
    hideErrorText: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    type: 'text',
    label: '',
    placeholder: '',
    value: '',
    defaultValue: '',
    error: '',
    onBlur: null,
    onFocus: null,
    onChange: null,
    onKeyDown: null,
    getContainerRef: null,
    getInputRef: null,
    hideErrorText: false,
  };

  state = {
    isEditing: false,
  };

  componentWillMount() {
    if (this.props.defaultValue && !this.props.value) {
      this.props.onChange(this.props.name, this.props.defaultValue);
    }
  }

  handleBlur = (e) => {
    this.setState({
      isEditing: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleFocus = (e) => {
    this.setState({
      isEditing: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  render() {
    const {
      label,
      error,
      getContainerRef,
      getInputRef,
      name,
      type,
      value,
      placeholder,
      onBlur,
      onFocus,
      onChange,
      onKeyDown,
      hideErrorText,
    } = this.props;

    const { isEditing } = this.state;

    const inputRest = {
      name,
      type,
      onBlur,
      onFocus,
      onChange,
      onKeyDown,
    };

    const isFilled = () => {
      if (value === 0) return true;
      return !!value;
    };

    return (
      <InputContainer
        {...getViewProps(this.props)}
        innerRef={getContainerRef}
        label={label}
        error={error}
        active={isEditing}
        filled={isFilled()}
        hideErrorText={hideErrorText}
      >
        <Input
          {...inputRest}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          isEditing={isEditing}
          hasLabel={!!label}
          getInputRef={getInputRef}
          disabled={!onChange}
        />
      </InputContainer>
    );
  }
}

export default InputUnderline;
