// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Components
import { Label } from './SearchBarFilterForm.styles';
import { Flex } from '../../../../themes/views';

// =============================
// Component
// =============================

const DateFilterTypeInput = ({
  field,
  form: { touched, errors, setFieldValue },
  t,
}) => (
  <Flex>
    <Flex align="center" mr="8px">
      <Label htmlFor="created_at" >{t('components.searchBar.filter.dateFilter.created_at')}</Label>
      <input
        type="radio"
        name="created_at"
        checked={field.value === 'created_at'}
        onChange={() => setFieldValue(field.name, 'created_at')}
      />
    </Flex>
    <Flex align="center" mr="8px">
      <Label htmlFor="ingested_at">{t('components.searchBar.filter.dateFilter.ingested_at')}</Label>
      <input
        type="radio"
        name="ingested_at"
        checked={field.value === 'ingested_at'}
        onChange={() => setFieldValue(field.name, 'ingested_at')}
      />
    </Flex>
    {touched[field.name] &&
    errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </Flex>
);

DateFilterTypeInput.propTypes = {
  field: PropTypes.shape.isRequired,
  form: PropTypes.shape.isRequired,
  t: PropTypes.func.isRequired,
};

DateFilterTypeInput.defaultProps = {
};

export default withI18n()(DateFilterTypeInput);
