// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Container, LoadingIcon, LoadingText } from './Loader.styles';

// Assets
import iconRipple from './../../../assets/images/icon-ripple.svg';

// =============================
// Component
// =============================
const Loader = ({ text, size, my, fullscreen }) => (
  <Container fullscreen={fullscreen} my={my}>
    <LoadingIcon size={size} svg={iconRipple} height="100%" width="100%" />
    {text && <LoadingText>{text}</LoadingText>}
  </Container>
);

Loader.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  my: PropTypes.string,
  fullscreen: PropTypes.bool,
};

Loader.defaultProps = {
  text: '',
  size: '',
  my: '',
  fullscreen: false,
};

export default Loader;
