// =============================
// Imports
// =============================

// External Dependencies
import styled, { keyframes } from 'styled-components';

import { Flex } from '../../../../themes/views';

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const LoaderContainer = styled(Flex)`
  animation: ${FadeIn} 1s linear 1;
  height: 100%;
  padding-bottom: 40px;
  width: 100%;
`;

export const ContentLoaded = styled.div`
  animation: ${FadeIn} 300ms linear 1;
`;

export default LoaderContainer;
