// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Styles
import { InlineFlex, Wave, ImageTag } from './ImageLoader.style';

// Assets
import waveSvg from '../../../assets/images/wave.svg';

// =============================
// Component
// =============================

class ImageLoader extends Component {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    this.image = new Image();
    this.image.onload = () => {
      this.setState({ loaded: true });
    };

    this.image.src = this.props.src;
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    this.image = undefined;
  }

  render() {
    if (this.state.loaded) return <ImageTag src={this.props.src} {...getViewProps(this.props)} />;
    return (
      <InlineFlex align="center" justify="center" className={this.props.className}>
        <Wave height="60px" width="50px" svg={waveSvg} />
      </InlineFlex>
    );
  }
}

export default ImageLoader;
