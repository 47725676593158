// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// HOC
import withI18n from '../../hoc/WithI18n';

// Components
import PrintContainer from './PrintContainer';

// Actions
import { getMultipart } from '../../../actions/GlobalActions';

// Helpers
import MultipartBuilder from './builders/MultipartBuilder';

// =============================
// Component
// =============================

function prepareMultipart(multipart, user, t, locale) {
  const builder = new MultipartBuilder(multipart, user, t, locale);

  return builder
    .addMainInformation()
    .addTracks()
    .addDescription()
    .addAlbum()
    .addDisplayArtist()
    .addMasterInfo()
    .addPublishingInfo()
    .addTags()
    .addDistribution()
    .addFiles()
    .addCustomFields()
    .build();
}

function mapStateToProps({ print, user }, { match, t, locale }) {
  const { id } = match.params;
  const multipart = print.data;

  return {
    id,
    title: multipart ? multipart.display_title : '',
    data: multipart ? prepareMultipart(multipart, user, t, locale) : [],
    user,
  };
}

const ConnectedPrintContainer = connect(mapStateToProps, { getData: getMultipart })(
  PrintContainer,
);

export default withRouter(withI18n()(ConnectedPrintContainer));
