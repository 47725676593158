// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import transform from 'lodash/transform';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import debounce from 'lodash/debounce';
import moment from 'moment';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import Chip from '../chip/Chip';
import SearchBarAutocomplete from './SearchBarAutocomplete';
import SearchBarFilter from './filter/SearchBarFilter';
import TagsChunk from './../tagschunk/TagsChunk';
import Tag from './../tag/Tag';
import SelectDropdown from './../selectdropdown/SelectDropdown';

// Helpers
import renderDate from '../entitygrid/renderers/renderDate';

// Themes
import { IconSearch, IconReset } from './../../../themes/icons';
import { Div, Flex } from './../../../themes/views';

// Styles
import { Container, Input, SearchIcons, MaiaNotice } from './SearchBar.styles';
import { Item } from './../dropdownlist/DropdownList.styles';

// =============================
// Component
// =============================
const SEARCH_REGEXP = ' *(tag|provider|status|formats|custom_id|recording_year|composition_year|re_release_year|languages|NumeroPratica|DescrizionePratica|CodiciGeneri|wbs|isrc|ean|album_ref|bpm): *([^,]*?) *';
const BPM_REGEXP = '^(bpm):';

const DEFAULT_SEARCH_FILTER = {
  customFields: {
    provider: [],
    status: [],
    custom_id: [],
    formats: [],
    recording_year: [],
    composition_year: [],
    re_release_year: [],
    languages: [],
    NumeroPratica: [],
    DescrizionePratica: [],
    CodiciGeneri: [],
    wbs: [],
  },
  fields: {
    isrc: [],
    ean: [],
    album_ref: [],
    bpm: [],
  },
  searchNames: [],
  matchKey: '',
  filter: undefined,
};

class SearchBar extends Component {
  execSearchName = debounce((value) => {
    const { searchByName } = this.props;
    searchByName(value);
  }, 500);

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.search.query || '',
      dropdownIsOpened: false,
      inputSearchDropdownIsOpened: false,
      tags: [],
      tagSubcategories: [],
      tagKeys: [],
      searchFilters: cloneDeep(DEFAULT_SEARCH_FILTER),
      searchNameSelectedIndex: -1,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { job } = this.props.search;
    const { job: nextJob } = nextProps.search;

    if (!job.done && nextJob.done) {
      this.sendSearchQuery();
    }

    if (job.done && job.id && !nextJob.id) {
      this.sendSearchQuery();
    }
  }

  onFocus = () => {
    this.input.removeAttribute('readonly');
  };

  onBlur = () => {
    this.input.selectionStart = 0;
    this.input.selectionEnd = 0;
    this.input.setAttribute('readonly', true);
    this.setState(() => ({ dropdownIsOpened: false }));
  };

  handleKeyDown = (event) => {
    const { value, searchNameSelectedIndex } = this.state;

    if (event.key === 'Enter'
      && !(new RegExp(`${BPM_REGEXP}`).exec(value))
      && !(this.getAutocompleteMatch(value) || {}).value
      && searchNameSelectedIndex === -1) {
      this.handleAddSearchValueToSearchNames(value);
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const isInputSearchOpened = !!(value && !value.includes(':'));

    if (isInputSearchOpened) {
      this.execSearchName(value);
    }

    return this.setState(() => ({
      value: (new RegExp(`${BPM_REGEXP}`).exec(value)) ? 'bpm:' : value,
      dropdownIsOpened: value === 'bpm:' ? true : !!(this.getAutocompleteMatch(value) || {}).value,
      inputSearchDropdownIsOpened: isInputSearchOpened,
    }));
  };

  handleAddSearchValueToSearchNames = (value) => {
    Promise.resolve()
      .then(() => this.setState({
        value: '',
        searchFilters: {
          ...this.state.searchFilters,
          searchNames: [
            ...this.state.searchFilters.searchNames,
            value,
          ],
        },
      }))
      .then(() => this.sendSearchQuery());
  };

  handleSelectFromDropdown = (matchKey, id, key, isSubcategory) => {
    const isSimpleField = keys(DEFAULT_SEARCH_FILTER.fields).includes(matchKey);
    const isNamesField = keys(DEFAULT_SEARCH_FILTER).includes(matchKey);
    const name = isSubcategory ? 'tagSubcategories' : 'tags';

    const addOrRemove = (arr, nId) => {
      const newArr = [...arr];

      if (newArr.indexOf(nId) !== -1) {
        return newArr.filter(o => o !== nId);
      }

      newArr.push(nId);
      return newArr;
    };

    this.setState(
      (prevState) => {
        if (matchKey === 'tag') {
          return {
            [name]: addOrRemove(prevState[name], id),
            tagKeys: addOrRemove(prevState.tagKeys, key),
            value: '',
            inputSearchDropdownIsOpened: false,
          };
        } else if (isSimpleField) { // for simple fields
          return {
            searchFilters: {
              ...prevState.searchFilters,
              fields: {
                ...prevState.searchFilters.fields,
                [matchKey]: addOrRemove(prevState.searchFilters.fields[matchKey], id),
              },
              matchKey,
            },
            value: '',
            inputSearchDropdownIsOpened: false,
          };
        } else if (isNamesField) {
          return {
            searchFilters: {
              ...prevState.searchFilters,
              [matchKey]: addOrRemove(prevState.searchFilters[matchKey], id),
              matchKey,
            },
            value: '',
            inputSearchDropdownIsOpened: false,
          };
        }
        // for custom fields
        return {
          searchFilters: {
            ...prevState.searchFilters,
            customFields: {
              ...prevState.searchFilters.customFields,
              [matchKey]: addOrRemove(prevState.searchFilters.customFields[matchKey], id),
            },
            matchKey,
          },
          value: '',
          inputSearchDropdownIsOpened: false,
        };
      },
      () => this.sendSearchQuery(),
    );
  };

  handleDeleteTag = (key, id) => {
    const { tagSubcategories } = this.state;
    const isSubcategory = tagSubcategories.indexOf(id) !== -1;

    this.handleSelectFromDropdown('tag', id, key, isSubcategory);
  };

  handleCloseDropdown = () => {
    this.setState(() => ({
      dropdownIsOpened: false,
      inputSearchDropdownIsOpened: false,
    }));
  };

  handleResetSearchQuery = () => {
    this.setState(
      () => ({
        tags: [],
        tagSubcategories: [],
        tagKeys: [],
        value: '',
        searchFilters: cloneDeep(DEFAULT_SEARCH_FILTER),
      }),
      () => {
        const { clearSearchJob } = this.props;
        clearSearchJob();
        this.sendSearchQuery(true);
      },
    );
  };

  handleSearchFilterChange = (data) => {
    this.setState(prevState => ({
      ...prevState,
      searchFilters: {
        ...prevState.searchFilters,
        filter: data,
      },
    }), this.sendSearchQuery);
  };

  handleFilterTagDelete(key) {
    this.setState(prevState => ({
      ...prevState,
      searchFilters: {
        ...prevState.searchFilters,
        filter: omit(prevState.searchFilters.filter, [key]),
      },
    }), this.sendSearchQuery);
  }

  handleDeleteCustomFieldTag(fieldKey, fieldValue) {
    this.handleSelectFromDropdown(fieldKey, fieldValue, fieldValue, false);
  }

  handleDeleteFieldTag(fieldKey, fieldValue) {
    this.handleSelectFromDropdown(fieldKey, fieldValue, fieldValue, false);
  }

  sendSearchQuery = (resetAll = false) => {
    const { getSearchResults, search } = this.props;
    const {
      value,
      tags,
      tagSubcategories,
      searchFilters: { customFields, filter, fields, searchNames },
    } = this.state;
    const prefixPayload = {
      tags: [...tags, ...tagSubcategories],
      albums: !resetAll ? search.prefixes.albums || [] : [],
      catalogs: !resetAll ? search.prefixes.catalogs || [] : [],
      searchNames: !resetAll ? searchNames : [],
    };

    const searchValue = value;

    if (!resetAll && !isEmpty(customFields.status)) {
      prefixPayload.workflow_statuses = (customFields.status || []).map(v => ({ id: v }));
    } else if (!resetAll && !isEmpty(customFields.custom_id)) {
      prefixPayload.custom_id = customFields.custom_id;
    } else if (!resetAll && !isEmpty(customFields.formats)) {
      prefixPayload.formats = (customFields.formats || []).map(v => ({ id: v }));
    } else if (!resetAll && !isEmpty(customFields.recording_year)) {
      prefixPayload.recording_year = customFields.recording_year;
    } else if (!resetAll && !isEmpty(customFields.composition_year)) {
      prefixPayload.composition_year = customFields.composition_year;
    } else if (!resetAll && !isEmpty(customFields.re_release_year)) {
      prefixPayload.re_release_year = customFields.re_release_year;
    } else if (!resetAll && !isEmpty(customFields.languages)) {
      prefixPayload.languages = customFields.languages;
    } else if (!resetAll && !isEmpty(customFields.NumeroPratica)) {
      prefixPayload.NumeroPratica = customFields.NumeroPratica;
    } else if (!resetAll && !isEmpty(customFields.DescrizionePratica)) {
      prefixPayload.DescrizionePratica = customFields.DescrizionePratica;
    } else if (!resetAll && !isEmpty(customFields.CodiciGeneri)) {
      prefixPayload.CodiciGeneri = customFields.CodiciGeneri;
    } else if (!resetAll && !isEmpty(customFields.wbs)) {
      prefixPayload.wbs = customFields.wbs;
    }

    keys(fields).forEach((key) => {
      if (!resetAll && !isEmpty(fields[key])) {
        prefixPayload[key] = fields[key];
      }
    });

    if (!resetAll && filter && filter.dateFilter) {
      const { dateFilter } = filter;
      const gte = moment(dateFilter.startedAt, 'DD/M/YYYY').toDate();
      const lt = moment(dateFilter.endedAt, 'DD/M/YYYY').add(1, 'day').toDate();

      gte.setUTCHours(0, 0, 0, 0);
      lt.setUTCHours(0, 0, 0, 0);

      prefixPayload[dateFilter.dateFilter] = {
        gte: gte.toISOString(),
        lt: lt.toISOString(),
      };
    }

    if (/^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/gm.test(searchValue)) {
      this.props.searchLink(searchValue);
      this.setState({
        tags: [],
        tagSubcategories: [],
        value: '',
        searchFilters: cloneDeep(DEFAULT_SEARCH_FILTER),
        inputSearchDropdownIsOpened: false,
      });
    } else {
      this.execSearchName.cancel();
      getSearchResults(
        searchValue,
        {},
        prefixPayload,
      );
      this.setState({ inputSearchDropdownIsOpened: false, value: '' });
    }
  };

  searchUpload = (event) => {
    const { target } = event;
    const file = target.files[0];

    if (file) {
      this.props.uploadAudioFile(file);
      target.value = '';
    }
  };

  getAutocompleteMatch = (value) => {
    if (!value) return undefined;
    const match =
      new RegExp(`^${SEARCH_REGEXP}$`).exec(value) || new RegExp(`,${SEARCH_REGEXP}$`).exec(value);
    if (!match) return undefined;

    return { match: match[1], value: match[2], index: match.index };
  };

  getAutocompleteMatchByName = () => {
    const { search: { inputSearchQuery } } = this.props;
    const { value } = this.state;
    const inputKeys = keys(inputSearchQuery);
    const hits = [];

    inputKeys.forEach(key => hits.push(...inputSearchQuery[key]));
    return hits.filter(hit => hit.includes(value));
  };

  getFieldTags() {
    const { searchFilters: { fields } } = this.state;

    return transform(fields, (acc, values, key) => {
      acc.push(...values.map(v => (
        <Tag
          key={v}
          id={v}
          value={v}
          label={`${key}: ${v}`}
          status={2}
          color="#000000"
          onDelete={() => {
            this.handleDeleteFieldTag(key, v);
          }}
        />
      )));
      return acc;
    }, []);
  }

  getCustomFieldTags() {
    const { searchFilters: { customFields, matchKey } } = this.state;
    const isLabelToUpperCase = matchKey && ['formats'].includes(matchKey);

    return transform(customFields, (acc, values, key) => {
      acc.push(...values.map(v => (
        <Tag
          key={v}
          id={v}
          value={v}
          label={`${key}: ${isLabelToUpperCase ? v.toUpperCase() : v}`}
          status={2}
          color="#000000"
          onDelete={() => {
            this.handleDeleteCustomFieldTag(key, v);
          }}
        />
      )));
      return acc;
    }, []);
  }

  getFilterTag() {
    const { t } = this.props;
    const { searchFilters: { filter } } = this.state;
    const tags = [];
    const dateRender = renderDate();

    if (!filter) return tags;

    if (filter.dateFilter) {
      const { dateFilter } = filter;
      const startedAt = dateFilter.startedAt ? dateRender(dateFilter.startedAt) : '*';
      const endedAt = dateFilter.endedAt ? dateRender(dateFilter.endedAt) : '*';
      const label = t(`components.searchBar.filter.dateFilter.${dateFilter.dateFilter}`);

      tags.push(
        <Tag
          key="dateFilter"
          id="dateFilter"
          value={dateFilter.dateFilter}
          label={`${label}: ${startedAt} - ${endedAt}`}
          status={2}
          color="#000000"
          onDelete={() => {
            this.handleFilterTagDelete('dateFilter', dateFilter.dateFilter);
          }}
        />,
      );
    }

    return tags;
  }

  getSearchNameTags() {
    const { searchFilters: { searchNames } } = this.state;
    return searchNames.map(name => (
      <Tag
        key={name}
        id={name}
        value={name}
        label={name}
        status={2}
        color="#000000"
        onDelete={() => {
          this.handleDeleteFieldTag('searchNames', name);
        }}
      />
    ));
  }

  render() {
    const {
      tagCategories, customFields, removeAlbum, removeCatalog, removeProvider, t,
    } = this.props;
    const {
      value, dropdownIsOpened, inputSearchDropdownIsOpened, tagKeys, searchFilters,
    } = this.state;
    const {
      search: { job },
    } = this.props;
    const activeSearch = !!job.type;
    const isUpload = job.type === 'upload';
    const uploading = isUpload && !job.done && job.uploadProgress < 1;
    const uploadDone = !job.done && isUpload && job.uploadProgress === 1;
    const autocompleteValue = this.getAutocompleteMatch(value) || {};
    const autocompleteInputValue = this.getAutocompleteMatchByName();

    const albumTag = (this.props.search.prefixes.albums || []).map(a => (
      <Tag
        key={a.id}
        id={a.id}
        value={a.id}
        label={a.name}
        status={2}
        color="#000000"
        onDelete={() => {
          removeAlbum();
        }}
      />
    ));

    const catalogTag = (this.props.search.prefixes.catalogs || []).map(a => (
      <Tag
        key={a.id}
        id={a.id}
        value={a.id}
        label={a.name}
        status={2}
        color="#000000"
        onDelete={() => {
          removeCatalog();
        }}
      />
    ));
    const providerTag = (this.props.search.prefixes.providers || []).map(a => (
      <Tag
        key={a.id}
        id={a.id}
        value={a.id}
        label={a.name}
        status={2}
        color="#000000"
        onDelete={() => {
          removeProvider(a.id);
        }}
      />
    ));

    const fieldTags = this.getFieldTags();
    const customFieldTags = this.getCustomFieldTags();
    const filterTags = this.getFilterTag();
    const searchNameTags = this.getSearchNameTags();

    return (
      <Container>
        <Input
          innerRef={(input) => {
            this.input = input;
          }}
          placeholder={t('components.searchBar.placeholder')}
          value={value}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onKeyDown={this.handleKeyDown}
        />
        <SearchIcons>
          <IconSearch
            size="28px"
            onClick={() => {
              this.handleAddSearchValueToSearchNames(value);
            }}
          />
          <IconReset
            size="28px"
            onClick={() => {
              this.handleResetSearchQuery();
            }}
          />
          <SearchBarFilter data={searchFilters.filter} onSubmit={this.handleSearchFilterChange} />
        </SearchIcons>
        {dropdownIsOpened ? (
          <SearchBarAutocomplete
            isOpen={dropdownIsOpened}
            value={autocompleteValue.value}
            matchKey={autocompleteValue.match}
            tagCategories={tagCategories}
            customFields={customFields}
            onSelect={this.handleSelectFromDropdown}
            onClose={this.handleCloseDropdown}
          />
        ) : (
          <SelectDropdown
            onSelectedIndex={selectedIndex =>
              this.setState({ searchNameSelectedIndex: selectedIndex })
            }
            value={autocompleteInputValue}
            renderItem={({ item, isSelected }) => (
              <Item
                height="40px"
                selected={isSelected}
                key={item}
                data-id={item}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.handleAddSearchValueToSearchNames(item);
                }}
                tabIndex="-1"
              >
                {item}
              </Item>
            )}
            isOpen={inputSearchDropdownIsOpened}
            onSelect={(newValue) => {
              if (newValue && newValue !== value) {
                this.handleAddSearchValueToSearchNames(newValue);
              }
            }}
            onClose={this.handleCloseDropdown}
            onClickOutside={this.handleCloseDropdown}
          />
        )}
        <Div mt="10px">
          <TagsChunk
            tagCategories={tagCategories}
            tagKeys={tagKeys}
            onDelete={this.handleDeleteTag}
          />
          {albumTag}
          {catalogTag}
          {providerTag}
          {fieldTags}
          {customFieldTags}
          {filterTags}
          {searchNameTags}
        </Div>

        {activeSearch && (
          <Flex align="center" mt="10px">
            <Chip name={job.display} handleDelete={this.props.clearSearchJob} mr="15px" />

            {uploading && (
              <MaiaNotice>
                Your file is being uploaded ({Math.round(job.uploadProgress * 100)}%)
              </MaiaNotice>
            )}

            {(job.processing || uploadDone || (!job.done && !isUpload)) && (
              <MaiaNotice>{t('components.searchBar.maiaNotice')}</MaiaNotice>
            )}
          </Flex>
        )}

        <Div hide>
          <input
            ref={(e) => {
              this.inputFile = e;
            }}
            type="file"
            onChange={this.searchUpload}
          />
        </Div>
      </Container>
    );
  }
}

SearchBar.propTypes = {
  getSearchResults: PropTypes.func.isRequired,
  removeAlbum: PropTypes.func.isRequired,
  removeCatalog: PropTypes.func.isRequired,
  removeProvider: PropTypes.func.isRequired,
  uploadAudioFile: PropTypes.func.isRequired,
  searchLink: PropTypes.func.isRequired,
  searchByName: PropTypes.func.isRequired,
  clearSearchJob: PropTypes.func.isRequired,
  search: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    job: PropTypes.object,
    query: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    prefixes: PropTypes.object,
    inputSearchQuery: PropTypes.object, // eslint-disable-line
  }).isRequired,
  tagCategories: PropTypes.array, // eslint-disable-line
  customFields: PropTypes.array, // eslint-disable-line
  t: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  tagCategories: undefined,
  customFields: undefined,
};

export default withI18n()(SearchBar);
