// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Helpers
import { enableGlobalScroll, disableGlobalScroll } from './../../../helpers/helpers';

// Styles
import { Backdrop, DialogBody, IconCross } from './Dialog.styles';

// Assets
import iconCross from '../../../assets/images/icon-cross.svg';

// =============================
// Component
// =============================

const dialogRoot = document.getElementById('dialog-root');

class Dialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    clickOutsideToClose: PropTypes.bool,
  };

  static defaultProps = {
    clickOutsideToClose: true,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentWillMount() {
    document.addEventListener('keydown', this.handleKeypress, false);
    disableGlobalScroll();
  }

  componentDidMount() {
    dialogRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress, false);
    enableGlobalScroll();
    dialogRoot.removeChild(this.el);
  }

  handleKeypress = ({ key }) => {
    const { onClose } = this.props;
    if (key !== 'Escape') return;
    onClose();
  };

  render() {
    const { onClose, children, clickOutsideToClose } = this.props;
    return ReactDOM.createPortal(
      <Backdrop
        onClick={() => {
          if (clickOutsideToClose) {
            onClose();
          }
        }}
        className="ignore-react-onclickoutside"
      >
        <DialogBody onClick={e => e.stopPropagation()}>
          <IconCross svg={iconCross} height="100%" width="100%" onClick={onClose} />
          {children}
        </DialogBody>
      </Backdrop>,
      this.el,
    );
  }
}

export default Dialog;
