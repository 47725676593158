// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from '../../../../hoc/WithI18n';

// Components
import SelectInput from './../../../inputs/selectinput/SelectInput';
import ApiSelectInput from './../../../../containers/inputs/apiselectinput/ApiSelectInputContainer';

// Helpers
import isBlank from './../../../../../helpers/isBlank';

// Constants
import { ARTIST_PANEL } from './../../../../../constants/SidePanelTypes';

// Themes
import { Flex } from './../../../../../themes/views';

// =============================
// Component
// =============================

class OwnerArtistInput extends Component {
  static propTypes = {
    ownership: PropTypes.object.isRequired, // eslint-disable-line
    errors: PropTypes.object.isRequired, // eslint-disable-line
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    onFocus: null,
    onBlur: null,
  };

  state = {};

  render() {
    const { ownership, errors, disabled, label, onChange, onFocus, onBlur, t } = this.props;

    const { artist, alias } = ownership;
    const hasAliases = !isBlank(artist && artist.aliases);
    return (
      <Flex align="center" justify="space-between" width="calc(100% - 70px)">
        <ApiSelectInput
          name="artist"
          parseName={e => e.full_name}
          label={label}
          entity="meta/artists"
          panelName={ARTIST_PANEL}
          defaultField="full_name"
          type="single"
          data={artist}
          values={artist ? artist.id : null}
          onChange={
            disabled
              ? null
              : (name, value, newArtist) => onChange({ ...ownership, artist: newArtist, alias: '' })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          error={errors.artist}
          width={hasAliases ? '48%' : '100%'}
        />
        {hasAliases && (
          <SelectInput
            type="single"
            name="alias"
            label={t('components.inputs.ownerInput.labelAlias')}
            onChange={
              disabled ? null : (name, value) => onChange({ ...ownership, alias: value || '' })
            }
            onFocus={onFocus}
            onBlur={onBlur}
            items={artist.aliases.map(a => ({ id: a, name: a }))}
            data={alias}
            search
            error={errors.alias}
            width="48%"
          />
        )}
      </Flex>
    );
  }
}

export default withI18n()(OwnerArtistInput);
