// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import { resetTextarea } from './../';

export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey};
  font-size: 12px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Container = styled.div`
  ${props => props.theme.fontSize.s};
  background-color: ${props => props.theme.colors.background};
  margin-top: 10px;
  padding: 20px;
  position: relative;
  width: 100%;
`;

export const TextArea = styled(resetTextarea)`
  background-color: ${props => props.theme.colors.background};
  height: 120px;
  text-align: center;
  width: 100%;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
`;
