// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Styles
import { Box, Icon } from './CheckBox.styles';

// Assets
import iconCheck from './../../../assets/images/icon-check.svg';


// =============================
// Component
// =============================

const CheckBox = ({ check, size, onClick, ...props }) => (
  <Box
    check={check}
    size={size}
    onClick={() => { onClick(); }}
    {...getViewProps(props)}
  >
    <Icon svg={iconCheck} height="100%" width="100%" />
  </Box>
);

CheckBox.propTypes = {
  check: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

CheckBox.defaultProps = {
  onClick: null,
  check: false,
  size: '20px',
};

export default CheckBox;
