// =============================
// Imports
// =============================

// Config
import env from '../../config/private/environment';

// Constants
import {
  OPEN_SIDE_PANEL,
  SET_PANEL_DATA,
} from './../constants/ActionTypes';

// Panels
import * as Panels from '../constants/SidePanelTypes';

// =============================
// Middleware
// =============================

export function getPanelUrl(panelName) {
  const baseURL = `${env.apiUrl}/meta`;

  switch (panelName) {
    case Panels.ALBUM_PANEL: return `${baseURL}/albums`;
    case Panels.ARTIST_PANEL: return `${baseURL}/artists`;
    case Panels.CATALOG_PANEL: return `${baseURL}/catalogs`;
    case Panels.LABEL_PANEL: return `${baseURL}/labels`;
    case Panels.PLAYLIST_PANEL: return `${baseURL}/playlists`;
    case Panels.PUBLISHER_PANEL: return `${baseURL}/publishers`;
    case Panels.TRACK_PANEL: return `${baseURL}/tracks`;
    case Panels.BATCH_TRACKS_PANEL: return `${baseURL}/tracks/batch`;
    case Panels.MULTIPART_TRACK_PANEL: return `${baseURL}/multiparts`;
    default: return '';
  }
}

const sidePanelMiddleware = store => next => (action) => {
  const panel = action.payload;

  switch (true) {
    case action.type === OPEN_SIDE_PANEL: {
      const url = getPanelUrl(panel.name);

      next(action);

      store.dispatch({
        type: SET_PANEL_DATA,
        payload: {
          url,
          uuid: panel.uuid,
        },
      });

      return null;
    }

    default:
      break;
  }

  return next(action);
};

export default sidePanelMiddleware;
