import Raven from 'raven-js';
import envConfig from './environment';

function setup() {
  if (!Raven.isSetup() && envConfig.nodeEnv === 'production' && envConfig.appEnv === 'prod') {
    Raven.config(envConfig.sentryDsn, {
      environment: envConfig.nodeEnv,
    }).install();
  }
}

export default setup;
