// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../hoc/WithI18n';
import hideable from './../../../hoc/Hideable';

// Components
import TrackList from './../../tracklist/TrackList';
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from '../../sidepanel/loader/Loader';
import CollapsibleBox from '../../collapsiblebox/CollapsibleBox';
import RatingInputBase from './../../inputs/ratinginput/RatingInput';
import SwitchInputBase from '../../inputs/switchinput/SwitchInput';
import TagListContainer from './../../../containers/taglist/TagListContainer';
import { formPanel } from '../../../hoc/Form';

// Schema
import BatchTracksSchema from './BatchTracks.schema';

// Themes
import { HeadColumn, MediaColumn } from './../../../../themes/entityGrid';
import { Div } from './../../../../themes/views';

// Styles
import { PanelContent } from './../../sidepanel/SidePanel.styles';

// =============================
// Set up
// =============================

const RatingInput = hideable(RatingInputBase);
const SwitchInput = hideable(SwitchInputBase);
const TagList = hideable(TagListContainer);

// =============================
// Component
// =============================

export class BatchTracks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      public: undefined,
      showcase: undefined,
      rating: undefined,
    };
  }


  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  handleNonDynamicChange = (name, value) => {
    this.setState({
      [name]: value,
    });

    this.props.updateForm(name, value);
  };

  updateTags = (name, value) => {
    const { panel, updateAdditionalFormData } = this.props;

    const tracksTags = panel.tracksTags || [];
    const tagList = tracksTags.filter(t => t.status === 2).map(t => t.tag.id);

    if (tagList.indexOf(value) === -1) {
      // Add tag to list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tags', method: 'POST' },
        { tracksTags: 'retrieve/tags' },
      );
    } else {
      // Remove tag from list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tags', method: 'DELETE' },
        { tracksTags: 'retrieve/tags' },
      );
    }
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    return t('components.panels.batchTracks.title');
  };

  render() {
    const { panel, hiddenFields, closePanel, openSidePanel, t } = this.props;

    const tracklist = panel.data || [];
    const tracksTags = panel.tracksTags || [];

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          title={this.renderPanelTitle()}
          closePanel={closePanel}
          panel={panel}
          isOwned
          isBatch
        />
        <CollapsibleBox title={t('components.panels.batchTracks.boxTracks')} isOpen>
          <Div overflow="auto" mb="40px">
            {tracklist.length > 0 && (
              <Div>
                <MediaColumn width="50px" />
                <HeadColumn width="200px">
                  {t('components.panels.batchTracks.tracksVersion')}
                </HeadColumn>
                <HeadColumn width="150px">
                  {t('components.panels.batchTracks.tracksVersion')}
                </HeadColumn>
                <HeadColumn width="250px">
                  {t('components.panels.batchTracks.tracksDisplayArtists')}
                </HeadColumn>
                <HeadColumn width="30px" ml="auto" pl="0" />
              </Div>
            )}
            <TrackList tracks={tracklist} disabled openSidePanel={openSidePanel} />
          </Div>
        </CollapsibleBox>
        <PanelContent justify="space-between">
          <Div width="510px" />
          <Div width="380px">
            <SwitchInput
              name="public"
              label={t('components.panels.batchTracks.labelPublic')}
              onChange={this.handleNonDynamicChange}
              value={this.state.public}
              textFalse={t('components.panels.batchTracks.publicFalse')}
              textTrue={t('components.panels.batchTracks.publicFalse')}
              mt="10px"
              show={!this.isInHideList('public')}
            />
            <SwitchInput
              name="showcase"
              label={t('components.panels.batchTracks.labelShowcase')}
              onChange={this.handleNonDynamicChange}
              value={this.state.showcase}
              textFalse={t('components.panels.batchTracks.showcaseFalse')}
              textTrue={t('components.panels.batchTracks.showcaseTrue')}
              show={!this.isInHideList('showcase')}
            />
            <RatingInput
              name="rating"
              label="Rating"
              onClick={this.handleNonDynamicChange}
              value={this.state.rating}
              mt="10px"
              show={!this.isInHideList('rating')}
            />
            <TagList
              data={tracksTags.map(tt => ({
                status: tt.status,
                value: tt.tag.id,
              }))}
              onChange={this.updateTags}
              show={!this.isInHideList('tags')}
              tagHideList={hiddenFields.filter(field => field.startsWith('categories'))}
            />
          </Div>
        </PanelContent>
      </PanelLoader>
    );
  }
}

BatchTracks.propTypes = {
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.array,
      // eslint-disable-next-line react/forbid-prop-types
      tracksTags: PropTypes.array,
    }).isRequired,
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    updateForm: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired,
    openSidePanel: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

export default formPanel(BatchTracksSchema, {}, { tracksTags: 'tags' })(withI18n()(BatchTracks));
