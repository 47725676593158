// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import sortBy from 'lodash/sortBy';

// HOC
import hideable from './../../../hoc/Hideable';
import { formPanel } from '../../../hoc/Form';
import withI18n from './../../../hoc/WithI18n';

// Components
import PanelHeader from './../../sidepanel/header/Header';
import PanelLoader from './../../sidepanel/loader/Loader';
import ImageLoader from './../../imageloader/ImageLoader';
import TrackList from './../../tracklist/TrackList';
import Tag from './../../tag/Tag';

import InputBase from './../../inputs/inputunderline/InputUnderline';
import ApiAutocompleteInput from '../../inputs/apiautocompleteinput/ApiAutocompleteInput';
import CollapsibleBoxBase from './../../collapsiblebox/CollapsibleBox';
import FileUploadBase from './../../sidepanel/fileupload/FileUpload';
import MultipleSelectBase from './../../inputs/multipleselect/MultipleSelect';
import SwitchInputBase from './../../inputs/switchinput/SwitchInput';
import SelectInputBase from '../../inputs/selectinput/SelectInput';
import TranslateAreaBase from './../../../containers/inputs/translatearea/TranslateAreaContainer';
import TagListBase from './../../../containers/taglist/TagListContainer';

// Helpers
import isBlank from './../../../../helpers/isBlank';
import { getName } from './../../../../helpers/I18n';

// Schema
import PlaylistSchema from './Playlist.schema';

// Styles
import { HeadColumn, MediaColumn } from './../../../../themes/entityGrid';
import { IconExportCsv, IconArchive } from './../../../../themes/icons';
import { Div, Flex } from './../../../../themes/views';
import { Action, Counter, CounterDesc, Count } from './../../sidepanel/header/Header.styles';

// =============================
// Set up
// =============================

const Input = hideable(InputBase);
const CollapsibleBox = hideable(CollapsibleBoxBase);
const FileUpload = hideable(FileUploadBase);
const SelectInput = hideable(SelectInputBase);
const MultipleSelect = hideable(MultipleSelectBase);
const SwitchInput = hideable(SwitchInputBase);
const TranslateArea = hideable(TranslateAreaBase);
const TagList = hideable(TagListBase);

// =============================
// Component
// =============================

const customFieldSections = ['root', 'distribution', 'picture'];

export class Playlist extends Component {
  static propTypes = {
    user: PropTypes.shape({
      customFields: PropTypes.shape({
        data: PropTypes.object, // eslint-disable-line
      }),
      sessionToken: PropTypes.string.isRequired,
    }).isRequired,
    panel: PropTypes.shape({
      url: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      data: PropTypes.object, // eslint-disable-line
      playlistTracks: PropTypes.array, // eslint-disable-line
      playlistTracksTags: PropTypes.array, // eslint-disable-line
    }).isRequired,
    updateForm: PropTypes.func.isRequired,
    updateAdditionalFormData: PropTypes.func.isRequired,
    delaySave: PropTypes.func.isRequired,
    changePlaylistTracksStatus: PropTypes.func.isRequired,
    deleteForm: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.object.isRequired, // eslint-disable-line
      errors: PropTypes.object.isRequired, // eslint-disable-line
    }).isRequired,
    closePanel: PropTypes.func.isRequired,
    exportCsv: PropTypes.func.isRequired,
    downloadArchive: PropTypes.func.isRequired,
    agents: PropTypes.array.isRequired, // eslint-disable-line
    openSidePanel: PropTypes.func.isRequired,
    tagCategories: PropTypes.array.isRequired, // eslint-disable-line
    canDownload: PropTypes.bool.isRequired,
    hiddenFields: PropTypes.array.isRequired, // eslint-disable-line
    descriptionAutocomplete: PropTypes.bool.isRequired,
    customFieldsToRightSide: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  checkIfOwned = () => {
    const { data } = this.props.panel;

    return !data || data.owned_by_tenant;
  };

  deleteTrackFromPlaylist = (trackId) => {
    const { updateAdditionalFormData } = this.props;

    updateAdditionalFormData(
      null,
      null,
      { url: `tracks/${trackId}`, method: 'DELETE' },
      { playlistTracks: 'tracks' },
    );
  };

  renderPanelTitle = () => {
    const { t } = this.props;
    const { data } = this.props.panel;

    return data && data.id ? data.title : t('components.panels.playlist.new');
  };

  renderPanelPic = () => {
    const { data } = this.props.panel;
    let imgLink;

    if (get(data, ['image', 'large'])) {
      imgLink = get(data, ['image', 'large', 'url']);
    } else if (get(data, ['image', 'original'])) {
      imgLink = get(data, ['image', 'original', 'url']);
    }

    return imgLink ? <ImageLoader src={imgLink} /> : null;
  };

  renderPanelContent = () => {
    const { t } = this.props;
    const { panel, tagCategories } = this.props;

    const playlistTracksTags = panel.playlistTracksTags || [];
    const playlistTracks = panel.playlistTracks || [];

    const getCounter = () => {
      if (playlistTracks.length >= 0) {
        return (
          <Counter>
            <CounterDesc>{t('components.panels.playlist.tracks')}</CounterDesc>
            <Count>{playlistTracks.length}</Count>
          </Counter>
        );
      }

      return null;
    };

    const getTags = (tags) => {
      const { locale } = this.props;
      let tagsList = null;
      if (tags.length) {
        tagsList = tags.map((tag) => {
          // Find color
          let color;
          for (let c = 0, catLen = tagCategories.length; c < catLen; c += 1) {
            const category = tagCategories[c];
            if (find(category.tags, tt => tt.id === tag.tag.id)) {
              ({ color } = category);
              break;
            }

            const { subCategories } = category;
            for (let s = 0, subLen = subCategories.length; s < subLen; s += 1) {
              const subCategory = subCategories[s];
              if (find(subCategory.tags, tt => tt.id === tag.tag.id)) {
                ({ color } = category);
                break;
              }
            }
          }

          return (
            <Tag
              id={tag.tag.id}
              color={color}
              key={tag.tag.id}
              label={getName(tag.tag, locale)}
              value={tag.tag.id}
              status={2}
            />
          );
        });

        return (
          <Div height="100px" overflow="auto">
            {tagsList}
          </Div>
        );
      }

      return null;
    };

    return (
      <Flex justify="space-between">
        <Div width="100px" mr="20px">
          {getCounter()}
        </Div>
        {getTags(playlistTracksTags)}
      </Flex>
    );
  };

  renderPanelActions = () => {
    const {
      exportCsv,
      changePlaylistTracksStatus,
      downloadArchive,
      panel,
      panel: { data },
      t,
    } = this.props;

    if (!data || !data.id) return null;

    return (
      <Flex direction="column">
        <Action
          onClick={() => {
            downloadArchive('playlist', data.id);
          }}
          align="center"
        >
          <IconArchive inherit />
          <Div ml="8px">{t('components.panels.playlist.downloadArchive')}</Div>
        </Action>
        <Action
          onClick={() => {
            exportCsv('playlist', data.id);
          }}
          align="center"
        >
          <IconExportCsv inherit />
          <Div ml="4px">{t('components.panels.playlist.exportCsv')}</Div>
        </Action>
        <Action
          onClick={() => {
            changePlaylistTracksStatus(panel, true);
          }}
          align="center"
        >
          <Div>{t('components.panels.playlist.makePublic')}</Div>
        </Action>
        <Action
          onClick={() => {
            changePlaylistTracksStatus(panel, false);
          }}
          align="center"
        >
          <Div>{t('components.panels.playlist.makePrivate')}</Div>
        </Action>
      </Flex>
    );
  };

  isShown = (name) => {
    const { form, panel } = this.props;

    if (startsWith(name, 'custom_fields')) {
      // eslint-disable-next-line
      name = 'custom_fields';
    }

    switch (name) {
      case 'tracks':
        return !!(panel.data && panel.data.nb_tracks > 0);
      case 'tags':
        return !!(this.checkIfOwned() && panel.data && panel.data.nb_tracks > 0);
      case 'distribution':
        return !!(this.checkIfOwned() && panel.data);
      case 'public':
        return !!(this.checkIfOwned() && panel.data);
      case 'showcase':
        return !!panel.data;
      case 'picture':
        return this.checkIfOwned() || !isBlank(get(panel, ['data', 'image', 'original']));
      case 'custom_fields':
        return !!(this.checkIfOwned() && panel.data);
      default:
        return this.checkIfOwned() || !isBlank(form.values[name]);
    }
  };

  isInHideList = (name) => {
    const { hiddenFields } = this.props;
    return hiddenFields.includes(name);
  };

  updateTags = (name, value) => {
    const { panel, updateAdditionalFormData } = this.props;

    const playlistTracksTags = panel.playlistTracksTags || [];
    const tagList = playlistTracksTags.filter(t => t.status === 2).map(t => t.tag.id);

    if (tagList.indexOf(value) === -1) {
      // Add tag to list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'POST' },
        { playlistTracksTags: 'tracks/tags' },
      );
    } else {
      // Remove tag from list
      updateAdditionalFormData(
        name,
        value,
        { url: 'tracks/tags', method: 'DELETE' },
        { playlistTracksTags: 'tracks/tags' },
      );
    }
  };

  updateCustomField = (name, value) => {
    const { updateForm, form } = this.props;

    const oldDatas = get(form, 'values.custom', {});

    updateForm('custom', { ...oldDatas, [name]: value });
  };

  renderCustomField = (field) => {
    const { t, locale, form, delaySave } = this.props;

    const fieldLocales = get(field, ['locales'], {});
    const fieldLabel = fieldLocales[locale] || field.name;

    switch (field.type) {
      case 'boolean':
        return (
          <SwitchInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            value={(form.values.custom || {})[field.key] || false}
            error={(form.errors.custom || {})[field.key] || ''}
            textFalse={t('components.panels.playlist.false')}
            textTrue={t('components.panels.playlist.true')}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="multiple"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || []}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'single_value_list': {
        const options = get(field, 'data.list_options', []);

        return (
          <SelectInput
            type="single"
            key={field.key}
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned()
              ? (n, v) => this.updateCustomField(n, v || '')
              : null
            }
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            items={options.map(o => ({ id: o, name: o }))}
            data={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || []}
            search
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );
      }

      case 'text':
        return (
          <Input
            key={field.key}
            type="text"
            name={field.key}
            label={fieldLabel}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            error={(form.errors.custom || {})[field.key] || ''}
            show={(
              this.isShown(`custom_fields.${field.key}`)
              && !this.isInHideList(`custom_fields.${field.key}`)
            )}
          />
        );

      case 'autocomplete':
        return (
          <ApiAutocompleteInput
            key={field.key}
            name={field.key}
            label={fieldLabel}
            entity={field.autocompleteApiUrl}
            isCustom={get(field, ['isCustom'], false)}
            parseName={e => e.title}
            onChange={this.checkIfOwned() ? this.updateCustomField : null}
            onFocus={() => { delaySave(true); }}
            onBlur={() => { delaySave(false); }}
            value={(form.values.custom || {})[field.key] || ''}
            sessionToken={this.props.user.sessionToken}
          />
        );

      default:
        return null;
    }
  };

  renderCustomFields = (section = null) => {
    const { t } = this.props;
    const { customFields } = this.props.user;

    const fields = get(customFields, 'data.fields', []).filter(
      (c) => {
        const isPlaylistCollection = c.collections.indexOf('playlist') >= 0;
        return !section
          ? isPlaylistCollection
          : isPlaylistCollection && c.section && c.section === section;
      },
    ).filter(
      c => section || (!section && (!c.section || !customFieldSections.includes(c.section))),
    );

    if (!fields.length) return null;

    const sortedFields = sortBy(fields, field => field.order);

    return section
      ? sortedFields.map(field => this.renderCustomField(field))
      : (
        <CollapsibleBox
          title={t('components.panels.playlist.boxCustomFields')}
          show={this.isShown('custom_fields') && !this.isInHideList('custom_fields')}
        >
          {sortedFields.map(field => this.renderCustomField(field))}
        </CollapsibleBox>
      );
  };

  render() {
    const {
      form,
      panel,
      closePanel,
      updateForm,
      delaySave,
      deleteForm,
      agents,
      openSidePanel,
      canDownload,
      hiddenFields,
      descriptionAutocomplete,
      customFieldsToRightSide,
      t,
      user,
    } = this.props;

    const playlistAgents = form.values.agents || [];
    const playlistTracksTags = panel.playlistTracksTags || [];
    const playlistTracks = panel.playlistTracks || [];

    return (
      <PanelLoader loading={panel.isLoading}>
        <PanelHeader
          back
          closePanel={closePanel}
          panel={panel}
          actions={this.renderPanelActions()}
          title={this.renderPanelTitle()}
          picture={this.renderPanelPic()}
          content={this.renderPanelContent()}
          deleteForm={deleteForm}
          isOwned={this.checkIfOwned()}
        />
        <CollapsibleBox
          title={t('components.panels.playlist.boxTracks')}
          isOpen
          counter={playlistTracks.length}
          show={this.isShown('tracks') && !this.isInHideList('tracks')}
        >
          <Div>
            <MediaColumn width="50px" />
            <HeadColumn width="200px">{t('components.panels.playlist.tracksTitle')}</HeadColumn>
            <HeadColumn width="150px">{t('components.panels.playlist.tracksVersion')}</HeadColumn>
            <HeadColumn width="250px" mr="auto">
              {t('components.panels.playlist.tracksDisplayArtists')}{' '}
            </HeadColumn>
            <HeadColumn width="30px" ml="auto" pl="0" />
            <HeadColumn width="30px" ml="auto" pl="0" />
            <HeadColumn width="30px" pl="0" />
          </Div>
          <div>
            <TrackList
              canDownload={canDownload}
              tracks={panel.playlistTracks}
              tracklistId={panel.data && panel.data.id}
              disabled={!this.checkIfOwned()}
              openSidePanel={openSidePanel}
              deleteTrackFromPlaylist={this.deleteTrackFromPlaylist}
            />
          </div>
        </CollapsibleBox>
        <Flex justify="space-between">
          <Div width="510px">
            <CollapsibleBox
              title={t('components.panels.playlist.boxPlaylist')}
              isOpen
              show={this.isShown('title') && !this.isInHideList('title')}
            >
              <Input
                type="text"
                name="title"
                label={t('components.panels.playlist.labelTitle')}
                error={form.errors.title}
                onChange={this.checkIfOwned() ? updateForm : null}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
                value={form.values.title}
              />
              {this.renderCustomFields('root')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.playlist.boxDistribution')}
              mt="5px"
              show={this.isShown('distribution') && !this.isInHideList('distribution')}
            >
              <MultipleSelect
                label={t('components.panels.playlist.labelAgents')}
                displayText={{
                  one: t('components.panels.playlist.oneAgent'),
                  multiple: t('components.panels.playlist.multipleAgents'),
                  none: t('components.panels.playlist.noneAgents'),
                  all: t('components.panels.playlist.allAgents'),
                }}
                name="agents"
                selectAll
                values={playlistAgents.map(agent => ({
                  value: agent,
                  status: 2,
                }))}
                data={agents.map(agent => ({
                  label: agent.company_name || `${agent.first_name} ${agent.last_name}`,
                  value: agent.id,
                }))}
                onChange={updateForm}
                onFocus={() => { delaySave(true); }}
                onBlur={() => { delaySave(false); }}
              />
              {this.renderCustomFields('distribution')}
            </CollapsibleBox>
            <CollapsibleBox
              title={t('components.panels.playlist.boxFiles')}
              mt="5px"
              show={this.isShown('picture') && !this.isInHideList('picture')}
            >
              <FileUpload
                disabled={!this.checkIfOwned()}
                filetype="Picture"
                panel={panel}
                type="image"
                file={get(panel, ['data', 'image', 'original'])}
                conversion_state={get(panel, ['data', 'image', 'is_converting'])}
              />
              {this.renderCustomFields('picture')}
            </CollapsibleBox>
            {!customFieldsToRightSide && this.renderCustomFields()}
          </Div>
          <Div width="380px">
            <SwitchInput
              name="public"
              label={t('components.panels.playlist.labelPublic')}
              onChange={updateForm}
              value={form.values.public}
              error={form.errors.public}
              textFalse={t('components.panels.playlist.publicFalse')}
              textTrue={t('components.panels.playlist.publicTrue')}
              show={this.isShown('public') && !this.isInHideList('public')}
            />
            <SwitchInput
              name="showcase"
              label={t('components.panels.playlist.labelShowcase')}
              onChange={updateForm}
              value={form.values.showcase}
              error={form.errors.showcase}
              textFalse={t('components.panels.playlist.showcaseFalse')}
              textTrue={t('components.panels.playlist.showcaseTrue')}
              show={this.isShown('showcase') && !this.isInHideList('showcase')}
            />
            <TranslateArea
              name="descriptions"
              label={t('components.panels.catalog.labelDescription')}
              data={form.values.descriptions}
              error={form.errors.descriptions}
              onChange={this.checkIfOwned() ? updateForm : null}
              onFocus={() => { delaySave(true); }}
              onBlur={() => { delaySave(false); }}
              mt="10px"
              show={this.isShown('descriptions') && !this.isInHideList('descriptions')}
              descriptionAutocomplete={descriptionAutocomplete}
              sessionToken={user.sessionToken}
            />
            {customFieldsToRightSide && this.renderCustomFields()}
            <TagList
              data={playlistTracksTags.map(trackTag => ({
                status: trackTag.status,
                value: trackTag.tag.id,
              }))}
              onChange={this.checkIfOwned() ? this.updateTags : null}
              mt="10px"
              show={this.isShown('tags') && !this.isInHideList('tags')}
              tagHideList={hiddenFields.filter(field => field.startsWith('categories'))}
            />
          </Div>
        </Flex>
      </PanelLoader>
    );
  }
}

export default formPanel(
  PlaylistSchema,
  {
    agents: agents => agents.map(agent => agent.id),
    tracks: tracks => tracks.map(track => track.id),
  },
  { playlistTracks: 'tracks', playlistTracksTags: 'tracks/tags' },
  { playlistTracks: 'tracks', playlistTracksTags: 'tracks/tags' },
  (values, panel) => {
    if (get(panel, 'data.owned_by_tenant') === false) {
      return {
        showcase: values.showcase,
      };
    }

    return values;
  },
)(withI18n()(Playlist));
