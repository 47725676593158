// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Styles
import { Container } from './DropdownList.styles';

// =============================
// Component
// =============================

class DropdownList extends Component {
  render() {
    const {
      isOpen,
      items,
      getRef,
      isShownOnEmpty,
      children,
      direction,
      verticalPosition,
      ...props
    } = this.props;
    return isOpen && (
      <Container
        isOpen={isOpen}
        innerRef={getRef ? e => getRef(e) : null}
        tabIndex="-1" /* Disable Tab navigation */
        isShownOnEmpty={isShownOnEmpty}
        direction={direction}
        verticalPosition={verticalPosition}
        {...getViewProps(props)}
      >
        {items ? items() : children}
      </Container>
    );
  }
}

DropdownList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.func,
  children: PropTypes.node,
  getRef: PropTypes.func,
  isShownOnEmpty: PropTypes.bool,
  direction: PropTypes.string,
  verticalPosition: PropTypes.string,
};

DropdownList.defaultProps = {
  getRef: null,
  isShownOnEmpty: false,
  items: null,
  children: null,
  direction: 'left',
  verticalPosition: 'top',
};

export default onClickOutside(DropdownList);
