// =============================
// Imports
// =============================

// External Dependencies
import isEmpty from 'lodash/isEmpty';

// Helpers
import { getName } from './I18n';

// =============================
// Helpers
// =============================

export function tagFilter(filter, locale) {
  return (tag) => {
    const synonyms = tag.synonyms.length ? tag.synonyms.find(s => s.locale === locale) : false;
    const synonymsValues = synonyms ? synonyms.values.map(v => v.toLowerCase()) : [];

    const includesInName = getName(tag, locale).toLowerCase().includes(filter.toLowerCase());
    if (includesInName) return includesInName;

    const includesInsynonyms = synonymsValues.some(value => value.includes(filter.toLowerCase()));
    return includesInsynonyms;
  };
}

let memoizedTags;
export function getTagsFromTagCategories(categories) {
  if (!isEmpty(memoizedTags)) return memoizedTags;

  memoizedTags = categories.reduce(
    (tags, category) =>
      tags.concat(category.tags, ...category.subCategories.map(subCategory => subCategory.tags)),
    [],
  );
  return memoizedTags;
}

let memoizedTagsWithCategories;
export function getTagsWithCategoriesFromTagCategories(categories) {
  if (!isEmpty(memoizedTagsWithCategories)) return memoizedTagsWithCategories;
  memoizedTagsWithCategories = categories.reduce(
    (tags, category) =>
      tags.concat(
        category.tags.map(t => ({ ...t, category })),
        ...category.subCategories.map(subCategory =>
          subCategory.tags.map(t => ({ ...t, category, subCategory })),
        ),
      ),
    [],
  );
  return memoizedTagsWithCategories;
}

let memoizedSubCategories;
export function getSubCategoriesFromTagCategories(categories) {
  if (!isEmpty(memoizedSubCategories)) return memoizedSubCategories;

  memoizedSubCategories = categories.reduce(
    (subCategories, category) => [
      ...subCategories,
      ...category.subCategories.map(sc => ({ ...sc, category })),
    ],
    [],
  );
  return memoizedSubCategories;
}
