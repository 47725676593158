// =============================
// Imports
// =============================

// External Dependencies
import styled, { css } from 'styled-components';

// Themes
import { Div } from './../../../themes/views';

// =============================
// Styled Components
// =============================

export const Container = styled(Div)`
  border-bottom: 1px solid #000;
  height: 50px;
  overflow: hidden;
  padding-bottom: 40px;
  ${props =>
    props.ultraLight &&
    css`
      height: 40px;
    `};
  ${props =>
    props.isOpen &&
    css`
      height: auto;
      overflow: visible;
    `};
`;

export const Title = styled.div`
  ${props => (props.light ? props.theme.fonts.openSans : props.theme.fonts.neutraDisplay)};
  ${props => props.theme.fontSize.xl};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: space-between;
  text-transform: uppercase;

  &:hover {
    > span {
      stroke: #000;
    }
  }

  ${props =>
    props.ultraLight &&
    css`
      ${props.theme.fonts.openSans};
      ${props.theme.fontSize.l};
      height: 40px;
    `};
`;

export const NoWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
`;

export const Count = styled.span`
  ${props => props.theme.fonts.openSans};
  ${props => props.theme.fontSize.xxs};
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  padding: 3px;
  width: 30px;
`;

export const Arrow = styled.div`
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  position: relative;
  transform: rotate(90deg);
  width: 25px;
`;

export const ArrowEl = styled.span`
  background-color: ${props => (props.isOpen ? '#000' : '#999')};
  height: 1px;
  position: absolute;
  top: 10px;
  transition: transform 0.2s ease, opacity 0.2s ease;
  width: 10px;

  &:nth-of-type(1) {
    opacity: 0;
    right: 0;
    transform-origin: bottom right;
    ${props =>
    !props.isOpen &&
      css`
        opacity: 1;
        transform: rotate(-45deg);
      `};
  }

  &:nth-of-type(2) {
    opacity: 0;
    right: 0;
    transform-origin: top right;
    ${props =>
    !props.isOpen &&
      css`
        opacity: 1;
        transform: rotate(45deg);
      `};
  }

  &:nth-of-type(3) {
    top: 10px;
    width: 25px;
  }

  &:nth-of-type(4) {
    opacity: 0;
    left: 0;
    transform-origin: bottom left;
    ${props =>
    props.isOpen &&
      css`
        opacity: 1;
        transform: rotate(-45deg);
      `};
  }

  &:nth-of-type(5) {
    opacity: 0;
    left: 0;
    transform-origin: top left;
    ${props =>
    props.isOpen &&
      css`
        opacity: 1;
        transform: rotate(45deg);
      `};
  }
`;
