// =============================
// Imports
// =============================

// External Dependencies
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Helpers
import TrackBuilder from './TrackBuilder';
import renderDate from '../../../presentationals/entitygrid/renderers/renderDate';

const dateRender = renderDate();

export default class MultipartBuilder {
  constructor(multipart, user, t, locale) {
    this.t = t;
    this.locale = locale;
    this.data = multipart;
    this.user = user;
    this.result = [];
  }

  addMainInformation() {
    this.result.push({
      label: this.t('components.print.multipart'),
      fields: [
        {
          name: this.t('components.print.title'),
          value: this.data.title,
        },
        {
          name: this.t('components.print.displayTitle'),
          value: this.data.display_title,
        },
        {
          name: this.t('components.print.version'),
          value: this.data.version ?
            TrackBuilder.getRightsType(this.data.version.names, this.t, this.locale) :
            this.t('components.print.empty'),
        },
        {
          name: this.t('components.print.rating'),
          value: this.data.rating,
        },
        {
          name: this.t('components.print.public'),
          value: this.data.public ?
            this.t('components.print.public') : this.t('components.print.private'),
        },
        {
          name: this.t('components.print.showcase'),
          value: this.data.showcase ?
            this.t('components.print.public') : this.t('components.print.private'),
        },
        {
          name: this.t('components.print.fingerprint'),
          value: this.data.fingerprint ?
            this.t('components.print.yes') : this.t('components.print.no'),
        },
        {
          name: this.t('components.print.internalTrackRef'),
          value: this.data.track_ref,
        },
        {
          name: this.t('components.print.releaseDate'),
          value: dateRender(this.data.release_date),
        },
      ],
    });
    return this;
  }

  addTracks() {
    const { tracks } = this.data;

    this.result.push({
      label: this.t('components.print.tracks'),
      fields: tracks ? tracks.map(track => ({
        name: track.display_title,
        value: [
          {
            name: '#',
            value: track.track_number,
          },
          {
            name: this.t('components.print.title'),
            value: track.display_title,
          },
          {
            name: this.t('components.print.version'),
            value: TrackBuilder.getRightsType(
              get(track.version, 'names', []),
              this.t, this.locale, this.t('components.print.empty')),
          },
          {
            name: this.t('components.print.displayArtists'),
            value: (track.display_artists || []).map(a => a.artist.full_name).join(', '),
          },

        ],
      })) : [],
    });

    return this;
  }

  addDescription() {
    this.result.push({
      label: this.t('components.print.descriptions'),
      fields: this.data.descriptions ? this.data.descriptions.map(description => ({
        name: `${this.t('components.print.description')} (${description.locale})`,
        value: description.value,
      })) : [],
    });

    return this;
  }

  addAlbum() {
    this.result.push({
      label: this.t('components.print.album'),
      fields: this.data.album ? [
        {
          name: this.t('components.print.title'),
          value: this.data.album.title,
        },
        {
          name: this.t('components.print.albumRef'),
          value: this.data.album.album_ref,
        },
      ] : [],
    });
    return this;
  }

  addDisplayArtist() {
    const artists = this.data.display_artists;

    this.result.push({
      label: this.t('components.print.displayArtists'),
      fields: artists ? artists.map(a => ({
        name: TrackBuilder.getRightsType(
          get(a, 'rights_type.names', []),
          this.t, this.locale, this.t('components.print.name')),
        value: a.artist.full_name,
      })) : [],
    });

    return this;
  }

  addMasterInfo() {
    const artists = this.data.artists_master_ownerships;
    const masterOwnerships = this.data.master_ownerships;

    this.result.push({
      label: this.t('components.print.masterInfo'),
      fields: [
        {
          name: TrackBuilder.getRightsType(
            get(this.data.original_nrs, 'rights_type.names', []),
            this.t, this.locale, this.t('components.print.original_nrs')),
          value: this.data.original_nrs ? this.data.original_nrs.name : null,
        },
        {
          name: this.t('components.print.isrc'),
          value: this.data.isrc,
        },
        {
          name: this.t('components.print.artists'),
          value: artists ? artists.map(a => ({
            name: TrackBuilder.getRightsType(
              get(a, 'rights_type.names', []),
              this.t, this.locale, this.t('components.print.artist')),
            value: [
              {
                name: this.t('components.print.name'),
                value: a.artist.full_name,
              },
              {
                name: this.t('components.print.ownershipPercentage'),
                value: a.ownership_percentage,
              },
              {
                name: this.t('components.print.syncPercentage'),
                value: a.sync_percentage,
              },
              {
                name: this.t('components.print.territories'),
                value: a.territories.join(', '),
              },
              {
                name: this.t('components.print.instruments'),
                value: a.instruments.map(instrument => TrackBuilder.getInstrument(
                  get(instrument, 'names', []),
                  this.locale)).join(', '),
              },
            ],
          })) : null,
        },
        {
          name: this.t('components.print.labels'),
          value: masterOwnerships ? masterOwnerships.map(owner => ({
            name: TrackBuilder.getRightsType(
              get(owner, 'rights_type.names', []),
              this.t, this.locale, this.t('components.print.name')),
            value: owner.label.label_name,
          })) : null,
        },
      ],
    });

    return this;
  }

  addPublishingInfo() {
    const artists = this.data.artists_publishing_ownerships;
    const publishingOwnerships = this.data.publishing_ownerships;

    this.result.push({
      label: this.t('components.print.publishingInfo'),
      fields: [
        {
          name: TrackBuilder.getRightsType(
            get(this.data.original_prs, 'rights_type.names', []),
            this.t, this.locale, this.t('components.print.original_prs')),
          value: this.data.original_prs ?
            this.data.original_prs.name : null,
        },
        {
          name: TrackBuilder.getRightsType(
            get(this.data.original_mrs, 'rights_type.names', []),
            this.t, this.locale, this.t('components.print.original_mrs')),
          value: this.data.original_mrs ?
            this.data.original_mrs.name : null,
        },
        {
          name: this.t('components.print.iswc'),
          value: this.data.iswc,
        },
        {
          name: this.t('components.print.cocv'),
          value: this.data.cocv,
        },
        {
          name: this.t('components.print.prsTunecode'),
          value: this.data.prs_tunecode,
        },
        {
          name: this.t('components.print.artists'),
          value: artists ? artists.map(a => ({
            name: TrackBuilder.getRightsType(
              get(a, 'rights_type.names', []),
              this.t, this.locale, this.t('components.print.artist')),
            value: a.artist.full_name,
          })) : null,
        },
        {
          name: this.t('components.print.publishers'),
          value: publishingOwnerships ? publishingOwnerships.map(owner => ({
            name: TrackBuilder.getRightsType(
              get(owner, 'rights_type.names', []),
              this.t, this.locale, this.t('components.print.publisher')),
            value: owner.publisher.publisher_name,
          })) : null,
        },
      ],
    });

    return this;
  }

  addTags() {
    const { tags } = this.data;

    this.result.push({
      label: this.t('components.print.tags'),
      fields: tags && !isEmpty(tags) ? [
        {
          name: this.t('components.print.tags'),
          value: tags.map(tag =>
            TrackBuilder.getRightsType(tag.tag.names, this.t, this.locale)).join(', '),
        },
      ] : [],
    });

    return this;
  }

  addDistribution() {
    const { agents } = this.data;

    this.result.push({
      label: this.t('components.print.distributions'),
      fields: agents && !isEmpty(agents) ? [
        {
          name: this.t('components.print.agents'),
          value: (agents || []).map(a => ({
            name: this.t('components.print.name'),
            value: `${a.first_name} ${a.last_name}`,
          })),
        },
      ] : [],
    });

    return this;
  }

  addFiles() {
    const { image } = this.data;
    const fields = [];

    if (image && image.original) {
      fields.push({
        name: this.t('components.print.image'),
        value: [
          {
            name: this.t('components.print.name'),
            value: image.original.file_name,
          },
          {
            name: this.t('components.print.url'),
            value: image.original.url,
          },
        ],
      });
    }

    this.result.push({
      label: this.t('components.print.files'),
      fields,
    });

    return this;
  }

  addCustomFields() {
    const { custom } = this.data;
    const customFields = get(this.user, 'customFields.data.fields', []);

    if (!custom) return this;

    this.result.push({
      label: this.t('components.print.customFields'),
      fields: Object.keys(custom).map(key => ({
        name: get(customFields.find(f => f.key === key), 'name', key),
        value: Array.isArray(custom[key])
          ? custom[key].join(', ')
          : custom[key],
      })),
    });

    return this;
  }

  build() {
    return this.result;
  }
}
