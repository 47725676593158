// =============================
// Imports
// =============================

// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// HOC
import withI18n from './../../../hoc/WithI18n';

// Styles
import { LoaderContainer, ContentLoaded } from './Loader.styles';

// =============================
// Component
// =============================

const SidePanelLoader = ({ loading, children, t }) => {
  if (loading) {
    return (
      <LoaderContainer direction="column" align="center" justify="center">
        {t('components.sidePanel.loading')}
      </LoaderContainer>
    );
  }

  return <ContentLoaded>{children}</ContentLoaded>;
};

SidePanelLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default withI18n()(SidePanelLoader);
