// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Components
import SearchBar from './../../presentationals/searchbar/SearchBar';

// Actions
import {
  getSearchResults,
  uploadAudioFile,
  searchLink,
  removeAlbum,
  removeCatalog,
  removeProvider,
  clearSearchJob,
  searchByName,
} from '../../../actions/SearchActions';

// =============================
// Component
// =============================

export default connect(
  ({ search, options, user }) => ({
    search,
    tagCategories: options.data.tags,
    customFields: user.customFields.data.fields,
  }),
  {
    getSearchResults,
    uploadAudioFile,
    searchLink,
    removeAlbum,
    removeCatalog,
    removeProvider,
    clearSearchJob,
    searchByName,
  },
)(SearchBar);
