// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

// Constants
import { HOME_PAGE_ROUTE } from './../../../constants/Routes';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import Header from './../../containers/header/HeaderContainer';
import ButtonPrimary from './../../presentationals/buttons/buttonprimary/ButtonPrimary';

// Styles
import { Content } from './NotFound.styles';
import { Flex } from './../../../themes/views';

// =============================
// Component
// =============================

class NotFoundPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    const { t } = this.props;

    return (
      <Flex minHeight="100vh" direction="column">
        <Header />
        <Helmet title={t('components.pages.notfound.pageTitle')} />
        <Content>
          <h1>{t('components.pages.notfound.pageContents')}</h1>
          <ButtonPrimary to={HOME_PAGE_ROUTE} mt="50px" width="250px">
            {t('components.pages.notfound.button')}
          </ButtonPrimary>
        </Content>
      </Flex>
    );
  }
}

export default withRouter(withI18n()(NotFoundPage));
