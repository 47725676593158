// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

// HOC
import withI18n from './../../hoc/WithI18n';

// Components
import TagCategory from './TagCategory';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Styles
import { Div, Flex } from './../../../themes/views';
import { Label, Input, IconSearch } from './TagList.styles';

// Assets
import iconSearch from '../../../assets/images/icon-search.svg';

// =============================
// Component
// =============================

class TagList extends Component {
  static propTypes = {
    tagCategories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    changeWithFilter: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onChange: null,
    onFocus: null,
    onBlur: null,
    changeWithFilter: false,
  };

  state = {
    filter: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextProps.data, this.props.data) ||
      !isEqual(nextProps.tagCategories, this.props.tagCategories) ||
      !isEqual(nextState, this.state)
    );
  }

  handleFilterChange = ({ target }) => {
    this.setState({ filter: target.value });
  };

  handleChange = (value) => {
    const { onChange, changeWithFilter, data } = this.props;

    if (onChange && !changeWithFilter) {
      onChange('tag', value);
    } else if (onChange && changeWithFilter) {
      const tags = data.filter(tag => tag.status === 2).map(tag => tag.value);

      const indexOfTag = tags.indexOf(value);
      if (indexOfTag >= 0) {
        tags.splice(indexOfTag, 1);
      } else {
        tags.push(value);
      }

      onChange('tags', tags);
    }
  };

  render() {
    const { tagCategories, data, onFocus, onBlur, t } = this.props;
    const { filter } = this.state;

    return (
      <Div {...getViewProps(this.props)}>
        <Flex align="center">
          <Label>{t('components.tagList.label')}</Label>
          <Flex width="240px" ml="auto" position="relative" mb="10px">
            <Input
              name="filter"
              placeholder={t('components.tagList.placeholder')}
              value={this.state.filter}
              onChange={this.handleFilterChange}
              onFocus={onFocus}
              onBlur={onBlur}
              hasLabel={false}
            />
            <IconSearch svg={iconSearch} height="100%" width="100%" />
          </Flex>
        </Flex>
        <Div>
          {tagCategories.map(tagCategory => (
            <TagCategory
              tagCategory={tagCategory}
              data={data}
              key={tagCategory.key}
              filter={filter}
              onChange={this.handleChange}
            />
          ))}
        </Div>
      </Div>
    );
  }
}

export default withI18n()(TagList);
