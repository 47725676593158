// =============================
// Imports
// =============================

import _get from 'lodash/get';

// Constants
import {
  GET_USER_INFO_LOADING,
  SET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  SET_USER_SESSION_TOKEN,
  GET_USER_PERMISSIONS_LOADING,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAILURE,
  SET_USER_PERMISSIONS,
  GET_USER_AGENTS_LOADING,
  GET_USER_AGENTS_SUCCESS,
  GET_USER_AGENTS_FAILURE,
  SET_USER_AGENTS,
  GET_USER_PROVIDERS_LOADING,
  GET_USER_PROVIDERS_SUCCESS,
  GET_USER_PROVIDERS_FAILURE,
  SET_USER_PROVIDERS,
  GET_CUSTOM_FIELDS_CONFIG_LOADING,
  GET_CUSTOM_FIELDS_CONFIG_SUCCESS,
  GET_CUSTOM_FIELDS_CONFIG_FAILURE,
  SET_CUSTOM_FIELDS_CONFIG,
  SET_CUSTOM_PREFERENCES,
} from './../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  sessionToken: null,
  userInfo: {
    isFetching: false,
    data: {},
  },
  permissions: {
    isFetching: false,
    data: {},
  },
  agents: {
    isFetching: false,
    data: [],
  },
  providers: {
    isFetching: false,
    data: [],
  },
  customFields: {
    isFetching: false,
    data: {},
  },
  canDownload: true,
  canDelete: false,
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  console.log(action);
  switch (action.type) {
    // Session token
    case SET_USER_SESSION_TOKEN:
      return {
        ...state,
        sessionToken: action.payload,
      };

    // Infos
    case GET_USER_INFO_LOADING:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isFetching: true,
        },
      };

    case GET_USER_INFO_SUCCESS:
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isFetching: false,
        },
      };

    case SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: action.payload,
        },
      };

    // Permissions
    case GET_USER_PERMISSIONS_LOADING:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isFetching: true,
        },
      };

    case GET_USER_PERMISSIONS_SUCCESS:
    case GET_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isFetching: false,
        },
      };

    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          data: action.payload,
        },
        canDownload: _get(action.payload, 'DOWNLOAD_BINARIES.active', true),
        canDelete: _get(action.payload, 'MANAGE_DOC_BINARIES.active', false),
      };

    // Agents
    case GET_USER_AGENTS_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isFetching: true,
        },
      };

    case GET_USER_AGENTS_SUCCESS:
    case GET_USER_AGENTS_FAILURE:
      return {
        ...state,
        agents: {
          ...state.agents,
          isFetching: false,
        },
      };

    case SET_USER_AGENTS:
      return {
        ...state,
        agents: {
          ...state.agents,
          data: action.payload,
        },
      };

    // Providers
    case GET_USER_PROVIDERS_LOADING:
      return {
        ...state,
        providers: {
          ...state.providers,
          isFetching: true,
        },
      };

    case GET_USER_PROVIDERS_SUCCESS:
    case GET_USER_PROVIDERS_FAILURE:
      return {
        ...state,
        providers: {
          ...state.providers,
          isFetching: false,
        },
      };

    case SET_USER_PROVIDERS:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: action.payload,
        },
      };
    // Custom Fields
    case GET_CUSTOM_FIELDS_CONFIG_LOADING:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: true,
        },
      };

    case GET_CUSTOM_FIELDS_CONFIG_SUCCESS:
    case GET_CUSTOM_FIELDS_CONFIG_FAILURE:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: false,
        },
      };

    case SET_CUSTOM_FIELDS_CONFIG:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          data: action.payload,
        },
      };

    case SET_CUSTOM_PREFERENCES:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            meta_ui_settings: {
              ...state.userInfo.data.meta_ui_settings,
              preferences: {
                ...state.userInfo.data.meta_ui_settings.preferences,
                ...action.payload,
              },
            },
          },
        },
      };

    default: return state;
  }
}
