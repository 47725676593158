// =============================
// Imports
// =============================

// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import PlaylistAdderDropdown from './../../containers/playlistadder/PlaylistAdderDropdownContainer';

// Helpers
import { getViewProps } from '../../../helpers/helpers';

// Themes
import { IconPlaylist } from './../../../themes/icons';
import { Flex } from './../../../themes/views';

// Styles
import { Action } from './../sidepanel/header/Header.styles';

// =============================
// Component
// =============================

// TODO: remove this component, just use PlaylistAdderDropdown, and move the
//   button outside of the component. Also right now this component is only used
//   for single track addition, not batch track addition

class PlaylistAdder extends Component {
  static propTypes = {
    sessionToken: PropTypes.string.isRequired,
    tracks: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    closeText: PropTypes.string,
    openText: PropTypes.string,
    direction: PropTypes.string,
    verticalPosition: PropTypes.string,
  };
  static defaultProps = {
    closeText: '',
    openText: '',
    verticalPosition: 'top',
    direction: 'right',
  };

  state = {
    open: false,
  };

  togglePlaylistAdder = (nextState) => {
    this.setState({ open: nextState });
  };

  render() {
    const { open } = this.state;
    const { openText, closeText, verticalPosition, direction, tracks } = this.props;

    const renderToggleButton = () => {
      if (!open) {
        return (
          <Action
            onClick={() => {
              this.togglePlaylistAdder(true);
            }}
          >
            <IconPlaylist inherit={openText} light={!openText} size="18px" />
            {openText && <Flex ml="5px">{openText}</Flex>}
          </Action>
        );
      }
      return (
        <Action
          className="playlistAdder"
          onClick={() => {
            this.togglePlaylistAdder(false);
          }}
        >
          <IconPlaylist inherit={closeText} light={!closeText} size="18px" />
          {closeText && <Flex ml="5px">{closeText}</Flex>}
        </Action>
      );
    };

    return (
      <Flex position="relative" width="100%" {...getViewProps(this.props)}>
        {renderToggleButton()}
        <PlaylistAdderDropdown
          trackIds={tracks}
          isOpen={open}
          direction={direction}
          verticalPosition={verticalPosition}
          onClose={() => this.togglePlaylistAdder(false)}
        />
      </Flex>
    );
  }
}

export default PlaylistAdder;
